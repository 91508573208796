import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { getUrlData } from '../actions';
import Grid from '@material-ui/core/Grid';
import { fetchError } from '../../../redux/actions';
import HeaderPageSupplements from '../../../components/HeaderPageSupplements';

import General from '../components/crawl/General';
import Indexability from '../components/crawl/Indexability';
import Canonical from '../components/crawl/Canonical';
import Redirects from '../components/crawl/Redirects';
import ExternalLinks from '../components/crawl/ExternalLinks';
import Headers from '../components/crawl/Headers';
import SeoTags from '../components/crawl/SeoTags';
import InternalLinks from '../components/crawl/InternalLinks';
import PageWeight from '../components/crawl/PageWeight';
import Ssl from '../components/crawl/Ssl';
import LoadTime from '../components/crawl/LoadTime';
import OriginsOfPage from '../components/crawl/OriginsOfPage';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const UrlViewer = props => {
  const history = useHistory();

  const urlParams = new URLSearchParams(props.location.search);

  const { setBreadcrumb } = useContext(AppContext);
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const [data, setData] = useState(null);
  const [value, setValue] = useState(urlParams.has('tab') ? parseInt(urlParams.get('tab')) : 0);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    const urlParams = new URLSearchParams(props.location.search);
    urlParams.set('tab', newValue);

    history.replace({
      pathname: props.location.pathname,
      search: '?' + urlParams.toString(),
    });

    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const url = decodeURIComponent(props.match.params.url);

    setBreadcrumb([{ text: url }]);

    if (project && project.id) {
      let hiddenFilters = [];

      if (urlParams.has('hiddenFilters')) {
        hiddenFilters = JSON.parse(urlParams.get('hiddenFilters'));
      }

      getUrlData(project.id, hiddenFilters, url)
        .then(result => {
          setData(result['hydra:member'][0]);
          setLoading(false);

          const newValue = urlParams.has('tab') ? parseInt(urlParams.get('tab')) : 0;
          if (value !== newValue) {
            setValue(newValue);
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.url, urlParams.get('hiddenFilters')]);

  const url = decodeURIComponent(props.match.params.url);
  const hiddenFilters = urlParams.has('hiddenFilters') ? JSON.parse(urlParams.get('hiddenFilters')) : [];

  return (
    <div className="app-wrapper">
      <Backdrop open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!loading && !data && (
        <ListEmptyResult
          title={<IntlMessages id="bundles.urlViewer.emptyPrompt.title" />}
          content={<IntlMessages id="bundles.urlViewer.emptyPrompt.text" />}
        />
      )}

      {!loading && data && (
        <Grid container spacing={GRID_SPACING}>
          <Grid md={12} item>
            <HeaderPageSupplements
              numbers={[
                {
                  label: <IntlMessages id="bundles.urlViewer.text.status" />,
                  number: !data.checks.was_redirected
                    ? data.response.firstStatusCode + ' ' + data.response.statusText
                    : data.response.firstStatusCode,
                },
                {
                  label: <IntlMessages id="bundles.urlViewer.text.time" />,
                  number: data.performance.navigation.totalLoadTime / 1000 + ' s',
                },
                {
                  label: <IntlMessages id="bundles.urlViewer.text.size" />,
                  number: (data.performance.dom.documentEncodedSize / 1024).toFixed(2) + ' KB',
                },
              ]}
              actions={[]}
            />
          </Grid>

          <Grid md={12} item>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.general" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.headers" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.headingTags" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.originsOfPage" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.internalLinks" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.externalLinks" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.loadTime" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.pageWeight" />} />
              <Tab label={<IntlMessages id="bundles.urlViewer.subtitle.ssl" />} />
            </Tabs>
          </Grid>

          <Grid md={12} item>
            <TabPanel value={value} index={0}>
              <Grid container spacing={GRID_SPACING}>
                <Grid md={12} item>
                  <General data={data} />
                </Grid>
                <Grid md={6} item>
                  <Indexability data={data} />
                </Grid>
                <Grid md={6} item>
                  <Canonical data={data} />
                </Grid>
                <Grid md={12} item>
                  <Redirects data={data} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Headers data={data} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              {data.checks.was_redirected && (
                <Box mb={6}>
                  <Alert severity="info" style={{ marginBottom: 0 }}>
                    <IntlMessages id="bundles.urlViewer.text.dataFromRedirectPage" />
                  </Alert>
                </Box>
              )}

              <SeoTags data={data} />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <OriginsOfPage projectId={project.id} hiddenFilters={hiddenFilters} url={url} />
            </TabPanel>

            <TabPanel value={value} index={4}>
              {data.checks.was_redirected && (
                <Box mb={6}>
                  <Alert severity="info" style={{ marginBottom: 0 }}>
                    <IntlMessages id="bundles.urlViewer.text.dataFromRedirectPage" />
                  </Alert>
                </Box>
              )}

              <InternalLinks internalLinks={data.content.internal_links} />
            </TabPanel>

            <TabPanel value={value} index={5}>
              {data.checks.was_redirected && (
                <Box mb={6}>
                  <Alert severity="info" style={{ marginBottom: 0 }}>
                    <IntlMessages id="bundles.urlViewer.text.dataFromRedirectPage" />
                  </Alert>
                </Box>
              )}

              <ExternalLinks externalLinks={data.content.external_links} />
            </TabPanel>

            <TabPanel value={value} index={6}>
              <LoadTime data={data} />
            </TabPanel>

            <TabPanel value={value} index={7}>
              <PageWeight data={data} />
            </TabPanel>

            <TabPanel value={value} index={8}>
              <Ssl data={data} />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UrlViewer;

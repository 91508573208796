import React, { useEffect } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import CardTheme from '../components/PricesList/CardTheme';
import RadioTheme from '../components/PricesList/RadioTheme';
import PriceCalculator from '../components/PriceCalculator';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetCurrentPrices, handleGetPrices } from '../actions';
import { STRIPE_PRODUCTS } from '../../constants';

const PlanIndex = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { currentPrices, selectedPrices, prices } = useSelector(({ payment }) => payment);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleGetPrices(authUser.paymentInterval));
    dispatch(handleGetCurrentPrices(authUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={9} lg={9}>
          {STRIPE_PRODUCTS.map((arr, i) => (
            <div>
              <Box mb={6}>
                <Typography variant="h1">
                  <IntlMessages id={'bundles.checkout.index.text.choose_' + arr.code} />
                </Typography>
              </Box>

              {'CardTheme' === arr.component && (
                <CardTheme
                  description={arr.description}
                  productCode={arr.code}
                  frequency={authUser.paymentInterval}
                  currentPlan={currentPrices}
                  selectedPlan={selectedPrices}
                  plans={prices[arr.code]}
                />
              )}

              {'RadioTheme' === arr.component && (
                <RadioTheme
                  description={arr.description}
                  productCode={arr.code}
                  frequency={authUser.paymentInterval}
                  currentPlan={currentPrices}
                  selectedPlan={selectedPrices}
                  plans={prices[arr.code]}
                />
              )}
            </div>
          ))}
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box mb={6}>
            <Typography variant="h2">
              <IntlMessages id="bundles.checkout.index.text.validate_plan" />
            </Typography>
          </Box>

          <Box>
            <PriceCalculator />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlanIndex;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import qs from 'qs';
import axios from 'axios';
import { SERVER_URL } from '../../constants/UploadApiConstants';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const HistoryFileProgress = props => {
  const [progression, setProgression] = useState(0);

  let interval = useRef(null);

  /**
   * Triggered on component mount.
   * Refresh data & start the interval.
   */
  useEffect(() => {
    refresh();

    interval.current = setInterval(refresh, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Triggered on component unmount.
   * Stop the interval.
   */
  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const refresh = async () => {
    const projectId = props.projectId;
    const key = 'project_' + projectId + '.historyFile_' + props.entity.id + '.linesReadedCount';
    const payload = {
      key: key,
    };

    const result = await axios.get(SERVER_URL + 'cache/get?' + qs.stringify(payload));

    let progressionNew = (result.data / props.entity.linesCount) * 100;

    if (progressionNew > 100) {
      progressionNew = 100;
    }

    setProgression(progressionNew);

    if (result.data >= props.entity.linesCount) {
      clearInterval(interval.current);
    }
  };

  return <LinearProgressWithLabel value={progression} />;
};

export default HistoryFileProgress;

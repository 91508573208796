export const INTERNAL_LINKS_SCHEMA = {
  table: 'internal_links',
  fields: [
    {
      dimension: 'href',
      label: 'dimension.internal_links.href',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
    {
      dimension: 'text',
      label: 'dimension.internal_links.text',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
    {
      dimension: 'rel',
      label: 'dimension.internal_links.rel',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'statusCode',
      label: 'dimension.internal_links.statusCode',
      type: 'number',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'nbLinks',
      label: 'dimension.internal_links.nbLinks',
      type: 'number',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
  ],
  order: null,
  orderBy: null,
  hasTimeDimension: false,
};

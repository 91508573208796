import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const CANONICAL_TAG_PRESENCE_BREAKDOWN = {
  '@id': 'CANONICAL_TAG_PRESENCE_BREAKDOWN',
  name: 'bundles.crawl.chart.canonical_tag_presence_breakdown.title',
  description: null,
  x: 0,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['contentCanonicalTagsCountString'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'desc',
    orderBy: 'count',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      /**
       * data indexes:
       *
       * 0 : 'No canonical tag',
       * 1 : 'Exactly 1',
       * 2 : 'More than 1',
       */
      let data = [0, 0, 0];
      let datasets = {};
      let backgroundColor = ['#1976d2', '#4caf50', '#dc004e'];

      rows.map(arr => {
        if (arr['contentCanonicalTagsCountString'].includes('No ')) {
          data[0] = arr.count;
        } else if (arr['contentCanonicalTagsCountString'].includes('Exactly 1')) {
          data[1] = arr.count;
        } else if (arr['contentCanonicalTagsCountString'].includes('More than 1')) {
          data[2] = arr.count;
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      return ['No canonical tag', 'Exactly 1', 'More than 1'];
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const value = chartData.labels[element[0].index];

      let filters = [
        {
          label: 'dimension.crawled_pages.checks_is_html',
          dimension: 'checks_is_html',
          operator: 'equals',
          values: [true],
        },
        {
          label: 'dimension.crawled_pages.checks_was_redirected',
          dimension: 'checks_was_redirected',
          operator: 'equals',
          values: [false],
        },
      ];

      switch (value) {
        case 'No canonical tag':
          filters.push({
            label: 'dimension.crawled_pages.content_canonical_tags_count',
            dimension: 'content_canonical_tags_count',
            operator: 'equals',
            values: [0],
          });
          break;
        case 'Exactly 1':
          filters.push({
            label: 'dimension.crawled_pages.content_canonical_tags_count',
            dimension: 'content_canonical_tags_count',
            operator: 'equals',
            values: [1],
          });
          break;
        case 'More than 1':
          filters.push({
            label: 'dimension.crawled_pages.content_canonical_tags_count',
            dimension: 'content_canonical_tags_count',
            operator: 'gt',
            values: [1],
          });
          break;
        default:
        //
      }

      return {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'checks_has_canonical',
          'content_canonical',
          'content_canonical_tags_count',
          'response_firstStatusCode',
        ],
        filters: filters,
      };
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

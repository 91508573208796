import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const LINKS_STATUS_BREAKDOWN = {
  '@id': 'LINKS_STATUS_BREAKDOWN',
  name: 'bundles.crawl.chart.links_status_breakdown.title',
  description: null,
  x: 0,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'internal_links',
    dimensions: ['statusCode'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['nbLinksTotal'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'asc',
    orderBy: 'statusCode',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    options: {
      //responsive: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

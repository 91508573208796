import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';

import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import IntlMessages from '@jumbo/utils/IntlMessages';

import { redirectReset } from '../../../../redux/actions';

const useStyles = makeStyles({
  icon: {
    color: red[500],
    fontSize: 38,
    marginBottom: '12px',
  },
  title: {
    marginBottom: '12px',
  },
});

const CheckoutFailedPage = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  dispatch(redirectReset());

  return (
    <React.Fragment>
      <AuthWrapper variant={'bgColor'}>
        <Box p={8} align={'center'} style={{ width: '100%' }}>
          <Box align={'center'} mb={6}>
            <ErrorIcon className={classes.icon} />

            <Typography className={classes.title} variant="h1" gutterBottom>
              <IntlMessages id="bundles.checkout.routes.checkoutFailed.title" />
            </Typography>

            <Box className={classes.description} mb={6}>
              <IntlMessages id="bundles.checkout.routes.checkoutFailed.body" />
            </Box>

            <Link to={'/checkout'}>
              <Button color="primary" variant={'outlined'}>
                Retry
              </Button>
            </Link>
          </Box>
        </Box>
      </AuthWrapper>
    </React.Fragment>
  );
};

export default CheckoutFailedPage;

import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box, Paper, Typography } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';

import CrawlProfileForm from '../form/CrawlProfileForm';
import CreateConfirmationModal from '../components/CreateConfirmationModal';
import {
  handleGetCrawlProfile,
  handleGetCrawlProfiles,
  handleClearCrawlProfile,
  handleCreateCrawlProfile,
  handleUpdateCrawlProfile,
} from '../actions';
import Grid from '@material-ui/core/Grid';

const CrawlProfileCreatePage = props => {
  const history = useHistory();
  const { setBreadcrumb } = useContext(AppContext);
  const { authUser } = useSelector(({ auth }) => auth);
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const { crawlProfilesList, crawlProfilesListLoading, crawlProfile } = useSelector(({ crawlProfile }) => crawlProfile);

  // modal confirmation
  const [openCreateConfirmation, setOpenCreateConfirmation] = useState(false);
  const [formValue, setFormValue] = useState({});

  // profile selector
  const [profilesValue, setProfilesValue] = useState(null);
  const [profilesOptions, setProfilesOptions] = useState([]);

  // sorting
  const orderBy = 'name';
  const order = 'asc';

  const dispatch = useDispatch();

  /**
   * Refresh values in the Profile Selector when:
   *
   * - a Profile is selected
   *
   * @param newValue
   * @param actionMeta
   */
  const handleChange = (newValue, actionMeta) => {
    if (null === newValue) {
      setProfilesValue(null);
      // clear profile (will create a new profile after save)
      dispatch(handleClearCrawlProfile());
    } else {
      setProfilesValue(newValue);
      // change profile
      dispatch(handleGetCrawlProfile(newValue.value));
    }
  };

  /**
   * Create, update Profile
   *
   * @param authUser
   * @param values
   */
  const handleSubmit = (authUser, values) => {
    if ('' === values.settings.maxUrls) {
      values.settings.maxUrls = null;
    }

    if ('' === values.settings.maxDepth) {
      values.settings.maxDepth = null;
    }

    if ('' === values.settings.crawlInterval) {
      values.settings.crawlInterval = null;
    }

    setFormValue(values);

    if (crawlProfile && crawlProfile.id) {
      // update...
      // update profile
      dispatch(handleUpdateCrawlProfile(crawlProfile.id, project.id, values, history));
    } else {
      // creation...
      for (const obj of crawlProfilesList['hydra:member']) {
        // check if a profile already exists with the same name yes
        if (obj.name === values.name) {
          // yes, open modal
          setOpenCreateConfirmation(true);
          // stop, do not create profile
          return;
        }
      }

      // create profile
      dispatch(handleCreateCrawlProfile(authUser, project.id, values, history));
    }
  };

  const createProfile = () => {
    console.debug(`[createProfile] execution started`);
    setOpenCreateConfirmation(false);

    // suffix profile name to avoid duplicates
    console.debug(`[createProfile] suffix profile name to avoid duplicates`);
    formValue.name = `${formValue.name} (1)`;

    dispatch(handleCreateCrawlProfile(authUser, project.id, formValue, history));
  };

  const updateProfile = () => {
    console.debug(`[updateProfile] execution started`);
    setOpenCreateConfirmation(false);

    let id = null;

    for (const obj of crawlProfilesList['hydra:member']) {
      // find crawl profile with the same name
      if (obj.name === formValue.name) {
        id = obj.id;
        console.debug(`[updateProfile] crawl profile #${id} with the same name found`);
        break;
      }
    }

    if (null === id) {
      throw new Error(`[updateProfile] crawl profile ID with name "${formValue.name}" not found`);
    }

    dispatch(handleUpdateCrawlProfile(id, project.id, formValue, history));
  };

  /**
   * Refresh Profile list on startup
   */
  useEffect(() => {
    const orderObj = {};
    orderObj[orderBy] = order;

    const payload = {
      project: project.id,
      order: orderObj,
    };

    dispatch(handleGetCrawlProfiles(payload));

    if (props.match.params.id !== undefined) {
      dispatch(handleGetCrawlProfile(props.match.params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  /**
   * Refresh Profile list and
   * Refresh value in the Profile Selector when:
   *
   * - a Profile is created
   * - a Profile is updated
   * - a Profile is deleted
   * - a Profile is selected
   */
  useEffect(() => {
    const orderObj = {};
    orderObj[orderBy] = order;

    const payload = {
      project: project.id,
      order: orderObj,
    };

    dispatch(handleGetCrawlProfiles(payload));

    if (crawlProfile.id) {
      setBreadcrumb([
        {
          to: '/crawls',
          text: <IntlMessages id="bundles.crawl.routes.index.title" />,
        },
        { text: <IntlMessages id="bundles.crawlProfile.routes.edit.title" /> },
      ]);

      setProfilesValue({
        value: crawlProfile.id,
        label: crawlProfile.name,
      });
    } else {
      setBreadcrumb([
        {
          to: '/crawls',
          text: <IntlMessages id="bundles.crawl.routes.index.title" />,
        },
        { text: <IntlMessages id="bundles.crawlProfile.routes.create.title" /> },
      ]);

      setProfilesValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlProfile]);

  useEffect(() => {
    let options = [];
    for (const obj of crawlProfilesList['hydra:member']) {
      options.push({
        value: obj.id,
        label: obj.name,
      });
    }

    setProfilesOptions(options);
  }, [crawlProfilesList]);

  return (
    <div className="app-wrapper">
      <Box mb={GRID_SPACING}>
        <Grid container spacing={GRID_SPACING} alignContent={'center'} alignItems={'center'} justify={'flex-start'}>
          <Grid item>
            <Typography variant="h1">
              <IntlMessages id="bundles.crawlProfile.routes.create.title" />
            </Typography>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 220 }}>
              <Select
                placeholder={'Default profile'}
                defaultValue={crawlProfile.id || null}
                value={profilesValue}
                isClearable={true}
                isLoading={crawlProfilesListLoading}
                options={profilesOptions}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Paper>
        <Box p={6}>
          <CrawlProfileForm
            id={crawlProfile.id || null}
            projectId={project.id}
            data={{
              name: crawlProfile.name || 'Default profile',
              segmentationProfile: crawlProfile.segmentationProfile || null,
              settings: crawlProfile.settings || '',
            }}
            onSubmit={handleSubmit}
          />
        </Box>
      </Paper>

      <CreateConfirmationModal
        open={openCreateConfirmation}
        data={formValue}
        handleYes={updateProfile}
        handleNo={createProfile}
        handleClose={() => setOpenCreateConfirmation(false)}
      />
    </div>
  );
};

export default CrawlProfileCreatePage;

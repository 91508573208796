import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const H1_LENGTH_BY_GROUP = {
  '@id': 'H1_LENGTH_BY_GROUP',
  name: 'bundles.crawl.chart.h1_length_by_group.title',
  description: null,
  x: 0,
  y: 16,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'contentH1LengthString'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
      {
        dimension: 'checks_has_h1',
        operator: 'equals',
        values: [true],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let dataPerfect = [];
      let dataEmpty = [];
      let dataTooShort = [];
      let dataTooLong = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        dataPerfect.push(0);
        dataEmpty.push(0);
        dataTooShort.push(0);
        dataTooLong.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        if (arr['contentH1LengthString'].includes('Perfect')) {
          dataPerfect[i] = arr.count;
        } else if (arr['contentH1LengthString'].includes('Empty')) {
          dataEmpty[i] = arr.count;
        } else if (arr['contentH1LengthString'].includes('short')) {
          dataTooShort[i] = arr.count;
        } else if (arr['contentH1LengthString'].includes('long')) {
          dataTooLong[i] = arr.count;
        }

        return true;
      });

      labels.map((label, i) => {
        const total = dataPerfect[i] + dataEmpty[i] + dataTooShort[i] + dataTooLong[i];

        dataPerfect[i] = (dataPerfect[i] / total) * 100;
        dataEmpty[i] = (dataEmpty[i] / total) * 100;
        dataTooShort[i] = (dataTooShort[i] / total) * 100;
        dataTooLong[i] = (dataTooLong[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: 'Perfect',
          data: dataPerfect,
          backgroundColor: '#4caf50',
        },
        {
          label: 'Empty',
          data: dataEmpty,
          backgroundColor: '#ff9800',
        },
        {
          label: 'Too short',
          data: dataTooShort,
          backgroundColor: '#f44336',
        },
        {
          label: 'Too long',
          data: dataTooLong,
          backgroundColor: '#dc004e',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let contentH1LengthString = chartData.datasets[element[0].datasetIndex].label; // 'Too long'

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.crawled_pages.contentH1LengthString',
        dimension: 'contentH1LengthString',
        operator: 'equals',
        values: [contentH1LengthString],
      });

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'content_h1', 'content_h1_chars_count', 'response_firstStatusCode'],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'contentTitleLengthString',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

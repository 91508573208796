export const PAGES_WITH_1_INLINK = {
  '@id': 'PAGES_WITH_1_INLINK',
  name: 'bundles.crawl.chart.pages_with_1_inlink.title',
  description: null,
  x: 0,
  y: 12,
  w: 4,
  h: 4,
  query: {
    table: 'internal_links',
    dimensions: [],
    dimensionsFunctions: [],
    groupBy: ['href'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count_over'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [
      {
        dimension: 'SUM(nbLinks)',
        operator: '=',
        values: [1],
      },
    ],
    order: null,
    orderBy: null,
    limit: 1,
    offset: 0,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'internal_links',
        groupBy: ['href'],
        ungrouped: 0,
        filters: [],
        measures: ['nbLinksTotal'],
        aggregationFilters: [
          {
            dimension: 'SUM(nbLinks)',
            operator: '=',
            values: [1],
          },
        ],
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

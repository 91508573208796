import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box, Paper, Typography } from '@material-ui/core';

import CrawlScheduleForm from '../form/CrawlScheduleForm';
import { handleGetCrawlSchedule, handleUpdateCrawlSchedule } from '../actions';
import Grid from '@material-ui/core/Grid';
import { DAYS_WEEK, FREQUENCY_CUSTOM, FREQUENCY_DAY, FREQUENCY_HOUR, FREQUENCY_WEEK } from '../constants';
import moment from 'moment';

const CrawlScheduleEditPage = props => {
  const history = useHistory();
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const { crawlSchedule } = useSelector(({ crawlSchedule }) => crawlSchedule);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleGetCrawlSchedule(props.match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update Crawl Schedule
   *
   * @param authUser
   * @param values
   */
  const handleSubmit = (authUser, values, dayWeek, selectedDate) => {
    let cronExpression = null;

    switch (values.frequency) {
      case FREQUENCY_HOUR:
        cronExpression = `${moment(selectedDate).format('m')} * * * *`;

        break;
      case FREQUENCY_DAY:
        cronExpression = `${moment(selectedDate).format('m')} ${moment(selectedDate).format('H')} * * *`;

        break;
      case FREQUENCY_WEEK:
        cronExpression = `${moment(selectedDate).format('m')} ${moment(selectedDate).format('H')} * * ${
          DAYS_WEEK[dayWeek].value
        }`;

        break;
      case FREQUENCY_CUSTOM:
        // nothing to do

        break;
      default:
      // nothing to do
    }

    if (cronExpression) {
      values.cronExpression = cronExpression;
    }

    dispatch(handleUpdateCrawlSchedule(props.match.params.id, project.id, values, history));
  };

  return (
    <div className="app-wrapper">
      <Box mb={GRID_SPACING}>
        <Grid container spacing={GRID_SPACING} alignContent={'center'} alignItems={'center'} justify={'flex-start'}>
          <Grid item>
            <Typography variant="h1">
              <IntlMessages id="bundles.crawlSchedule.routes.edit.title" />
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Paper>
        <Box p={6}>
          <CrawlScheduleForm
            id={crawlSchedule.id}
            projectId={project.id}
            data={{
              cronExpression: crawlSchedule.cronExpression || null,
              frequency: crawlSchedule.frequency || '',
              profile:
                crawlSchedule.hasOwnProperty('profile') && crawlSchedule.profile.hasOwnProperty('@id')
                  ? crawlSchedule.profile['@id']
                  : '',
            }}
            onSubmit={handleSubmit}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default CrawlScheduleEditPage;

import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import MeasuresListGrouped from '../../../../components/Drawer/MeasuresList';

/**
 * This component displays a list of filters.
 */
const FiltersList = props => {
  return (
    <MeasuresListGrouped
      schema={props.schema}
      values={[]}
      handleClickMeasure={props.handleAddFilter}
      handleClickAddIcon={props.handleAddFilter}
      handleClickDeleteIcon={() => {}}
    />
  );
};

FiltersList.propTypes = {
  schema: PropTypes.array.isRequired,
  handleAddFilter: PropTypes.func.isRequired,
};

export default React.memo(FiltersList);

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Button, Grid } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { Box } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Formik } from 'formik';
import { validateSegmentProfile } from '../validation/SegmentProfile';
import { handleDeleteSegmentationProfile } from '../actions';
import SegmentationRule from '../components/SegmentationRule';

import Typography from '@material-ui/core/Typography';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const Rules = SortableContainer(({ rules, segmentationStats, handleAddRule, handleRemoveRule, handleRuleInputChange }) => {
  return (
    <Box>
      {rules.map((rule, index) => (
        <SegmentationRule
          key={index}
          index={index}
          i={index}
          totalItems={rules.length}
          values={rule}
          urlsFound={
            segmentationStats.labels.indexOf(rule.name) !== -1
              ? segmentationStats.data[segmentationStats.labels.indexOf(rule.name)]
              : 0
          }
          urlsFoundPercent={
            segmentationStats.labels.indexOf(rule.name) !== -1
              ? (100 * segmentationStats.data[segmentationStats.labels.indexOf(rule.name)]) / segmentationStats.crawled
              : 0
          }
          handleAddRule={handleAddRule}
          handleRemoveRule={handleRemoveRule}
          handleRuleInputChange={handleRuleInputChange}
        />
      ))}
    </Box>
  );
});

const SegmentProfileForm = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { segmentationProfile, segmentationStats } = useSelector(({ segmentation }) => segmentation);

  let params = JSON.parse(JSON.stringify(props.data.rules));

  const [inputList, setInputList] = useState(params);

  const dispatch = useDispatch();

  // handle click event of the Add button
  const handleAddRule = () => {
    //setFieldValue('startCrawl', 0, false);
    setInputList([...inputList, { name: '', pattern: '' }]);
  };

  // handle click event of the Remove button
  const handleRemoveRule = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle input change
  const handleRuleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setInputList(arrayMove(inputList, oldIndex, newIndex));
  };

  useEffect(() => {
    if (Object.keys(segmentationProfile).length > 0) {
      setInputList(segmentationProfile.rules);
    } else {
      setInputList([{ name: '', pattern: '' }]);
    }
  }, [segmentationProfile]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      validate={values => validateSegmentProfile(values)}
      onSubmit={values => props.onSubmit(authUser, values)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <InputLabel>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <IntlMessages id="bundles.segmentation.entity.profile.name.label" />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <IntlMessages id={'bundles.segmentation.entity.profile.name.help'} />
                      </React.Fragment>
                    }>
                    <HelpIcon color={'action'} fontSize={'small'} className={'ml-1'} />
                  </Tooltip>
                </Grid>
              </Grid>
            </InputLabel>
            <TextField
              id="name"
              name="name"
              placeholder={'My profile'}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              variant={'outlined'}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <IntlMessages id="bundles.segmentation.entity.profile.rules.label" />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <IntlMessages id={'bundles.segmentation.entity.profile.rules.help'} />
                      </React.Fragment>
                    }>
                    <HelpIcon color={'action'} fontSize={'small'} className={'ml-1'} />
                  </Tooltip>
                </Grid>
              </Grid>
            </InputLabel>

            <Box pt={3} pb={3}>
              <Rules
                rules={inputList}
                segmentationStats={segmentationStats}
                onSortEnd={onSortEnd}
                handleAddRule={handleAddRule}
                handleRemoveRule={handleRemoveRule}
                handleRuleInputChange={handleRuleInputChange}
                useDragHandle={true}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} name="updateCrawlData" />}
                label={<IntlMessages id="bundles.segmentation.entity.profile.updateData.label" />}
              />
            </Box>

            <Typography className={'form-help'} variant="body2" color={'textSecondary'}>
              <IntlMessages id="bundles.segmentation.entity.profile.updateData.help" />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {props.handleCancel ? (
              <Button variant="outlined" color="primary" onClick={props.handleCancel}>
                <IntlMessages id="bundles.segmentation.action.cancel" />
              </Button>
            ) : (
              <BackButton variant="outlined" color="primary">
                <IntlMessages id="bundles.segmentation.action.cancel" />
              </BackButton>
            )}

            <Box ml={6} clone>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  setFieldValue('rules', inputList, false);
                  handleSubmit();
                }}>
                <IntlMessages id="bundles.segmentation.action.save" />
              </Button>
            </Box>

            {props.id && (
              <Box ml={6} clone>
                <Button
                  type="button"
                  variant="contained"
                  color="danger"
                  onClick={() => dispatch(handleDeleteSegmentationProfile(props.projectId, props.id))}>
                  <IntlMessages id="bundles.segmentation.action.delete" />
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default SegmentProfileForm;

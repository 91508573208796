import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const DEFAULT_FILTERS = [];
const DEFAULT_HIDDEN_FILTERS = [];

const FilteringContainer = props => {
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [addFilter, setAddFilter] = useState(false);
  const [addFilterSchema, setAddFilterSchema] = useState(null);
  const [editedFilter, setEditedFilter] = useState(null);

  const qsParameterName = 'filters';
  const [filters, setFilters] = useState(
    urlParams.has(qsParameterName) ? JSON.parse(urlParams.get(qsParameterName)) : DEFAULT_FILTERS,
  );

  // hidden filters
  const qsParameterName2 = 'hiddenFilters';
  const [hiddenFilters, setHiddenFilters] = useState(
    urlParams.has(qsParameterName2) ? JSON.parse(urlParams.get(qsParameterName2)) : DEFAULT_HIDDEN_FILTERS,
  );

  /**
   * Triggered on component mount.
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    setLoading(true);
    setFiltersOpen(false);

    const newFilters = urlParams.has(qsParameterName) ? JSON.parse(urlParams.get(qsParameterName)) : DEFAULT_FILTERS;
    if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
      setFilters(newFilters);
    }

    setAddFilter(false);
    setAddFilterSchema(null);
    setEditedFilter(null);

    const newHiddenFilters = urlParams.has(qsParameterName2)
      ? JSON.parse(urlParams.get(qsParameterName2))
      : DEFAULT_HIDDEN_FILTERS;
    if (JSON.stringify(hiddenFilters) !== JSON.stringify(newHiddenFilters)) {
      setHiddenFilters(newHiddenFilters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {string} dimension
   */
  const handleAddFilter = dimension => {
    let value = null;

    for (let i = 0; i < props.schema.length; i++) {
      if (dimension === props.schema[i]['dimension']) {
        value = props.schema[i];
        break;
      }

      if (
        props.schema[i].hasOwnProperty('array') &&
        props.schema[i].hasOwnProperty('fields') &&
        true === props.schema[i].array
      ) {
        for (let j = 0; j < props.schema[i].fields.length; j++) {
          if (dimension === props.schema[i].fields[j]['dimension']) {
            value = props.schema[i].fields[j];
            break;
          }
        }
      }
    }

    setAddFilter(true);
    setAddFilterSchema(value);
  };

  const handleEditFilter = index => {
    let filterToEdit = filters[index];
    filterToEdit['index'] = index;

    let value = null;

    for (let i = 0; i < props.schema.length; i++) {
      if (filterToEdit.dimension === props.schema[i]['dimension']) {
        value = props.schema[i];
        break;
      }

      if (
        props.schema[i].hasOwnProperty('array') &&
        props.schema[i].hasOwnProperty('fields') &&
        true === props.schema[i].array
      ) {
        for (let j = 0; j < props.schema[i].fields.length; j++) {
          if (filterToEdit.dimension === props.schema[i].fields[j]['dimension']) {
            value = props.schema[i].fields[j];
            break;
          }
        }
      }
    }

    if (null === value) {
      throw new Error(
        `[FilteringContainer][handleEditFilter] Cannot find the edited filter, please if props.schema is correctly filled`,
      );
    }

    setFiltersOpen(true);
    setAddFilter(true);
    setEditedFilter(filterToEdit);
    setAddFilterSchema(value);
  };

  const handleCancelAddFilter = () => {
    setAddFilter(false);
    setAddFilterSchema(null);
    setEditedFilter(null);
  };

  const handleSaveFilter = (label, dimension, operator, value, editIndex = null, cb) => {
    const urlParams = new URLSearchParams(location.search);

    let newFilters = [];

    if (urlParams.has(qsParameterName)) {
      newFilters = JSON.parse(urlParams.get(qsParameterName));
    }

    if (null === editIndex) {
      newFilters.push({
        label: label,
        dimension: dimension,
        operator: operator,
        values: [value],
      });
    } else {
      newFilters[editIndex] = {
        label: label,
        dimension: dimension,
        operator: operator,
        values: [value],
      };
    }

    urlParams.set(qsParameterName, JSON.stringify(newFilters));

    history.push({
      pathname: location.pathname,
      search: '?' + urlParams.toString(),
    });

    setFiltersOpen(false);
    setFilters(newFilters);
    setAddFilter(false);
    setAddFilterSchema(null);
    setEditedFilter(null);

    if (cb) {
      cb();
    }
  };

  const handleDeleteFilter = (index, cb) => {
    const urlParams = new URLSearchParams(location.search);

    let newFilters = [];

    if (urlParams.has(qsParameterName)) {
      newFilters = JSON.parse(urlParams.get(qsParameterName));
    }

    newFilters.splice(index, 1);

    urlParams.set(qsParameterName, JSON.stringify(newFilters));

    history.push({
      pathname: location.pathname,
      search: '?' + urlParams.toString(),
    });

    setFilters(newFilters);

    if (cb) {
      cb();
    }
  };

  const handleOpenFilteringDialog = () => {
    setFiltersOpen(true);
  };

  const handleCloseFilteringDialog = () => {
    setFiltersOpen(false);
    setAddFilter(false);
    setEditedFilter(null);
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        loading: loading,

        filtersOpen: filtersOpen,
        addFilter: addFilter,
        setAddFilter: setAddFilter,
        addFilterSchema: addFilterSchema,
        setAddFilterSchema: setAddFilterSchema,
        editedFilter: editedFilter,
        setEditedFilter: setEditedFilter,
        filters: filters,
        setFilters: setFilters,
        hiddenFilters: hiddenFilters,

        handleAddFilter: handleAddFilter,
        handleEditFilter: handleEditFilter,
        handleCancelAddFilter: handleCancelAddFilter,
        handleSaveFilter: handleSaveFilter,
        handleDeleteFilter: handleDeleteFilter,
        handleOpenFilteringDialog: handleOpenFilteringDialog,
        handleCloseFilteringDialog: handleCloseFilteringDialog,
      })}
    </React.Fragment>
  );
};

FilteringContainer.propTypes = {
  schema: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

FilteringContainer.whyDidYouRender = {
  logOnDifferentValues: true,
};

export default FilteringContainer;

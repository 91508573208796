import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Alert } from '@material-ui/lab';
import { Button, Box } from '@material-ui/core';

class FailedAlert extends React.Component {
  render() {
    return (
      <Alert severity="error" style={{ marginBottom: 0 }}>
        <Box mb={6}>
          <h3>
            <IntlMessages id="pages.logAnalyzer.installScript.title.failed" />
          </h3>
        </Box>

        <Box mb={6}>
          <IntlMessages id="pages.logAnalyzer.installScript.description.failed" />
        </Box>

        <Box mb={6} pl={4}>
          <ol>
            <li>
              <p>
                <IntlMessages id="pages.logAnalyzer.installScript.failed.step.1.title" />
              </p>
            </li>
            <li>
              <p>
                <IntlMessages id="pages.logAnalyzer.installScript.failed.step.2.title" />
              </p>
            </li>
            <li>
              <p>
                <IntlMessages id="pages.logAnalyzer.installScript.failed.step.3.title" />
              </p>
            </li>
          </ol>
        </Box>

        <p className="mb-0">
          <Button variant="contained" color="primary" onClick={this.props.handleStartValidateInstall}>
            <IntlMessages id="pages.logAnalyzer.installScript.step.5.btn.retry" />
          </Button>
        </p>
      </Alert>
    );
  }
}

export default FailedAlert;

import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const NON_2XX_CANONICAL_BY_GROUP = {
  '@id': 'NON_2XX_CANONICAL_BY_GROUP',
  name: 'bundles.crawl.chart.non_2xx_canonical_by_group.title',
  description: null,
  x: 0,
  y: 20,
  w: 12,
  h: 8,
  query: {
    table: 'canonicals',
    dimensions: ['category', 'statusCode'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['countUniqueCanonicalUrl'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let goodStatusCodeData = [];
      let badStatusCodeData = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        goodStatusCodeData.push(0);
        badStatusCodeData.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        if (arr.statusCode > 299) {
          badStatusCodeData[i] = arr.countUniqueCanonicalUrl;
        } else {
          goodStatusCodeData[i] = arr.countUniqueCanonicalUrl;
        }

        return true;
      });

      labels.map((label, i) => {
        const total = badStatusCodeData[i] + goodStatusCodeData[i];

        badStatusCodeData[i] = (badStatusCodeData[i] / total) * 100;
        goodStatusCodeData[i] = (goodStatusCodeData[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: '2xx canonical pages',
          data: goodStatusCodeData,
          backgroundColor: '#4caf50',
        },
        {
          label: 'Non-2xx canonical pages',
          data: badStatusCodeData,
          backgroundColor: '#dc004e',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let status = chartData.datasets[element[0].datasetIndex].label; // '2xx canonical pages'

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.canonicals.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });

      if ('2xx canonical pages' === status) {
        filters.push({
          label: 'dimension.canonicals.statusCode',
          dimension: 'statusCode',
          operator: 'lte',
          values: [299],
        });
      } else {
        filters.push({
          label: 'dimension.canonicals.statusCode',
          dimension: 'statusCode',
          operator: 'gte',
          values: [300],
        });
      }

      return {
        schemaName: 'canonicals',
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'statusCode',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

/**
 * Checks if a item is active.
 *
 * @param {string} dimension
 * @param {array} values
 * @returns {boolean}
 */
export const isItemSelected = (dimension, values) => {
  return values.indexOf(dimension) < 0 ? false : true;
};

const MeasuresListItem = props => {
  if (props.obj.hasOwnProperty('array') && props.obj.hasOwnProperty('fields') && true === props.obj.array) {
    return null;
  }

  return (
    <div className={isItemSelected(props.obj.dimension, props.values) ? 'list-item-explorer active' : 'list-item-explorer'}>
      <a
        type={'button'}
        className="label"
        href={'#/'}
        onClick={() => {
          props.handleClickMeasure(props.obj);
        }}>
        <img
          className="icon"
          src={`/images/datatypes/${props.obj.type}.png`}
          alt={props.obj.type}
          style={{ opacity: 0.2 }}
        />
        <h4 className="title">
          <IntlMessages id={props.obj.label} />
        </h4>
      </a>

      <div className="field-extra">
        {props.children}

        {props.values.indexOf(props.obj.dimension) >= 0 ? (
          <ClearIcon
            className="icon-close"
            color={'inherit'}
            fontSize={'small'}
            onClick={() => {
              props.handleClickDeleteIcon(props.obj);
            }}
          />
        ) : (
          <AddIcon
            className="icon-close"
            color={'inherit'}
            fontSize={'small'}
            onClick={() => {
              props.handleClickAddIcon(props.obj);
            }}
          />
        )}
      </div>
    </div>
  );
};

MeasuresListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  obj: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  handleClickMeasure: PropTypes.func.isRequired,
  handleClickAddIcon: PropTypes.func.isRequired,
  handleClickDeleteIcon: PropTypes.func.isRequired,
};

const MeasuresList = props => {
  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={'styled-list'}>
      {props.schema.map((obj, i) => (
        <MeasuresListItem
          key={i}
          children={props.children}
          obj={obj}
          values={props.values}
          handleClickMeasure={props.handleClickMeasure}
          handleClickAddIcon={props.handleClickAddIcon}
          handleClickDeleteIcon={props.handleClickDeleteIcon}
        />
      ))}
    </List>
  );
};

MeasuresList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  schema: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  handleClickMeasure: PropTypes.func.isRequired,
  handleClickAddIcon: PropTypes.func.isRequired,
  handleClickDeleteIcon: PropTypes.func.isRequired,
};

const MeasuresListGrouped = props => {
  return (
    <Box p={0}>
      <Box p={3}>
        <MeasuresList
          schema={props.schema}
          values={props.values}
          handleClickMeasure={props.handleClickMeasure}
          handleClickAddIcon={props.handleClickAddIcon}
          handleClickDeleteIcon={props.handleClickDeleteIcon}
        />
      </Box>

      {props.schema.map((obj, i) => (
        <React.Fragment key={i}>
          {obj.hasOwnProperty('array') && obj.hasOwnProperty('fields') && true === obj.array && (
            <React.Fragment>
              <Divider light={true} />

              <Box p={3}>
                <Typography variant="h6" gutterBottom={true}>
                  <IntlMessages id={obj.label} />
                </Typography>

                <MeasuresList
                  schema={obj.fields}
                  values={props.values}
                  handleClickMeasure={props.handleClickMeasure}
                  handleClickAddIcon={props.handleClickAddIcon}
                  handleClickDeleteIcon={props.handleClickDeleteIcon}
                />
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MeasuresListGrouped;

import React from 'react';
import { Alert } from '@material-ui/lab';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const AlertNoSubscription = () => {
  return (
    <Alert severity="warning">
      You do not have a current subscription.
      <Box ml={1} clone>
        <Link to={'/products'}>
          <Button variant="contained" color="primary" size="small">
            Select a plan
          </Button>
        </Link>
      </Box>
    </Alert>
  );
};

export default AlertNoSubscription;

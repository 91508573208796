import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import InstallScript from '../InstallScript/InstallScript';
import SendLogFtp from './SendLogFtp';
import UploadLog from './UploadLog';
import HistoryList from '../logsHistory/components/HistoryList';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const LogAnalyzerConfigure = props => {
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
    },
    [ project ]
  );

  const refresh = async () => {
    //
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  if (!project || !project.id) {
    return null;
  }

  return (
    <Grid container alignItems="flex-start" item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="disabled tabs example">
          <Tab label="Analyse de logs temps réel" />
          <Tab label="Send by FTP" />
          <Tab label="Upload" />
        </Tabs>

        <Paper>
          <Box p={6}>
            <TabPanel value={tab} index={0}>
              <InstallScript projectId={project.id} />
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <SendLogFtp projectId={project.id} />
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <UploadLog projectId={project.id} />
            </TabPanel>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <HistoryList project={project} store={props.store} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LogAnalyzerConfigure;

import { LIST_CRAWL_SCHEDULES, GET_CRAWL_SCHEDULE } from '../constants';

const INIT_STATE = {
  crawlSchedulesList: {
    'hydra:member': [],
  },
  crawlSchedule: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CRAWL_SCHEDULES: {
      return {
        ...state,
        crawlSchedulesList: action.payload,
      };
    }
    case GET_CRAWL_SCHEDULE: {
      return {
        ...state,
        crawlSchedule: action.payload,
      };
    }
    default:
      return state;
  }
};

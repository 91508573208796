import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectForm from '../form/ProjectForm';
import { handleGetProject, handleUpdateProject } from '../actions';

const ProjectEdit = props => {
  const { project } = useSelector(({ project }) => project);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleGetProject(props.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectForm
      data={{
        name: project.name,
        domain: project.domain,
        region: project.region,
      }}
      onSubmit={(authUser, values) => dispatch(handleUpdateProject(project.id, values))}
      disableDomainField={true}
      disableRegionField={true}
    />
  );
};

export default ProjectEdit;

export const TOTAL_INBOUND_LINK = {
  '@id': 'TOTAL_INBOUND_LINK',
  name: 'bundles.crawl.chart.total_inbound_links.title',
  description: null,
  x: 0,
  y: 0,
  w: 6,
  h: 4,
  query: {
    table: 'internal_links',
    dimensions: [],
    dimensionsFunctions: [],
    groupBy: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['nbLinksTotal'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: 1,
    offset: 0,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'internal_links',
        columns: ['href', 'category', 'text', 'rel', 'statusCode', 'nbLinks'],
        filters: [],
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import AlertCanceled from './alerts/canceled';
import AlertIncomplete from './alerts/incomplete';
import AlertIncompleteExpired from './alerts/incomplete_expired';
import AlertNoSubscription from './alerts/noSubscription';
import AlertPastDue from './alerts/past_due';
import AlertTrialing from './alerts/trialing';
import AlertUnpaid from './alerts/unpaid';

const HeaderSubscriptionStatus = props => {
  // trial_end: 1610892747
  //trial_start: 1609596747
  if (!props.subscriptionUser) {
    return <AlertNoSubscription />;
  }

  switch (props.subscriptionUser.status) {
    case 'active':
      return null;
    case 'incomplete':
      return <AlertIncomplete />;
    case 'incomplete_expired':
      return <AlertIncompleteExpired />;
    case 'past_due':
      return <AlertPastDue />;
    case 'unpaid':
      return <AlertUnpaid />;
    case 'canceled':
      return <AlertCanceled />;
    case 'trialing':
      return <AlertTrialing subscriptionUser={props.subscriptionUser} />;
    default:
      return null;
  }
};

export default HeaderSubscriptionStatus;

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from '@material-ui/core';

class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toolTip: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.toggleToolTip(true);

    clearTimeout(this.toggleTimeout);
    this.toggleTimeout = setTimeout(() => {
      this.toggleToolTip(false);
    }, 3000);
  }

  toggleToolTip(enabled) {
    this.setState({
      toolTip: enabled,
    });
  }

  render() {
    return (
      <Tooltip title="Copied!" open={this.state.toolTip} placement="right">
        <CopyToClipboard text={this.props.valueToCopy}>
          <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
            <Button appearance="default" onClick={this.onClick}>
              <i className="zmdi zmdi-copy mr-2" /> Copy to clipboard
            </Button>
          </div>
        </CopyToClipboard>
      </Tooltip>
    );
  }
}

export default CopyButton;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CRAWL_DASHBOARDS_SIDEBAR } from 'bundles/crawl/constants';
import IntlMessages from '../../../../utils/IntlMessages';

/**
 * Return the crawl ID according to the querystring and the project.
 *
 * @param project
 * @param location
 * @returns {null|number}
 */
export function getCrawlId(project, location) {
  const urlParams = new URLSearchParams(location.search);

  // get project ID
  const projectId = project && project.id ? project.id : '';

  // get crawl ID
  let newCrawlId = null;

  // if there is a crawl identifier in the querystring, this crawl ID takes priority,
  // otherwise if the project contains crawls, use the crawl ID of the last crawl.
  if (urlParams.has('crawlId') && urlParams.get('crawlId')) {
    newCrawlId = parseInt(urlParams.get('crawlId'));
  } else if (projectId && project.hasOwnProperty('crawls') && project.crawls.length > 0) {
    newCrawlId = project.crawls[0].split('/').pop();
  }

  return newCrawlId;
}

/**
 * Crawl ID customized hook
 */
export function useCrawlId() {
  const location = useLocation();
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const [crawlId, setCrawlId] = useState(getCrawlId(project, location));

  useEffect(() => {
    // get crawl ID
    const newCrawlId = getCrawlId(project, location);

    if (crawlId !== newCrawlId) {
      setCrawlId(newCrawlId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, crawlId, location]);

  return [crawlId, setCrawlId];
}

/**
 * Return crawl dashboards menus
 *
 * @param {number} projectId
 * @param {null|object} crawl
 */
export function getCrawlSubMenus(projectId, crawl) {
  let crawlDashboards = [];

  if (null === crawl) {
    return crawlDashboards;
  }

  for (let i = 0; i < CRAWL_DASHBOARDS_SIDEBAR.length; i++) {
    let children = [];

    switch (CRAWL_DASHBOARDS_SIDEBAR[i].type) {
      case 'collapse':
        for (let j = 0; j < CRAWL_DASHBOARDS_SIDEBAR[i].children.length; j++) {
          let link = `/dashboards/view/${CRAWL_DASHBOARDS_SIDEBAR[i].children[j].id}?predefined=1&module=crawled_pages&projectId=${projectId}&crawlId=${crawl.id}`;

          children.push({
            name: <IntlMessages id={CRAWL_DASHBOARDS_SIDEBAR[i].children[j].name} />,
            type: 'item',
            link: link,
          });
        }

        crawlDashboards.push({
          name: <IntlMessages id={CRAWL_DASHBOARDS_SIDEBAR[i].name} />,
          type: 'collapse',
          children: children,
        });

        break;

      case 'item':
        let link = `/dashboards/view/${CRAWL_DASHBOARDS_SIDEBAR[i].children[0].id}?predefined=1&module=crawled_pages&projectId=${projectId}&crawlId=${crawl.id}`;

        crawlDashboards.push({
          name: <IntlMessages id={CRAWL_DASHBOARDS_SIDEBAR[i].name} />,
          type: 'item',
          link: link,
        });

        break;

      default:
      // nothing to do
    }
  }

  let filters = [];

  let hiddenFilters = [
    {
      dimension: 'crawlId',
      operator: 'equals',
      values: [crawl.partitionId],
      label: 'bundles.dataExplorer.table.head.crawlId',
    },
  ];

  crawlDashboards.push({
    name: <IntlMessages id={'sidebar.crawl.exploreData'} />,
    type: 'item',
    link: `/log_analyzer/explorer?projectId=${projectId}&schema=crawled_pages&filters=${JSON.stringify(
      filters,
    )}&hiddenFilters=${JSON.stringify(hiddenFilters)}`,
  });

  return crawlDashboards;
}

import { SUMMARY } from './dashboards/summary';
import { INDEXABILITY } from './dashboards/indexability';
import { STATUS_CODES } from './dashboards/status_codes';
import { CANONICAL } from './dashboards/canonical';
import { HEADING_TAGS } from './dashboards/heading_tags';
import { TITLE } from './dashboards/title';
import { DESCRIPTION } from './dashboards/description';
import { CANONICAL_TAG } from './dashboards/canonical_tag';
import { LOAD_TIME } from './dashboards/load_time';
import { PAGE_SIZE } from './dashboards/page_size';
import { CATEGORY } from './dashboards/category';
import { INBOUND_LINKS } from './dashboards/inbound_links';
import { EXTERNAL_LINKS } from './dashboards/external_links';
import { INTERNAL_LINKS } from './dashboards/internal_links';
import { LINKS_FLOW } from './dashboards/links_flow';

export const GET_CRAWL = 'get_crawl';
export const RESET_CRAWLS = 'reset_crawls';

export const CRAWL_STATUS = ['pending', 'running', 'finished', 'stopped', 'failed'];
export const CRAWL_ACTIVE_STATUS = ['pending', 'running'];

export const CRAWL_DASHBOARDS = [
  SUMMARY,
  INDEXABILITY,
  STATUS_CODES,
  CANONICAL,
  CATEGORY,
  TITLE,
  DESCRIPTION,
  HEADING_TAGS,
  CANONICAL_TAG,
  INBOUND_LINKS,
  EXTERNAL_LINKS,
  INTERNAL_LINKS,
  LINKS_FLOW,
  LOAD_TIME,
  PAGE_SIZE,
];

export const CRAWL_DASHBOARDS_SIDEBAR = [
  {
    name: 'sidebar.crawl.dashboard.summary',
    type: 'item',
    children: [SUMMARY],
  },
  {
    name: 'sidebar.crawl.dashboard.indexability',
    type: 'collapse',
    children: [INDEXABILITY, CANONICAL, STATUS_CODES],
  },
  {
    name: 'sidebar.crawl.dashboard.seoTags',
    type: 'collapse',
    children: [TITLE, DESCRIPTION, HEADING_TAGS, CANONICAL_TAG],
  },
  {
    name: 'sidebar.crawl.dashboard.linking',
    type: 'collapse',
    children: [INTERNAL_LINKS, EXTERNAL_LINKS, INBOUND_LINKS, LINKS_FLOW],
  },
  {
    name: 'sidebar.crawl.dashboard.performances',
    type: 'collapse',
    children: [LOAD_TIME, PAGE_SIZE],
  },
];

import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const CANONICAL_TAG_BREAKDOWN_BY_GROUP = {
  '@id': 'CANONICAL_TAG_BREAKDOWN_BY_GROUP',
  name: 'bundles.crawl.chart.canonical_tag_breakdown_by_group.title',
  description: null,
  x: 0,
  y: 12,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'content_canonical_status'],
    dimensionsFunctions: [],
    groupBy: ['content_canonical_status'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
      {
        dimension: 'checks_has_canonical',
        operator: 'equals',
        values: [true],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let dataMatching = [];
      let dataNotMatching = [];
      let dataEmpty = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        dataMatching.push(0);
        dataNotMatching.push(0);
        dataEmpty.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        switch (arr.content_canonical_status) {
          case 'matching':
            dataMatching[i] = arr.count;
            break;
          case 'not matching':
            dataNotMatching[i] = arr.count;
            break;
          case 'empty':
            dataEmpty[i] = arr.count;
            break;
          default:
        }

        return true;
      });

      labels.map((label, i) => {
        const total = dataMatching[i] + dataNotMatching[i] + dataEmpty[i];

        dataMatching[i] = (dataMatching[i] / total) * 100;
        dataNotMatching[i] = (dataNotMatching[i] / total) * 100;
        dataEmpty[i] = (dataEmpty[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: 'pages declaring to be the canonical version',
          data: dataMatching,
          backgroundColor: '#1976d2',
        },
        {
          label: 'pages declaring another page to be the canonical version',
          data: dataNotMatching,
          backgroundColor: '#dc004e',
        },
        {
          label: 'pages with empty "href" attribute in canonical tag',
          data: dataEmpty,
          backgroundColor: '#ca6702',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let content_canonical_status = chartData.datasets[element[0].datasetIndex].label; // 'pages declaring to be the canonical version'

      const labels = [
        'pages declaring to be the canonical version',
        'pages declaring another page to be the canonical version',
        'pages with empty "href" attribute in canonical tag',
      ];

      const codes = ['matching', 'not matching', 'empty'];

      const i = labels.indexOf(content_canonical_status);
      content_canonical_status = codes[i];

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.crawled_pages.content_canonical_status',
        dimension: 'content_canonical_status',
        operator: 'equals',
        values: [content_canonical_status],
      });

      return {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'checks_has_canonical',
          'content_canonical',
          'content_canonical_status',
          'response_firstStatusCode',
        ],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'content_canonical_status',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { OPEN_MODAL, CLOSE_MODAL, SELECT_PROJECT, LIST_PROJECTS, CLEAR_PROJECT } from '../constants';
import { RESET_CRAWL_PROFILES } from '../../crawlProfile/constants';
import { RESET_CRAWLS } from '../../crawl/constants';
import { getProjectList, getProject } from 'services/api/MyApiUtil';

export const openModal = () => {
  return dispatch => {
    dispatch({
      type: OPEN_MODAL,
    });
  };
};

export const closeModal = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };
};

export const selectProject = (newValue, location, history) => {
  return dispatch => {
    dispatch(fetchStart());

    getProject(newValue)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: SELECT_PROJECT,
          payload: result,
        });
        dispatch({
          type: RESET_CRAWL_PROFILES,
        });
        dispatch({
          type: RESET_CRAWLS,
        });

        // redirect
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('projectId', result.id);

        history.replace({
          pathname: location.pathname,
          search: '?' + urlParams.toString(),
        });

        // if a new project is selected from a crawl dashboard,
        // then redirect to the crawls list.
        const regex = new RegExp('^/dashboards/view.*');

        if (regex.test(location.pathname)) {
          history.push(`/crawls?projectId=${result.id}`);
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleClearProject = (location, history) => {
  return dispatch => {
    dispatch({
      type: CLEAR_PROJECT,
    });

    /**
     * remove `projectId` from querystring
     */
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('projectId');

    history.push({
      pathname: location.pathname,
      search: '?' + urlParams.toString(),
    });
  };
};

export const handleGetProjects = payload => {
  return dispatch => {
    dispatch(fetchStart());

    getProjectList(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: LIST_PROJECTS,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

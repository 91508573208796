import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { LIST_DASHBOARDS, GET_DASHBOARD } from '../constants';
import { getDashboardList, getDashboard, getCrawl, getLatestCrawl } from 'services/api/MyApiUtil';

import { CRAWL_DASHBOARDS } from 'bundles/crawl/constants';
import { LOG_ANALYZER_DASHBOARDS } from 'bundles/logAnalyzer/constants';
import { dataExplorerHelpers } from '../../logAnalyzer/LogsExplorer/constants';

export const handleGetDashboards = payload => {
  return dispatch => {
    dispatch(fetchStart());

    getDashboardList(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: LIST_DASHBOARDS,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleGetDashboard = id => {
  return dispatch => {
    dispatch(fetchStart());

    getDashboard(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_DASHBOARD,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleGetCrawlPredefinedDashboard = (projectId, dashboardId, crawlId) => {
  return async dispatch => {
    dispatch(fetchStart());

    let hiddenFilters = [];
    let crawl = {};

    try {
      if ('latest' === crawlId) {
        crawl = await getLatestCrawl(projectId);
      } else {
        crawl = await getCrawl(crawlId);
      }
    } catch (e) {
      //
    }

    if (crawl) {
      hiddenFilters = dataExplorerHelpers['crawler'].getDefaultHiddenFilters({
        partitionId: crawl.partitionId,
      });
    }

    for (let i = 0; i < CRAWL_DASHBOARDS.length; i++) {
      if (CRAWL_DASHBOARDS[i].id === dashboardId) {
        CRAWL_DASHBOARDS[i].project = projectId;
        for (let j = 0; j < CRAWL_DASHBOARDS[i].questions.length; j++) {
          CRAWL_DASHBOARDS[i].questions[j].project = projectId;
          CRAWL_DASHBOARDS[i].questions[j].query.hiddenFilters = hiddenFilters;
        }

        dispatch(fetchSuccess());
        dispatch({
          type: GET_DASHBOARD,
          payload: CRAWL_DASHBOARDS[i],
        });
      }
    }
  };
};

export const handleGetPredefinedDashboard = (projectId, id, module) => {
  return async dispatch => {
    dispatch(fetchStart());

    if ('logs' === module) {
      for (let i = 0; i < LOG_ANALYZER_DASHBOARDS.length; i++) {
        if (LOG_ANALYZER_DASHBOARDS[i].id === id) {
          LOG_ANALYZER_DASHBOARDS[i].project = projectId;
          for (let j = 0; j < LOG_ANALYZER_DASHBOARDS[i].questions.length; j++) {
            LOG_ANALYZER_DASHBOARDS[i].questions[j].project = projectId;
          }

          dispatch(fetchSuccess());
          dispatch({
            type: GET_DASHBOARD,
            payload: LOG_ANALYZER_DASHBOARDS[i],
          });
        }
      }
    }
  };
};

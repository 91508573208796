import { handleDeleteProject } from '../../../../project/actions';

export const MOST_LINKED_EXTERNAL_HOSTS = {
  '@id': 'MOST_LINKED_EXTERNAL_HOSTS',
  name: 'bundles.crawl.chart.most_linked_external_hosts.title',
  description: null,
  x: 0,
  y: 4,
  w: 12,
  h: 10,
  query: {
    table: 'crawled_pages',
    dimensions: ['content.external_links.host'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'desc',
    orderBy: 'count',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'table',
    hover: true,

    handleClick: function(projectId, hiddenFilters, row, history) {
      const config = {
        schemaName: 'crawled_pages',
        columns: ['url', 'content.external_links.host', 'content.external_links.href', 'content.external_links.text'],
        filters: [
          {
            label: 'dimension.crawled_pages.content.external_links.host',
            dimension: 'content.external_links.host',
            operator: 'equals',
            values: [row['content.external_links.host']],
          },
          {
            label: 'dimension.crawled_pages.checks_is_html',
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.checks_was_redirected',
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
        ],
        orderBy: 'url',
        order: 'asc',
      };

      let to =
        '/log_analyzer/explorer?projectId=' +
        projectId +
        '&schema=' +
        config.schemaName +
        '&filters=' +
        JSON.stringify(config.filters);

      if (hiddenFilters.length > 0) {
        to += '&hiddenFilters=' + JSON.stringify(hiddenFilters);
      }
      if (config.hasOwnProperty('columns') && config.columns.length > 0) {
        to += '&columns=' + JSON.stringify(config.columns);
      }

      if (config.hasOwnProperty('groupBy') && config.groupBy.length > 0) {
        to += '&groupBy=' + JSON.stringify(config.groupBy);
      }

      if (config.hasOwnProperty('measures') && config.measures.length > 0) {
        to += '&measures=' + JSON.stringify(config.measures);
      }

      if (config.hasOwnProperty('aggregationFilters') && config.aggregationFilters.length > 0) {
        to += '&aggregationFilters=' + JSON.stringify(config.aggregationFilters);
      }

      if (config.hasOwnProperty('orderBy')) {
        to += '&orderBy=' + config.orderBy;
      }

      if (config.hasOwnProperty('order')) {
        to += '&order=' + config.order;
      }

      if (config.hasOwnProperty('ungrouped')) {
        to += '&ungrouped=' + config.ungrouped;
      }

      history.push(to);
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

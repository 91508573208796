import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Chip from '@material-ui/core/Chip';
import { CRAWL_STATUS } from '../constants';

/**
 * This component properly displays the status of a crawl.
 */
const CrawlStatusBadge = props => {
  let color = null;
  let style = null;
  let label = null;

  const url = `/dashboards/view/summary?predefined=1&module=crawled_pages&projectId=${props.projectId}&crawlId=${props.id}`;

  switch (props.status) {
    case 'pending':
      color = 'secondary';
      style = { backgroundColor: '#6c757d' };
      label = <IntlMessages id={'bundles.crawl.entity.crawl.status.' + props.status} />;
      break;

    case 'running':
      color = 'primary';
      style = {};
      label = <IntlMessages id={'bundles.crawl.entity.crawl.status.' + props.status} />;
      break;

    case 'finished':
      color = 'primary';
      style = { backgroundColor: '#28a745' };
      label = <IntlMessages id={'bundles.crawl.entity.crawl.status.' + props.status} />;
      break;

    case 'stopped':
      color = 'secondary';
      style = { backgroundColor: '#6c757d' };
      label = <IntlMessages id={'bundles.crawl.entity.crawl.status.' + props.status} />;
      break;

    case 'failed':
      color = 'primary';
      style = { backgroundColor: '#dc3545', color: '#ffffff' };
      label = <IntlMessages id={'bundles.crawl.entity.crawl.status.' + props.status} />;
      break;

    default:
      throw new Error('unknown `props.status` value');
  }

  return (
    <Chip
      component={props.clickable ? Link : 'span'}
      clickable={props.clickable}
      to={url}
      color={color}
      label={label}
      style={style}
    />
  );
};

CrawlStatusBadge.propTypes = {
  id: PropTypes.number.isRequired,
  clickable: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  status: PropTypes.oneOf(CRAWL_STATUS).isRequired,
};

export default React.memo(CrawlStatusBadge);

import React from 'react';

const Spider = () => {
  return (
    // <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" class="MuiSvgIcon-root" style={{marginRight: '16px'}, {width: '23px'}, {height: '23px'}, {enableBackground: "new 0 0 512, 512"}} ><g><g><polygon points="31,235 31,452 61,452 61,265 136,265 136,235 "/></g></g><g><g><polygon points="376,235 376,265 451,265 451,452 481,452 481,235 "/></g></g><g><g><polygon points="91,175 91,0 61,0 61,205 136,205 136,175 "/></g></g><g><g><polygon points="421,0 421,175 376,175 376,205 451,205 451,0 "/></g></g><g><g><path d="M151,115V0h-30v145h45v-15c0-5.11,0.435-10.12,1.257-15H151z"/></g></g><g><g><path d="M361,0v115h-16.257c0.822,4.88,1.257,9.89,1.257,15v15h45V0H361z"/></g></g><g><g><path d="M375.064,295c-1.312,10.477-3.985,20.536-7.828,30H391v187h30V295H375.064z"/></g></g><g><g><path d="M136.936,295H91v217h30V325h23.764C140.921,315.536,138.248,305.477,136.936,295z"/></g></g><g><g><path d="M166,205v75c0,44.594,32.435,81.606,75,88.749V415h30v-46.251c42.565-7.142,75-44.154,75-88.749v-75H166z"/></g></g><g><g><path d="M301,90.335V25h-30v46.89c-4.794-1.234-9.82-1.89-15-1.89s-10.206,0.656-15,1.89V25h-30v65.335c-9.329,10.575-15,24.454-15,39.665v45h120v-45C316,114.789,310.329,100.91,301,90.335z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
    <svg
      viewBox="0 0 512 512"
      className={'MuiSvgIcon-root'}
      style={{ marginRight: '16px', maxWidth: '20px', maxHeight: '20px' }}>
      <g>
        <g>
          <polygon points="31,235 31,452 61,452 61,265 136,265 136,235 " />
        </g>
      </g>
      <g>
        <g>
          <polygon points="376,235 376,265 451,265 451,452 481,452 481,235 " />
        </g>
      </g>
      <g>
        <g>
          <polygon points="91,175 91,0 61,0 61,205 136,205 136,175 " />
        </g>
      </g>
      <g>
        <g>
          <polygon points="421,0 421,175 376,175 376,205 451,205 451,0 " />
        </g>
      </g>
      <g>
        <g>
          <path d="M151,115V0h-30v145h45v-15c0-5.11,0.435-10.12,1.257-15H151z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M361,0v115h-16.257c0.822,4.88,1.257,9.89,1.257,15v15h45V0H361z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M375.064,295c-1.312,10.477-3.985,20.536-7.828,30H391v187h30V295H375.064z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M136.936,295H91v217h30V325h23.764C140.921,315.536,138.248,305.477,136.936,295z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M166,205v75c0,44.594,32.435,81.606,75,88.749V415h30v-46.251c42.565-7.142,75-44.154,75-88.749v-75H166z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M301,90.335V25h-30v46.89c-4.794-1.234-9.82-1.89-15-1.89s-10.206,0.656-15,1.89V25h-30v65.335c-9.329,10.575-15,24.454-15,39.665v45h120v-45C316,114.789,310.329,100.91,301,90.335z" />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Spider;

import axios from './Api';
import qs from 'qs';

function addToken() {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

addToken();

// country
// -------

export async function getCountryList(payload = {}) {
  const result = await axios.get('/countries?' + qs.stringify(payload));
  return result.data;
}

// project
// -------

export async function getProject(id) {
  const result = await axios.get('/projects/' + id);
  return result.data;
}

export async function getProjectList(payload = {}) {
  addToken();

  const result = await axios.get('/projects?' + qs.stringify(payload));
  return result.data;
}

export async function createProject(payload) {
  const result = await axios.post('/projects', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function updateProject(id, payload) {
  const result = await axios.put('/projects/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function deleteProject(id) {
  const result = await axios.delete('/projects/' + id);
  return result.data;
}

// projectModule
// -------------

export async function getProjectModuleByCode(projectId, moduleCode) {
  const result = await axios.get('/projects/' + projectId + '/modules/' + moduleCode);
  return result.data;
}

export async function updateProjectModule(id, payload) {
  const result = await axios.put('/project_modules/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

// notification
// ------------

export async function getNotificationList(payload) {
  const result = await axios.get('/notifications?' + qs.stringify(payload));
  return result.data;
}

export async function readAllNotification(payload) {
  const result = await axios.post('/notifications/read_all');
  return result.data;
}

export async function readNotification(id) {
  const payload = {
    readed: true,
  };

  const result = await axios.put('/notifications/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

// user
// -------------

export async function loginCheck(email, password) {
  const form_data = new FormData();

  form_data.append('_username', email);
  form_data.append('_password', password);

  const result = await axios.post('/login_check', form_data);

  if (result.data.token) {
    return result.data.token;
  }

  return false;
}

export async function sendUserConfirmationEmail(id) {
  const result = await axios.post(
    '/users/' + id + '/send_confirmation_email',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result.data;
}

export async function validateUser(token) {
  const result = await axios.post(
    '/users/confirm/' + token,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result.data;
}

export async function sendResetEmail(username) {
  const form_data = new FormData();
  form_data.append('username', username);

  const result = await axios.post('/users/resetting/send_email', form_data);

  return result.data;
}

export async function changePassword(token, payload) {
  const result = await axios.post('/users/reset/' + token, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function getUser(id) {
  const result = await axios.get('/users/' + id);
  return result.data;
}

export async function getUserByUsername(username) {
  const result = await axios.get('/users?username=' + username);
  return result.data['hydra:member'][0];
}

export async function createUser(payload) {
  const result = await axios.post('/users', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function updateUser(id, payload) {
  const result = await axios.put('/users/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function getGoogleAuth(token) {
  const payload = qs.stringify({
    token: token,
  });

  const result = await axios.post('/google_auth', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result;
}

export async function changeUserPlan(userId, newStripePriceId) {
  const payload = new FormData();
  payload.append('newStripePriceId', newStripePriceId);

  const result = await axios.post('/users/' + userId + '/plan/change', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function getProrationPreviewOnChange(userId, newStripePriceId) {
  const result = await axios.get('/users/' + userId + '/plan/change/prorationPreview?newStripePriceId=' + newStripePriceId);
  return result.data;
}

// logAnalyzer
// -----------

export async function detectLogs(projectId) {
  const payload = qs.stringify({
    projectId: projectId,
    chunk: 0,
  });

  const result = await axios.post('/modules/log_analyzer/detect_objects', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
}

export async function readLogs(projectId, logFormatArray = {}) {
  const payload = qs.stringify({
    projectId: projectId,
    logFormat: logFormatArray,
  });

  const result = await axios.post('/modules/log_analyzer/read', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
}

// LogAnalyzerFileHistory
// ----------------------

export async function getLogAnalyzerFileHistory(id) {
  const result = await axios.get('/log_analyzer_file_histories/' + id);
  return result.data;
}

export async function getLogAnalyzerFileHistoryList(payload = {}) {
  const result = await axios.get('/log_analyzer_file_histories?' + qs.stringify(payload));
  return result.data;
}

export async function updateLogAnalyzerFileHistory(id, payload) {
  const result = await axios.put('/log_analyzer_file_histories/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function importLogAnalyzerFileHistory(payload) {
  const result = await axios.post(
    'https://us-central1-seologs-1091.cloudfunctions.net/seologs-import-single-log-file',
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result.data;
}

// Queries
// -------

export async function queryLogs(projectId, queryKey, begin = '20200920', end = '20200928', bot = 'Google') {
  const payload = qs.stringify({
    projectId: projectId,
    begin: begin,
    end: end,
    bot: bot,
  });

  const result = await axios.post('/queries/log_analyzer/' + queryKey, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
}

export async function querySql(
  projectId,
  table,
  dimensions,
  dimensionsFunctions,
  groupBy,
  timeDimensions,
  measures,
  filters,
  aggregationFilters,
  order,
  limit = 100,
  offset = 0,
  ungrouped = 0,
  exportCsv = 0,
) {
  const payload = qs.stringify({
    projectId: projectId,
    table: table,
    dimensions: dimensions,
    dimensionsFunctions: dimensionsFunctions,
    groupBy: groupBy,
    timeDimensions: timeDimensions,
    measures: measures,
    filters: filters,
    aggregationFilters: aggregationFilters,
    order: order,
    limit: limit,
    offset: offset,
    ungrouped: ungrouped,
    exportCsv: exportCsv,
  });

  const result = await axios.post('/query/sql', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
}

export async function requestWebsiteBeforeValidateInstall(projectId, validationCode) {
  const payload = qs.stringify({
    projectId: projectId,
    validationCode: validationCode,
  });

  const result = await axios.post('/modules/log_analyzer/request_website_before_validate_install', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
}

export async function validateInstall(projectId, validationCode) {
  const payload = qs.stringify({
    projectId: projectId,
    validationCode: validationCode,
  });

  const result = await axios.post('/modules/log_analyzer/validate_install', payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
}

// crawl
// -----

export async function getCrawl(id) {
  const result = await axios.get('/crawls/' + id);
  return result.data;
}

export async function getCrawlList(payload = {}) {
  const result = await axios.get('/crawls?' + qs.stringify(payload));
  return result.data;
}

export async function getLatestCrawl(projectId) {
  const payload = {
    website: projectId,
    itemsPerPage: 1,
    order: {
      id: 'desc',
    },
  };

  const result = await axios.get('/crawls?' + qs.stringify(payload));

  if (result.data['hydra:member'].length > 0) {
    return result.data['hydra:member'][0];
  }

  return null;
}

export async function createCrawl(projectId, crawlProfileId, payload) {
  const result = await axios.post(`/crawls/profile/${crawlProfileId}?execute_crawl=1`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function deleteCrawl(id) {
  const result = await axios.delete('/crawls/' + id);
  return result.data;
}

export async function stopCrawl(id) {
  const result = await axios.post(
    `/crawls/${id}/stop`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result.data;
}

// crawlProfile
// ------------

export async function getCrawlProfile(id) {
  const result = await axios.get('/crawl_profiles/' + id);
  return result.data;
}

export async function getCrawlProfileList(payload = {}) {
  const result = await axios.get('/crawl_profiles?' + qs.stringify(payload));
  return result.data;
}

export async function createCrawlProfile(payload) {
  const result = await axios.post('/crawl_profiles', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function updateCrawlProfile(id, payload) {
  const result = await axios.put('/crawl_profiles/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function deleteCrawlProfile(id) {
  const result = await axios.delete('/crawl_profiles/' + id);
  return result.data;
}

// crawlSchedule
// ------------

export async function getCrawlSchedule(id) {
  const result = await axios.get('/crawl_schedules/' + id);
  return result.data;
}

export async function getCrawlScheduleList(payload = {}) {
  const result = await axios.get('/crawl_schedules?' + qs.stringify(payload));
  return result.data;
}

export async function createCrawlSchedule(payload) {
  const result = await axios.post('/crawl_schedules', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function updateCrawlSchedule(id, payload) {
  const result = await axios.put('/crawl_schedules/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function deleteCrawlSchedule(id) {
  const result = await axios.delete('/crawl_schedules/' + id);
  return result.data;
}

// segmentationProfile
// -------------------

export async function getSegmentationProfile(id) {
  const result = await axios.get('/segmentation_profiles/' + id);
  return result.data;
}

export async function getSegmentationProfileList(projectId, payload = {}) {
  const result = await axios.get('/projects/' + projectId + '/segmentation_profiles?' + qs.stringify(payload));
  return result.data;
}

export async function createSegmentationProfile(payload) {
  const result = await axios.post('/segmentation_profiles', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function updateSegmentationProfile(id, payload) {
  const result = await axios.put('/segmentation_profiles/' + id + '/update', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function deleteSegmentationProfile(id) {
  const result = await axios.delete('/segmentation_profiles/' + id);
  return result.data;
}

export async function updateSegmentationProfileCategory(id) {
  const result = await axios.post(
    `/segmentation_profiles/${id}/update_category`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result.data;
}

// segmentationRule
// ----------------

export async function getSegmentationRule(id) {
  const result = await axios.get('/segmentation_rules/' + id);
  return result.data;
}

export async function getSegmentationRuleList(segmentationProfileId, payload = {}) {
  const result = await axios.get('/segmentation_profiles/' + segmentationProfileId + '/rules?' + qs.stringify(payload));
  return result.data;
}

export async function createSegmentationRule(payload) {
  const result = await axios.post('/segmentation_rules', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function updateSegmentationRule(id, payload) {
  const result = await axios.put('/segmentation_rules/' + id, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data;
}

export async function deleteSegmentationRule(id) {
  const result = await axios.delete('/segmentation_rules/' + id);
  return result.data;
}

// dashboard
// ---------

export async function getDashboard(id) {
  const result = await axios.get('/dashboards/' + id);
  return result.data;
}

export async function getDashboardList(payload = {}) {
  const result = await axios.get('/dashboards?' + qs.stringify(payload));
  return result.data;
}

// usage
// -----

export async function getUsageList(payload = {}) {
  const result = await axios.get('/usages?' + qs.stringify(payload));
  return result.data;
}

// prometheus
// ----------

export async function queryMetric(projectId, crawlId, metricName, labels = '') {
  let labelsPart = '{';
  labelsPart += labels;
  if (labels) {
    labelsPart += ', ';
  }
  labelsPart += `projectId="${projectId}", crawlId="${crawlId}"`;
  labelsPart += '}';

  const url = `http://35.195.63.228:9090/api/v1/query?query=${metricName}${labelsPart}`;

  const result = await axios.get(url);

  return result.data;
}

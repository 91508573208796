import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';

const Indexability = props => {
  return (
    <CmtCard>
      <CmtCardHeader title={<IntlMessages id="bundles.urlViewer.subtitle.indexability" />} />
      <CmtCardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.indexable" />
              </TableCell>
              <TableCell>
                {props.data.indexable ? (
                  <Chip label={'Yes'} style={{ color: '#ffffff', backgroundColor: '#28a745' }} />
                ) : (
                  <Chip label={'No'} style={{ color: '#ffffff', backgroundColor: '#dc3545' }} />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.indexableReason" />
              </TableCell>
              <TableCell>{props.data.indexable_reason}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.status" />
              </TableCell>
              <TableCell>
                {props.data.response.firstStatusCode} {!props.data.checks.was_redirected && props.data.response.statusText}
              </TableCell>
            </TableRow>
            {props.data.performance.redirectCount > 0 && (
              <TableRow>
                <TableCell>Redirect count</TableCell>
                <TableCell>{props.data.performance.redirectCount}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CmtCardContent>
    </CmtCard>
  );
};

Indexability.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Indexability;

/**
 * @param {Object} values
 */
export function validateCrawlProfile(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
}

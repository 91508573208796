import { GET_USAGE } from '../constants';

const INIT_STATE = {
  usage: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USAGE: {
      return {
        ...state,
        usage: action.payload,
      };
    }
    default:
      return state;
  }
};

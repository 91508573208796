export const EXTERNAL_LINKS_FIELDS = [
  {
    array: true,
    label: 'dimension.content.external_links',
    fields: [
      {
        dimension: 'content.external_links.tagName',
        label: 'dimension.crawled_pages.content.external_links.tagName',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.external_links.host',
        label: 'dimension.crawled_pages.content.external_links.host',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.external_links.href',
        label: 'dimension.crawled_pages.content.external_links.href',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.external_links.text',
        label: 'dimension.crawled_pages.content.external_links.text',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.external_links.html',
        label: 'dimension.crawled_pages.content.external_links.html',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.external_links.rel',
        label: 'dimension.crawled_pages.content.external_links.rel',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.external_links.title',
        label: 'dimension.crawled_pages.content.external_links.title',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.external_links.ping',
        label: 'dimension.crawled_pages.content.external_links.ping',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.external_links.statusCode',
        label: 'dimension.crawled_pages.content.external_links.statusCode',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.external_links.firstStatusCode',
        label: 'dimension.crawled_pages.content.external_links.firstStatusCode',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.external_links.contentType',
        label: 'dimension.crawled_pages.content.external_links.contentType',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.external_links.isHttps',
        label: 'dimension.crawled_pages.content.external_links.isHttps',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
    ],
  },
];

import { INLINKS_MAP } from './inbound_links/INLINKS_MAP';

export const LINKS_FLOW = {
  '@id': 'links_flow',
  id: 'links_flow',
  name: 'sidebar.crawl.dashboard.linksFlow',
  description: null,
  project: '/api/projects/131',
  questions: [INLINKS_MAP],
};

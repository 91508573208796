import IntlMessages from '@jumbo/utils/IntlMessages';
import React from 'react';

/**
 * Stripe Public key
 *
 * @type {string}
 * @see https://dashboard.stripe.com/test/apikeys
 */
export const STRIPE_PUBLIC_KEY = 'pk_test_rV8HherPuDPH2T0X6F37dFaY';

/**
 * Name of the property that contains the Stripe customer ID in the `authUser` object.
 *
 * @type {string}
 */
export const STRIPE_CUSTOMER_ID_FIELD = 'stripeCustomerId';

/**
 * Extra options added when the subscription is created in Stripe.
 *
 * @type {Object}
 */
export const CREATE_SUBSCRIPTION_EXTRA_OPTIONS = {
  collection_method: 'charge_automatically',
};

/**
 * Extra options added when the subscription is updated in Stripe.
 *
 * @type {Object}
 */
export const UPDATE_SUBSCRIPTION_EXTRA_OPTIONS = {
  collection_method: 'charge_automatically',
  proration_behavior: 'always_invoice',
};

export const STRIPE_PRODUCTS = [
  {
    id: 'prod_I1On643JF0MPew',
    code: 'crawler',
    component: 'CardTheme',
    optional: false,
    mainPlan: true,
    description: priceObj => (
      <React.Fragment>
        <strong>{Intl.NumberFormat().format(priceObj.metadata.quota_urls)}</strong>
        &nbsp;
        <IntlMessages id="bundles.Subscription.text.urlsMonth" />
        <br />
        {0 === parseInt(priceObj.metadata.quota_projects) ? (
          <strong>
            <IntlMessages id="bundles.project.global.unlimited" />
          </strong>
        ) : (
          <strong>{Intl.NumberFormat().format(priceObj.metadata.quota_projects)}</strong>
        )}
        &nbsp;
        <IntlMessages id="bundles.Subscription.text.projects" />
        <br />
        <strong>{Intl.NumberFormat().format(priceObj.metadata.quota_parallel_crawls)}</strong>
        &nbsp;
        <IntlMessages id="bundles.Subscription.text.parallelCrawl" />
      </React.Fragment>
    ),
  },
  {
    id: 'prod_I2sryo1zDR9YUd',
    code: 'log_analyzer',
    component: 'RadioTheme',
    optional: true,
    mainPlan: false,
    description: priceObj => (
      <React.Fragment>
        <strong>{Intl.NumberFormat().format(priceObj.metadata.quota_urls)}</strong>{' '}
        <IntlMessages id="bundles.Subscription.text.urlsAnalyzedDay" />
      </React.Fragment>
    ),
  },
];

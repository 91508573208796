export const AVERAGE_LOAD_TIME_BY_PAGE_GROUP = {
  '@id': 'AVERAGE_LOAD_TIME_BY_PAGE_GROUP',
  name: 'bundles.crawl.chart.average_load_time_by_group.title',
  description: null,
  x: 0,
  y: 20,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['avg_load_time'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        data.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        data[i] = arr.avg_load_time;

        return true;
      });

      datasets = [
        {
          label: 'Average load time (ms)',
          data: data,
          backgroundColor: '#1976d2',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });

      return {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'performance_navigation_totalLoadTime',
          'totalLoadTimeString',
          'response_firstStatusCode',
        ],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
      },
      scales: {
        y: {
          ticks: {
            callback: function(value, index, values) {
              return value + ' ms';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'avg_load_time',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import { TOTAL_INDEXABLE_PAGES } from './indexability/TOTAL_INDEXABLE_PAGES';
import { TOTAL_NON_INDEXABLE_PAGES } from './indexability/TOTAL_NON_INDEXABLE_PAGES';
import { NON_INDEXABLE_BREAKDOWN } from './indexability/NON_INDEXABLE_BREAKDOWN';
import { INDEXABILITY_BY_PAGE_GROUP_BREAKDOWN } from './indexability/INDEXABILITY_BY_PAGE_GROUP_BREAKDOWN';
import { NON_INDEXABLE_PAGES_BY_GROUP } from './indexability/NON_INDEXABLE_PAGES_BY_GROUP';

export const INDEXABILITY = {
  '@id': 'indexability',
  id: 'indexability',
  name: 'sidebar.crawl.dashboard.whatIsIndexable',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_INDEXABLE_PAGES,
    TOTAL_NON_INDEXABLE_PAGES,
    NON_INDEXABLE_BREAKDOWN,
    INDEXABILITY_BY_PAGE_GROUP_BREAKDOWN,
    NON_INDEXABLE_PAGES_BY_GROUP,
  ],
};

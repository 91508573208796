import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const moreValues = {
  br: <br />,
};

const InjectMassage = props => <FormattedMessage {...props} values={{ ...props.values, ...moreValues }} />;
export default injectIntl(InjectMassage, {
  withRef: false,
});

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import HeaderPageSupplements from 'components/HeaderPageSupplements';

import DashboardList from '../components/DashboardList';
import { handleGetDashboards } from '../actions';
import { Link, useHistory } from 'react-router-dom';

const DashboardIndex = props => {
  const history = useHistory();
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const { dashboardsList } = useSelector(({ dashboard }) => dashboard);
  // sorting
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');

  const dispatch = useDispatch();

  useEffect(() => {
    const orderObj = {};
    orderObj[orderBy] = order;

    const payload = {
      order: orderObj,
    };

    if (project && project.id) {
      payload['project'] = '/api/projects/' + project.id;
    }

    dispatch(handleGetDashboards(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * sorting
   *
   * @param {string} dimension
   */
  const handleChangeSort = dimension => {
    const isAsc = orderBy === dimension && order === 'asc';

    const newOrderBy = dimension;
    const newOrder = isAsc ? 'desc' : 'asc';

    const orderObj = {};
    orderObj[newOrderBy] = newOrder;

    const payload = {
      order: orderObj,
    };

    dispatch(handleGetDashboards(payload));

    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid md={6} item>
          <Typography variant="h1">
            <IntlMessages id="bundles.dashboard.routes.index.title" />
          </Typography>
        </Grid>

        <Grid md={6} item>
          <HeaderPageSupplements
            numbers={[
              {
                label: <IntlMessages id="bundles.dashboard.global.totalCount" />,
                number: dashboardsList['hydra:totalItems'] || 0,
              },
            ]}
            actions={[
              <Link to={'/dashboards/create'}>
                <Button color="primary" variant={'contained'}>
                  <IntlMessages id="bundles.dashboard.action.create" />
                </Button>
              </Link>,
            ]}
          />
        </Grid>

        <Grid md={12} item>
          {dashboardsList['hydra:member'].length === 0 ? (
            <ListEmptyResult
              title={<IntlMessages id="bundles.dashboard.emptyPrompt.title" />}
              content={<IntlMessages id="bundles.dashboard.emptyPrompt.text" />}
              actionTitle={<IntlMessages id="bundles.dashboard.action.create" />}
              onClick={() => {
                history.push('/dashboards/create');
              }}
            />
          ) : (
            <Paper>
              <DashboardList projects={dashboardsList} order={order} orderBy={orderBy} handleChangeSort={handleChangeSort} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardIndex;

import { TOTAL_CANONICAL_TAG } from './canonical_tag/TOTAL_CANONICAL_TAG';
import { TOO_MANY_CANONICAL_TAG } from './canonical_tag/TOO_MANY_CANONICAL_TAG';
import { CANONICAL_TAG_BREAKDOWN } from './canonical_tag/CANONICAL_TAG_BREAKDOWN';
import { CANONICAL_TAG_PRESENCE_BREAKDOWN } from './canonical_tag/CANONICAL_TAG_PRESENCE_BREAKDOWN';
import { CANONICAL_TAG_PRESENCE_BY_GROUP } from './canonical_tag/CANONICAL_TAG_PRESENCE_BY_GROUP';
import { CANONICAL_TAG_BREAKDOWN_BY_GROUP } from './canonical_tag/CANONICAL_TAG_BREAKDOWN_BY_GROUP';

export const CANONICAL_TAG = {
  '@id': 'canonical_tag',
  id: 'canonical_tag',
  name: 'sidebar.crawl.dashboard.canonical',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_CANONICAL_TAG,
    TOO_MANY_CANONICAL_TAG,
    //PROBLEMATIC_CANONICAL_TAG,
    CANONICAL_TAG_PRESENCE_BREAKDOWN,
    CANONICAL_TAG_BREAKDOWN,
    //PROBLEMATIC_CANONICAL_TAG_BREAKDOWN,
    CANONICAL_TAG_PRESENCE_BY_GROUP,
    CANONICAL_TAG_BREAKDOWN_BY_GROUP,
  ],
};

import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { LIST_PROJECTS, GET_PROJECT } from '../constants';
import { getProjectList, getProject, createProject, updateProject, deleteProject } from 'services/api/MyApiUtil';
import { redirect, showMessage } from 'redux/actions';
import { handleClearProject } from 'bundles/projectSelector/actions';

export const handleGetProjects = payload => {
  return dispatch => {
    dispatch(fetchStart());

    getProjectList(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: LIST_PROJECTS,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleGetProject = id => {
  return dispatch => {
    dispatch(fetchStart());

    getProject(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_PROJECT,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleCreateProject = (user, payload, location, history) => {
  return dispatch => {
    dispatch(fetchStart());

    payload['users'] = [];
    payload['users'].push(user['@id']);

    createProject(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.project.notification.create.success', 'success'));
        dispatch(redirect('/projects?projectId=' + result.id));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleUpdateProject = (id, payload) => {
  return dispatch => {
    dispatch(fetchStart());

    updateProject(id, payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.project.notification.update.success', 'success'));
        dispatch(redirect('/projects'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

/**
 * @param {number} id The project ID
 * @param {boolean} clearProject If `true` then clear the selected project in the appbar
 */
export const handleDeleteProject = (id, clearProject, location, history) => {
  return dispatch => {
    dispatch(fetchStart());

    deleteProject(id)
      .then(result => {
        if (true === clearProject) {
          dispatch(handleClearProject(location, history));
        }

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.project.notification.deleted.success', 'success'));
        dispatch(handleGetProjects());
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

import { fetchError } from '../../../redux/actions';
import { START_NOTIFICATION_INTERVAL, LIST_NOTIFICATIONS, READ_ALL_NOTIFICATIONS, READ_NOTIFICATION } from '../constants';
import { getNotificationList, readAllNotification, readNotification } from 'services/api/MyApiUtil';

export const handleStartNotificationInterval = interval => {
  return dispatch => {
    dispatch({
      type: START_NOTIFICATION_INTERVAL,
      payload: interval,
    });
  };
};

export const handleGetNotifications = payload => {
  return dispatch => {
    getNotificationList(payload)
      .then(result => {
        dispatch({
          type: LIST_NOTIFICATIONS,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleReadAllNotifications = payload => {
  return dispatch => {
    readAllNotification({})
      .then(result => {
        dispatch({
          type: READ_ALL_NOTIFICATIONS,
        });
        dispatch(handleGetNotifications(payload));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleReadNotification = (id, payload) => {
  return dispatch => {
    readNotification(id)
      .then(result => {
        dispatch({
          type: READ_NOTIFICATION,
        });
        dispatch(handleGetNotifications(payload));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const Headers = props => {
  return (
    <CmtCard>
      <CmtCardHeader title={`Headers (${props.data.response.headers.length})`} />
      <CmtCardContent>
        <Table>
          <TableBody>
            {props.data.response.headers.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CmtCardContent>
    </CmtCard>
  );
};

Headers.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Headers;

import React, { useEffect } from 'react';
import { Box, Button, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetUpcomingInvoice } from '../../actions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  priceDetails: {},
}));

const PriceCalculator = () => {
  const classes = useStyles();
  const { authUser, subscriptionUser } = useSelector(({ auth }) => auth);
  const { subscriptionItems, incomingInvoice } = useSelector(({ payment }) => payment);

  const dispatch = useDispatch();

  let currentItems = [];
  if (subscriptionUser) {
    for (const item of subscriptionUser.items.data) {
      currentItems.push({
        id: item.id,
      });
    }
  }

  useEffect(() => {
    dispatch(handleGetUpcomingInvoice(authUser, subscriptionUser, subscriptionItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subscriptionItems)]);

  if (JSON.stringify(currentItems) === JSON.stringify(subscriptionItems)) {
    return (
      <Paper>
        <Box p={6} align={'center'}>
          <Typography color="textSecondary" variant="body2">
            <IntlMessages id="bundles.checkout.components.priceCalculator.text.noChange" />
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (subscriptionItems.length === 0) {
    return (
      <Paper>
        <Box p={6}>
          <Typography color="textSecondary" variant="body2">
            <IntlMessages id="bundles.checkout.components.priceCalculator.text.selectPlan" />
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (null !== incomingInvoice) {
    return (
      <Paper className={classes.root}>
        <Box p={4}>
          <Box className={classes.priceDetails}>
            <Grid container spacing={3}>
              {incomingInvoice.lines.data.map(obj => (
                <React.Fragment key={obj.id}>
                  <Grid item lg={6} align={'right'}>
                    <Typography color="textSecondary" variant="body2">
                      {obj.quantity} × <IntlMessages id={'bundles.Subscription.text.' + obj.price.metadata.module} />
                      {obj.price.nickname && '( ' + obj.price.nickname + ')'}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} align={'right'}>
                    <Typography color="textSecondary" variant="body2">
                      {Intl.NumberFormat().format(obj.amount / 100)} &euro;
                    </Typography>
                  </Grid>
                  <Grid item lg={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ))}

              <Grid item lg={6} align={'right'}>
                <IntlMessages id="bundles.checkout.components.priceCalculator.text.subtotal" />
              </Grid>
              <Grid item lg={6} align={'right'}>
                {Intl.NumberFormat().format(incomingInvoice.subtotal / 100)} &euro;
              </Grid>

              <Grid item lg={6} align={'right'}>
                <IntlMessages id="bundles.checkout.components.priceCalculator.text.vat" />
              </Grid>
              <Grid item lg={6} align={'right'}>
                {Intl.NumberFormat().format(incomingInvoice.tax / 100)} &euro;
              </Grid>

              <Grid item lg={12}>
                <Divider />
              </Grid>

              <Grid item lg={6} align={'right'}>
                <strong>
                  <IntlMessages id="bundles.checkout.components.priceCalculator.text.amountDue" />
                </strong>
              </Grid>
              <Grid item lg={6} align={'right'}>
                <strong>{Intl.NumberFormat().format(incomingInvoice.amount_due / 100)} &euro;</strong>
              </Grid>
            </Grid>
          </Box>

          <Box mt={6} align={'center'}>
            <Link to={'/checkout'}>
              <Button variant="contained" color="primary">
                <IntlMessages id="bundles.checkout.components.priceCalculator.action.submit" />
              </Button>
            </Link>
          </Box>
        </Box>
      </Paper>
    );
  }

  return null;
};

export default PriceCalculator;

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select/Select';
import TextField from '@material-ui/core/TextField/TextField';

import AddButton from '../AddButton';
import BaseFilter from './BaseFilter';

class BasicFilter extends BaseFilter {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      operators: [],
      inputElement: null,
    };

    if (this.props.editedFilter !== null) {
      const operators = this.findOperatorsByType(this.props.schema.type);
      const inputElement = this.createInputElementByType(this.props.schema.type);

      this.state = {
        ...this.state,
        operators: operators,
        inputElement: inputElement,
      };
    }
  }

  componentDidMount() {
    const operators = this.findOperatorsByType(this.props.schema.type);
    const inputElement = this.createInputElementByType(this.props.schema.type);

    this.setState({
      operators: operators,
      inputElement: inputElement,
    });
  }

  findOperatorsByType(type) {
    switch (type) {
      case 'number':
        return [
          {
            value: 'equals',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.equals'} />,
          },
          {
            value: 'notEquals',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.notEquals'} />,
          },
          {
            value: 'lt',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.lt'} />,
          },
          {
            value: 'lte',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.lte'} />,
          },
          {
            value: 'gt',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.gt'} />,
          },
          {
            value: 'gte',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.gte'} />,
          },
        ];

      case 'string':
        return [
          {
            value: 'equals',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.equals'} />,
          },
          {
            value: 'notEquals',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.notEquals'} />,
          },
          {
            value: 'contains',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.contains'} />,
          },
          {
            value: 'notContains',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.notContains'} />,
          },
          {
            value: 'startsWith',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.startsWith'} />,
          },
          {
            value: 'endsWith',
            label: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.endsWith'} />,
          },
        ];

      default:
        throw new Error('unknown type with value "' + type + '" in findOperatorsByType()');
    }
  }

  createInputElementByType(type) {
    switch (type) {
      case 'number':
        return (
          <TextField
            type={'number'}
            id="value"
            name="value"
            value={this.state.value || ''}
            onChange={this.handleInputChange}
            fullWidth
            required
            variant="outlined"
          />
        );

      case 'string':
        return (
          <TextField
            id="value"
            name="value"
            value={this.state.value || ''}
            onChange={this.handleInputChange}
            fullWidth
            required
            variant="outlined"
          />
        );

      default:
        throw new Error('unknown type with value "' + type + '" in createInputElementByType()');
    }
  }

  render() {
    return (
      <Box p={4}>
        <Box mb={4}>
          <h3>
            <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.field.operator.placeholder'} />
          </h3>
        </Box>

        <Box mb={4}>
          <Select
            id="operator"
            name="operator"
            value={this.state.operator}
            variant="outlined"
            onChange={this.handleOperatorChange}
            fullWidth
            required>
            {this.state.operators.map((obj, i) => (
              <MenuItem key={i} value={obj.value}>
                {obj.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box mb={4}>
          <h3>
            <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.field.value.placeholder'} />
          </h3>
        </Box>

        <Box mb={4}>{this.createInputElementByType(this.props.schema.type)}</Box>

        <AddButton
          schema={this.props.schema}
          operator={this.state.operator}
          value={this.state.value}
          editedFilterIndex={this.props.editedFilter ? this.props.editedFilter.index : null}
          handleSaveFilter={this.props.handleSaveFilter}
        />
      </Box>
    );
  }
}

export default BasicFilter;

import { MISSING_DESCRIPTION } from './description/MISSING_DESCRIPTION';
import { EMPTY_DESCRIPTION } from './description/EMPTY_DESCRIPTION';
import { DESCRIPTION_PRESENCE } from './description/DESCRIPTION_PRESENCE';
import { DESCRIPTION_LENGTH } from './description/DESCRIPTION_LENGTH';
import { DESCRIPTION_PRESENCE_BY_GROUP } from './description/DESCRIPTION_PRESENCE_BY_GROUP';
import { DESCRIPTION_LENGTH_BY_GROUP } from './description/DESCRIPTION_LENGTH_BY_GROUP';

export const DESCRIPTION = {
  '@id': 'description',
  id: 'description',
  name: 'sidebar.crawl.dashboard.description',
  description: null,
  project: '/api/projects/131',
  questions: [
    MISSING_DESCRIPTION,
    EMPTY_DESCRIPTION,
    //DUPLICATE_DESCRIPTION,
    DESCRIPTION_PRESENCE,
    DESCRIPTION_LENGTH,
    DESCRIPTION_PRESENCE_BY_GROUP,
    DESCRIPTION_LENGTH_BY_GROUP,
  ],
};

import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { updateUser } from 'services/api/MyApiUtil';

export const handleUpdateProfile = (id, values) => {
  return dispatch => {
    dispatch(fetchStart());

    let payload = {
      firstname: values.firstname,
      lastname: values.lastname,
      username: values.email,
      email: values.email,
      emailBilling: values.emailBilling ? values.emailBilling : null,
      country: values.country ? values.country : null,
    };

    updateUser(id, payload)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.profile.notification.update.success', 'success'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleChangePassword = (id, newPassword) => {
  return dispatch => {
    dispatch(fetchStart());

    let payload = {
      plainPassword: newPassword,
    };

    updateUser(id, payload)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.profile.notification.changePassword.success', 'success'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

import React from 'react';
import moment from 'moment';
import { Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  lightText: {
    color: theme.palette.lightBtn.textColor,
  },
}));

const DashboardList = props => {
  const classes = useStyles();

  const columns = ['name', 'createdAt'];

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column} sortDirection={props.orderBy === column ? props.order : false}>
                <TableSortLabel
                  active={props.orderBy === column}
                  direction={props.orderBy === column ? props.order : 'asc'}
                  onClick={() => {
                    props.handleChangeSort(column);
                  }}>
                  <IntlMessages id={'bundles.dashboard.entity.dashboard.' + column + '.short'} />
                </TableSortLabel>
              </TableCell>
            ))}

            <TableCell align={'right'} style={{ width: '15%' }}>
              <IntlMessages id="bundles.dashboard.global.actions" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects['hydra:member'].map(entity => (
            <TableRow key={entity.id}>
              <TableCell>
                <Link to={'/dashboards/view/' + entity.id}>{entity.name}</Link>
              </TableCell>
              <TableCell className={classes.lightText}>
                <Typography variant={'body2'}>{entity.createdAt && moment(entity.createdAt).fromNow()}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default DashboardList;

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import { Grid, Paper, Typography } from '@material-ui/core';
import HeaderPageSupplements from 'components/HeaderPageSupplements';
import CreateProjectButton from '../components/CreateProjectButton';
import ProjectList from '../components/ProjectList';
import { handleGetProjects } from '../actions';
import { useHistory } from 'react-router-dom';

const ProjectIndex = () => {
  const history = useHistory();
  const { setBreadcrumb } = useContext(AppContext);
  const { planUser, isActiveSubscription } = useSelector(({ auth }) => auth);
  const { projectsList } = useSelector(({ project }) => project);
  // sorting
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');

  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumb([{ text: <IntlMessages id="bundles.project.routes.index.title" /> }]);

    const orderObj = {};
    orderObj[orderBy] = order;

    const payload = {
      order: orderObj,
    };

    dispatch(handleGetProjects(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * sorting
   *
   * @param {string} dimension
   */
  const handleChangeSort = dimension => {
    const isAsc = orderBy === dimension && order === 'asc';

    const newOrderBy = dimension;
    const newOrder = isAsc ? 'desc' : 'asc';

    const orderObj = {};
    orderObj[newOrderBy] = newOrder;

    const payload = {
      order: orderObj,
    };

    dispatch(handleGetProjects(payload));

    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  const projectsCount = projectsList['hydra:totalItems'] || 0;

  const canCreateProject =
    !isActiveSubscription ||
    !planUser ||
    (projectsCount >= parseInt(planUser.metadata.quota_projects) && 0 !== parseInt(planUser.metadata.quota_projects))
      ? false
      : true;

  let projectsQuota = 0;
  if (planUser && 0 === parseInt(planUser.metadata.quota_projects)) {
    projectsQuota = (
      <strong>
        <IntlMessages id="bundles.project.global.unlimited" />
      </strong>
    );
  } else if (planUser && planUser.metadata.quota_projects) {
    projectsQuota = planUser.metadata.quota_projects;
  }

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid md={6} item>
          <Typography variant="h1">
            <IntlMessages id="bundles.project.routes.index.title" />
          </Typography>
        </Grid>

        <Grid md={6} item>
          <HeaderPageSupplements
            numbers={[
              {
                label: <IntlMessages id="bundles.project.global.projectsCount" />,
                number: projectsList['hydra:totalItems'] || 0,
              },
              {
                label: <IntlMessages id="bundles.project.global.projectsQuota" />,
                number: projectsQuota,
              },
            ]}
            actions={[<CreateProjectButton variant={'outlined'} />]}
          />
        </Grid>

        <Grid md={12} item>
          {projectsList['hydra:member'].length === 0 ? (
            <ListEmptyResult
              title={<IntlMessages id="bundles.project.emptyPrompt.title" />}
              content={<IntlMessages id="bundles.project.emptyPrompt.text" />}
              actionTitle={canCreateProject && <IntlMessages id="bundles.project.action.create" />}
              onClick={() => {
                history.push('/projects/create');
              }}
            />
          ) : (
            <Paper>
              <ProjectList projects={projectsList} order={order} orderBy={orderBy} handleChangeSort={handleChangeSort} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectIndex;

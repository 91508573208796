import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const CANONICAL_TAG_BREAKDOWN = {
  '@id': 'CANONICAL_TAG_BREAKDOWN',
  name: 'bundles.crawl.chart.canonical_tag_breakdown.title',
  description: null,
  x: 6,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['content_canonical_status'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
      {
        dimension: 'checks_has_canonical',
        operator: 'equals',
        values: [true],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'desc',
    orderBy: 'count',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      /**
       * data indexes:
       *
       * 0 : 'pages declaring to be the canonical version',
       * 1 : 'pages declaring another page to be the canonical version',
       * 2 : 'pages with empty "href" attribute in canonical tag',
       */
      let data = [0, 0, 0];
      let datasets = {};
      let backgroundColor = ['#1976d2', '#dc004e', '#ca6702'];

      rows.map(arr => {
        switch (arr.content_canonical_status) {
          case 'matching':
            data[0] = arr.count;
            break;
          case 'not matching':
            data[1] = arr.count;
            break;
          case 'empty':
            data[2] = arr.count;
            break;
          default:
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      return [
        'pages declaring to be the canonical version',
        'pages declaring another page to be the canonical version',
        'pages with empty "href" attribute in canonical tag',
      ];
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

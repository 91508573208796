import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';

const ViewUrlLink = props => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  return (
    <Tooltip title={<IntlMessages id={'generic.action.inspectUrl.title'} />}>
      <Link
        to={`/urlViewer/${encodeURIComponent(props.url)}/view?hiddenFilters=${urlParams.get('hiddenFilters')}${
          props.tab !== undefined ? '&tab=' + props.tab : ''
        }`}>
        <VisibilityIcon color={'primary'} fontSize={'inherit'} />
      </Link>
    </Tooltip>
  );
};

ViewUrlLink.propTypes = {
  url: PropTypes.string.isRequired,
  tab: PropTypes.number,
};

export default ViewUrlLink;

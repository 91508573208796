import {
  DATE_PICKER_OPEN,
  DATE_PICKER_CLOSE,
  DATE_PICKER_SELECT,
  GET_LOGS_STARTED,
  GET_LOGS,
  CLEAR_LOGS,
  CLEAR_METRIC,
  GET_METRIC,
  START_EXPORT,
  SHOW_EXPORT_RESULT,
  CLOSE_EXPORT_RESULT,
} from '../constants';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { querySql, queryMetric } from '../../../../services/api/MyApiUtil';

export const openDatePicker = () => {
  return dispatch => {
    dispatch({ type: DATE_PICKER_OPEN });
  };
};

export const closeDatePicker = () => {
  return dispatch => {
    dispatch({ type: DATE_PICKER_CLOSE });
  };
};

export const selectDateRange = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: DATE_PICKER_SELECT,
      payload: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  };
};

/**
 * Parse dimension name.
 *
 * If the dimension contains one or more dots,
 * then returns the element after the last dot.
 *
 * Example:
 *
 * console.log('content.internal_links.href'); // href
 *
 * Why?
 *
 * We can pass dimensions containing dots during bigquery queries (example: content.internal_links.href),
 * but bigquery returns dimensions without dots (example: href).
 *
 * This is used to get the dimension value from the bigquery row.
 *
 * @param {string} str dimension name.
 *
 * @returns {string}
 */
const parseDimension = str => {
  return str.includes('.') ? str.split('.').pop() : str;
};

export const handleGetLogs = (props, filters, page, rowsPerPage, timeDimensions) => {
  return async dispatch => {
    console.log('handleGetLogs()');
    if (timeDimensions.dimension) {
      if (!timeDimensions.dateRange[0] || !timeDimensions.dateRange[1]) {
        return;
      }
    }

    if (false === props.autoRefresh) {
      dispatch(fetchStart());
    }

    const projectId = props.projectId;

    let rows = [];
    let csvData = [];
    let order = {};

    if (props.orderBy && props.order) {
      order[props.orderBy] = props.order;
    }

    // merge filters and props.hiddenFilters
    const filtersAll = [...filters, ...props.hiddenFilters];

    try {
      dispatch({
        type: CLEAR_LOGS,
        payload: {
          id: props.id,
        },
      });

      dispatch({
        type: GET_LOGS_STARTED,
        payload: {
          id: props.id,
        },
      });

      // count total rows
      let countDimensions = [];
      if (!props.ungrouped) {
        countDimensions = props.dimensions;
      }

      /**
       * use `count` measure if props.ungrouped equals true
       * use `count_over` measure if props.ungrouped equals false
       */
      const countMeasure = props.ungrouped ? 'count' : 'count_over';
      const countTemp = await querySql(
        projectId,
        props.table,
        countDimensions,
        [],
        props.groupBy || [],
        timeDimensions.dimension ? timeDimensions : null,
        [countMeasure],
        filtersAll,
        [], //props.aggregationFilters,
        {},
        1,
        0,
        props.ungrouped,
      );

      let count = 0;
      if (countTemp.hasOwnProperty('hydra:member') && countTemp['hydra:member'].length > 0) {
        count = countTemp['hydra:member'][0][countMeasure];
      }

      if (!props.ungrouped && props.dimensions.length === 0 && countTemp['hydra:member'].length === 1) {
        count = 1;
      }

      let dimensionsFunctions = [];
      props.dimensionsFunctions.map(obj => {
        if (null === obj) {
          dimensionsFunctions.push(null);
        } else {
          dimensionsFunctions.push(obj.function);
        }

        return obj;
      });

      // get rows
      const data = await querySql(
        projectId,
        props.table,
        props.dimensions,
        dimensionsFunctions,
        props.groupBy || [],
        timeDimensions.dimension ? timeDimensions : null,
        props.measures,
        filtersAll,
        props.aggregationFilters,
        order,
        rowsPerPage,
        page * rowsPerPage,
        props.ungrouped,
      );

      // csv
      let csvRow = ['date', 'code', 'url'];
      csvData.push(csvRow);

      data['hydra:member'].map((arr, i) => {
        // csv
        let csvRow = [];
        props.dimensions.map(dimension => {
          csvRow.push(arr[parseDimension(dimension)]);

          return dimension;
        });
        props.measures.map(measure => {
          csvRow.push(arr[measure]);

          return measure;
        });
        csvData.push(csvRow);

        // table
        let row = {
          id: i,
        };
        props.dimensions.map(dimension => {
          row[dimension] = arr[parseDimension(dimension)];

          return dimension;
        });
        props.measures.map(measure => {
          row[measure] = arr[measure];

          return measure;
        });
        rows.push(row);

        return arr;
      });

      if (false === props.autoRefresh) {
        dispatch(fetchSuccess());
      }

      dispatch({
        type: GET_LOGS,
        payload: {
          id: props.id,
          count: count,
          csvData: csvData,
          rows: rows,
        },
      });
    } catch (error) {
      dispatch({
        type: CLEAR_LOGS,
        payload: {
          id: props.id,
        },
      });

      dispatch(fetchError(error.message));
    }
  };
};

export const exportCsv = (props, filters, timeDimensions) => {
  return async dispatch => {
    if (timeDimensions.dimension) {
      if (!timeDimensions.dateRange[0] || !timeDimensions.dateRange[1]) {
        return;
      }
    }

    const projectId = props.projectId;

    let order = {};

    if (props.orderBy && props.order) {
      order[props.orderBy] = props.order;
    }

    // merge filters and props.hiddenFilters
    const filtersAll = [...filters, ...props.hiddenFilters];

    try {
      dispatch(fetchStart());
      dispatch({
        type: START_EXPORT,
      });

      let dimensionsFunctions = [];
      props.dimensionsFunctions.map(obj => {
        if (null === obj) {
          dimensionsFunctions.push(null);
        } else {
          dimensionsFunctions.push(obj.function);
        }

        return obj;
      });

      const data = await querySql(
        projectId,
        props.table,
        props.dimensions,
        dimensionsFunctions,
        props.groupBy || [],
        timeDimensions.dimension ? timeDimensions : null,
        props.measures,
        filtersAll,
        props.aggregationFilters,
        order,
        null,
        null,
        props.ungrouped,
        1,
      );

      dispatch(fetchSuccess());
      dispatch({
        type: SHOW_EXPORT_RESULT,
        payload: data,
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
};

export const getMetric = (projectId, crawlId = null, metricName, labels = '') => {
  return async dispatch => {
    const key = `${projectId}.${crawlId}.${metricName}.${labels}`;

    dispatch({
      type: CLEAR_METRIC,
      payload: {
        id: key,
      },
    });

    dispatch({
      type: GET_LOGS_STARTED,
      payload: {
        id: key,
      },
    });

    const data = await queryMetric(projectId, crawlId, metricName, labels);

    dispatch({
      type: GET_METRIC,
      payload: {
        id: key,
        data: data,
      },
    });
  };
};

export const closeExportResult = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_EXPORT_RESULT,
    });
  };
};

/**
 * @param context
 * @returns {string}
 */
export function tooltipLabelCallback(context) {
  let label = context.dataset.label || '';

  if (label) {
    label += ': ';
  }

  if (context.parsed.y !== null) {
    let value = context.parsed.y;

    if (context.parsed.y % 1 !== 0) {
      value = context.parsed.y.toFixed(1);
    }

    label += value + '%';
  }

  return label;
}

export function tooltipLabelCalcPercentCallback(context) {
  const dataset = context.dataset;

  const total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
    return previousValue + currentValue;
  });

  const currentValue = dataset.data[context.dataIndex];
  const percentage = (currentValue / total) * 100;

  return context.label + ': ' + context.formattedValue + ' (' + percentage.toFixed(2) + '%)';
}

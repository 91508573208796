import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AppTableHead from './includes/AppTableHead';
import AppTableRow from './includes/AppTableRow';

const AppTable = props => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Table>
        <AppTableHead
          dimensions={props.dimensions}
          measures={props.measures}
          schemaName={props.schemaName}
          orderBy={props.orderBy}
          order={props.order}
          handleChangeSort={props.handleChangeSort}
        />

        <TableBody>
          {props.rows.hasOwnProperty(props.id) && (
            <React.Fragment>
              {props.rows[props.id].map(entity => (
                <AppTableRow
                  key={entity.id}
                  dimensions={props.dimensions}
                  entity={entity}
                  hiddenFilters={props.hiddenFilters}
                  hover={props.visualization.hasOwnProperty('hover') ? props.visualization.hover : false}
                  measures={props.measures}
                  projectId={props.projectId}
                  table={props.table}
                  visualization={props.visualization}
                  onClick={
                    props.visualization.hasOwnProperty('handleClick')
                      ? () => props.visualization.handleClick(props.projectId, props.hiddenFilters, entity, history)
                      : null
                  }
                />
              ))}
            </React.Fragment>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={props.rowsPerPageOptions}
        component="div"
        count={props.count[props.id] || 0}
        rowsPerPage={props.rowsPerPage || 100}
        page={props.page || 0}
        onChangePage={props.handleChangePage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
      />
      {true === props.showExportAction && <Button onClick={props.handleExportCsv}>CSV export</Button>}
    </React.Fragment>
  );
};

export default AppTable;

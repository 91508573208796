export const RESPONSE_HEADERS_FIELDS = [
  {
    array: true,
    label: 'dimension.response.headers',
    fields: [
      {
        dimension: 'response.headers.name',
        label: 'dimension.crawled_pages.response.headers.name',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'response.headers.value',
        label: 'dimension.crawled_pages.response.headers.value',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
    ],
  },
];

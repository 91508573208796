import React, { useContext, useEffect } from 'react';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import AccountView from 'bundles/profile/components/ProfileView';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { Grid } from '@material-ui/core';
import { GRID_SPACING } from '../../../@jumbo/constants/ThemeOptions';

import ProfileTabs from '../components/ProfileTabs';

const ProfileViewPage = () => {
  const { setBreadcrumb } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumb([
      {
        text: <IntlMessages id="sidebar.profile" />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={12}>
          <ProfileTabs tabActive={'/profile'} />
        </Grid>

        <Grid item md={12}>
          <AccountView />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileViewPage;

import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import React from 'react';

export const LIST_CRAWL_SCHEDULES = 'list_crawl_schedules';
export const GET_CRAWL_SCHEDULE = 'get_crawl_schedule';

export const FREQUENCY_HOUR = 'every hour';
export const FREQUENCY_DAY = 'every day';
export const FREQUENCY_WEEK = 'every week';
export const FREQUENCY_CUSTOM = 'custom';

export const DAYS_WEEK = [
  {
    value: '0',
    label: <IntlMessages id="bundles.crawlSchedule.global.sunday" />,
  },
  {
    value: '1',
    label: <IntlMessages id="bundles.crawlSchedule.global.monday" />,
  },
  {
    value: '2',
    label: <IntlMessages id="bundles.crawlSchedule.global.tuesday" />,
  },
  {
    value: '3',
    label: <IntlMessages id="bundles.crawlSchedule.global.wednesday" />,
  },
  {
    value: '4',
    label: <IntlMessages id="bundles.crawlSchedule.global.thursday" />,
  },
  {
    value: '5',
    label: <IntlMessages id="bundles.crawlSchedule.global.friday" />,
  },
  {
    value: '6',
    label: <IntlMessages id="bundles.crawlSchedule.global.saturday" />,
  },
];

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Button, Box, Grid, useTheme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

import dashboardStyles from '../../../theme/dashboard.style';

import Question from '../components/Question';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { handleGetDashboard, handleGetPredefinedDashboard, handleGetCrawlPredefinedDashboard } from '../actions';
import AppDateRangePicker from '../../../components/Dialog/AppDateRangePicker';

import FilteringContainer from 'bundles/logAnalyzer/LogsFilters/components/FilteringContainer';
import ChooseFilters from 'bundles/logAnalyzer/LogsFilters/components/Drawer/ChooseFilters';
import { SCHEMAS } from 'constants/dataExplorer/schema';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { CLEAR_DASHBOARD } from '../constants';

const ReactGridLayout = WidthProvider(RGL);

const actions = [
  {
    icon: <InfoIcon />,
    label: 'More Detail',
  },
  {
    icon: <CloseIcon />,
    label: 'Close',
  },
];

const DashboardView = props => {
  const urlParams = new URLSearchParams(props.location.search);

  const schemaName = urlParams.get('module');
  const schema = SCHEMAS[schemaName].fields;

  return (
    <FilteringContainer schema={schema}>
      <DashboardViewContainer {...props} />
    </FilteringContainer>
  );
};

const DashboardViewContainer = props => {
  const classes = dashboardStyles();
  const theme = useTheme();
  const urlParams = new URLSearchParams(props.location.search);

  const { setBreadcrumb, setPageActions } = useContext(AppContext);
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const { dashboard } = useSelector(({ dashboard }) => dashboard);
  const [layout, setLayout] = useState([]);

  const [dashboardId, setDashboardId] = useState(null);
  const crawlId = urlParams.has('crawlId') ? urlParams.get('crawlId') : null;

  // auto refresh
  const defaultAutoRefresh = urlParams.has('autoRefresh') && 1 === parseInt(urlParams.get('autoRefresh')) ? true : false;
  const [autoRefresh, setAutoRefresh] = useState(defaultAutoRefresh);
  const [ts, setTs] = useState(null);
  let interval = useRef(null);

  const schemaName = urlParams.get('module');
  const schema = SCHEMAS[schemaName].fields;

  const dispatch = useDispatch();

  useEffect(() => {
    if (dashboardId) {
      if (urlParams.has('predefined') && '1' === urlParams.get('predefined')) {
        switch (urlParams.get('module')) {
          case 'crawled_pages':
            if (crawlId) {
              dispatch(handleGetCrawlPredefinedDashboard(project.id, dashboardId, crawlId));
            }
            break;

          case 'logs':
            dispatch(handleGetPredefinedDashboard(project.id, dashboardId, urlParams.get('module')));
            break;
          default:
        }
      } else {
        dispatch(handleGetDashboard(dashboardId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId, crawlId]);

  useEffect(() => {
    setDashboardId(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    // prepare toolbar buttons
    // -----------------------
    let toolbarActions = [];

    // delete button
    if (!urlParams.has('predefined') || '0' === urlParams.get('predefined')) {
      const deleteButton = <Button variant={'outlined'}>Supprimer</Button>;

      toolbarActions.push(deleteButton);
    }

    // refresh button
    let refreshButton = null;

    if (true === autoRefresh) {
      refreshButton = (
        <IconButton color={'disabled'} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      );
    } else {
      refreshButton = (
        <Tooltip title={<IntlMessages id={'generic.action.refresh.title'} />}>
          <IconButton
            color="primary"
            aria-label="refresh"
            onClick={() => {
              forceRefresh();
            }}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      );
    }

    // auto refresh button
    let autoRefreshButton = null;

    if (true === autoRefresh) {
      autoRefreshButton = (
        <Tooltip title={<IntlMessages id={'generic.action.autoRefreshStop.title'} />}>
          <IconButton color="primary" aria-label="play" onClick={toggleAutoRefresh}>
            <PauseIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      autoRefreshButton = (
        <Tooltip title={<IntlMessages id={'generic.action.autoRefreshStart.title'} />}>
          <IconButton color="primary" aria-label="play" onClick={toggleAutoRefresh}>
            <PlayArrowIcon />
          </IconButton>
        </Tooltip>
      );
    }

    toolbarActions.push(refreshButton);
    toolbarActions.push(autoRefreshButton);

    setBreadcrumb([{ text: dashboard.name ? <IntlMessages id={dashboard.name} /> : '' }]);
    setPageActions(toolbarActions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, autoRefresh]);

  useEffect(() => {
    let layoutNew = [];

    if (dashboard.questions.length > 0) {
      for (let i = 0; i < dashboard.questions.length; i++) {
        layoutNew.push({
          i: dashboard.questions[i]['@id'],
          x: dashboard.questions[i].x,
          y: dashboard.questions[i].y,
          w: dashboard.questions[i].w,
          h: dashboard.questions[i].h,
        });
      }
    }

    setLayout(layoutNew);
  }, [dashboard]);

  const onDateChange = () => {
    //
  };

  // auto refresh methods
  // --------------------

  const forceRefresh = () => {
    setTs(Date.now());
  };

  const toggleAutoRefresh = () => {
    if (autoRefresh) {
      setAutoRefresh(false);

      // stop auto refresh
      clearInterval(interval.current);
    } else {
      setAutoRefresh(true);

      // start auto refresh
      interval.current = setInterval(forceRefresh, 30000);
    }
  };

  /**
   * Triggered on component mount.
   * Start the interval if `autoRefresh` is `true`.
   */
  useEffect(() => {
    if (autoRefresh) {
      // start auto refresh
      interval.current = setInterval(forceRefresh, 30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Triggered on component unmount.
   * Stop the interval.
   */
  useEffect(() => {
    return () => {
      setPageActions([]);

      // Stop refreshing charts automatically
      clearInterval(interval.current);

      /**
       * Because the chart requests are stored in localstorage,
       * if we change the crawl ID, the same data are displayed,
       * to avoid this we clear dashboards.
       */
      dispatch({
        type: CLEAR_DASHBOARD,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        {urlParams.has('module') && 'logs' === urlParams.get('module') && (
          <Grid md={12} item>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'flex-start'}
              alignItems={'center'}
              style={{ float: 'left' }}>
              {props.filters.map((obj, i) => (
                <Box mr={1}>
                  <Chip
                    key={'filter-delete-' + i}
                    label={
                      <IntlMessages
                        id="bundles.LogsFilters.components.CodeFilter.action.filter.detail"
                        values={{
                          label: <IntlMessages id={obj.label} />,
                          operator: (
                            <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.' + obj.operator} />
                          ),
                          values: obj.values.join(', '),
                        }}
                      />
                    }
                    onClick={() => {
                      props.handleEditFilter(i);
                    }}
                    onDelete={() => {
                      props.handleDeleteFilter(i);
                    }}
                    color="primary"
                    className={'mr-2'}
                  />
                </Box>
              ))}

              <Box mr={1}>
                <ChooseFilters
                  projectId={project.id}
                  name={'filter'}
                  title={<IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.filter.title" />}
                  open={props.filtersOpen}
                  schema={schema}
                  schemaName={schemaName}
                  addFilter={props.addFilter}
                  addFilterSchema={props.addFilterSchema}
                  editedFilter={props.editedFilter}
                  filters={props.filters}
                  startDate={urlParams.get('startDate') || ''}
                  endDate={urlParams.get('endDate') || ''}
                  handleCancel={props.handleCloseFilteringDialog}
                  handleAddFilter={props.handleAddFilter}
                  handleCancelAddFilter={props.handleCancelAddFilter}
                  handleSaveFilter={props.handleSaveFilter}
                />

                <Tooltip
                  title={<IntlMessages id={'bundles.LogsFilters.components.CodeFilter.action.filter.open.tooltip'} />}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      props.handleOpenFilteringDialog();
                    }}>
                    <IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.filter.btn" />
                  </Button>
                </Tooltip>
              </Box>
            </Box>

            <AppDateRangePicker projectId={project.id} onDateChange={onDateChange} />
          </Grid>
        )}

        <Grid md={12} item>
          <ReactGridLayout
            className="layout"
            layout={layout}
            cols={12}
            rowHeight={30}
            width={1200}
            containerPadding={[0, 0]}
            margin={[24, 24]}
            isDraggable={false}
            isResizable={false}>
            {dashboard.questions.map((question, i) => (
              <div key={question['@id']}>
                <Box p={1} style={{ display: 'flex', height: '100%' }}>
                  {question.visualization &&
                  question.visualization.options &&
                  question.visualization.options.hideCardHeader ? (
                    <Question
                      ts={ts}
                      autoRefresh={autoRefresh}
                      rowsPerPageOptions={[]}
                      showExportAction={false}
                      question={question}
                    />
                  ) : (
                    <CmtCard className={classes.toggleCardRoot} style={{ width: '100%' }}>
                      <CmtCardHeader
                        title={question.name ? <IntlMessages id={question.name} /> : '?'}
                        separator={{
                          color: theme.palette.borderColor.main,
                        }}
                        //actions={['Actions List', actions]}
                        //actionHandleIcon={<MoreVertIcon fontSize="default" />}
                      />
                      <PerfectScrollbar>
                        <Box p={question.visualization.type === 'table' ? 0 : 6}>
                          <Question
                            ts={ts}
                            globalFilters={props.filters}
                            autoRefresh={autoRefresh}
                            rowsPerPageOptions={[]}
                            showExportAction={false}
                            question={question}
                          />
                        </Box>
                      </PerfectScrollbar>
                    </CmtCard>
                  )}
                </Box>
              </div>
            ))}
          </ReactGridLayout>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardView;

import { UPDATE_SUBSCRIPTIONS_LIST } from '../constants';

const INIT_STATE = {
  subscriptions: {
    data: [],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTIONS_LIST: {
      return {
        ...state,
        subscriptions: action.payload,
      };
    }
    default:
      return state;
  }
};

import { TOTAL_CANONICAL } from './canonical/TOTAL_CANONICAL';
import { NON_INDEXABLE_CANONICAL } from './canonical/NON_INDEXABLE_CANONICAL';
import { NON_INDEXABLE_CANONICAL_BREAKDOWN } from './canonical/NON_INDEXABLE_CANONICAL_BREAKDOWN';
import { NON_INDEXABLE_CANONICAL_BY_GROUP } from './canonical/NON_INDEXABLE_CANONICAL_BY_GROUP';
import { NON_2XX_CANONICAL } from './canonical/NON_2XX_CANONICAL';
import { NON_2XX_CANONICAL_BREAKDOWN } from './canonical/NON_2XX_CANONICAL_BREAKDOWN';
import { NON_2XX_CANONICAL_BY_GROUP } from './canonical/NON_2XX_CANONICAL_BY_GROUP';

export const CANONICAL = {
  '@id': 'canonical',
  id: 'canonical',
  name: 'sidebar.crawl.dashboard.canonical',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_CANONICAL,
    NON_INDEXABLE_CANONICAL,
    NON_2XX_CANONICAL,
    NON_INDEXABLE_CANONICAL_BREAKDOWN,
    NON_2XX_CANONICAL_BREAKDOWN,
    NON_INDEXABLE_CANONICAL_BY_GROUP,
    NON_2XX_CANONICAL_BY_GROUP,
  ],
};

import { OPEN_MODAL, CLOSE_MODAL, SELECT_PROJECT, CLEAR_PROJECT } from '../constants';

const INIT_STATE = {
  open: false,
  project: {
    id: null,
    name: '',
    domain: '',
    region: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        open: true,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        open: false,
      };
    }
    case SELECT_PROJECT: {
      return {
        ...state,
        project: action.payload,
        open: false,
      };
    }
    case CLEAR_PROJECT: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};

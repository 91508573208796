import React from 'react';

class BaseFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      operator: 'equals',
      value: null,
    };

    if (this.props.editedFilter !== null) {
      this.state = {
        operator: this.props.editedFilter.operator,
        value: this.props.editedFilter.values[0],
      };
    }

    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOperatorChange = this.handleOperatorChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const newValue = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      value: newValue,
    });
  }

  handleOperatorChange(e) {
    const value = e.target.value;

    this.setState({
      operator: value,
    });
  }

  handleSelectItem(operator, value) {
    this.setState({
      operator: operator,
      value: value,
    });
  }
}

export default BaseFilter;

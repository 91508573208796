/**
 * @param {Object} values
 */
export function validateChangePassword(values) {
  const errors = {};

  // newPassword
  const passwordRegex = /(?=.*[0-9])/;

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  } else if (values.newPassword.length < 8) {
    errors.newPassword = 'Password must be 8 characters long.';
  } else if (!passwordRegex.test(values.newPassword)) {
    errors.newPassword = 'Invalid password. Must contain one number.';
  }

  // newPasswordConfirm
  if (!values.newPasswordConfirm) {
    errors.newPasswordConfirm = 'Required';
  }

  if (values.newPassword && values.newPasswordConfirm && values.newPassword !== values.newPasswordConfirm) {
    errors.newPasswordConfirm = 'Passwords do not match';
  }

  return errors;
}

export const INTERNAL_LINKS_FIELDS = [
  {
    array: true,
    label: 'dimension.content.internal_links',
    fields: [
      {
        dimension: 'content.internal_links.tagName',
        label: 'dimension.crawled_pages.content.internal_links.tagName',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content.internal_links.text',
        label: 'dimension.crawled_pages.content.internal_links.text',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.internal_links.html',
        label: 'dimension.crawled_pages.content.internal_links.html',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.internal_links.href',
        label: 'dimension.crawled_pages.content.internal_links.href',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.internal_links.rel',
        label: 'dimension.crawled_pages.content.internal_links.rel',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.internal_links.title',
        label: 'dimension.crawled_pages.content.internal_links.title',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
    ],
  },
];

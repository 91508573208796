import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const INDEXABILITY_BY_PAGE_GROUP_BREAKDOWN = {
  '@id': 'INDEXABILITY_BY_PAGE_GROUP_BREAKDOWN',
  name: 'bundles.crawl.chart.indexability_by_page_group_breakdown.title',
  description: null,
  x: 0,
  y: 12,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'indexable'],
    dimensionsFunctions: [],
    groupBy: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',

    getDatasetsCallback: function(rows) {
      let datasets = {};
      let dataIndexable = [];
      let dataNonIndexable = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        dataIndexable.push(0);
        dataNonIndexable.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        if (true === arr['indexable']) {
          dataIndexable[i] = arr.count;
        } else {
          dataNonIndexable[i] = arr.count;
        }

        return true;
      });

      labels.map((label, i) => {
        const total = dataIndexable[i] + dataNonIndexable[i];

        dataIndexable[i] = (dataIndexable[i] / total) * 100;
        dataNonIndexable[i] = (dataNonIndexable[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: 'Indexable pages',
          data: dataIndexable,
          backgroundColor: '#1976d2',
        },
        {
          label: 'Non-indexable pages',
          data: dataNonIndexable,
          backgroundColor: '#dc004e',
        },
      ];

      return datasets;
    },

    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let indexable = chartData.datasets[element[0].datasetIndex].label; // 'Non-indexable pages'

      const labels = ['Indexable pages', 'Non-indexable pages'];

      const codes = [true, false];

      const i = labels.indexOf(indexable);
      indexable = codes[i];

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.crawled_pages.indexable',
        dimension: 'indexable',
        operator: 'equals',
        values: [indexable],
      });

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'indexable', 'indexable_reason', 'response_firstStatusCode'],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            //autoSkip: false,
            //stepSize: 10,
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'xxx',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

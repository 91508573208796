export const CONSOLE_LOGS_FIELDS = [
  {
    array: true,
    label: 'dimension.content.console_logs',
    fields: [
      {
        dimension: 'content.console_logs.type',
        label: 'dimension.crawled_pages.content.console_logs.type',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.console_logs.message',
        label: 'dimension.crawled_pages.content.console_logs.message',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content.console_logs.location',
        label: 'dimension.crawled_pages.content.console_logs.location',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
    ],
  },
];

import { UPDATE_AUTH_USER, UPDATE_LOAD_USER, DESTROY_SESSION } from '../../@jumbo/constants/ActionTypes';

export const setAuthUser = (user, plan, subscription) => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: {
        user: user,
        plan: plan,
        subscription: subscription,
      },
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const destroySession = () => {
  return dispatch => {
    dispatch({
      type: DESTROY_SESSION,
    });
  };
};

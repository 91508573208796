import React from 'react';
import PropTypes from 'prop-types';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const ExternalLinks = props => {
  // prepare datagrid rows
  let rows = [];
  for (let i = 0; i < props.externalLinks.length; i++) {
    let row = props.externalLinks[i];
    // add `id` (required by datagrid)
    row['id'] = i + 1;
    rows.push(row);
  }

  // prepare datagrid columns
  const columns = [
    {
      headerName: <IntlMessages id={'bundles.urlViewer.text.link.tagName'} />,
      field: 'tagName',
      width: 150,
    },
    {
      headerName: <IntlMessages id={'bundles.urlViewer.text.link.href'} />,
      field: 'href',
      width: 1000,
      description: 'Specifies the URL of the page the link goes to',
    },
    {
      headerName: <IntlMessages id={'bundles.urlViewer.text.link.text'} />,
      field: 'text',
      width: 300,
    },
    {
      headerName: <IntlMessages id={'bundles.urlViewer.text.link.html'} />,
      field: 'html',
      width: 150,
      hide: true,
    },
    {
      headerName: <IntlMessages id={'bundles.urlViewer.text.link.rel'} />,
      field: 'rel',
      width: 300,
      description: 'Specifies the relationship between the current document and the linked document',
    },
    {
      headerName: <IntlMessages id={'bundles.urlViewer.text.link.title'} />,
      field: 'title',
      width: 1000,
    },
  ];

  return (
    <CmtCard>
      <CmtCardHeader title={`External links (${rows.length})`} />
      <CmtCardContent>
        <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[]}
            density={'compact'}
            components={{
              Toolbar: GridToolbar,
            }}
            disableSelectionOnClick
            disableDensitySelector
          />
        </div>
      </CmtCardContent>
    </CmtCard>
  );
};

ExternalLinks.propTypes = {
  externalLinks: PropTypes.array.isRequired,
};

export default ExternalLinks;

import React from 'react';
import { useDispatch } from 'react-redux';

import ProjectForm from '../form/ProjectForm';
import { handleCreateProject } from '../actions';

const ProjectCreate = () => {
  const dispatch = useDispatch();

  return (
    <ProjectForm
      data={{ name: '', domain: '', region: '' }}
      onSubmit={(authUser, values, location, history) => dispatch(handleCreateProject(authUser, values, location, history))}
      disableDomainField={false}
      disableRegionField={false}
    />
  );
};

export default ProjectCreate;

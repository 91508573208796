import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { Home, CreditCard } from '@material-ui/icons';
import BarChartIcon from '@material-ui/icons/BarChart';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import Spider from './spiderLogo';

import { getCrawl } from 'services/api/MyApiUtil';
import { useCrawlId, getCrawlSubMenus } from './SideBarHelpers';
import { LOG_ANALYZER_DASHBOARDS } from 'bundles/logAnalyzer/constants';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const { project } = useSelector(({ projectSelector }) => projectSelector);

  // eslint-disable-next-line
  const [ crawlId, setCrawlId ] = useCrawlId();
  const [crawl, setCrawl] = useState(null);

  // get project ID
  const projectId = project && project.id ? project.id : '';

  useEffect(() => {
    if (crawlId) {
      console.debug('[SideBar] call getCrawl()...');
      getCrawl(crawlId)
        .then(result => {
          console.debug('[SideBar] setCrawl()');
          setCrawl(result);
        })
        .catch(function(error) {
          //dispatch(fetchError(error.message));
        });
    }

    if (!crawlId && crawl) {
      setCrawl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlId]);

  const crawlDashboards = getCrawlSubMenus(projectId, crawl);

  let logAnalyzerDashboards = [];
  for (let i = 0; i < LOG_ANALYZER_DASHBOARDS.length; i++) {
    logAnalyzerDashboards.push({
      name: <IntlMessages id={LOG_ANALYZER_DASHBOARDS[i].name} />,
      type: 'item',
      link: '/dashboards/view/' + LOG_ANALYZER_DASHBOARDS[i].id + '?predefined=1&module=logs&projectId=' + projectId,
    });
  }

  const navigationMenus = [
    {
      //name: <IntlMessages id={'sidebar.main'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.projects'} />,
          icon: <Home />,
          type: 'item',
          link: '/projects',
        },
        {
          name: <IntlMessages id={'sidebar.crawl'} />,
          icon: <Spider />,
          type: 'collapse',
          children: [
            {
              name: <IntlMessages id={'sidebar.crawl.crawls'} />,
              type: 'item',
              link: '/crawls?projectId=' + projectId,
            },
            ...crawlDashboards,
            {
              name: <IntlMessages id={'sidebar.crawl.schedules'} />,
              type: 'item',
              link: '/crawl_schedules?projectId=' + projectId,
            },
          ],
        },
        {
          name: <IntlMessages id={'sidebar.logAnalyzer'} />,
          icon: <BarChartIcon />,
          type: 'collapse',
          children: [
            ...logAnalyzerDashboards,
            ...[
              {
                name: <IntlMessages id={'sidebar.logs.exploreData'} />,
                type: 'item',
                link: `/log_analyzer/explorer?projectId=${projectId}&schema=logs&measures=[]`,
              },
              {
                name: <IntlMessages id={'sidebar.logs.send'} />,
                type: 'item',
                link: '/log_analyzer/configure?projectId=' + projectId,
              },
            ],
          ],
        },
        {
          name: <IntlMessages id={'sidebar.segmentation'} />,
          icon: <BookmarksIcon />,
          type: 'item',
          link: '/segmentation?projectId=' + projectId,
        },
        /*
        {
          name: <IntlMessages id={'sidebar.dashboards'} />,
          icon: <Dashboard />,
          type: 'item',
          link: '/dashboards?projectId=' + projectId,
        },
        */
        {
          name: <IntlMessages id={'sidebar.billing'} />,
          icon: <CreditCard />,
          type: 'item',
          link: '/billing/invoices',
        },
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

SideBar.whyDidYouRender = {
  logOnDifferentValues: true,
};

export default SideBar;

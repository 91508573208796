import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const formatBytes = (a, b = 2) => {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));

  return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
};

const PageWeight = props => {
  return (
    <CmtCard>
      <CmtCardHeader title={`Page weight`} />
      <CmtCardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>documentEncodedSize</TableCell>
              <TableCell>{formatBytes(props.data.performance.dom.documentEncodedSize)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>documentDecodedSize</TableCell>
              <TableCell>{formatBytes(props.data.performance.dom.documentDecodedSize)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>assetsEncodedSize</TableCell>
              <TableCell>{formatBytes(props.data.performance.dom.assetsEncodedSize)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>assetsDecodedSize</TableCell>
              <TableCell>{formatBytes(props.data.performance.dom.assetsDecodedSize)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>totalEncodedSize</TableCell>
              <TableCell>{formatBytes(props.data.performance.dom.totalEncodedSize)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>totalDecodedSize</TableCell>
              <TableCell>{formatBytes(props.data.performance.dom.totalDecodedSize)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CmtCardContent>
    </CmtCard>
  );
};

PageWeight.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageWeight;

import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer/Drawer';
import DrawerHeader from './DrawerHeader';

class MyDrawer extends React.Component {
  render() {
    return (
      <Drawer
        anchor={this.props.anchor || 'right'}
        open={this.props.open}
        onClose={() => {
          this.props.handleClose();
        }}>
        <div className="color-theme" style={{ width: this.props.width || '300px' }}>
          {this.props.withHeader && (
            <DrawerHeader
              title={this.props.headerTitle}
              handleClose={() => {
                this.props.handleClose();
              }}
            />
          )}

          <div className="color-theme-body">{this.props.children}</div>
        </div>
      </Drawer>
    );
  }
}

export default MyDrawer;

MyDrawer.propTypes = {
  open: PropTypes.bool,
  withHeader: PropTypes.bool,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClose: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody } from '@material-ui/core';
import CrawlListTableHead from './CrawlListTableHead';
import CrawlListTableRow from './CrawlListTableRow';

/**
 * This component displays the crawl list table.
 */
const CrawlList = props => {
  return (
    <Table>
      <CrawlListTableHead order={props.order} orderBy={props.orderBy} handleChangeSort={props.handleChangeSort} />
      <TableBody>
        {props.crawls['hydra:member'].map(entity => (
          <CrawlListTableRow
            key={entity.id}
            durationValues={props.durationValues}
            id={entity.id}
            crawlStartedAt={entity.crawlStartedAt}
            crawlEndedAt={entity.crawlEndedAt}
            name={entity.name}
            status={entity.status}
            website={entity.website}
            urlsCrawled={
              entity.hasOwnProperty('report') && entity.report && entity.report.hasOwnProperty('urls')
                ? entity.report.urls.crawled
                : null
            }
            handleDelete={props.handleDelete}
            handleStop={props.handleStop}
          />
        ))}
      </TableBody>
    </Table>
  );
};

CrawlList.propTypes = {
  crawls: PropTypes.object.isRequired,
  durationValues: PropTypes.object.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStop: PropTypes.func.isRequired,
};

export default CrawlList;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import Select from 'react-select';
import { Button, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import HelpIcon from '@material-ui/icons/Help';
import MyDrawer from 'components/Drawer';

import { Formik } from 'formik';
import { validateCrawlProfile } from '../validation/CrawlProfile';
import { handleDeleteCrawlProfile } from '../actions';
import {
  handleCreateSegmentationProfile,
  handleGetSegmentationProfiles,
  handleClearSegmentationProfile,
} from '../../segmentation/actions';
import ExcludedUrl from '../components/ExcludedUrl';

import { GRID_SPACING } from '../../../@jumbo/constants/ThemeOptions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SegmentProfileForm from '../../segmentation/form/SegmentProfileForm';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const Rules = SortableContainer(({ excludedUrls, handleAddRule, handleRemoveRule, handleRuleInputChange }) => {
  return (
    <Box>
      {excludedUrls.map((value, index) => (
        <ExcludedUrl
          key={index}
          index={index}
          i={index}
          totalItems={excludedUrls.length}
          value={value}
          handleAddRule={handleAddRule}
          handleRemoveRule={handleRemoveRule}
          handleRuleInputChange={handleRuleInputChange}
        />
      ))}
    </Box>
  );
});

const CrawlProfileForm = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { segmentationProfileList } = useSelector(({ segmentation }) => segmentation);
  const { crawlProfile } = useSelector(({ crawlProfile }) => crawlProfile);

  const dispatch = useDispatch();

  // parallel URLs states
  const [parallelUrlsMax, setParallelUrlsMax] = useState(8);
  const [parallelUrlsMarks, setParallelUrlsMarks] = useState([]);
  // excluded URLs states
  const [excludedUrls, setExcludedUrls] = useState(['']);
  // segmentation states
  const [segmentationValue, setSegmentationValue] = useState(null);
  const [segmentationOptions, setSegmentationOptions] = useState([]);
  const [segmentationOpenDrawer, setSegmentationOpenDrawer] = useState(false);

  /**
   * Refresh segmentation <select> choices
   */
  const refreshSegmentationOptions = entities => {
    let options = [];
    for (const obj of entities) {
      options.push({
        value: obj['@id'],
        label: obj.name,
      });
    }

    setSegmentationOptions(options);
  };

  /**
   * Create Segmentation Profile
   *
   * @param authUser
   * @param values
   */
  const handleSubmitSegmentation = (authUser, values) => {
    const onSuccess = segmentationProfile => {
      // close segmentation profile drawer
      setSegmentationOpenDrawer(false);
      // refresh segmentation profiles list
      dispatch(
        handleGetSegmentationProfiles(
          props.projectId,
          {
            order: {
              name: 'asc',
            },
          },
          result => {
            // refresh segmentation <select> choices
            refreshSegmentationOptions(result['hydra:member']);
            // set segmentation <select> value
            setSegmentationValue({
              value: segmentationProfile['@id'],
              label: segmentationProfile.name,
            });
            dispatch(handleClearSegmentationProfile());
          },
        ),
      );
    };

    dispatch(handleCreateSegmentationProfile(authUser, props.projectId, values, onSuccess));
  };

  useEffect(() => {
    dispatch(
      handleGetSegmentationProfiles(props.projectId, {
        order: {
          name: 'asc',
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // refresh segmentation <select> choices
    refreshSegmentationOptions(segmentationProfileList['hydra:member']);

    /**
     * set segmentation <select> value
     */
    if (crawlProfile.segmentationProfile) {
      const result = segmentationOptions.filter(obj => {
        return obj.value === crawlProfile.segmentationProfile;
      });

      setSegmentationValue(result[0]);
    } else {
      setSegmentationValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentationProfileList]);

  useEffect(() => {
    if (true === props.data.settings.javascript) {
      setParallelUrlsMax(3);
    } else {
      setParallelUrlsMax(8);
    }

    /**
     * set segmentation <select> value when a crawl profile is selected or cleared
     */
    if (props.data.segmentationProfile) {
      const result = segmentationOptions.filter(obj => {
        return obj.value === props.data.segmentationProfile;
      });

      setSegmentationValue(result[0]);
    } else {
      setSegmentationValue(null);
    }

    dispatch(handleClearSegmentationProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  /**
   * Change parallel URLs choices
   */
  useEffect(() => {
    let marks = [];

    for (let i = 1; i <= parallelUrlsMax; i++) {
      marks.push({
        value: i,
        label: i.toString(),
      });
    }

    setParallelUrlsMarks(marks);
  }, [parallelUrlsMax]);

  // handle click event of the Add button
  const handleAddRule = () => {
    setExcludedUrls([...excludedUrls, '']);
  };

  // handle click event of the Remove button
  const handleRemoveRule = index => {
    const list = [...excludedUrls];
    list.splice(index, 1);
    setExcludedUrls(list);
  };

  // handle input change
  const handleRuleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...excludedUrls];
    list[index] = value;
    setExcludedUrls(list);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setExcludedUrls(arrayMove(excludedUrls, oldIndex, newIndex));
  };

  useEffect(() => {
    if (Array.isArray(crawlProfile.settings.excludedUrls) && crawlProfile.settings.excludedUrls.length > 0) {
      setExcludedUrls(crawlProfile.settings.excludedUrls);
    } else {
      setExcludedUrls(['']);
    }
  }, [crawlProfile]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      validate={values => validateCrawlProfile(values)}
      onSubmit={values => props.onSubmit(authUser, values)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.name.label" />
            </InputLabel>
            <TextField
              id="name"
              name="name"
              placeholder={'My profile'}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              variant={'outlined'}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.userAgent.label" />
            </InputLabel>
            <TextField
              id="userAgent"
              name="settings[userAgent]"
              placeholder={'SeoLogs/1.0'}
              value={values.settings.userAgent || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.maxUrls.label" />
            </InputLabel>
            <TextField
              id="maxUrls"
              name="settings[maxUrls]"
              type={'number'}
              placeholder={'10000'}
              value={values.settings.maxUrls || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.maxDepth.label" />
            </InputLabel>
            <TextField
              id="maxDepth"
              name="settings[maxDepth]"
              type={'number'}
              value={values.settings.maxDepth || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.crawlInterval.label" />
            </InputLabel>
            <TextField
              id="crawlInterval"
              name="settings[crawlInterval]"
              type={'number'}
              value={values.settings.crawlInterval || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.javascript.label" />
            </InputLabel>
            <Switch
              id="javascript"
              name="settings[javascript]"
              checked={values.settings.javascript || false}
              onChange={e => {
                const max = true === e.currentTarget.checked ? 3 : 8;

                setParallelUrlsMax(max);

                if (values.settings.parallelUrls > max) {
                  setFieldValue('settings[parallelUrls]', max, false);
                }

                handleChange(e);
              }}
              color="primary"
              inputProps={{ 'aria-label': 'settings' }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.includeSubDomains.label" />
            </InputLabel>
            <Switch
              id="includeSubDomains"
              name="settings[includeSubDomains]"
              checked={values.settings.includeSubDomains || false}
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'settings' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mb={3}>
              <InputLabel>
                <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.jsonPages.label" />
              </InputLabel>
            </Box>

            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography color={'textSecondary'} variant={'body2'} component={'p'}>
                  <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectJsonPages.label" />
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">
                        <Box mb={3}>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectJsonPages.help.1" />
                        </Box>
                        <Box mb={3}>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectJsonPages.help.2" />
                        </Box>
                        <Box>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectJsonPages.help.3" />
                        </Box>
                      </Typography>
                    </React.Fragment>
                  }>
                  <HelpIcon color={'action'} fontSize={'small'} />
                </Tooltip>
              </Grid>
            </Grid>

            <Switch
              id="collectJson"
              name="settings[collectJson]"
              checked={values.settings.collectJson || false}
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'settings' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mb={3}>
              <InputLabel>
                <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.xmlPages.label" />
              </InputLabel>
            </Box>

            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography color={'textSecondary'} variant={'body2'} component={'p'}>
                  <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectXmlPages.label" />
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">
                        <Box mb={3}>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectXmlPages.help.1" />
                        </Box>
                        <Box mb={3}>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectXmlPages.help.2" />
                        </Box>
                        <Box>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.collectXmlPages.help.3" />
                        </Box>
                      </Typography>
                    </React.Fragment>
                  }>
                  <HelpIcon color={'action'} fontSize={'small'} />
                </Tooltip>
              </Grid>
            </Grid>

            <Switch
              id="collectXml"
              name="settings[collectXml]"
              checked={values.settings.collectXml || false}
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'settings' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mb={3}>
              <InputLabel>
                <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.noFollowLinks.label" />
              </InputLabel>
            </Box>

            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography color={'textSecondary'} variant={'body2'} component={'p'}>
                  <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.includeNoFollowLinks.label" />
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">
                        <Box>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.includeNoFollowLinks.help.1" />
                        </Box>
                      </Typography>
                    </React.Fragment>
                  }>
                  <HelpIcon color={'action'} fontSize={'small'} />
                </Tooltip>
              </Grid>
            </Grid>

            <Switch
              id="includeNoFollowLinks"
              name="settings[includeNoFollowLinks]"
              checked={values.settings.hasOwnProperty('includeNoFollowLinks') ? values.settings.includeNoFollowLinks : false}
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'settings' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mb={3}>
              <InputLabel>
                <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.robotsTxt.label" />
              </InputLabel>
            </Box>

            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography color={'textSecondary'} variant={'body2'} component={'p'}>
                  <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.respectRobotsFile.label" />
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body2">
                        <Box mb={3}>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.respectRobotsFile.help.1" />
                        </Box>
                        <Box mb={3}>
                          <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.respectRobotsFile.help.2" />
                        </Box>
                      </Typography>
                    </React.Fragment>
                  }>
                  <HelpIcon color={'action'} fontSize={'small'} />
                </Tooltip>
              </Grid>
            </Grid>

            <Switch
              id="respectRobotsFile"
              name="settings[respectRobotsFile]"
              checked={values.settings.hasOwnProperty('respectRobotsFile') ? values.settings.respectRobotsFile : true}
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'settings' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mb={3}>
              <InputLabel>
                <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.parallelUrls.label" />
              </InputLabel>
            </Box>
            <Slider
              id="parallelUrls"
              name="settings[parallelUrls]"
              defaultValue={values.settings.parallelUrls || 1}
              value={values.settings.parallelUrls || 1}
              valueLabelDisplay="auto"
              step={1}
              marks={parallelUrlsMarks}
              min={1}
              max={parallelUrlsMax}
              onChange={(event, value) => {
                setFieldValue('settings[parallelUrls]', value, false);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mb={3}>
              <InputLabel>
                <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.excludedUrls.label" />
              </InputLabel>
            </Box>

            <Typography color={'textSecondary'} variant={'body2'} component={'p'}>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.excludedUrls.description" />
            </Typography>

            <Box pt={3} pb={3}>
              <Rules
                excludedUrls={excludedUrls}
                onSortEnd={onSortEnd}
                handleAddRule={handleAddRule}
                handleRemoveRule={handleRemoveRule}
                handleRuleInputChange={handleRuleInputChange}
                useDragHandle={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <MyDrawer
              open={segmentationOpenDrawer}
              withHeader={true}
              headerTitle={'Segmentation'}
              handleClose={() => {
                setSegmentationOpenDrawer(false);
              }}
              width={'600px'}>
              <Box p={6}>
                <SegmentProfileForm
                  id={null}
                  projectId={props.projectId}
                  data={{
                    name: 'New profile',
                    rules: [{ name: '', pattern: '' }],
                  }}
                  handleCancel={() => {
                    setSegmentationOpenDrawer(false);
                  }}
                  onSubmit={handleSubmitSegmentation}
                />
              </Box>
            </MyDrawer>

            <InputLabel>
              <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.segmentation.label" />
            </InputLabel>

            <Grid container spacing={GRID_SPACING} alignContent={'center'} alignItems={'center'} justify={'flex-start'}>
              <Grid item md={6}>
                <Select
                  placeholder={
                    <IntlMessages id="bundles.crawlProfile.entity.crawlProfile.settings.segmentation.placeholder" />
                  }
                  defaultValue={segmentationValue}
                  value={segmentationValue}
                  isClearable={true}
                  //isLoading={crawlProfilesListLoading}
                  options={segmentationOptions}
                  onChange={(newValue, actionMeta) => {
                    setSegmentationValue(newValue);
                    setFieldValue('segmentationProfile', newValue ? newValue.value : null, false);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    setSegmentationOpenDrawer(true);
                  }}>
                  <Box mr={2}>
                    <AddCircleOutlineIcon />
                  </Box>
                  <IntlMessages id="bundles.crawlProfile.action.createSegmentation" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BackButton variant="outlined" color="primary">
              <IntlMessages id="bundles.crawlProfile.action.cancel" />
            </BackButton>

            <Box ml={6} clone>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  setFieldValue('settings[excludedUrls]', excludedUrls, false);
                  setFieldValue('segmentationProfile', segmentationValue ? segmentationValue.value : null, false);
                  setFieldValue('startCrawl', 0, false);
                  handleSubmit();
                }}>
                <IntlMessages id="bundles.crawlProfile.action.save" />
              </Button>
            </Box>

            <Box ml={6} clone>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  setFieldValue('settings[excludedUrls]', excludedUrls, false);
                  setFieldValue('segmentationProfile', segmentationValue ? segmentationValue.value : null, false);
                  setFieldValue('startCrawl', 1, false);
                  handleSubmit();
                }}>
                <IntlMessages id="bundles.crawlProfile.action.saveAndStart" />
              </Button>
            </Box>

            {props.id && (
              <Box ml={6} clone>
                <Button type="button" variant="contained" onClick={() => dispatch(handleDeleteCrawlProfile(props.id))}>
                  <IntlMessages id="bundles.crawlProfile.action.delete" />
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default CrawlProfileForm;

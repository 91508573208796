import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Alert } from '@material-ui/lab';
import { Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

class SuccessAlert extends React.Component {
  render() {
    const projectId = this.props.projectId;

    return (
      <Alert severity="success" style={{ marginBottom: 0 }}>
        <Box mb={6}>
          <h3>
            <IntlMessages id="pages.logAnalyzer.installScript.title.success" />
          </h3>
        </Box>

        <Box mb={6}>
          <IntlMessages id="pages.logAnalyzer.installScript.text.1.success" />
          <br />
          <IntlMessages id="pages.logAnalyzer.installScript.text.2.success" />
        </Box>

        <Link to={'/log_analyzer/explorer?projectId=' + projectId + '&schema=logs'}>
          <Button variant="contained" color="primary">
            <IntlMessages id="pages.logAnalyzer.installScript.btn.success" />
          </Button>
        </Link>
      </Alert>
    );
  }
}

export default SuccessAlert;

import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { LIST_CRAWL_PROFILES, GET_CRAWL_PROFILE, CLEAR_CRAWL_PROFILE } from '../constants';
import {
  createCrawlProfile,
  getCrawlProfile,
  getCrawlProfileList,
  updateCrawlProfile,
  deleteCrawlProfile,
  createCrawl,
} from 'services/api/MyApiUtil';
import { showMessage } from 'redux/actions';

export const handleGetCrawlProfiles = payload => {
  return dispatch => {
    dispatch(fetchStart());

    getCrawlProfileList(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: LIST_CRAWL_PROFILES,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleGetCrawlProfile = id => {
  return dispatch => {
    dispatch(fetchStart());

    getCrawlProfile(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_CRAWL_PROFILE,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleClearCrawlProfile = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_CRAWL_PROFILE,
    });
  };
};

export const handleCreateCrawlProfile = (user, projectId, payload, history) => {
  return dispatch => {
    dispatch(fetchStart());

    payload['project'] = '/api/projects/' + projectId;

    if (Array.isArray(payload.settings.excludedUrls)) {
      // remove empty lines
      payload.settings.excludedUrls = payload.settings.excludedUrls.filter(item => item);
    }

    createCrawlProfile(payload)
      .then(result => {
        result.settings.excludedUrls.push('');

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawlProfile.notification.create.success', 'success'));
        dispatch({
          type: GET_CRAWL_PROFILE,
          payload: result,
        });

        if (payload.startCrawl) {
          // start crawl and redirect to data explorer
          dispatch(handleCreateCrawl(projectId, result.id, history));
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleUpdateCrawlProfile = (id, projectId, payload, history) => {
  return dispatch => {
    dispatch(fetchStart());

    if (Array.isArray(payload.settings.excludedUrls)) {
      // remove empty lines
      payload.settings.excludedUrls = payload.settings.excludedUrls.filter(item => item);
    }

    updateCrawlProfile(id, payload)
      .then(result => {
        result.settings.excludedUrls.push('');

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawlProfile.notification.update.success', 'success'));
        dispatch({
          type: GET_CRAWL_PROFILE,
          payload: result,
        });

        if (payload.startCrawl) {
          // start crawl and redirect to data explorer
          dispatch(handleCreateCrawl(projectId, id, history));
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleDeleteCrawlProfile = id => {
  return dispatch => {
    dispatch(fetchStart());

    deleteCrawlProfile(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawlProfile.notification.deleted.success', 'success'));
        dispatch(handleGetCrawlProfiles());
        dispatch({
          type: CLEAR_CRAWL_PROFILE,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleCreateCrawl = (projectId, crawlProfileId, history) => {
  return dispatch => {
    dispatch(fetchStart());

    createCrawl(projectId, crawlProfileId, {})
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawl.notification.start.success', 'success'));

        // &crawlId=${result.crawl.id}
        history.push(`/crawls?projectId=${projectId}`);
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

import { fetchError, fetchStart, fetchSuccess, redirect, showMessage } from 'redux/actions';
import {
  UPDATE_PRICES_LIST,
  UPDATE_CURRENT_PRICES,
  UPDATE_SELECTED_PRICES,
  UPDATE_SUBSCRIPTION_ITEMS,
  UPDATE_INCOMING_INVOICE,
  UPDATE_DESIRED_PAYMENT_METHOD,
  RESET_CHECKOUT,
} from '../constants';
import { getCountryList } from '../../../../services/api/MyApiUtil';
import {
  getCustomer,
  getPrice,
  getProductAndPrices,
  getSubscriptionList,
  getSubscriptionItem,
  getProrationPreview,
  createSubscription,
  updateSubscription,
} from 'services/stripe/StripeUtil';
import { UPDATE_SUBSCRIPTION_USER, UPDATE_PLAN_USER } from '@jumbo/constants/ActionTypes';
import {
  STRIPE_CUSTOMER_ID_FIELD,
  CREATE_SUBSCRIPTION_EXTRA_OPTIONS,
  UPDATE_SUBSCRIPTION_EXTRA_OPTIONS,
  STRIPE_PRODUCTS,
} from '../../constants';

export const handleGetPrices = paymentInterval => {
  return async dispatch => {
    try {
      dispatch(fetchStart());

      let prices = {};

      for (let i = 0; i < STRIPE_PRODUCTS.length; i++) {
        const result = await getProductAndPrices(STRIPE_PRODUCTS[i].id); // {product: {...}, prices: {...}}

        const moduleCode = result.product.metadata.module;

        prices[moduleCode] = [];

        for (let j = 0; j < result.prices.data.length; j++) {
          if (result.prices.data[j].hasOwnProperty('recurring') && result.prices.data[j].recurring) {
            if (result.prices.data[j].recurring.interval === paymentInterval) {
              prices[moduleCode].push(result.prices.data[j]);
            }
          }
        }
      }

      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_PRICES_LIST,
        payload: prices,
      });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };
};

export const handleGetCurrentPrices = authUser => {
  return async dispatch => {
    dispatch(fetchStart());

    let currentPrices = {
      crawler: null,
      log_analyzer: null,
    };

    let subscriptionItems = [];
    const subscriptions = await getSubscriptionList(authUser[STRIPE_CUSTOMER_ID_FIELD]);
    if (subscriptions && subscriptions.data.length > 0) {
      const subscription = subscriptions.data[0];

      for (let i = 0; i < subscription.items.data.length; i++) {
        const si = subscription.items.data[i];

        subscriptionItems.push({
          id: si.id,
        });

        for (let i = 0; i < STRIPE_PRODUCTS.length; i++) {
          if (STRIPE_PRODUCTS[i].code === si.price.metadata.module) {
            currentPrices[STRIPE_PRODUCTS[i].code] = si.price;
          }
        }
      }
    }

    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_CURRENT_PRICES,
      payload: currentPrices,
    });
    dispatch({
      type: UPDATE_SUBSCRIPTION_ITEMS,
      payload: subscriptionItems,
    });
  };
};

export const handleSelectPrice = (priceId, productCode, selectedPrices, subscriptionItems) => {
  return async dispatch => {
    dispatch(fetchStart());

    getPrice(priceId).then(async result => {
      selectedPrices[productCode] = result;

      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_SELECTED_PRICES,
        payload: selectedPrices,
      });

      // iterate over subscriptionItems
      for (let i = 0; i < subscriptionItems.length; i++) {
        const obj = subscriptionItems[i];
        // if property `id` exists, it means that the item is active in the current subscription
        if (obj.hasOwnProperty('id')) {
          let si = await getSubscriptionItem(obj.id);
          // if this item has the same productCode
          if (si.price.metadata.module === productCode) {
            // then delete it
            subscriptionItems[i]['deleted'] = true;
          }
        } else if (obj.hasOwnProperty('price')) {
          let price = await getPrice(obj.price);
          if (price.metadata.module === productCode) {
            // then delete it
            subscriptionItems.splice(i, 1);
          }
        }
      }

      subscriptionItems.push({
        price: priceId,
      });

      dispatch({
        type: UPDATE_SUBSCRIPTION_ITEMS,
        payload: subscriptionItems,
      });
    });
  };
};

export const handleRemovePrice = (productCode, selectedPrices, subscriptionItems) => {
  return async dispatch => {
    dispatch(fetchStart());

    // iterate over subscriptionItems
    for (let i = 0; i < subscriptionItems.length; i++) {
      const obj = subscriptionItems[i];
      // if property `id` exists, it means that the item is active in the current subscription
      if (obj.hasOwnProperty('id')) {
        let si = await getSubscriptionItem(obj.id);
        // if this item has the same productCode
        if (si.price.metadata.module === productCode) {
          // then delete it
          subscriptionItems[i]['deleted'] = true;
        }
      } else if (obj.hasOwnProperty('price')) {
        let price = await getPrice(obj.price);
        if (price.metadata.module === productCode) {
          // then delete it
          subscriptionItems.splice(i, 1);
        }
      }
    }

    if (true === selectedPrices.hasOwnProperty(productCode)) {
      selectedPrices[productCode] = null;
    }

    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_SELECTED_PRICES,
      payload: selectedPrices,
    });

    dispatch({
      type: UPDATE_SUBSCRIPTION_ITEMS,
      payload: subscriptionItems,
    });
  };
};

export const handleGetUpcomingInvoice = (authUser, subscriptionUser, subscriptionItems) => {
  return async dispatch => {
    if (0 === subscriptionItems.length) {
      return;
    }

    dispatch(fetchStart());

    const countries = await getCountryList();
    const customer = await getCustomer(authUser[STRIPE_CUSTOMER_ID_FIELD]);

    let country = null;

    if (customer.shipping && customer.shipping.address && customer.shipping.address.country) {
      country = countries['hydra:member'].find(obj => obj.code === customer.shipping.address.country);
    }

    if (country && true === country.isEU && !country.stripeId) {
      throw new Error(`country with code ${country.code} does not have a stripeId`);
    }

    let payload = {
      customer: authUser[STRIPE_CUSTOMER_ID_FIELD],
      subscription_proration_behavior: 'always_invoice',
      //      'subscription_proration_date'     => time(),
      subscription_items: subscriptionItems,
    };

    if (country && true === country.isEU) {
      payload['subscription_default_tax_rates'] = [country.stripeId];
    }

    if (subscriptionUser) {
      payload['subscription'] = subscriptionUser.id;
    }

    getProrationPreview(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_INCOMING_INVOICE,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handlePay = (authUser, subscriptionUser, subscriptionItems, desiredPaymentMethodId, onError) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());

      const countries = await getCountryList();
      const customer = await getCustomer(authUser[STRIPE_CUSTOMER_ID_FIELD]);

      let country = null;

      if (customer.shipping && customer.shipping.address && customer.shipping.address.country) {
        country = countries['hydra:member'].find(obj => obj.code === customer.shipping.address.country);
      }

      if (country && true === country.isEU && !country.stripeId) {
        throw new Error(`country with code ${country.code} does not have a stripeId`);
      }

      let newSubscription = null;

      if (!subscriptionUser) {
        let minimalPayload = {
          customer: authUser[STRIPE_CUSTOMER_ID_FIELD],
          default_payment_method: desiredPaymentMethodId,
          items: subscriptionItems,
        };

        if (country && true === country.isEU) {
          minimalPayload['default_tax_rates'] = [country.stripeId];
        }

        const subscriptions = await getSubscriptionList(authUser[STRIPE_CUSTOMER_ID_FIELD], {
          status: 'all',
        });

        minimalPayload['trial_from_plan'] = subscriptions.data.length === 0 ? true : false;

        let payload = {
          ...minimalPayload,
          ...CREATE_SUBSCRIPTION_EXTRA_OPTIONS,
        };

        newSubscription = await createSubscription(payload);
      } else {
        let minimalPayload = {
          default_payment_method: desiredPaymentMethodId,
          items: subscriptionItems,
        };

        if (country && true === country.isEU) {
          minimalPayload['default_tax_rates'] = [country.stripeId];
        }

        const payload = {
          ...minimalPayload,
          ...UPDATE_SUBSCRIPTION_EXTRA_OPTIONS,
        };

        newSubscription = await updateSubscription(subscriptionUser.id, payload);
      }

      let newPlan = null;

      for (let i = 0; i < newSubscription.items.data.length; i++) {
        for (let j = 0; j < STRIPE_PRODUCTS.length; j++) {
          if (
            true === STRIPE_PRODUCTS[j].mainPlan &&
            STRIPE_PRODUCTS[j].code === newSubscription.items.data[i].price.metadata.module
          ) {
            newPlan = newSubscription.items.data[i].price;
          }
        }
      }

      dispatch({
        type: UPDATE_SUBSCRIPTION_USER,
        payload: newSubscription,
      });
      dispatch({
        type: UPDATE_PLAN_USER,
        payload: newPlan,
      });
      dispatch({
        type: RESET_CHECKOUT,
      });
      dispatch(fetchSuccess());
      dispatch(redirect('/checkout/success'));
    } catch (e) {
      dispatch(fetchError());
      dispatch(redirect('/checkout/failed'));
    }
  };
};

export const handleUpdateDesiredPaymentMethod = (pm, showNotification) => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(fetchSuccess());

    if (true === showNotification) {
      dispatch(showMessage('bundles.checkout.notification.changePaymentMethod.success', 'success'));
    }

    dispatch({
      type: UPDATE_DESIRED_PAYMENT_METHOD,
      payload: pm,
    });
  };
};

import React, { useContext, useEffect } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Box, Paper, Typography } from '@material-ui/core';
import ProjectEdit from '../components/ProjectEdit';

const ProjectEditPage = props => {
  const { setBreadcrumb } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumb([
      {
        to: '/projects',
        text: <IntlMessages id="bundles.project.routes.index.title" />,
      },
      { text: <IntlMessages id="bundles.project.routes.edit.title" /> },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Box mb={6}>
        <Typography variant="h1">
          <IntlMessages id="bundles.project.routes.edit.title" />
        </Typography>
      </Box>

      <Paper>
        <Box p={6}>
          <ProjectEdit id={props.match.params.id} store={props.store} />
        </Box>
      </Paper>
    </div>
  );
};

export default ProjectEditPage;

import {
  UPDATE_PRICES_LIST,
  UPDATE_CURRENT_PRICES,
  UPDATE_SELECTED_PRICES,
  UPDATE_SUBSCRIPTION_ITEMS,
  UPDATE_INCOMING_INVOICE,
  UPDATE_DESIRED_PAYMENT_METHOD,
  RESET_CHECKOUT,
} from '../constants';

const INIT_STATE = {
  currentPrices: {
    crawler: null,
    log_analyzer: null,
  },
  selectedPrices: {
    crawler: null,
    log_analyzer: null,
  },
  prices: {
    crawler: [],
    log_analyzer: [],
  },
  subscriptionItems: [],
  incomingInvoice: null,
  desiredPaymentMethod: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PRICES_LIST: {
      return {
        ...state,
        prices: action.payload,
      };
    }
    case UPDATE_CURRENT_PRICES: {
      return {
        ...state,
        currentPrices: action.payload,
      };
    }
    case UPDATE_SELECTED_PRICES: {
      return {
        ...state,
        selectedPrices: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION_ITEMS: {
      return {
        ...state,
        subscriptionItems: action.payload,
      };
    }
    case UPDATE_INCOMING_INVOICE: {
      return {
        ...state,
        incomingInvoice: action.payload,
      };
    }
    case UPDATE_DESIRED_PAYMENT_METHOD: {
      return {
        ...state,
        desiredPaymentMethod: action.payload,
      };
    }
    case RESET_CHECKOUT: {
      return {
        ...state,
        currentPrices: {
          crawler: null,
          log_analyzer: null,
        },
        selectedPrices: {
          crawler: null,
          log_analyzer: null,
        },
        prices: {
          crawler: [],
          log_analyzer: [],
        },
        subscriptionItems: [],
        desiredPaymentMethod: null,
      };
    }
    default:
      return state;
  }
};

import { UPDATE_DEFAULT_PAYMENT_METHOD, UPDATE_PAYMENT_METHODS_LIST, SHOW_ADD_CARD_FORM } from '../constants';

const INIT_STATE = {
  showAddCardForm: false,
  paymentMethodsList: [],
  defaultPaymentMethod: {
    id: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_METHODS_LIST: {
      return {
        ...state,
        paymentMethodsList: action.payload,
      };
    }
    case UPDATE_DEFAULT_PAYMENT_METHOD: {
      return {
        ...state,
        defaultPaymentMethod: action.payload,
      };
    }
    case SHOW_ADD_CARD_FORM: {
      return {
        ...state,
        showAddCardForm: action.payload,
      };
    }
    default:
      return state;
  }
};

import { TOTAL_EXTERNAL_HOSTS } from './external_links/TOTAL_EXTERNAL_HOSTS';
import { TOO_MANY_EXTERNAL_LINKS } from './external_links/TOO_MANY_EXTERNAL_LINKS';
import { AVERAGE_EXTERNAL_LINK } from './external_links/AVERAGE_EXTERNAL_LINK';
import { TOTAL_EXTERNAL_LINKS_BY_GROUP } from './external_links/TOTAL_EXTERNAL_LINKS_BY_GROUP';
import { AVERAGE_EXTERNAL_LINKS_BY_GROUP } from './external_links/AVERAGE_EXTERNAL_LINKS_BY_GROUP';
import { MOST_LINKED_EXTERNAL_HOSTS } from './external_links/MOST_LINKED_EXTERNAL_HOSTS';

export const EXTERNAL_LINKS = {
  '@id': 'external_links',
  id: 'external_links',
  name: 'sidebar.crawl.dashboard.externalLinks',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_EXTERNAL_HOSTS,
    TOO_MANY_EXTERNAL_LINKS,
    AVERAGE_EXTERNAL_LINK,
    TOTAL_EXTERNAL_LINKS_BY_GROUP,
    AVERAGE_EXTERNAL_LINKS_BY_GROUP,
    MOST_LINKED_EXTERNAL_HOSTS,
  ],
};

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import { Button, Box, Grid, FormControl, FormHelperText, MenuItem, Select, TextField, InputLabel } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { getCountryList } from 'services/api/MyApiUtil';
import { validateProfileForm } from '../validation/ProfileForm';
import Typography from '@material-ui/core/Typography';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const ProfileForm = props => {
  const [countryOptions, setCountryOptions] = useState([]);

  /**
   * Triggered on component mount.
   */
  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    let options = [];

    const data = await getCountryList();

    for (let i = 0; i < data['hydra:member'].length; i++) {
      options.push({
        label: data['hydra:member'][i]['name'],
        value: data['hydra:member'][i]['@id'],
      });
    }

    setCountryOptions(options);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      validate={values => validateProfileForm(values)}
      onSubmit={values => props.onSubmit(values)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TextField
                id="firstname"
                name="firstname"
                label={<IntlMessages id="bundles.profile.entity.account.firstname.field" />}
                value={values.firstname || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.firstname && Boolean(errors.firstname)}
                helperText={touched.firstname && errors.firstname}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="lastname"
                name="lastname"
                label={<IntlMessages id="bundles.profile.entity.account.lastname.field" />}
                value={values.lastname || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.lastname && Boolean(errors.lastname)}
                helperText={touched.lastname && errors.lastname}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth={true} required>
                <TextField
                  id="email"
                  name="email"
                  label={<IntlMessages id="bundles.profile.entity.account.email.field" />}
                  value={values.email || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  required
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <TextField
                  id="emailBilling"
                  name="emailBilling"
                  label={<IntlMessages id="bundles.profile.entity.account.emailBilling.field" />}
                  value={values.emailBilling || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.emailBilling && Boolean(errors.emailBilling)}
                  helperText={touched.emailBilling && errors.emailBilling}
                />
              </FormControl>

              <Typography className={'form-help'} variant="body2" color={'textSecondary'}>
                <IntlMessages id="bundles.profile.entity.account.emailBilling.help" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth={true} required>
                <InputLabel id="country-label" shrink>
                  <IntlMessages id="bundles.profile.entity.account.country.field" />
                </InputLabel>
                <Select
                  labelId="country-label"
                  name={'country'}
                  value={values.country}
                  onChange={event => {
                    setFieldValue('country', event.target.value, false);
                  }}
                  fullWidth>
                  <MenuItem value="">
                    <em>
                      <IntlMessages id="bundles.shipping.form.field.label.country" />
                    </em>
                  </MenuItem>
                  {countryOptions.map(obj => (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </Select>

                {Boolean(errors.country) && <FormHelperText error={true}>{errors.country}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <BackButton variant="outlined" color="primary">
                <IntlMessages id="bundles.project.action.cancel" />
              </BackButton>

              <Box ml={6} clone>
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id="bundles.profile.action.save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ProfileForm;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Formik } from 'formik';
import { getCountryList } from 'services/api/MyApiUtil';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { validateRegisterForm } from '../validation/Register';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

const RegisterForm = props => {
  const classes = useStyles();
  const [countryOptions, setCountryOptions] = useState([]);

  /**
   * Triggered on component mount.
   */
  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    let options = [];

    const data = await getCountryList();

    for (let i = 0; i < data['hydra:member'].length; i++) {
      options.push({
        label: data['hydra:member'][i]['name'],
        value: data['hydra:member'][i]['@id'],
      });
    }

    setCountryOptions(options);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      validate={values => validateRegisterForm(values)}
      onSubmit={values => props.onSubmit(values)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Create an account
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label={<IntlMessages id="appModule.firstname" />}
                name={'firstname'}
                value={values.firstname}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.firstname && Boolean(errors.firstname)}
                helperText={touched.firstname && errors.firstname}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label={<IntlMessages id="appModule.lastname" />}
                name={'lastname'}
                value={values.lastname}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={touched.lastname && Boolean(errors.lastname)}
                helperText={touched.lastname && errors.lastname}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth={true} required>
                <InputLabel shrink>
                  <IntlMessages id="appModule.email" />
                </InputLabel>
                <TextField
                  name={'email'}
                  value={values.email}
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth={true} required>
                <InputLabel shrink>
                  <IntlMessages id="appModule.password" />
                </InputLabel>
                <TextField
                  type="password"
                  name={'password'}
                  value={values.password}
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth={true} required>
                <InputLabel id="country-label" shrink>
                  <IntlMessages id="bundles.shipping.form.field.label.country" />
                </InputLabel>
                <Select
                  labelId="country-label"
                  name={'country'}
                  value={values.country}
                  onChange={event => {
                    setFieldValue('country', event.target.value, false);
                  }}
                  fullWidth>
                  <MenuItem value="">
                    <em>
                      <IntlMessages id="bundles.shipping.form.field.label.country" />
                    </em>
                  </MenuItem>
                  {countryOptions.map(obj => (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </Select>

                {Boolean(errors.country) && <FormHelperText error={true}>{errors.country}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
                mb={0}
                mt={3}>
                <Box>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    <IntlMessages id="appModule.regsiter" />
                  </Button>
                </Box>

                <Typography className={classes.alrTextRoot}>
                  <NavLink to="/signin">
                    <IntlMessages id="signUp.alreadyMember" />
                  </NavLink>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;

import { MISSING_TITLE } from './title/MISSING_TITLE';
import { EMPTY_TITLE } from './title/EMPTY_TITLE';
import { TITLE_PRESENCE } from './title/TITLE_PRESENCE';
import { TITLE_LENGTH } from './title/TITLE_LENGTH';
import { TITLE_PRESENCE_BY_GROUP } from './title/TITLE_PRESENCE_BY_GROUP';
import { TITLE_LENGTH_BY_GROUP } from './title/TITLE_LENGTH_BY_GROUP';

export const TITLE = {
  '@id': 'title',
  id: 'title',
  name: 'sidebar.crawl.dashboard.title',
  description: null,
  project: '/api/projects/131',
  questions: [
    MISSING_TITLE,
    EMPTY_TITLE,
    //DUPLICATE_TITLE,
    TITLE_PRESENCE,
    TITLE_LENGTH,
    TITLE_PRESENCE_BY_GROUP,
    TITLE_LENGTH_BY_GROUP,
  ],
};

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { DESTROY_SESSION } from '../../@jumbo/constants/ActionTypes';
import { THEME_TYPES } from '../../@jumbo/constants/ThemeOptions';

import Common from './Common';
import Auth from './Auth';
import Invoice from 'bundles/billing/invoice/reducers';
import PaymentMethod from 'bundles/billing/paymentMethod/reducers';
import Shipping from 'bundles/billing/shipping/reducers';
import ChangePlan from 'bundles/billing/subscription/reducers';
import Project from 'bundles/project/reducers';
import ProjectSelector from 'bundles/projectSelector/reducers';
import Crawl from 'bundles/crawl/reducers';
import CrawlProfile from 'bundles/crawlProfile/reducers';
import CrawlSchedule from 'bundles/crawlSchedule/reducers';
import Dashboard from 'bundles/dashboard/reducers';
import Payment from 'bundles/billing/checkout/reducers';
import LogsExplorer from 'bundles/logAnalyzer/LogsExplorer/reducers';
import Segmentation from 'bundles/segmentation/reducers';
import Usage from 'bundles/billing/usage/reducers';
import Notification from 'bundles/notification/reducers';

const appReducer = history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    invoice: Invoice,
    paymentMethod: PaymentMethod,
    shipping: Shipping,
    changePlan: ChangePlan,
    project: Project,
    projectSelector: ProjectSelector,
    payment: Payment,
    logsExplorer: LogsExplorer,
    crawl: Crawl,
    crawlProfile: CrawlProfile,
    crawlSchedule: CrawlSchedule,
    dashboard: Dashboard,
    segmentation: Segmentation,
    usage: Usage,
    notification: Notification,
  });

export const initialState = {
  common: {
    initialURL: '/',
    error: '',
    loading: false,
    message: '',
    level: '',
    redirectTo: null,
    themeType: THEME_TYPES.LIGHT,
  },
  auth: {
    authUser: null,
    planUser: null,
    subscriptionUser: null,
    loadUser: false,
    isActiveSubscription: false,
  },
  invoice: {
    invoicesList: [],
  },
  paymentMethod: {
    showAddCardForm: false,
    paymentMethodsList: [],
    defaultPaymentMethod: {
      id: null,
    },
  },
  shipping: {
    customer: {
      name: null,
      phone: null,
      address: {},
    },
  },
  changePlan: {
    subscriptions: {
      data: [],
    },
  },
  project: {
    projectsList: {
      'hydra:member': [],
    },
    project: {
      name: '',
      domain: '',
      region: '',
    },
  },
  projectSelector: {
    open: false,
    project: {
      id: null,
      name: '',
      domain: '',
      region: '',
    },
  },
  payment: {
    currentPrices: {
      crawler: null,
      log_analyzer: null,
    },
    selectedPrices: {
      crawler: null,
      log_analyzer: null,
    },
    prices: {
      crawler: [],
      log_analyzer: [],
    },
    subscriptionItems: [],
    incomingInvoice: null,
    desiredPaymentMethod: null,
  },
  logsExplorer: {
    // date picker
    openDateRangePicker: false,
    // logs explorer
    count: {},
    csvData: {},
    rows: {},
    isLoading: {},
    metric: {},
    // export modal
    showExportResult: false,
    exportResult: {},
    exportStatus: 'void',
  },
  crawl: {
    crawl: {},
  },
  crawlProfile: {
    crawlProfilesList: {
      'hydra:member': [],
    },
    crawlProfile: {
      name: '',
      settings: {},
    },
    crawlProfilesListLoading: true,
  },
  crawlSchedule: {
    crawlSchedulesList: {
      'hydra:member': [],
    },
    crawlSchedule: {},
  },
  dashboard: {
    dashboardsList: {
      'hydra:member': [],
    },
    dashboard: {
      questions: [],
    },
  },
  segmentation: {
    segmentationProfileList: {
      'hydra:member': [],
    },
    segmentationProfile: {},
    segmentationStats: {
      isLoading: false,
      labels: [],
      data: [],
      crawled: 0,
      withSegment: 0,
      withoutSegment: 0,
      pieData: [
        { name: 'segmented', value: 0, color: '#fff' },
        { name: 'undefined', value: 0, color: '#3700B3' },
      ],
    },
  },
  usage: {
    usage: {},
  },
  notification: {
    notificationsList: {
      'hydra:member': [],
      'hydra:totalItems': 0,
    },
  },
  router: {
    location: {
      pathname: '/signin',
      search: '',
      hash: '',
      query: {},
    },
    action: 'POP',
  },
};

export const rootReducer = history => (state, action) => {
  if (action.type === DESTROY_SESSION) {
    state = initialState;
  }

  return appReducer(history)(state, action);
};

export const NON_2XX_CANONICAL = {
  '@id': 'NON_2XX_CANONICAL',
  name: 'bundles.crawl.chart.non_2xx_canonical.title',
  description: null,
  x: 8,
  y: 0,
  w: 4,
  h: 4,
  query: {
    table: 'canonicals',
    dimensions: [],
    dimensionsFunctions: [],
    groupBy: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['countUniqueCanonicalUrl'],
    filters: [
      {
        dimension: 'statusCode',
        operator: 'gt',
        values: [299],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'canonicals',
        filters: [
          {
            label: 'dimension.canonicals.statusCode',
            dimension: 'statusCode',
            operator: 'gt',
            values: [299],
          },
        ],
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

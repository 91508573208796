import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CmtProgressBar from '../../../../@coremat/CmtProgressBar';

const UsageList = props => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <IntlMessages id="bundles.usage.name.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.usage.limit.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.usage.value.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.usage.percent.short" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={0}>
          <TableCell align={'left'}>
            <IntlMessages id="bundles.usage.urls_count.short" />
          </TableCell>
          <TableCell>{props.usage.urlsCountQuota || 'unknow'}</TableCell>
          <TableCell>{props.usage.urlsCount || 0}</TableCell>
          <TableCell>
            <CmtProgressBar
              onlyContained={false}
              thickness={4}
              pointer={false}
              pointerSize={8}
              hideValue={false}
              valuePos="right"
              value={(100 * props.usage.urlsCount) / props.usage.urlsCountQuota}
              total={100}
              renderValue={(value, total) => {
                const percent = (100 * props.usage.urlsCount) / props.usage.urlsCountQuota;

                return percent.toFixed(1) + ' %';
              }}
              emptyColor={'#e9ecef'}
              containedColor="#1a90ff"
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default UsageList;

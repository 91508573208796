import axios from 'axios';
import qs from 'qs';

const STRIPE_BACKEND_ENDPOINT = 'https://apidev.seo-logs.com/api/stripe';

function addToken() {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

addToken();

const stripeRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

/**
 * Create `Customer` in Stripe
 *
 * @param {Object} userObj
 * @returns {Promise<void>}
 * @see https://stripe.com/docs/api/customers/create
 */
export async function createCustomer(userObj) {
  const stripeRequestBody = {
    email: userObj.email,
    name: userObj.firstname + ' ' + userObj.lastname,
    metadata: {
      //seologs_user_id: userObj.id,
    },
  };

  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/customers/create',
    stripeRequestBody,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get `Customer`
 *
 * @param {string} stripeCustomerId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/products/retrieve
 */
export async function getCustomer(stripeCustomerId) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/customers/retrieve?' + stripeCustomerId,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Update `Customer`
 *
 * @param {string} stripeCustomerId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/customers/update
 */
export async function updateCustomer(stripeCustomerId, payload) {
  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/customers/update?' + stripeCustomerId,
    payload,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get `PaymentMethod`
 *
 * @param {string} stripePaymentMethodId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/prices/retrieve
 */
export async function getPaymentMethod(stripePaymentMethodId) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/paymentMethods/retrieve?' + stripePaymentMethodId,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Update `PaymentMethod`
 *
 * @param {string} stripePaymentMethodId
 * @param {Object} payload
 *
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/payment_methods/update
 */
export async function updatePaymentMethod(stripePaymentMethodId, payload) {
  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/paymentMethods/update?' + stripePaymentMethodId,
    payload,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get `PaymentMethod` list of the `Customer`
 *
 * @param {string} stripeCustomerId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/payment_methods/list
 */
export async function getPaymentMethodList(stripeCustomerId) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/paymentMethods/all?type=card&customer=' + stripeCustomerId,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get the default `PaymentMethod` of the `Customer`
 *
 * @param {string} stripeCustomerId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/payment_methods/list
 */
export async function getDefaultPaymentMethod(stripeCustomerId) {
  const customer = await getCustomer(stripeCustomerId);
  const defaultPaymentMethod = customer.invoice_settings.default_payment_method;

  if (defaultPaymentMethod) {
    const result = await getPaymentMethod(defaultPaymentMethod);

    return result;
  }

  return {
    id: null,
  };
}

/**
 * Get `Price`
 *
 * @param {string} stripePriceId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/prices/retrieve
 */
export async function getPrice(stripePriceId) {
  const stripeResult = await axios.get(STRIPE_BACKEND_ENDPOINT + '/prices/retrieve?' + stripePriceId, stripeRequestConfig);

  return stripeResult.data;
}

/**
 * Get `Price` list
 *
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/prices/list
 */
export async function getPriceList(payload) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/prices/all?' + qs.stringify(payload),
    stripeRequestConfig,
  );

  // order by price
  stripeResult.data.data.sort((a, b) => (a.unit_amount > b.unit_amount ? 1 : -1));

  return stripeResult.data;
}

/**
 * Get `Product` and `Prices`
 *
 * @param {string} stripeProductId
 * @returns {Promise<{product: *, prices: *}>}
 * @see https://stripe.com/docs/api/products/retrieve
 * @see https://stripe.com/docs/api/prices/list
 */
export async function getProductAndPrices(stripeProductId) {
  // get product
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/products/retrieve?' + stripeProductId,
    stripeRequestConfig,
  );

  // get prices
  let stripeResult2 = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/prices/all?product=' + stripeProductId + '&limit=100&active=true',
    stripeRequestConfig,
  );

  stripeResult2.data.data.sort(function(a, b) {
    return a.unit_amount - b.unit_amount;
  });

  return {
    product: stripeResult.data,
    prices: stripeResult2.data,
  };
}

/**
 * Attach `PaymentMethod` to `Customer`
 *
 * @param {string} stripeCustomerId
 * @param {string} paymentMethodId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/payment_methods/attach
 */
export async function attachPaymentMethod(stripeCustomerId, paymentMethodId) {
  const stripeRequestBody = {
    customer: stripeCustomerId,
  };

  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/paymentMethods/attach?' + paymentMethodId,
    stripeRequestBody,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Detach `PaymentMethod`
 *
 * @param {string} paymentMethodId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/payment_methods/detach
 */
export async function detachPaymentMethod(paymentMethodId) {
  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/paymentMethods/detach?' + paymentMethodId,
    null,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get `Invoice`
 *
 * @param {string} stripeInvoiceId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/invoices/retrieve
 */
export async function getInvoice(stripeInvoiceId) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/invoices/retrieve?' + stripeInvoiceId,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get `Invoice` list
 *
 * @param {string} stripeCustomerId
 * @returns {Promise<{product: *, prices: *}>}
 * @see https://stripe.com/docs/api/invoices/list
 */
export async function getInvoices(stripeCustomerId) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/invoices/all?customer=' + stripeCustomerId,
    stripeRequestConfig,
  );

  return {
    data: stripeResult.data,
  };
}

/**
 * Set default `PaymentMethod` of a `Customer`
 *
 * @param {string} stripeCustomerId
 * @param {string} paymentMethodId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/customers/update
 */
export async function setDefaultPaymentMethod(stripeCustomerId, paymentMethodId) {
  const stripeRequestBody = {
    'invoice_settings[default_payment_method]': paymentMethodId,
  };

  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/customers/update?' + stripeCustomerId,
    stripeRequestBody,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Cancel `Subscription`
 *
 * @param {string} stripeSubscriptionId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/subscriptions/cancel
 */
export async function cancelSubscription(stripeSubscriptionId) {
  const stripeResult = await axios.delete(
    STRIPE_BACKEND_ENDPOINT + '/subscriptions/cancel?' + stripeSubscriptionId,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Create `Subscription`
 *
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/subscriptions/create
 */
export async function createSubscription(payload) {
  const stripeResult = await axios.post(STRIPE_BACKEND_ENDPOINT + '/subscriptions/create', payload, stripeRequestConfig);

  return stripeResult.data;
}

/**
 * Update `Subscription`
 *
 * @param {string} stripeSubscriptionId
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/subscriptions/create
 */
export async function updateSubscription(stripeSubscriptionId, payload) {
  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/subscriptions/update?' + stripeSubscriptionId,
    payload,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Get `Subscription` list of the `Customer`
 *
 * @param {string} stripeCustomerId
 * @param {object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/subscriptions/list
 */
export async function getSubscriptionList(stripeCustomerId, payload = {}) {
  addToken();

  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/subscriptions/all?customer=' + stripeCustomerId + '&' + qs.stringify(payload),
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Preview the effects of updating a subscription, including a preview of what proration will take place
 *
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/invoices/upcoming
 */
export async function getProrationPreview(payload) {
  const stripeResult = await axios.get(
    STRIPE_BACKEND_ENDPOINT + '/invoices/upcoming?' + qs.stringify(payload),
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Create `SetupIntent`
 *
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/setup_intents/create?
 */
export async function createSetupIntent(payload) {
  const stripeResult = await axios.post(STRIPE_BACKEND_ENDPOINT + '/setupIntents/create', payload, stripeRequestConfig);

  return stripeResult.data;
}

/**
 * Get `SubscriptionItem`
 *
 * @param {string} stripePaymentMethodId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/subscription_items/retrieve
 */
export async function getSubscriptionItem(siId) {
  const stripeResult = await axios.get(STRIPE_BACKEND_ENDPOINT + '/subscriptionItems/retrieve?' + siId, stripeRequestConfig);

  return stripeResult.data;
}

/**
 * Get `Tax ID` list
 *
 * @param {String} customerId
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/customer_tax_ids/list
 */
export async function getTaxIdList(customerId, payload) {
  const stripeResult = await axios.get(STRIPE_BACKEND_ENDPOINT + '/customers/allTaxIds?' + customerId, stripeRequestConfig);

  return stripeResult.data;
}

/**
 * Create `Tax ID`
 *
 * @param {string} customerId
 * @param {Object} payload
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/customer_tax_ids/create
 */
export async function createTaxId(customerId, payload) {
  const stripeResult = await axios.post(
    STRIPE_BACKEND_ENDPOINT + '/customers/createTaxId?' + customerId,
    payload,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

/**
 * Delete `Tax ID`
 *
 * @param {string} customerId
 * @param {string} taxId
 * @returns {Promise<*>}
 * @see https://stripe.com/docs/api/customer_tax_ids/delete
 */
export async function deleteTaxId(customerId, taxId) {
  const stripeResult = await axios.delete(
    STRIPE_BACKEND_ENDPOINT + '/customers/deleteTaxId?' + customerId + '&' + taxId,
    stripeRequestConfig,
  );

  return stripeResult.data;
}

import React, { useEffect, useState, useMemo, useRef, useImperativeHandle } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { handleShowAddCardForm, getDefaultPaymentMethod2, getPaymentMethodsList } from '../actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import TextField from '@material-ui/core/TextField/TextField';
import Grid from '@material-ui/core/Grid';
import { fetchSuccess, fetchError, showMessage } from 'redux/actions';
import { attachPaymentMethod, setDefaultPaymentMethod, createSetupIntent } from 'services/stripe/StripeUtil';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return <Component onReady={element => (elementRef.current = element)} {...props} />;
};

const useOptions = (factory, deps) => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '18px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  return options;
};

const CardForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { authUser } = useSelector(({ auth }) => auth);
  const { paymentMethodsList } = useSelector(({ paymentMethod }) => paymentMethod);
  const [defaultMode, setDefaultMode] = useState(false);
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  const handleCardSetup = () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!clientSecret) {
      return;
    }

    setDisabled(true);

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: name,
          },
        },
      })
      .then(async function(result) {
        if (result.error) {
          setDisabled(false);
          dispatch(fetchError(result.error.message + ' (code: ' + result.error.code + ')'));
        } else {
          const paymentMethodId = result.setupIntent.payment_method;

          if (props.forceDefaultPaymentMethod && true === props.forceDefaultPaymentMethod) {
            setDefaultMode(true);
          }

          // attach the payment method to customer in stripe
          try {
            await attachPaymentMethod(authUser[STRIPE_CUSTOMER_ID_FIELD], paymentMethodId);

            if (true === defaultMode) {
              await setDefaultPaymentMethod(authUser[STRIPE_CUSTOMER_ID_FIELD], paymentMethodId);
              await dispatch(getDefaultPaymentMethod2(authUser[STRIPE_CUSTOMER_ID_FIELD]));
            }

            await dispatch(getPaymentMethodsList(authUser[STRIPE_CUSTOMER_ID_FIELD]));

            // show message
            dispatch(showMessage('bundles.PaymentMode.notification.create.success', 'success'));

            if (props.onSuccess) {
              props.onSuccess(paymentMethodId);
            }

            dispatch(fetchSuccess());
          } catch (error) {
            //
          }

          // hide form
          dispatch(handleShowAddCardForm(false));
          setDisabled(false);
        }
      });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (0 === paymentMethodsList.length) {
      setDefaultMode(true);
    }

    createSetupIntent({
      payment_method_types: ['card'],
      customer: authUser[STRIPE_CUSTOMER_ID_FIELD],
    }).then(result => {
      setClientSecret(result.client_secret);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    dispatch(handleShowAddCardForm(false));

    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // We expose this so that when the form is submitted we can
    // do 3D Secure from the parent
    handleCardSetup();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="flex-start" item xs={12} spacing={6}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="name">
            <IntlMessages id="bundles.PaymentMode.entity.paymentMode.name.short" />
          </InputLabel>
          <TextField
            id="name"
            placeholder="John Doe"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
            required
            variant="outlined"
            style={options}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="cardNumber">
            <IntlMessages id="bundles.PaymentMode.entity.paymentMode.cardNumber.short" />
          </InputLabel>

          <TextField
            id="cardNumber"
            name="cardNumber"
            fullWidth
            required
            variant="outlined"
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
                options: options,
              },
            }}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <InputLabel htmlFor="expiry">
            <IntlMessages id="bundles.PaymentMode.entity.paymentMode.expirationDate.short" />
          </InputLabel>

          <TextField
            id="expiry"
            name="expiry"
            variant="outlined"
            required
            fullWidth
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
                options: options,
              },
            }}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <InputLabel htmlFor="cvc">
            <IntlMessages id="bundles.PaymentMode.entity.paymentMode.cvc.short" />
          </InputLabel>
          <TextField
            id="cvc"
            name="cvc"
            variant="outlined"
            required
            fullWidth
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
                options: options,
              },
            }}
          />
        </Grid>

        {props.forceDefaultPaymentMethod && true === props.forceDefaultPaymentMethod ? null : (
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox checked={defaultMode} onChange={e => setDefaultMode(e.target.checked)} name="defaultMode" />
              }
              label={<IntlMessages id="bundles.PaymentMode.entity.paymentMode.defaultMode.short" />}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          {props.hideCancelButton && true === props.hideCancelButton ? null : (
            <Box mr={6} clone>
              <Button variant="outlined" color="primary" mr={3} className={'mr-3'} onClick={handleCancel}>
                <IntlMessages id="bundles.PaymentMode.action.cancel" />
              </Button>
            </Box>
          )}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disabled}
            fullWidth={props.btnSubmitFullWith || false}>
            <IntlMessages id={props.btnSubmitText || 'bundles.PaymentMode.action.save'} />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CardForm;

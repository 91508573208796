export const RETRY_INTERVAL = 3000;
export const RETRY_LIMIT = 2;

export const DOWNLOAD_URL_LANG_PHP = 'http://download.seo-logs.com/seologs.php.dist';

export const FRAMEWORK_PHP_LIST = [
  { label: 'Wordpress', filename: 'wp-config.php' },
  { label: 'Prestashop', filename: 'config/config.inc.php' },
  { label: 'Symfony', filename: 'web/app.php or public/index.php' },
];

export const CODE_PHP =
  '<?php\n' +
  '// edit {FILENAME}\n' +
  "define('SEOLOGS_PROJECT_ID', {PROJECT_ID});\n" +
  "define('SEOLOGS_TOKEN', '{TOKEN}');\n\n" +
  "require_once($_SERVER['DOCUMENT_ROOT'] . DIRECTORY_SEPARATOR . 'seologs.php');\n" +
  '?>';

import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';

import StatisticsModernCard from '@jumbo/components/Common/StatisticsModernCard';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

const StatusCodeIntro = props => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let chartDataNew = [];
    let statusCodes = [
      {
        value: '2',
        backgroundColor: green[500],
      },
      {
        value: '3',
        backgroundColor: yellow[500],
      },
      {
        value: '4',
        backgroundColor: orange[500],
      },
      {
        value: '5',
        backgroundColor: red[500],
      },
    ];

    if (props.rows.hasOwnProperty(props.id)) {
      // iterate over status codes
      statusCodes.map(statusCode => {
        let found = false;

        // iterate over rows
        props.rows[props.id].map(arr => {
          if (arr['code'] === statusCode.value) {
            chartDataNew.push({
              title: arr['percent_over_total'].toFixed(1) + '%',
              subtitle: arr['code'] + 'xx',
              backgroundColor: statusCode.backgroundColor,
            });
            found = true;
          }

          return true;
        });

        if (!found) {
          chartDataNew.push({
            title: '0%',
            subtitle: statusCode.value + 'xx',
            backgroundColor: statusCode.backgroundColor,
          });
        }

        return true;
      });
    }

    setChartData(chartDataNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows[props.id]]);

  return (
    <Grid container spacing={GRID_SPACING}>
      {chartData.map(obj => (
        <Grid md={3} item>
          <StatisticsModernCard backgroundColor={obj.backgroundColor} title={obj.title} subTitle={obj.subtitle} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StatusCodeIntro;

import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TableCell from '@material-ui/core/TableCell';
import { Box } from '@material-ui/core';
import ViewUrlLink from 'bundles/urlViewer/components/ViewUrlLink';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';
import ValuePrettier from '../../../../logAnalyzer/LogsExplorer/components/ValuePrettier';

const parseDimension = str => {
  return str.includes('.') ? str.split('.').pop() : str;
};

const showUrlViewerButton = (table, dimension, entity) => {
  const acceptedTables = ['crawled_pages', 'canonicals'];
  const acceptedValues = ['url', 'canonicalUrl', 'sourceUrl'];

  if (!acceptedTables.includes(table)) {
    return false;
  }

  for (let i = 0; i < acceptedValues.length; i++) {
    if (acceptedValues[i] === dimension && entity[acceptedValues[i]]) {
      return true;
    }
  }

  return false;
};

const AppTableCell = ({ dimension, entity, table }) => {
  return (
    <TableCell key={dimension}>
      <Box display="flex" flexDirection="row" justifyContent={'flex-start'} alignItems={'center'}>
        <Box mr={2}>
          <ValuePrettier value={entity.hasOwnProperty(dimension) ? entity[dimension] : entity[parseDimension(dimension)]} />
        </Box>

        {true === showUrlViewerButton(table, dimension, entity) && (
          <React.Fragment>
            <Box mr={2}>
              <ViewUrlLink url={entity[dimension]} />
            </Box>

            <Box mr={2}>
              <Tooltip title={<IntlMessages id={'generic.action.openUrlNewTab.title'} />}>
                <a href={entity[dimension]} target="_blank" rel="noopener noreferrer">
                  <OpenInNewIcon color={'primary'} fontSize={'inherit'} />
                </a>
              </Tooltip>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </TableCell>
  );
};

AppTableCell.propTypes = {
  dimension: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired,
  table: PropTypes.string.isRequired,
};

export default React.memo(AppTableCell);

export const NOTIFICATION_SELECTOR_EVENTS = [
  // project
  'createProject',
  'updateProject',
  'deleteProject',

  // crawl
  'startCrawl',
  'endCrawl',
  'failCrawl',
  'stopCrawl',

  // usage
  'quotaReached',
];

export const TOTAL_CANONICAL = {
  '@id': 'TOTAL_CANONICAL',
  name: 'bundles.crawl.chart.total_canonical.title',
  description: null,
  x: 0,
  y: 0,
  w: 4,
  h: 4,
  query: {
    table: 'canonicals',
    dimensions: [],
    dimensionsFunctions: [],
    groupBy: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['countUniqueCanonicalUrl'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'canonicals',
        filters: [],
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MyDrawer from 'components/Drawer';
import MeasuresListGrouped from './MeasuresList';
import PropTypes from 'prop-types';

class ChooseCheckboxes extends React.Component {
  constructor(props) {
    super(props);

    let values = [...props.values];

    this.state = {
      values: values,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(obj) {
    let newValues = [...this.state.values];
    const index = newValues.indexOf(obj.dimension);

    if (index === -1) {
      newValues.push(obj.dimension);
    } else {
      newValues.splice(index, 1);
    }

    this.setState({
      values: newValues,
    });
  }

  handleCancel() {
    const newValues = Object.assign([], this.props.values);

    this.setState({
      values: newValues,
    });

    this.props.handleCancel();
  }

  render() {
    return (
      <MyDrawer
        open={this.props.open}
        withHeader={true}
        headerTitle={this.props.title}
        handleClose={() => {
          this.handleCancel();
        }}>
        <MeasuresListGrouped
          schema={this.props.schema}
          values={this.state.values}
          handleClickMeasure={obj => this.handleChange(obj)}
          handleClickAddIcon={obj => this.handleChange(obj)}
          handleClickDeleteIcon={obj => this.handleChange(obj)}
        />

        <Box align={'center'} pb={6}>
          <Button
            autoFocus
            onClick={() => {
              this.handleCancel();
            }}
            color="primary">
            <IntlMessages id="generic.text.cancel" />
          </Button>

          <Button
            onClick={() => {
              this.props.handleOk(this.state.values);
            }}
            color="primary"
            variant="contained">
            <IntlMessages id="generic.text.save" />
          </Button>
        </Box>
      </MyDrawer>
    );
  }
}

ChooseCheckboxes.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  schema: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
};

export default ChooseCheckboxes;

import React from 'react';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { Box } from '@material-ui/core';

import { Formik } from 'formik';
import { validateChangePassword } from '../validation/ChangePasswordValidation';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const ChangePasswordForm = props => {
  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => validateChangePassword(values)}
      onSubmit={values => props.onSubmit(values)}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.newPassword.field" />
              </InputLabel>
              <TextField
                type="password"
                id="newPassword"
                name="newPassword"
                value={values.newPassword || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                variant="outlined"
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.confirmPassword.field" />
              </InputLabel>
              <TextField
                type="password"
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                value={values.newPasswordConfirm || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                variant="outlined"
                error={touched.newPasswordConfirm && Boolean(errors.newPasswordConfirm)}
                helperText={touched.newPasswordConfirm && errors.newPasswordConfirm}
              />
            </Grid>

            <Grid item xs={12}>
              <BackButton variant="outlined" color="primary">
                <IntlMessages id="bundles.project.action.cancel" />
              </BackButton>

              <Box ml={6} clone>
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id="bundles.profile.action.save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;

import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const TITLE_PRESENCE = {
  '@id': 'TITLE_PRESENCE',
  name: 'bundles.crawl.chart.title_presence.title',
  description: null,
  x: 0,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['checks_has_title'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [0, 0];
      let backgroundColor = ['#4caf50', '#dc004e'];

      rows.map(arr => {
        if (true === arr['checks_has_title']) {
          data[0] = arr.count;
        } else {
          data[1] = arr.count;
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      return ['Exactly 1', 'No title'];
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import { TOTAL_INTERNAL_LINKS } from './internal_links/TOTAL_INTERNAL_LINKS';
import { AVERAGE_INTERNAL_LINKS } from './internal_links/AVERAGE_INTERNAL_LINKS';
import { LINKS_STATUS_BREAKDOWN } from './internal_links/LINKS_STATUS_BREAKDOWN';
import { LINKS_STATUS_INSIGHT } from './internal_links/LINKS_STATUS_INSIGHT';
import { TOTAL_INTERNAL_LINKS_BY_GROUP } from './internal_links/TOTAL_INTERNAL_LINKS_BY_GROUP';
import { AVERAGE_INTERNAL_LINKS_BY_GROUP } from './internal_links/AVERAGE_INTERNAL_LINKS_BY_GROUP';

export const INTERNAL_LINKS = {
  '@id': 'internal_links',
  id: 'internal_links',
  name: 'sidebar.crawl.dashboard.internalLinks',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_INTERNAL_LINKS,
    AVERAGE_INTERNAL_LINKS,
    LINKS_STATUS_BREAKDOWN,
    LINKS_STATUS_INSIGHT,
    TOTAL_INTERNAL_LINKS_BY_GROUP,
    AVERAGE_INTERNAL_LINKS_BY_GROUP,
  ],
};

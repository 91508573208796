export const CANONICALS_SCHEMA = {
  table: 'canonicals',
  fields: [
    {
      dimension: 'canonicalUrl',
      label: 'dimension.canonicals.canonicalUrl',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
    {
      dimension: 'category',
      label: 'dimension.canonicals.category',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'statusCode',
      label: 'dimension.canonicals.statusCode',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'indexable',
      label: 'dimension.canonicals.indexable',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'indexableReason',
      label: 'dimension.canonicals.indexableReason',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'sourceUrl',
      label: 'dimension.canonicals.sourceUrl',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
  ],
  order: null,
  orderBy: null,
  hasTimeDimension: false,
};

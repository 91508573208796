import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const AppTableHead = props => {
  return (
    <TableHead>
      <TableRow>
        {props.dimensions.map(dimension => (
          <TableCell key={dimension} sortDirection={props.orderBy === dimension ? props.order : false}>
            <TableSortLabel
              active={props.orderBy === dimension}
              direction={props.orderBy === dimension ? props.order : 'asc'}
              onClick={() => {
                props.handleChangeSort(dimension);
              }}>
              <IntlMessages id={'dimension.' + props.schemaName + '.' + dimension} />
            </TableSortLabel>
          </TableCell>
        ))}

        {props.measures.map(measure => (
          <TableCell key={measure} sortDirection={props.orderBy === measure ? props.order : false}>
            <TableSortLabel
              active={props.orderBy === measure}
              direction={props.orderBy === measure ? props.order : 'asc'}
              onClick={() => {
                props.handleChangeSort(measure);
              }}>
              <IntlMessages id={'dimension.' + measure} />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AppTableHead.propTypes = {
  dimensions: PropTypes.array.isRequired,
  measures: PropTypes.array.isRequired,
  schemaName: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  handleChangeSort: PropTypes.func.isRequired,
};
export default AppTableHead;

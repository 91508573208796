import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Grid, Switch, Select, MenuItem, Box, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { Formik } from 'formik';

const PieConfigForm = props => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.initialValues}
      //validate={values => myFunction(values)}
      onSubmit={values => props.handleSubmit('pie', values)}
      //onSubmit={values => handleSubmit(values)}
    >
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.dataExplorer.visualization.form.pie.legend.display.label" />
              </InputLabel>
              <Switch
                id="legendDisplay"
                name="legend[display]"
                checked={values.legend.display}
                onChange={handleChange}
                color="primary"
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.dataExplorer.visualization.form.pie.legend.position.label" />
              </InputLabel>
              <Select id="legendPosition" name="legend[position]" value={values.legend.position} onChange={handleChange}>
                <MenuItem value={'top'}>Top</MenuItem>
                <MenuItem value={'left'}>Left</MenuItem>
                <MenuItem value={'bottom'}>Bottom</MenuItem>
                <MenuItem value={'right'}>Right</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Box ml={6} clone>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                  }}>
                  <IntlMessages id="bundles.crawlProfile.action.save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default PieConfigForm;

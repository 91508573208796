import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, Grid, Box, Paper, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultPaymentMethod2, getPaymentMethodsList } from '../../paymentMethod/actions';
import { handlePay, handleUpdateDesiredPaymentMethod } from '../actions';
import BillingFormModal from '../components/checkout/BillingFormModal';
import PaymentMethodModal from '../components/checkout/PaymentMethodModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardForm from '../../paymentMethod/components/CardForm';
import AuthWrapper from '../../../../@jumbo/components/Common/authComponents/AuthWrapper';
import { Redirect, withRouter } from 'react-router';
import { STRIPE_CUSTOMER_ID_FIELD, STRIPE_PUBLIC_KEY } from '../../constants';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const CheckoutPage = () => {
  const { redirectTo } = useSelector(({ common }) => common);
  const { authUser, subscriptionUser } = useSelector(({ auth }) => auth);
  const { subscriptionItems, desiredPaymentMethod, incomingInvoice } = useSelector(({ payment }) => payment);
  const { defaultPaymentMethod, paymentMethodsList } = useSelector(({ paymentMethod }) => paymentMethod);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const [openCardForm, setOpenCardForm] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodsList(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    dispatch(getDefaultPaymentMethod2(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultPaymentMethod) {
      dispatch(handleUpdateDesiredPaymentMethod(defaultPaymentMethod, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultPaymentMethod)]);

  useEffect(() => {
    if (null === paymentMethodsList) {
      setOpenCardForm(false);
      return;
    }

    if (0 === paymentMethodsList.length) {
      setOpenCardForm(true);
    } else {
      setOpenCardForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(paymentMethodsList)]);

  if (!incomingInvoice) {
    return <Redirect to={'/products'} />;
  }

  return (
    <React.Fragment>
      <AuthWrapper variant={'bgColor'}>
        <Box p={8} align={'center'} style={{ width: '100%' }}>
          <Typography variant="h1" gutterBottom>
            <IntlMessages
              id="bundles.checkout.routes.checkout.title"
              values={{
                amount: Intl.NumberFormat().format(incomingInvoice.amount_due / 100),
              }}
            />
          </Typography>

          <Box align={'center'} mb={6}>
            <IntlMessages id="bundles.checkout.routes.checkout.body" />
          </Box>

          {desiredPaymentMethod && desiredPaymentMethod.id && (
            <Box mb={6}>
              <Paper elevation={1}>
                <Box p={6}>
                  <Grid container justify={'center'} alignItems={'center'} spacing={6}>
                    <Grid item>
                      <img
                        alt={desiredPaymentMethod.card.brand}
                        src={'/images/cards/' + desiredPaymentMethod.card.brand + '.png'}
                        height={'32px'}
                      />
                    </Grid>
                    <Grid item>
                      {desiredPaymentMethod.card.brand.charAt(0).toUpperCase()}
                      {desiredPaymentMethod.card.brand.slice(1)}&nbsp;
                      <small style={{ letterSpacing: '2px' }}>
                        <span>&#11044;&#11044;&#11044;&#11044;</span>&nbsp;
                      </small>
                      {desiredPaymentMethod.card.last4} <IntlMessages id="bundles.checkout.routes.checkout.text.expiresOn" />{' '}
                      {desiredPaymentMethod.card.exp_month < 10
                        ? '0' + desiredPaymentMethod.card.exp_month
                        : desiredPaymentMethod.card.exp_month}
                      /{desiredPaymentMethod.card.exp_year}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          )}

          {true === openCardForm && (
            <Elements stripe={stripePromise}>
              <CardForm
                hideCancelButton={true}
                onSuccess={pm => dispatch(handlePay(authUser, subscriptionUser, subscriptionItems, pm.id))}
                btnSubmitText={'bundles.checkout.routes.checkout.action.pay'}
                btnSubmitFullWith={true}
                forceDefaultPaymentMethod={true}
              />
            </Elements>
          )}

          <Backdrop
            open={loading}
            //onClick={handleClose}
            style={{ zIndex: 2 }}>
            <CircularProgress color="primary" size={60} />
          </Backdrop>

          {desiredPaymentMethod && desiredPaymentMethod.id && (
            <Box mb={6}>
              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  dispatch(handlePay(authUser, subscriptionUser, subscriptionItems, desiredPaymentMethod.id));
                }}>
                <IntlMessages id="bundles.checkout.routes.checkout.action.pay" />
              </Button>
            </Box>
          )}

          <Box mb={6} align={'center'}>
            {paymentMethodsList.length > 0 && (
              <React.Fragment>
                <Link href="#" onClick={() => setOpenChangeModal(true)}>
                  <IntlMessages id="bundles.checkout.routes.checkout.action.selectPaymentMethod" />
                </Link>
                <Typography>
                  <IntlMessages id="bundles.checkout.routes.checkout.text.or" />
                </Typography>
              </React.Fragment>
            )}
            <Link href="#" onClick={() => setOpenBillingModal(true)}>
              <IntlMessages id="bundles.checkout.routes.checkout.action.changeBillingInfo" />
            </Link>
          </Box>

          <div align={'left'}>
            <BackButton variant="outlined" color="primary">
              <IntlMessages id="bundles.checkout.routes.checkout.action.cancel" />
            </BackButton>
          </div>

          <BillingFormModal open={openBillingModal} handleClose={() => setOpenBillingModal(false)} />

          <PaymentMethodModal
            open={openChangeModal}
            handleClose={() => setOpenChangeModal(false)}
            onSelectItem={newValue => dispatch(handleUpdateDesiredPaymentMethod(newValue, true))}
          />
        </Box>
      </AuthWrapper>
      {redirectTo && <Redirect to={redirectTo} />}
    </React.Fragment>
  );
};

export default CheckoutPage;

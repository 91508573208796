import { MISSING_H1 } from './h1/MISSING_H1';
import { EMPTY_H1 } from './h1/EMPTY_H1';
import { IDENTICAL_H1_TITLE } from './h1/IDENTICAL_H1_TITLE';
import { HEADING_TAGS_NOT_START_WITH_H1 } from './h1/HEADING_TAGS_NOT_START_WITH_H1';
import { H1_PRESENCE } from './h1/H1_PRESENCE';
import { H1_LENGTH } from './h1/H1_LENGTH';
import { H1_PRESENCE_BY_GROUP } from './h1/H1_PRESENCE_BY_GROUP';
import { H1_LENGTH_BY_GROUP } from './h1/H1_LENGTH_BY_GROUP';

export const HEADING_TAGS = {
  '@id': 'heading_tags',
  id: 'heading_tags',
  name: 'sidebar.crawl.dashboard.headingTags',
  description: null,
  project: '/api/projects/131',
  questions: [
    MISSING_H1,
    EMPTY_H1,
    //DUPLICATE_H1,
    HEADING_TAGS_NOT_START_WITH_H1,
    IDENTICAL_H1_TITLE,
    H1_PRESENCE,
    H1_LENGTH,
    H1_PRESENCE_BY_GROUP,
    H1_LENGTH_BY_GROUP,
  ],
};

import React from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import CopyButton from 'components/CopyButton';
import { FRAMEWORK_PHP_LIST, CODE_PHP } from '../../constants/InstallScriptConstants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

class CodeTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: FRAMEWORK_PHP_LIST[0].label,
      codeString: null,
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  /**
   * Triggered when a tab is clicked.
   *
   * @param event
   * @param value
   */
  handleChangeTab(event, value) {
    this.setState({
      value: value,
    });
  }

  parseCode(filename) {
    const projectId = this.props.projectId;
    const token = localStorage.getItem('token');

    let code = CODE_PHP;

    code = code.replace('{FILENAME}', filename);
    code = code.replace('{PROJECT_ID}', projectId);
    code = code.replace('{TOKEN}', token);

    return code;
  }

  render() {
    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChangeTab}
            aria-label="code choice tabs">
            {FRAMEWORK_PHP_LIST.map(obj => (
              <Tab value={obj.label} label={obj.label} />
            ))}
          </Tabs>
        </AppBar>

        {FRAMEWORK_PHP_LIST.map(obj => (
          <TabPanel value={this.state.value} index={obj.label}>
            <p style={{ position: 'relative' }}>
              <CopyButton valueToCopy={this.parseCode(obj.filename)} />
              <SyntaxHighlighter language="php">{this.parseCode(obj.filename)}</SyntaxHighlighter>
            </p>
          </TabPanel>
        ))}
      </React.Fragment>
    );
  }
}

export default CodeTabs;

import React from 'react';
import { Alert } from '@material-ui/lab';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const AlertIncompleteExpired = () => {
  return (
    <Alert severity="warning">
      Your subscription is on hold because of a problem with your last payment (incomplete_expired).
      <Box ml={1} clone>
        <Link to={'/billing/paymentMethods'}>
          <Button variant="contained" color="primary" size="small">
            Update your payment method
          </Button>
        </Link>
      </Box>
    </Alert>
  );
};

export default AlertIncompleteExpired;

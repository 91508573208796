import { FREQUENCY_HOUR, FREQUENCY_DAY, FREQUENCY_WEEK, FREQUENCY_CUSTOM } from '../constants';

/**
 * @param {Object} values
 */
export function validateCrawlSchedule(values, dayWeek, selectedDate) {
  const errors = {};

  if (!values.profile) {
    errors.profile = 'Required';
  }

  if (!values.frequency) {
    errors.frequency = 'Required';
  }

  if (values.frequency) {
    switch (values.frequency) {
      case FREQUENCY_HOUR:
        if (!selectedDate) {
          errors.selectedDate = 'Required';
        }

        break;
      case FREQUENCY_DAY:
        if (!selectedDate) {
          errors.selectedDate = 'Required';
        }

        break;
      case FREQUENCY_WEEK:
        if (!dayWeek) {
          errors.dayWeek = 'Required';
        }

        if (!selectedDate) {
          errors.selectedDate = 'Required';
        }

        break;
      case FREQUENCY_CUSTOM:
        if (!values.cronExpression) {
          errors.cronExpression = 'Required';
        } else if (
          !/^(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})$/i.test(
            values.cronExpression,
          )
        ) {
          errors.cronExpression = 'Invalid CRON expression';
        }

        break;
      default:
        throw new Error('unknown frequency');
    }
  }

  return errors;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

/**
 * This component displays a row of active filters.
 */
const ActiveFilters = props => {
  return (
    <React.Fragment>
      {props.filters.map((obj, i) => (
        <Box key={i} mr={1}>
          <Chip
            key={'filter-delete-' + i}
            label={
              <IntlMessages
                id="bundles.LogsFilters.components.CodeFilter.action.filter.detail"
                values={{
                  label: <IntlMessages id={obj.label} />,
                  operator: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.operator.' + obj.operator} />,
                  values: obj.values.join(', '),
                }}
              />
            }
            onClick={() => {
              props.handleEditFilter(i);
            }}
            onDelete={() => {
              props.handleDeleteFilter(i);
            }}
            color="primary"
            className={'mr-2'}
          />
        </Box>
      ))}
    </React.Fragment>
  );
};

ActiveFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  handleEditFilter: PropTypes.func.isRequired,
  handleDeleteFilter: PropTypes.func.isRequired,
};

export default React.memo(ActiveFilters);

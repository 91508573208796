import LogAnalyzerHelpers from './../services/log_analyzer';
import CrawlerHelpers from './../services/crawler';

export const dataExplorerHelpers = {
  log_analyzer: LogAnalyzerHelpers,
  crawler: CrawlerHelpers,
};

export const DATE_PICKER_OPEN = 'open_date_picker';
export const DATE_PICKER_CLOSE = 'close_date_picker';
export const DATE_PICKER_SELECT = 'select_date_in_date_picker';
export const GET_LOGS_STARTED = 'get_logs_started';
export const GET_LOGS = 'get_logs';
export const CLEAR_LOGS = 'clear_logs';
export const GET_METRIC = 'get_metric';
export const CLEAR_METRIC = 'clear_metric';
export const SHOW_EXPORT_RESULT = 'show_export_result';
export const CLOSE_EXPORT_RESULT = 'close_export_result';
export const START_EXPORT = 'start_export';

// granularity
export const GRANULARITY_DEFAULT = 2; // day
export const GRANULARITY_CHOICES = [
  {
    label: 'Par minute',
    shortLabel: 'Minute',
    value: 'minute',
    function: 'FORMAT_DATETIME("%%B %%d, %%Y, %%H:%%M", DATETIME_TRUNC(%s, MINUTE))',
  },
  {
    label: 'Par heure',
    shortLabel: 'Heure',
    value: 'hour',
    function: 'FORMAT_DATETIME("%%B %%d, %%Y, %%H:%%M", DATETIME_TRUNC(%s, HOUR))',
  },
  {
    label: 'Par jour',
    shortLabel: 'Jour',
    value: 'day',
    function: 'FORMAT_DATETIME("%%B %%d, %%Y", DATETIME_TRUNC(%s, DAY))',
  },
  {
    label: 'Par semaine',
    shortLabel: 'Semaine',
    value: 'week',
    function: 'FORMAT_DATETIME("Week %%V, %%Y", DATETIME_TRUNC(%s, WEEK))',
  },
  {
    label: 'Par mois',
    shortLabel: 'Mois',
    value: 'month',
    function: 'FORMAT_DATETIME("%%B, %%Y", DATETIME_TRUNC(%s, MONTH))',
  },
  {
    label: 'Par trimestre',
    shortLabel: 'Trimestre',
    value: 'trimester',
    function: 'FORMAT_DATETIME("Q%%Q - %%Y", DATETIME_TRUNC(%s, QUARTER))',
  },
  {
    label: 'Par année',
    shortLabel: 'Année',
    value: 'year',
    function: 'FORMAT_DATETIME("%%Y", DATETIME_TRUNC(%s, YEAR))',
  },
];

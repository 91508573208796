export const PAGE_WEIGHT_FIELDS = [
  {
    array: true,
    label: 'dimension.content.page_weight',
    fields: [
      {
        dimension: 'performance_dom_documentDecodedSize',
        label: 'dimension.crawled_pages.performance_dom_documentDecodedSize',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'performance_dom_documentEncodedSize',
        label: 'dimension.crawled_pages.performance_dom_documentEncodedSize',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'performance_dom_totalDecodedSize',
        label: 'dimension.crawled_pages.performance_dom_totalDecodedSize',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'performance_dom_totalEncodedSize',
        label: 'dimension.crawled_pages.performance_dom_totalEncodedSize',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'documentDecodedSizeString',
        label: 'dimension.crawled_pages.documentDecodedSizeString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'documentEncodedSizeString',
        label: 'dimension.crawled_pages.documentEncodedSizeString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'totalDecodedSizeString',
        label: 'dimension.crawled_pages.totalDecodedSizeString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'totalEncodedSizeString',
        label: 'dimension.crawled_pages.totalEncodedSizeString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
    ],
  },
];

export const LIST_PROJECTS = 'list_projects';
export const GET_PROJECT = 'get_project';
export const CREATE_PROJECT = 'create_project';
export const UPDATE_PROJECT = 'update_project';
export const DELETE_PROJECT = 'delete_project';

export const DATABASE_REGION_LIST = [
  {
    header: 'Americas',
    choices: [
      { label: 'United States', value: 'US' },
      { label: 'Los Angeles', value: 'us-west2' },
      { label: 'Montréal', value: 'northamerica-northeast1' },
      { label: 'Northern Virginia', value: 'us-east4' },
      { label: 'Salt Lake City', value: 'us-west3' },
      { label: 'São Paulo', value: 'southamerica-east1' },
      { label: 'South Carolina', value: 'us-east1' },
    ],
  },
  {
    header: 'Europe',
    choices: [
      { label: 'European Union', value: 'EU' },
      { label: 'Finland', value: 'europe-north1' },
      { label: 'Frankfurt', value: 'europe-west3' },
      { label: 'London', value: 'europe-west2' },
      { label: 'Züric', value: 'europe-west6' },
    ],
  },
  {
    header: 'Asia Pacific',
    choices: [
      { label: 'Hong Kong', value: 'asia-east2' },
      { label: 'Mumbai', value: 'asia-south1' },
      { label: 'Osaka', value: 'asia-northeast2' },
      { label: 'Seoul', value: 'asia-northeast3' },
      { label: 'Singapore', value: 'asia-southeast1' },
      { label: 'Sydney', value: 'australia-southeast1' },
      { label: 'Taiwan', value: 'asia-east1' },
      { label: 'Tokyo', value: 'asia-northeast1' },
    ],
  },
];

export const STORAGE_REGION_LIST = [
  {
    header: 'Americas',
    choices: [
      { label: 'United States', value: 'US' },
      //{label: 'Iowa'              , value: 'us-central1'},
      { label: 'Los Angeles', value: 'us-west2' },
      { label: 'Montréal', value: 'northamerica-northeast1' },
      { label: 'Northern Virginia', value: 'us-east4' },
      //{label: 'Oregon'            , value: 'us-west1'},
      { label: 'Salt Lake City', value: 'us-west3' },
      { label: 'São Paulo', value: 'southamerica-east1' },
      { label: 'South Carolina', value: 'us-east1' },
    ],
  },
  {
    header: 'Europe',
    choices: [
      { label: 'European Union', value: 'EU' },
      //{label: 'Belgium'        , value: 'europe-west1'},
      { label: 'Finland', value: 'europe-north1' },
      { label: 'Frankfurt', value: 'europe-west3' },
      { label: 'London', value: 'europe-west2' },
      //{label: 'Netherlands'    , value: 'europe-west4'},
      { label: 'Zürich', value: 'europe-west6' },
    ],
  },
  {
    header: 'Asia Pacific',
    choices: [
      { label: 'Asia', value: 'ASIA' },
      { label: 'Hong Kong', value: 'asia-east2' },
      { label: 'Mumbai', value: 'asia-south1' },
      { label: 'Osaka', value: 'asia-northeast2' },
      { label: 'Seoul', value: 'asia-northeast3' },
      { label: 'Singapore', value: 'asia-southeast1' },
      { label: 'Sydney', value: 'australia-southeast1' },
      { label: 'Taiwan', value: 'asia-east1' },
      { label: 'Tokyo', value: 'asia-northeast1' },
    ],
  },
];

export const REGION_LIST = [
  {
    header: 'Americas',
    choices: [
      { label: 'United States', value: 'US' },
      //{label: 'Iowa'              , value: 'us-central1'},
      { label: 'Los Angeles', value: 'us-west2' },
      { label: 'Montréal', value: 'northamerica-northeast1' },
      { label: 'Northern Virginia', value: 'us-east4' },
      //{label: 'Oregon'            , value: 'us-west1'},
      { label: 'Salt Lake City', value: 'us-west3' },
      { label: 'São Paulo', value: 'southamerica-east1' },
      { label: 'South Carolina', value: 'us-east1' },
    ],
  },
  {
    header: 'Europe',
    choices: [
      { label: 'European Union', value: 'EU' },
      //{label: 'Belgium'        , value: 'europe-west1'},
      { label: 'Finland', value: 'europe-north1' },
      { label: 'Frankfurt', value: 'europe-west3' },
      { label: 'London', value: 'europe-west2' },
      //{label: 'Netherlands'    , value: 'europe-west4'},
      { label: 'Zürich', value: 'europe-west6' },
    ],
  },
  {
    header: 'Asia Pacific',
    choices: [
      { label: 'Asia', value: 'ASIA' },
      { label: 'Hong Kong', value: 'asia-east2' },
      { label: 'Mumbai', value: 'asia-south1' },
      { label: 'Osaka', value: 'asia-northeast2' },
      { label: 'Seoul', value: 'asia-northeast3' },
      { label: 'Singapore', value: 'asia-southeast1' },
      { label: 'Sydney', value: 'australia-southeast1' },
      { label: 'Taiwan', value: 'asia-east1' },
      { label: 'Tokyo', value: 'asia-northeast1' },
    ],
  },
];

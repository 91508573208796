import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CmtMediaObject from '@coremat/CmtMediaObject';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton, fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Icon from '@mdi/react';
import { mdiRocketLaunch } from '@mdi/js';
import FolderIcon from '@material-ui/icons/Folder';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { handleReadNotification } from 'bundles/notification/actions';

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: '10px 0',
    position: 'relative',
    borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
    '& .Cmt-media-image': {
      //alignSelf: 'flex-start',
      //width: 56,
    },
    '& .Cmt-media-body': {
      //width: 'calc(100% - 56px)',
      //flex: 'inherit',
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      '& $dateTextRoot': {
        width: 0,
      },
      '& $hideContent': {
        transform: 'translateX(0)',
        width: '100%',
      },
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    //cursor: 'pointer',
  },
  hideShowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  hideContent: {
    transition: 'all 0.4s ease',
    transform: 'translateX(110%)',
    overflow: 'hidden',
    '& Button': {
      '&:not(:first-child)': {
        marginLeft: 10,
      },
    },
  },
}));

const icons = {
  Crawl: <Icon path={mdiRocketLaunch} size={1} />,
  Project: <FolderIcon />,
  Default: <NotificationsIcon />,
};

const getCrawlContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.projectName ? item.projectName : ''}
    </Box>
    <Box component="span" ml={1}>
      {item.subject}
    </Box>
  </Typography>
);

const getProjectContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.projectName ? item.projectName : ''}
    </Box>
    <Box component="span" ml={1}>
      {item.subject}
    </Box>
  </Typography>
);

const getGenericContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.projectName ? item.projectName : ''}
    </Box>
    <Box component="span" ml={1}>
      {item.subject}
    </Box>
  </Typography>
);

const NotificationItem = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getTitle = (item, classes) => {
    switch (item.entityName) {
      case 'Crawl':
        return getCrawlContent(item, classes);
      case 'Project':
        return getProjectContent(item, classes);
      default:
        return getGenericContent(item, classes);
    }
  };

  const getSubTitle = item => (
    <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
      <Box color="primary.main" fontSize={16}>
        {item.entityName ? icons[item.entityName] : icons['Default']}
      </Box>
      <Box ml={2}>{moment(item.created).fromNow()}</Box>
    </Box>
  );

  return (
    <Box className={classes.feedItemRoot}>
      <CmtMediaObject
        title={getTitle(item, classes)}
        subTitle={getSubTitle(item)}
        actionsComponent={
          <Box display="flex" alignItems="center">
            <Box className={classes.hideShowContent}>
              <Box className={classes.hideContent}>
                <IconButton aria-label="readItem" onClick={() => dispatch(handleReadNotification(item.id, { readed: 0 }))}>
                  <CloseIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default NotificationItem;

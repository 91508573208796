import React from 'react';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { Box } from '@material-ui/core';

import { Formik } from 'formik';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const ForgotPasswordForm = props => {
  return (
    <Formik
      initialValues={props.data}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => props.onSubmit(values)}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.resetting.label.email" />
              </InputLabel>

              <TextField
                name={'email'}
                label={<IntlMessages id="bundles.resetting.label.email" />}
                value={values.email}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              <BackButton variant="outlined" color="primary">
                <IntlMessages id="bundles.resetting.action.cancel" />
              </BackButton>

              <Box ml={6} clone>
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id="bundles.resetting.action.resetPassword" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;

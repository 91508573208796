import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AppTableCell from './AppTableCell';

const AppTableRow = ({ dimensions, entity, hiddenFilters, measures, projectId, table, visualization }) => {
  const history = useHistory();
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:hover': {
        cursor: visualization.hasOwnProperty('hover') && visualization.hover ? 'pointer' : 'default',
      },
    },
  }))(TableRow);

  return (
    <StyledTableRow
      key={entity.id}
      hover={visualization.hasOwnProperty('hover') ? visualization.hover : false}
      onClick={
        visualization.hasOwnProperty('handleClick')
          ? () => visualization.handleClick(projectId, hiddenFilters, entity, history)
          : null
      }>
      {dimensions.map(dimension => (
        <AppTableCell key={dimension} dimension={dimension} entity={entity} table={table} />
      ))}

      {measures.map(measure => (
        <TableCell key={measure}>{entity[measure]}</TableCell>
      ))}
    </StyledTableRow>
  );
};

AppTableRow.propTypes = {
  dimensions: PropTypes.array.isRequired,
  entity: PropTypes.object.isRequired,
  hiddenFilters: PropTypes.array.isRequired,
  measures: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  table: PropTypes.string.isRequired,
  visualization: PropTypes.object.isRequired,
};

//export default AppTableRow;
export default React.memo(AppTableRow);

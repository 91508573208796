export const SLOW_PAGES = {
  '@id': 'SLOW_PAGES',
  name: 'bundles.crawl.chart.slow_pages.title',
  description: null,
  x: 0,
  y: 0,
  w: 6,
  h: 4,
  query: {
    table: 'crawled_pages',
    dimensions: [],
    dimensionsFunctions: [],
    groupBy: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'performance_navigation_totalLoadTime',
        operator: 'gte',
        values: [1000],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'performance_navigation_totalLoadTime',
          'totalLoadTimeString',
          'response_firstStatusCode',
        ],
        filters: [
          {
            label: 'dimension.crawled_pages.performance_navigation_totalLoadTime',
            dimension: 'performance_navigation_totalLoadTime',
            operator: 'gte',
            values: [1000],
          },
        ],
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

/**
 * @param {Object} values
 */
export function validateProject(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.domain) {
    errors.domain = 'Required';
  } else if (
    !/^((?:([a-z0-9]\.|[a-z0-9][a-z0-9-]{0,61}[a-z0-9])\.)+)([a-z0-9]{2,63}|(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]))\.?$/i.test(
      values.domain,
    )
  ) {
    errors.domain = 'Invalid domain name';
  }

  if (!values.region) {
    errors.region = 'Required';
  }

  return errors;
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Divider, Grid, CircularProgress, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';

import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import IntlMessages from '@jumbo/utils/IntlMessages';

import { getInvoice } from 'services/stripe/StripeUtil';
import { fetchError, fetchSuccess, redirectReset } from '../../../../redux/actions';

const useStyles = makeStyles({
  icon: {
    color: green[500],
    fontSize: 38,
    marginBottom: '12px',
  },
  title: {
    marginBottom: '12px',
  },
});

const CheckoutSuccessPage = () => {
  const classes = useStyles();
  const { subscriptionUser } = useSelector(({ auth }) => auth);
  const [invoice, setInvoice] = useState(null);

  const dispatch = useDispatch();

  dispatch(redirectReset());

  if (subscriptionUser && subscriptionUser.latest_invoice) {
    getInvoice(subscriptionUser.latest_invoice)
      .then(result => {
        setInvoice(result);
        dispatch(fetchSuccess());
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  }

  return (
    <React.Fragment>
      <AuthWrapper variant={'bgColor'}>
        <Box p={8} align={'center'} style={{ width: '100%' }}>
          <Box align={'center'} mb={6}>
            <CheckCircleIcon className={classes.icon} />

            <Typography className={classes.title} variant="h1" gutterBottom>
              <IntlMessages id="bundles.checkout.routes.checkoutSuccess.title" />
            </Typography>

            <Box className={classes.description}>
              <IntlMessages id="bundles.checkout.routes.checkoutSuccess.body" />
            </Box>
          </Box>

          {null === invoice ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Box mb={6}>
                <Grid container spacing={3}>
                  <Grid item lg={6} align={'right'}>
                    <strong>
                      <IntlMessages id="bundles.checkout.routes.checkoutSuccess.text.name" />
                    </strong>
                  </Grid>
                  <Grid item lg={6} align={'right'}>
                    <strong>{invoice.customer_name}</strong>
                  </Grid>
                  <Grid item lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item lg={6} align={'right'}>
                    <strong>
                      <IntlMessages id="bundles.checkout.routes.checkoutSuccess.text.reference" />
                    </strong>
                  </Grid>
                  <Grid item lg={6} align={'right'}>
                    <strong>{invoice.number}</strong>
                  </Grid>
                  <Grid item lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item lg={6} align={'right'}>
                    <strong>
                      <IntlMessages id="bundles.checkout.routes.checkoutSuccess.text.amountPaid" />
                    </strong>
                  </Grid>
                  <Grid item lg={6} align={'right'}>
                    <strong>{Intl.NumberFormat().format(invoice.amount_paid / 100)} &euro;</strong>
                  </Grid>
                  <Grid item lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item lg={12} align={'right'}>
                    <a rel="noopener noreferrer" href={invoice.invoice_pdf} target="_blank">
                      <IntlMessages id="bundles.checkout.routes.checkoutSuccess.action.downloadInvoicePdf" />
                    </a>
                  </Grid>
                  <Grid item lg={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Box>
              <Link to={'/projects'}>
                <Button color="primary" variant={'outlined'}>
                  <IntlMessages id="bundles.checkout.routes.checkoutSuccess.action.return" />
                </Button>
              </Link>
            </React.Fragment>
          )}
        </Box>
      </AuthWrapper>
    </React.Fragment>
  );
};

export default CheckoutSuccessPage;

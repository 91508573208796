import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IntlMessages from '@jumbo/utils/IntlMessages';

class ProfileTabs extends React.Component {
  render() {
    return (
      <Tabs value={this.props.tabActive} indicatorColor="primary" textColor="primary" aria-label="billing tabs">
        <Tab component={Link} label={<IntlMessages id="sidebar.profile" />} to={'/profile'} value={'/profile'} />
        <Tab
          component={Link}
          label={<IntlMessages id="sidebar.notifications" />}
          to={'/settings/notificationSelector'}
          value={'/settings/notificationSelector'}
        />
      </Tabs>
    );
  }
}

export default withRouter(ProfileTabs);

import { SLOW_PAGES } from './load_time/SLOW_PAGES';
import { AVERAGE_LOAD_TIME } from './load_time/AVERAGE_LOAD_TIME';
import { LOAD_TIME_BREAKDOWN } from './load_time/LOAD_TIME_BREAKDOWN';
import { LOAD_TIME_INSIGHT } from './load_time/LOAD_TIME_INSIGHT';
import { LOAD_TIME_BY_PAGE_GROUP_BREAKDOWN } from './load_time/LOAD_TIME_BY_PAGE_GROUP_BREAKDOWN';
import { AVERAGE_LOAD_TIME_BY_PAGE_GROUP } from './load_time/AVERAGE_LOAD_TIME_BY_PAGE_GROUP';

export const LOAD_TIME = {
  '@id': 'load_time',
  id: 'load_time',
  name: 'sidebar.crawl.dashboard.loadTime',
  description: null,
  project: '/api/projects/131',
  questions: [
    SLOW_PAGES,
    AVERAGE_LOAD_TIME,
    LOAD_TIME_BREAKDOWN,
    LOAD_TIME_INSIGHT,
    LOAD_TIME_BY_PAGE_GROUP_BREAKDOWN,
    AVERAGE_LOAD_TIME_BY_PAGE_GROUP,
  ],
};

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { handleDetach, handleSetDefaultMode } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';

const PaymentModeList = props => {
  const { authUser } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            <IntlMessages id="bundles.PaymentMode.global.card" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.PaymentMode.entity.paymentMode.name.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.PaymentMode.entity.paymentMode.expirationDate.short" />
          </TableCell>
          <TableCell colSpan={2} align={'right'}>
            <IntlMessages id="bundles.PaymentMode.global.actions" />
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.data.map(obj => (
          <TableRow key={obj.id}>
            <TableCell align={'left'}>
              <img alt={obj.card.brand} src={'/images/cards/' + obj.card.brand + '.png'} width={'64px'} />
            </TableCell>
            <TableCell align={'left'}>
              {obj.card.brand.charAt(0).toUpperCase()}
              {obj.card.brand.slice(1)}&nbsp;
              <IntlMessages id="bundles.PaymentMode.global.endingWith" />
              &nbsp;
              {obj.card.last4}
            </TableCell>
            <TableCell>{obj.billing_details.name}</TableCell>
            <TableCell>
              {obj.card.exp_month < 10 ? '0' + obj.card.exp_month : obj.card.exp_month}/{obj.card.exp_year}
            </TableCell>
            <TableCell>
              <Button
                key={'detach-' + obj.id}
                data-id={obj.id}
                size="small"
                color="primary"
                onClick={() => dispatch(handleDetach(obj.id, authUser[STRIPE_CUSTOMER_ID_FIELD]))}>
                <IntlMessages id="bundles.PaymentMode.action.delete" />
              </Button>
            </TableCell>
            <TableCell>
              {props.defaultPaymentMethod.id === obj.id ? (
                <Typography component="span" color={'textSecondary'}>
                  <IntlMessages id="bundles.PaymentMode.global.defaultMethod" />
                </Typography>
              ) : (
                <Button
                  key={'set-default-mode-' + obj.id}
                  data-id={obj.id}
                  size="small"
                  color="primary"
                  onClick={() => dispatch(handleSetDefaultMode(obj.id, authUser[STRIPE_CUSTOMER_ID_FIELD]))}>
                  <IntlMessages id="bundles.PaymentMode.action.setDefault" />
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PaymentModeList;

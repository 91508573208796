import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Switch from '@material-ui/core/Switch';
import { NOTIFICATION_SELECTOR_EVENTS } from '../constants';

/**
 * This component displays the event list table.
 */
const EventList = props => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <IntlMessages id="bundles.notificationSelector.table.head.cell.event.label" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.notificationSelector.table.head.cell.sendEmail.label" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {NOTIFICATION_SELECTOR_EVENTS.map((eventName, i) => (
          <TableRow key={i}>
            <TableCell>
              <InputLabel>
                <IntlMessages id={`bundles.notificationSelector.event.${eventName}.label`} />
              </InputLabel>
            </TableCell>
            <TableCell>
              <Switch
                id={`switch_${eventName}`}
                name={`notificationsConfig[events][${eventName}][enabled]`}
                checked={
                  props.values &&
                  props.values.hasOwnProperty('notificationsConfig') &&
                  props.values.notificationsConfig &&
                  props.values.notificationsConfig.hasOwnProperty('events') &&
                  props.values.notificationsConfig.events &&
                  props.values.notificationsConfig.events.hasOwnProperty(eventName) &&
                  props.values.notificationsConfig.events[eventName].hasOwnProperty('enabled')
                    ? props.values.notificationsConfig.events[eventName].enabled
                    : false
                }
                onChange={e => {
                  const value = e.currentTarget.checked;
                  props.setFieldValue(`notificationsConfig[events][${eventName}][channels][0]`, 'email', false);
                  props.setFieldValue(`notificationsConfig[events][${eventName}][enabled]`, value, false);
                  props.handleChange(e);
                }}
                color="primary"
                inputProps={{ 'aria-label': 'settings' }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

EventList.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default EventList;

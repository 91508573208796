import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import {
  Backdrop,
  Button,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@material-ui/core';
import { getProjectModuleByCode, getLogAnalyzerFileHistoryList } from 'services/api/MyApiUtil';
import HistoryFileStatus from './HistoryFileStatus';
import HistoryFileProgress from './HistoryFileProgress';
import { useDispatch, useSelector } from 'react-redux';
import { handleRetryImport } from '../actions';

const HistoryList = props => {
  const dispatch = useDispatch();
  const { project } = useSelector(({ projectSelector }) => projectSelector);

  const [projectModule, setProjectModule] = useState({
    status: null,
  });
  const [data, setData] = useState({
    'hydra:member': [],
  });

  let interval = useRef(null);

  /**
   * Triggered on component mount.
   * Refresh data & start the interval.
   */
  useEffect(() => {
    refresh();

    interval.current = setInterval(refresh, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Triggered on component unmount.
   * Stop the interval.
   */
  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const refresh = async () => {
    const projectModuleNew = await getProjectModuleByCode(project.id, 'log_analyzer');

    let payload = {
      project: '/api/projects/' + project.id,
    };

    payload.chunk = '0';

    const dataNew = await getLogAnalyzerFileHistoryList(payload);

    setProjectModule(projectModuleNew);
    setData(dataNew);
  };

  const formatBytes = (a, b = 2) => {
    if (0 === a) return '0 Bytes';
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    );
  };

  return (
    <React.Fragment>
      <Backdrop open={projectModule.status === 'finalize'} style={{ zIndex: 3000 }}>
        <Paper width={'100%'}>
          <Box p={4}>
            <Typography variant="h6" gutterBottom>
              <IntlMessages id="bundles.LogsHistory.title.finalization" />
            </Typography>

            <p align={'center'} className="mb-0">
              <CircularProgress color="primary" />
            </p>
          </Box>
        </Paper>
      </Backdrop>

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.filename" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.status" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.filesize" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.importMode" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.uploadedAt" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.linesCount" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.dateFirstLine" />
              </TableCell>
              <TableCell>
                <IntlMessages id="bundles.LogsHistory.table.head.dateLastLine" />
              </TableCell>
              <TableCell colSpan={2} style={{ width: '1%' }}>
                <IntlMessages id="bundles.LogsHistory.table.head.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data['hydra:member'].map(entity => (
              <TableRow key={entity.id}>
                <TableCell rowSpan={1}>{entity.filename}</TableCell>
                <TableCell rowSpan={1} align={'center'}>
                  <HistoryFileStatus entity={entity} />
                  <br />

                  {entity.status === 'running' ? (
                    <HistoryFileProgress projectId={project.id} store={props.store} entity={entity} />
                  ) : null}
                </TableCell>
                <TableCell rowSpan={1}>{formatBytes(entity.filesize)}</TableCell>
                <TableCell rowSpan={1}>{entity.importMode}</TableCell>
                <TableCell rowSpan={1}>{moment(entity.uploadedAt).fromNow()}</TableCell>
                <TableCell rowSpan={1}>{Intl.NumberFormat().format(entity.linesCount) || '-'}</TableCell>
                <TableCell rowSpan={1}>{entity.dateFirstLine || '-'}</TableCell>
                <TableCell rowSpan={1}>{entity.dateLastLine || '-'}</TableCell>
                <TableCell>
                  {('failed' === entity.status || 'configuration_required' === entity.status) && (
                    <Button color="primary" onClick={() => dispatch(handleRetryImport(project.id, entity.id))}>
                      <IntlMessages id="bundles.LogsHistory.action.retry" />
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {'configuration_required' === entity.status && (
                    // /log_analyzer/configure
                    <Button color="primary" href={'/log_analyzer/profile?fileId=' + entity.id}>
                      <IntlMessages id="bundles.LogsHistory.action.configure" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default HistoryList;

export const CODES_INSIGHT = {
  '@id': 'CODES_INSIGHT',
  name: 'bundles.crawl.chart.codes_insight.title',
  description: null,
  x: 6,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['response_firstStatusCode'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'asc',
    orderBy: 'response_firstStatusCode',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'table',
    colorFunction: 'getThemeColor',
    hover: true,

    handleClick: function(projectId, hiddenFilters, row, history) {
      const codeFilter =
        null === row['response_firstStatusCode']
          ? {
              label: 'dimension.crawled_pages.response_firstStatusCode',
              dimension: 'response_firstStatusCode',
              operator: 'empty',
              values: [true],
            }
          : {
              label: 'dimension.crawled_pages.response_firstStatusCode',
              dimension: 'response_firstStatusCode',
              operator: 'equals',
              values: [row['response_firstStatusCode']],
            };

      const config = {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'depth', 'response_firstStatusCode'],
        filters: [codeFilter],
        orderBy: 'url',
        order: 'asc',
      };

      let to =
        '/log_analyzer/explorer?projectId=' +
        projectId +
        '&schema=' +
        config.schemaName +
        '&filters=' +
        JSON.stringify(config.filters);

      if (hiddenFilters.length > 0) {
        to += '&hiddenFilters=' + JSON.stringify(hiddenFilters);
      }
      if (config.hasOwnProperty('columns') && config.columns.length > 0) {
        to += '&columns=' + JSON.stringify(config.columns);
      }

      if (config.hasOwnProperty('groupBy') && config.groupBy.length > 0) {
        to += '&groupBy=' + JSON.stringify(config.groupBy);
      }

      if (config.hasOwnProperty('measures') && config.measures.length > 0) {
        to += '&measures=' + JSON.stringify(config.measures);
      }

      if (config.hasOwnProperty('aggregationFilters') && config.aggregationFilters.length > 0) {
        to += '&aggregationFilters=' + JSON.stringify(config.aggregationFilters);
      }

      if (config.hasOwnProperty('orderBy')) {
        to += '&orderBy=' + config.orderBy;
      }

      if (config.hasOwnProperty('order')) {
        to += '&order=' + config.order;
      }

      if (config.hasOwnProperty('ungrouped')) {
        to += '&ungrouped=' + config.ungrouped;
      }

      history.push(to);
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';

import { Button } from '@material-ui/core';

/**
 * props required:
 *
 * - schema
 * - operator
 * - value
 * - editedFilterIndex
 * - handleSaveFilter
 */
class AddButton extends React.Component {
  render() {
    return (
      <p>
        {null === this.props.editedFilterIndex ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              this.props.handleSaveFilter(
                this.props.schema.label,
                this.props.schema.dimension,
                this.props.operator,
                this.props.value,
              )
            }>
            <IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.add" />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              this.props.handleSaveFilter(
                this.props.schema.label,
                this.props.schema.dimension,
                this.props.operator,
                this.props.value,
                this.props.editedFilterIndex,
              )
            }>
            <IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.edit" />
          </Button>
        )}
      </p>
    );
  }
}

export default AddButton;

import React from 'react';
import { Box, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import CopyButton from '../../../../components/CopyButton';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { EmailSupport } from '../../../../@jumbo/constants/AppConstants';

/**
 * This component displays the crawl list table.
 */
const DebugModeContent = () => {
  const code1 =
    '// ...\n' +
    '// add the new line BEFORE including the file seologs.php\n' +
    'define("SEOLOGS_DEBUG", true); // new line added\n' +
    '\n' +
    "require_once($_SERVER['DOCUMENT_ROOT'] . DIRECTORY_SEPARATOR . 'seologs.php');\n";

  const code2 = 'YOUR_SYSTEM_TEMP_DIRECTORY/seologs.log\n\nExample (Linux):\n/tmp/seologs.log';

  const code3 =
    '// ...\n' +
    '// add the new line BEFORE including the file seologs.php\n' +
    'define("SEOLOGS_DEBUG", true); // new line added\n' +
    'define("SEOLOGS_DEBUG_PATH", "YOUR_DIRECTORY/seologs.log"); // new line added\n' +
    '\n' +
    "require_once($_SERVER['DOCUMENT_ROOT'] . DIRECTORY_SEPARATOR . 'seologs.php');";

  return (
    <Box>
      <Box mb={6}>
        <h3>Debug mode</h3>
      </Box>

      <Box mb={6}>If validation fails, don't panic, follow these steps:</Box>

      <Box mb={6}>
        1. Add a constant <code>SEOLOGS_DEBUG</code> as in the example below:
      </Box>

      <Box mb={6}>
        <p style={{ position: 'relative' }}>
          <CopyButton valueToCopy={code1} />
          <SyntaxHighlighter language="php">{code1}</SyntaxHighlighter>
        </p>
      </Box>

      <Box mb={6}>
        A file <code>seologs.log</code> containing logs that will help you to solve the problems will be created by default
        in your <strong>temporary folder</strong>:
      </Box>

      <Box mb={6}>
        <p style={{ position: 'relative' }}>
          <CopyButton valueToCopy={code2} />
          <SyntaxHighlighter language="php">{code2}</SyntaxHighlighter>
        </p>
      </Box>

      <Box mb={6}>
        You can change the location of the file by adding a constant <code>SEOLOGS_DEBUG_PATH</code> as in the example below:
      </Box>

      <Box mb={6}>
        <p style={{ position: 'relative' }}>
          <CopyButton valueToCopy={code3} />
          <SyntaxHighlighter language="php">{code3}</SyntaxHighlighter>
        </p>
      </Box>

      <Box mb={6}>
        2. Click the button <strong>Verify installation</strong> to check the installation
      </Box>

      <Box mb={6}>
        3. Open the file <code>seologs.log</code> (or other path you specified)
      </Box>

      <Box mb={6}>4. Look for any error message in the file:</Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Message</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Action</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Cannot add request "xxxx" to data file
            </TableCell>
            <TableCell align="right">
              Check if the file <code>seologs.data</code> located in your <strong>temporary directory</strong> has write
              permissions
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Curl error: xxx
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                color="primary"
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:${EmailSupport}`}>
                Contact support
              </Button>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Request ignored because $_SERVER[HTTP_USER_AGENT] is empty
            </TableCell>
            <TableCell align="right">
              Make sure your web server (apache or other) exposes the variable <code>$_SERVER[HTTP_USER_AGENT]</code>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Unable to create file "xxxx.log"
            </TableCell>
            <TableCell align="right">Check if the file "xxxx.log" has write permissions</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Unexpected HTTP code: 401
            </TableCell>
            <TableCell align="right">
              Check the value of the constant <code>SEOLOS_TOKEN</code>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Unexpected HTTP code: 5xx
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                color="primary"
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:${EmailSupport}`}>
                Contact support
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default DebugModeContent;

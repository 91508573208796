import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box } from '@material-ui/core';
import Question from '../../../dashboard/components/Question';
import { INTERNAL_LINKS_FIELDS } from 'constants/dataExplorer/schema/crawled_pages/internal_links';
import FilteringContainer from 'bundles/logAnalyzer/LogsFilters/components/FilteringContainer';
import ChooseFilters from '../../../logAnalyzer/LogsFilters/components/Drawer/ChooseFilters';
import ActiveFiltersAndAddFilterButton from '../../../logAnalyzer/LogsFilters/components/ActiveFiltersAndAddFilterButton';
import Grid from '@material-ui/core/Grid';
import ChooseCheckboxes from '../../../../components/Drawer/ChooseCheckboxes';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { useColumnsSelector } from '../../../columnsSelector/hooks';

const OriginsOfPage = props => {
  const schema = [
    {
      dimension: 'url',
      label: 'dimension.crawled_pages.url',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
    ...INTERNAL_LINKS_FIELDS[0].fields,
  ].map(obj => {
    return {
      ...obj,
      visible: true,
    };
  });

  return (
    <FilteringContainer schema={schema}>
      <OriginsOfPageChild schema={schema} {...props} />
    </FilteringContainer>
  );
};

const OriginsOfPageChild = props => {
  const [ts, setTs] = useState(Date.now());

  // sorting
  const [orderBy, setOrderBy] = useState('href');
  const [order, setOrder] = useState('asc');

  // columns selector
  const [
    openColumnsSelector,
    columnsSelected,
    handleOpenColumnsSelector,
    handleCancelColumnsSelector,
    handleSubmitColumnsSelector,
  ] = useColumnsSelector(props.schema);

  useEffect(() => {
    setTs(Date.now());
    // eslint-disable-next-line
    },
    [ columnsSelected, props.filters, order, orderBy ]
  );

  /**
   * @param {string} dimension
   */
  const handleChangeSort = dimension => {
    const isAsc = orderBy === dimension && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(dimension);
  };

  const title = <IntlMessages id="bundles.urlViewer.subtitle.originsOfPage" />;

  const filters = [
    {
      dimension: 'content.internal_links.href',
      operator: 'equals',
      values: [props.url],
    },
    ...props.filters,
  ];

  return (
    <CmtCard>
      <CmtCardHeader title={title} />
      <CmtCardContent>
        <Grid container spacing={GRID_SPACING}>
          <Grid md={12} item>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'flex-start'}
              alignItems={'center'}
              style={{ float: 'left' }}>
              <ActiveFiltersAndAddFilterButton
                filters={props.filters}
                handleDeleteFilter={props.handleDeleteFilter}
                handleEditFilter={props.handleEditFilter}
                handleOpenFilteringDialog={props.handleOpenFilteringDialog}
              />

              <ChooseFilters
                projectId={props.projectId}
                name={'filter'}
                title={<IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.filter.title" />}
                open={props.filtersOpen}
                schema={props.schema}
                schemaName={'crawled_pages'}
                addFilter={props.addFilter}
                addFilterSchema={props.addFilterSchema}
                editedFilter={props.editedFilter}
                startDate={''}
                endDate={''}
                filters={props.filters}
                hiddenFilters={props.hiddenFilters}
                handleCancel={props.handleCloseFilteringDialog}
                handleAddFilter={props.handleAddFilter}
                handleCancelAddFilter={props.handleCancelAddFilter}
                handleSaveFilter={props.handleSaveFilter}
              />

              <Box mr={1}>
                <ChooseCheckboxes
                  title={<IntlMessages id="generic.action.columns.title" />}
                  open={openColumnsSelector}
                  schema={props.schema}
                  values={columnsSelected}
                  handleCancel={handleCancelColumnsSelector}
                  handleOk={handleSubmitColumnsSelector}
                />

                <Tooltip title={<IntlMessages id={'generic.action.columns.open.tooltip'} />}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleOpenColumnsSelector();
                    }}>
                    <IntlMessages id="generic.action.columns.btn" />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>

          <Grid md={12} item>
            <Question
              ts={ts}
              globalFilters={props.filters}
              autoRefresh={false}
              rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
              showExportAction={true}
              handleChangeSort={handleChangeSort}
              question={{
                '@id': 'data_explorer',
                project: props.projectId,
                query: {
                  projectId: props.projectId,
                  table: 'crawled_pages',
                  dimensions: columnsSelected,
                  dimensionsFunctions: [],
                  measures: [],
                  filters: filters,
                  hiddenFilters: props.hiddenFilters,
                  aggregationFilters: [],
                  order: order,
                  orderBy: orderBy,
                  ungrouped: 1,
                },
                visualization: {
                  type: 'table',
                  options: {},
                },
              }}
            />
          </Grid>
        </Grid>
      </CmtCardContent>
    </CmtCard>
  );
};

OriginsOfPage.propTypes = {
  projectId: PropTypes.number.isRequired,
  schema: PropTypes.array.isRequired,
  hiddenFilters: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};

export default OriginsOfPage;

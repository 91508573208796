import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Box from '@material-ui/core/Box';

import PieConfigForm from '../form/PieConfigForm';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const VisualizationConfigurator = props => {
  return (
    <Box>
      <Box p={6}>
        <h3>
          <IconButton
            aria-label="cancel"
            onClick={() => {
              props.handleCancel();
            }}>
            <ArrowBackIcon />
          </IconButton>

          <IntlMessages id={'bundles.dataExplorer.visualization.text.options'} />
        </h3>
      </Box>

      <Box p={6} pt={0}>
        {'table' === props.visualization.type && <p>in progress...</p>}

        {'pie' === props.visualization.type && (
          <PieConfigForm
            initialValues={props.visualization.options || {}}
            handleChange={props.handleChange}
            handleSubmit={props.handleChange}
          />
        )}
      </Box>
    </Box>
  );
};

VisualizationConfigurator.propTypes = {
  visualization: PropTypes.object,
  handleCancel: PropTypes.func,
  handleChange: PropTypes.func,
};

export default VisualizationConfigurator;

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button } from '@material-ui/core';
import { requestWebsiteBeforeValidateInstall, validateInstall } from 'services/api/MyApiUtil';
import FailedAlert from './components/Alert/FailedAlert';
import SuccessAlert from './components/Alert/SuccessAlert';
import CodeTabs from './components/Tabs/CodeTabs';
import { RETRY_INTERVAL, RETRY_LIMIT, DOWNLOAD_URL_LANG_PHP } from './constants/InstallScriptConstants';
import DebugModeContent from './components/DebugModeContent';

const uuidv4 = require('uuid/v4');

class InstallScript extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: null,
      status: null,
      validationCode: null,
      retryCount: 0,
    };

    this.handleStartValidateInstall = this.handleStartValidateInstall.bind(this);
    this.validateInstall = this.validateInstall.bind(this);
  }

  componentDidMount() {
    clearInterval(this.interval);
  }

  /**
   * Triggered when button `Verify installation` is clicked.
   *
   * This function:
   *
   * 1. Generate a random code
   * 2. Show loader
   * 3. Request the website with the Seologsbot userAgent and the code
   * 4. Call `this.validateInstall()` every x milliseconds
   *
   * @returns {Promise<void>}
   */
  async handleStartValidateInstall() {
    const projectId = this.props.projectId;
    const validationCode = uuidv4();

    this.setState({
      status: 'running',
      validationCode: validationCode,
    });

    await requestWebsiteBeforeValidateInstall(projectId, validationCode);

    this.validateInstall();

    this.interval = setInterval(this.validateInstall, RETRY_INTERVAL);
  }

  /**
   * Called every x milliseconds by `this.handleStartValidateInstall()`.
   *
   * This function:
   *
   * 1. Check if a log has been found in our database with the Seologsbot userAgent and the code
   * 2. If yes then display success and stop calling this function every x milliseconds
   *
   * @returns {Promise<void>}
   */
  async validateInstall() {
    const projectId = this.props.projectId;
    const result = await validateInstall(projectId, this.state.validationCode);

    let retryCount = this.state.retryCount + 1;
    let found = false;

    result['hydra:member'].map(obj => {
      if (obj.totalResults > 0) {
        found = true;
      }

      return obj;
    });

    if (true === found) {
      this.setState({
        interval: null,
        status: 'success',
      });

      clearInterval(this.interval);

      return;
    }

    if (retryCount >= RETRY_LIMIT) {
      this.setState({
        interval: null,
        status: 'failed',
        validationCode: null,
        retryCount: 0,
      });

      clearInterval(this.interval);

      return;
    }

    this.setState({
      retryCount: retryCount,
    });
  }

  render() {
    return (
      <div>
        <Box mb={6}>
          <h3>
            <IntlMessages id="pages.logAnalyzer.installScript.title.sharedHost" />
          </h3>
        </Box>
        <Box mb={6}>
          <h4>
            <IntlMessages id="pages.logAnalyzer.installScript.requirements.title" />
          </h4>
        </Box>
        <Box mb={6} pl={4}>
          <ul>
            <li>
              <IntlMessages id="pages.logAnalyzer.installScript.requirements.php" />
            </li>
            <li>
              <IntlMessages id="pages.logAnalyzer.installScript.requirements.curl" />
            </li>
          </ul>
        </Box>
        <Box mb={6}>
          <h4>
            <IntlMessages id="pages.logAnalyzer.installScript.installation.title" />
          </h4>
        </Box>
        <Box mb={6}>
          <IntlMessages id="pages.logAnalyzer.installScript.installation.description" />
        </Box>
        <Box mb={6} pl={4}>
          <ol>
            <li>
              <Box mb={6}>
                <IntlMessages id="pages.logAnalyzer.installScript.step.1.title" />
              </Box>

              <Box mb={6}>
                <a href={DOWNLOAD_URL_LANG_PHP} download={'seologs.php'} target={'_blank'} rel={'noopener noreferrer'}>
                  <Button variant="contained" color="primary">
                    <IntlMessages id="pages.logAnalyzer.installScript.step.1.btn.download" />
                  </Button>
                </a>
              </Box>
            </li>
            <li>
              <Box mb={6}>
                <IntlMessages id="pages.logAnalyzer.installScript.step.2.title" />
              </Box>
            </li>
            <li>
              <Box mb={6}>
                <IntlMessages id="pages.logAnalyzer.installScript.step.3.title" />
              </Box>
            </li>
            <li>
              <Box mb={6}>
                <IntlMessages id="pages.logAnalyzer.installScript.step.4.title" />
              </Box>

              <Box mb={6}>
                <CodeTabs store={this.props.store} projectId={this.props.projectId} />
              </Box>
            </li>
            <li>
              <Box mb={6}>
                <IntlMessages id="pages.logAnalyzer.installScript.step.5.title" />
              </Box>

              <Box mb={6}>
                <IntlMessages id="pages.logAnalyzer.installScript.step.5.description" />
              </Box>

              {this.state.status === 'failed' ? (
                <FailedAlert store={this.props.store} handleStartValidateInstall={this.handleStartValidateInstall} />
              ) : null}

              {this.state.status === 'running' ? <CircularProgress /> : null}

              {this.state.status === 'success' ? (
                <SuccessAlert store={this.props.store} projectId={this.props.projectId} />
              ) : null}

              {this.state.status === null ? (
                <Button variant="contained" color="primary" onClick={this.handleStartValidateInstall}>
                  <IntlMessages id="pages.logAnalyzer.installScript.step.5.btn.validate" />
                </Button>
              ) : null}
            </li>
          </ol>
        </Box>
        <Box mb={6}>
          <DebugModeContent />
        </Box>
      </div>
    );
  }
}

export default InstallScript;

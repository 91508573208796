export const LOAD_TIME_INSIGHT = {
  '@id': 'LOAD_TIME_INSIGHT',
  name: 'bundles.crawl.chart.load_time_insight.title',
  description: null,
  x: 6,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['totalLoadTimeString'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'table',
    hover: true,

    handleClick: function(projectId, hiddenFilters, row, history) {
      const config = {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'performance_navigation_totalLoadTime',
          'totalLoadTimeString',
          'response_firstStatusCode',
        ],
        filters: [
          {
            label: 'dimension.crawled_pages.totalLoadTimeString',
            dimension: 'totalLoadTimeString',
            operator: 'equals',
            values: [row['totalLoadTimeString']],
          },
        ],
        orderBy: 'url',
        order: 'asc',
      };

      let to =
        '/log_analyzer/explorer?projectId=' +
        projectId +
        '&schema=' +
        config.schemaName +
        '&filters=' +
        JSON.stringify(config.filters);

      if (hiddenFilters.length > 0) {
        to += '&hiddenFilters=' + JSON.stringify(hiddenFilters);
      }
      if (config.hasOwnProperty('columns') && config.columns.length > 0) {
        to += '&columns=' + JSON.stringify(config.columns);
      }

      if (config.hasOwnProperty('groupBy') && config.groupBy.length > 0) {
        to += '&groupBy=' + JSON.stringify(config.groupBy);
      }

      if (config.hasOwnProperty('measures') && config.measures.length > 0) {
        to += '&measures=' + JSON.stringify(config.measures);
      }

      if (config.hasOwnProperty('aggregationFilters') && config.aggregationFilters.length > 0) {
        to += '&aggregationFilters=' + JSON.stringify(config.aggregationFilters);
      }

      if (config.hasOwnProperty('orderBy')) {
        to += '&orderBy=' + config.orderBy;
      }

      if (config.hasOwnProperty('order')) {
        to += '&order=' + config.order;
      }

      if (config.hasOwnProperty('ungrouped')) {
        to += '&ungrouped=' + config.ungrouped;
      }

      history.push(to);
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

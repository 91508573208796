import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import Divider from '@material-ui/core/Divider';
import { getUser } from 'services/api/MyApiUtil';
import { useDispatch, useSelector } from 'react-redux';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';

const ProfileView = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStart());

    getUser(authUser.id)
      .then(data => {
        setUser(data);
        dispatch(fetchSuccess());
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Paper>
        <Box p={GRID_SPACING}>
          <Box mb={6}>
            <Typography variant="h1">
              <IntlMessages id="bundles.profile.title.yourInformations" />
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.firstname.field" />
              </InputLabel>
              <Typography variant={'body1'}>{user.firstname || ''}</Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.lastname.field" />
              </InputLabel>
              <Typography variant={'body1'}>{user.lastname || ''}</Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.email.field" />
              </InputLabel>
              <Typography variant={'body1'}>{user.email || ''}</Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.emailBilling.field" />
              </InputLabel>
              <Typography variant={'body1'}>{user.emailBilling || '-'}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Link to={'/profile/edit'} style={{ float: 'right' }}>
                <Button variant="contained" color="primary">
                  <IntlMessages id="bundles.profile.action.edit" />
                </Button>
              </Link>

              <InputLabel>
                <IntlMessages id="bundles.profile.entity.account.country.field" />
              </InputLabel>
              <Typography variant={'body1'}>{user.country ? `${user.countryName} (${user.countryCode})` : '-'}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider />

        <Box p={4}>
          <Typography variant={'h6'} gutterBottom>
            <IntlMessages id="bundles.profile.title.changePassword" />
          </Typography>

          <Link to={'/profile/change-password'} style={{ float: 'right' }}>
            <Button variant="contained" color="primary">
              <IntlMessages id="bundles.profile.action.edit" />
            </Button>
          </Link>

          <InputLabel>
            <IntlMessages id="bundles.profile.entity.account.password.field" />
          </InputLabel>
          <Typography variant={'body1'}>****************</Typography>
        </Box>
      </Paper>
    </form>
  );
};

export default ProfileView;

import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { UPDATE_INVOICES_LIST } from '../constants';
import { getInvoices } from 'services/stripe/StripeUtil';

/**
 * @param id customer ID in Stripe
 * @returns {function(*): void}
 */
export const getInvoicesList = id => {
  return dispatch => {
    dispatch(fetchStart());

    getInvoices(id)
      .then(({ data }) => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_INVOICES_LIST,
          payload: data.data,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

import { updateLogAnalyzerFileHistory } from 'services/api/MyApiUtil';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';

const request = require('request-promise-native');

export const handleRetryImport = (projectId, id) => {
  return dispatch => {
    dispatch(fetchStart());

    const payload = {
      status: 'waiting',
      metadata: [],
    };

    updateLogAnalyzerFileHistory(id, payload)
      .then(result => {
        const payload2 = {
          projectId: projectId,
          fileHistoryId: id,
          lineStart: 0,
          lineStop: 100000,
        };

        request({
          method: 'POST',
          uri: 'https://us-central1-seologs-1091.cloudfunctions.net/seologs-import-single-log-file',
          body: payload2,
          json: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.LogsHistory.notification.import.success', 'success'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

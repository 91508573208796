import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chip: {
    //
  },
});

/**
 * This component properly displays the status of an invoice.
 */
const InvoiceStatusBadge = props => {
  const classes = useStyles();

  let style = null;
  let label = null;

  switch (props.invoice.status) {
    case 'draft':
      style = { color: '#818182', backgroundColor: '#fefefe' };
      label = <IntlMessages id="bundles.Invoice.entity.invoice.status.draft" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Invoice.entity.invoice.status.draft.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'open':
      style = { color: '#856404', backgroundColor: '#fff3cd' };
      label = <IntlMessages id="bundles.Invoice.entity.invoice.status.open" />;

      if (
        false === props.invoice.paid &&
        true === props.invoice.attempted &&
        props.invoice.attempt_count > 0 &&
        props.invoice.next_payment_attempt
      ) {
        style = { color: '#721c24', backgroundColor: '#f8d7da' };
        label = <IntlMessages id="bundles.Invoice.entity.invoice.status.attempt" />;

        return (
          <Tooltip
            title={
              <IntlMessages
                id="bundles.Invoice.entity.invoice.status.attempt.help"
                values={{
                  attempt_count: props.invoice.attempt_count,
                  next_payment_attempt: moment.unix(props.invoice.next_payment_attempt).format('DD/MM/YYYY'),
                }}
              />
            }>
            <div>
              <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
            </div>
          </Tooltip>
        );
      }

      return (
        <Tooltip title={<IntlMessages id="bundles.Invoice.entity.invoice.status.open.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'paid':
      style = { color: '#155724', backgroundColor: '#d4edda' };
      label = <IntlMessages id="bundles.Invoice.entity.invoice.status.paid" />;

      if (
        0 === props.invoice.amount_due &&
        0 === props.invoice.amount_paid &&
        'subscription_create' === props.invoice.billing_reason
      ) {
        return (
          <Tooltip title={<IntlMessages id="bundles.Invoice.entity.invoice.status.free_trial_paid.help" />}>
            <div>
              <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
            </div>
          </Tooltip>
        );
      }

      return (
        <Tooltip title={<IntlMessages id="bundles.Invoice.entity.invoice.status.paid.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'uncollectible':
      style = { color: '#721c24', backgroundColor: '#f8d7da' };
      label = <IntlMessages id="bundles.Invoice.entity.invoice.status.uncollectible" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Invoice.entity.invoice.status.uncollectible.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'void':
      style = { color: '#383d41', backgroundColor: '#e2e3e5' };
      label = <IntlMessages id="bundles.Invoice.entity.invoice.status.void" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Invoice.entity.invoice.status.void.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    default:
      throw new Error('unknown `props.invoice.status` value');
  }
};

InvoiceStatusBadge.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default React.memo(InvoiceStatusBadge);

import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

const getBackgroundColorByCode = code => {
  if ('Unknown' === code) {
    return '#323232';
  } else if (parseInt(code) < 300) {
    return '#4caf50';
  } else if (parseInt(code) < 400) {
    return '#ff9800';
  } else if (parseInt(code) < 500) {
    return '#f44336';
  } else {
    return '#dc004e';
  }
};

export const STATUS_CODE = {
  '@id': 'status_code',
  id: 'status_code',
  name: 'sidebar.logs.dashboard.statusCode',
  description: null,
  project: '/api/projects/131',
  questions: [
    {
      '@id': 'pages_crawled_by_code_group',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code_group.title',
      description: null,
      x: 0,
      y: 0,
      w: 12,
      h: 3,
      query: {
        table: 'logs',
        dimensions: ['code'],
        dimensionsFunctions: [{ function: 'LEFT(%s, 1)' }],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count', 'percent_over_total'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: null,
        orderBy: null,
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'StatusCodeIntro',
        options: {
          hideCardHeader: true,
          legend: {
            align: 'center',
            position: 'bottom',
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_code',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code.title',
      description: null,
      x: 0,
      y: 3,
      w: 4,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['code'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'code',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getColorByStatusCode',
        options: {
          responsive: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_code_day',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code_day.title',
      description: null,
      x: 4,
      y: 3,
      w: 8,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['datetime', 'code'],
        dimensionsFunctions: [{ function: 'FORMAT_DATETIME("%%d-%%m-%%Y", DATETIME_TRUNC(%s, DAY))' }],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'datetime',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getColorByStatusCode',

        getDatasetsCallback: function(rows) {
          // get labels.
          const labels = rows.map(obj => obj.datetime).filter((v, i, a) => a.indexOf(v) === i);

          // get http codes.
          const codes = rows
            .map(obj => obj.code)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over status codes.
          // a dataset will be added by code.
          const datasetsSetup = codes.map(code => ({
            label: code,
            data: dataWithEmptyValues,
            backgroundColor: getBackgroundColorByCode(code),
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.datetime);
            const iDataset = codes.indexOf(arr.code ? arr.code : 'Unknown');

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },
        getLabelsCallback: function(rows) {
          let labels = [];

          rows.map(arr => {
            labels.push(arr.datetime);

            return true;
          });

          labels = labels.filter((v, i, a) => a.indexOf(v) === i);

          return labels;
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const day = chartData.labels[element[0].index]; // '27-04-2022'
          const code = chartData.datasets[element[0].datasetIndex].label;

          // convert date from DD-MM-YYYY to YYYY-MM-DD
          const dayUsFormat = day
            .split('-')
            .reverse()
            .join('-');

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.code',
                dimension: 'code',
                operator: 'equals',
                values: [code],
              },
            ],
            startDate: dayUsFormat,
            endDate: dayUsFormat,
          };
        },

        options: {
          plugins: {
            legend: {
              labels: {
                //boxWidth: 6,
                //usePointStyle: true,
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                color: '#f5f6f9',
                borderColor: '#f5f6f9',
                borderDash: [6, 3],
                offset: true,
              },
              ticks: {
                crossAlign: 'center',
                maxRotation: 0,
                padding: 10,
              },
            },
            y: {
              stacked: true,
              grid: {
                color: '#f5f6f9',
                borderColor: '#f5f6f9',
                offset: true,
                tickLength: 0,
              },
              ticks: {
                padding: 20,
              },
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'datetime',
          datasetsDimension: 'code',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_code_bot',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code_bot.title',
      description: null,
      x: 0,
      y: 20,
      w: 6,
      h: 5,
      query: {
        table: 'logs',
        dimensions: ['bot', 'code'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'desc',
        orderBy: 'count',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getColorByStatusCode',

        getDatasetsCallback: function(rows) {
          // get labels.
          const labels = rows.map(obj => obj.bot).filter((v, i, a) => a.indexOf(v) === i);

          // get http codes.
          const codes = rows
            .map(obj => obj.code)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over status codes.
          // a dataset will be added by code.
          const datasetsSetup = codes.map(code => ({
            label: code,
            data: dataWithEmptyValues,
            backgroundColor: getBackgroundColorByCode(code),
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.bot);
            const iDataset = codes.indexOf(arr.code ? arr.code : 'Unknown');

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },
        getLabelsCallback: function(rows) {
          let labels = [];

          rows.map(arr => {
            labels.push(arr.bot);

            return true;
          });

          labels = labels.filter((v, i, a) => a.indexOf(v) === i);

          return labels;
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const bot = chartData.labels[element[0].index];
          const code = chartData.datasets[element[0].datasetIndex].label;

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.code',
                dimension: 'code',
                operator: 'equals',
                values: [code],
              },
              {
                label: 'dimension.logs.bot',
                dimension: 'bot',
                operator: 'equals',
                values: [bot],
              },
            ],
          };
        },

        options: {
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'bot',
          datasetsDimension: 'code',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_code_domainType',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code_domainType.title',
      description: null,
      x: 0,
      y: 25,
      w: 6,
      h: 5,
      query: {
        table: 'logs',
        dimensions: ['domainType', 'code'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'desc',
        orderBy: 'count',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getColorByStatusCode',

        getDatasetsCallback: function(rows) {
          // get labels.
          const labels = rows.map(obj => obj.domainType).filter((v, i, a) => a.indexOf(v) === i);

          // get http codes.
          const codes = rows
            .map(obj => obj.code)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over status codes.
          // a dataset will be added by code.
          const datasetsSetup = codes.map(code => ({
            label: code,
            data: dataWithEmptyValues,
            backgroundColor: getBackgroundColorByCode(code),
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.domainType);
            const iDataset = codes.indexOf(arr.code ? arr.code : 'Unknown');

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },
        getLabelsCallback: function(rows) {
          let labels = [];

          rows.map(arr => {
            labels.push(arr.domainType);

            return true;
          });

          labels = labels.filter((v, i, a) => a.indexOf(v) === i);

          return labels;
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const domainType = chartData.labels[element[0].index];
          const code = chartData.datasets[element[0].datasetIndex].label;

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.code',
                dimension: 'code',
                operator: 'equals',
                values: [code],
              },
              {
                label: 'dimension.logs.domainType',
                dimension: 'domainType',
                operator: 'equals',
                values: [domainType],
              },
            ],
          };
        },

        options: {
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'domainType',
          datasetsDimension: 'code',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_code_domain',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code_domain.title',
      description: null,
      x: 6,
      y: 20,
      w: 6,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['domain', 'code'],
        dimensionsFunctions: [],
        groupBy: ['domain'],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'desc',
        orderBy: 'count',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getColorByStatusCode',
        getDatasetsCallback: function(rows) {
          // get labels.
          const labels = rows.map(obj => obj.domain).filter((v, i, a) => a.indexOf(v) === i);

          // get http codes.
          const codes = rows
            .map(obj => obj.code)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over status codes.
          // a dataset will be added by code.
          const datasetsSetup = codes.map(code => ({
            label: code,
            data: dataWithEmptyValues,
            backgroundColor: getBackgroundColorByCode(code),
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.domain);
            const iDataset = codes.indexOf(arr.code ? arr.code : 'Unknown');

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },
        getLabelsCallback: function(rows) {
          let labels = [];

          rows.map(arr => {
            labels.push(arr.domain);

            return true;
          });

          labels = labels.filter((v, i, a) => a.indexOf(v) === i);

          return labels;
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const domain = chartData.labels[element[0].index];
          const code = chartData.datasets[element[0].datasetIndex].label;

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.code',
                dimension: 'code',
                operator: 'equals',
                values: [code],
              },
              {
                label: 'dimension.logs.domain',
                dimension: 'domain',
                operator: 'equals',
                values: [domain],
              },
            ],
          };
        },

        options: {
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'domain',
          datasetsDimension: 'code',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_code_scheme',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_code_scheme.title',
      description: null,
      x: 0,
      y: 30,
      w: 6,
      h: 5,
      query: {
        table: 'logs',
        dimensions: ['scheme', 'code'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'desc',
        orderBy: 'count',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getColorByStatusCode',

        getDatasetsCallback: function(rows) {
          // get labels.
          const labels = rows.map(obj => obj.scheme).filter((v, i, a) => a.indexOf(v) === i);

          // get http codes.
          const codes = rows
            .map(obj => obj.code)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over status codes.
          // a dataset will be added by code.
          const datasetsSetup = codes.map(code => ({
            label: code,
            data: dataWithEmptyValues,
            backgroundColor: getBackgroundColorByCode(code),
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.scheme);
            const iDataset = codes.indexOf(arr.code ? arr.code : 'Unknown');

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },
        getLabelsCallback: function(rows) {
          let labels = [];

          rows.map(arr => {
            labels.push(arr.scheme);

            return true;
          });

          labels = labels.filter((v, i, a) => a.indexOf(v) === i);

          return labels;
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const scheme = chartData.labels[element[0].index];
          const code = chartData.datasets[element[0].datasetIndex].label;

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.code',
                dimension: 'code',
                operator: 'equals',
                values: [code],
              },
              {
                label: 'dimension.logs.scheme',
                dimension: 'scheme',
                operator: 'equals',
                values: [scheme],
              },
            ],
          };
        },

        options: {
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'scheme',
          datasetsDimension: 'code',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
  ],
};

import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  LIST_SEGMENTATION_PROFILES,
  GET_SEGMENTATION_PROFILE,
  CLEAR_SEGMENTATION_PROFILE,
  GET_SEGMENTATION_STATS,
} from '../constants';
import {
  getSegmentationProfileList,
  getSegmentationProfile,
  createSegmentationProfile,
  updateSegmentationProfile,
  deleteSegmentationProfile,
  createSegmentationRule,
  querySql,
  getLatestCrawl,
  updateSegmentationProfileCategory,
} from 'services/api/MyApiUtil';
import { showMessage } from 'redux/actions';

export const handleGetSegmentationProfiles = (projectId, payload, onSuccess) => {
  return dispatch => {
    dispatch(fetchStart());

    getSegmentationProfileList(projectId, payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: LIST_SEGMENTATION_PROFILES,
          payload: result,
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleGetSegmentationProfile = id => {
  return dispatch => {
    dispatch(fetchStart());

    getSegmentationProfile(id)
      .then(result => {
        result.rules.push({ name: '', pattern: '' });

        dispatch(fetchSuccess());
        dispatch({
          type: GET_SEGMENTATION_PROFILE,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleClearSegmentationProfile = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SEGMENTATION_PROFILE,
    });
  };
};

export const handleCreateSegmentationProfile = (user, projectId, payload, onSuccess) => {
  return dispatch => {
    dispatch(fetchStart());

    const profilePayload = {
      name: payload.name,
      project: '/api/projects/' + projectId,
    };

    createSegmentationProfile(profilePayload)
      .then(async result => {
        result.rules = [];

        for (let i = 0; i < payload.rules.length; i++) {
          if (!payload.rules[i].name || !payload.rules[i].pattern) {
            continue;
          }

          const rulePayload = {
            name: payload.rules[i].name,
            pattern: payload.rules[i].pattern,
            project: '/api/projects/' + projectId,
            segmentationProfile: '/api/segmentation_profiles/' + result.id,
          };

          const ruleResult = await createSegmentationRule(rulePayload);

          result.rules.push(ruleResult);
        }

        result.rules.push({ name: '', pattern: '' });

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.segmentation.notification.create.success', 'success'));

        dispatch({
          type: GET_SEGMENTATION_PROFILE,
          payload: result,
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleUpdateSegmentationProfile = (id, projectId, payload) => {
  return dispatch => {
    dispatch(fetchStart());

    let rules = [];

    for (let i = 0; i < payload.rules.length; i++) {
      if (!payload.rules[i].name || !payload.rules[i].pattern) {
        continue;
      }

      rules.push({
        name: payload.rules[i].name,
        pattern: payload.rules[i].pattern,
        project: '/api/projects/' + projectId,
        segmentationProfile: '/api/segmentation_profiles/' + id,
      });
    }

    const profilePayload = {
      name: payload.name,
      project: '/api/projects/' + projectId,
      rules: rules,
    };

    updateSegmentationProfile(id, profilePayload)
      .then(async result => {
        if (payload.hasOwnProperty('updateCrawlData') && true === payload.updateCrawlData) {
          // update crawl data
          await updateSegmentationProfileCategory(id);
        }

        result.rules.push({ name: '', pattern: '' });

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.segmentation.notification.update.success', 'success'));

        if (payload.hasOwnProperty('updateCrawlData') && true === payload.updateCrawlData) {
          dispatch(showMessage('bundles.segmentation.notification.updateCrawlData.success', 'success'));
        }

        dispatch({
          type: GET_SEGMENTATION_PROFILE,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleDeleteSegmentationProfile = (projectId, id) => {
  return dispatch => {
    dispatch(fetchStart());

    deleteSegmentationProfile(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.segmentation.notification.deleted.success', 'success'));
        dispatch(
          handleGetSegmentationProfiles(projectId, {
            order: {
              name: 'asc',
            },
          }),
        );
        dispatch({
          type: CLEAR_SEGMENTATION_PROFILE,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

/**
 * not used
 */
export const handleCountUrlBySegement = (projectId, crawlId, segmentationProfile) => {
  return async dispatch => {
    let sumSegmented = 0;
    let stats = {
      isLoading: true,
      labels: [],
      data: [],
      crawled: 0,
      withSegment: 0,
      withoutSegment: 0,
      pieData: [
        { name: 'segmented', value: 0, color: '#fff' },
        { name: 'undefined', value: 0, color: '#3700B3' },
      ],
    };

    dispatch({
      type: GET_SEGMENTATION_STATS,
      payload: stats,
    });

    const latestCrawl = await getLatestCrawl(projectId);
    console.log('latestCrawl', latestCrawl);

    if (!latestCrawl) {
      return;
    }

    for (let i = 0; i < segmentationProfile.rules.length; i++) {
      if (!segmentationProfile.rules[i].name || !segmentationProfile.rules[i].pattern) {
        continue;
      }

      let filters = [
        {
          dimension: 'crawlId',
          operator: 'equals',
          values: [latestCrawl.partitionId],
        },
        {
          dimension: 'url',
          operator: 'regexpMatch',
          values: [segmentationProfile.rules[i].pattern],
        },
      ];

      const result = await querySql(projectId, 'crawled_pages', [], [], [], null, ['count'], filters, [], {}, null, null, 1);

      stats.labels.push(segmentationProfile.rules[i].name);
      stats.data.push(result['hydra:member'][0]['count']);

      sumSegmented += result['hydra:member'][0]['count'];
    }

    // count total crawled URLs
    // ------------------------
    let filters = [
      {
        dimension: 'crawlId',
        operator: 'equals',
        values: [latestCrawl.partitionId],
      },
    ];

    const result = await querySql(projectId, 'crawled_pages', [], [], [], null, ['count'], filters, [], {}, null, null, 1);
    stats.crawled = result['hydra:member'][0]['count'];

    // URLs with segment
    // -----------------
    stats.withSegment = sumSegmented;

    // URLs without segment
    // --------------------
    stats.withoutSegment = result['hydra:member'][0]['count'] - sumSegmented;

    stats.pieData = [
      { name: 'segmented', value: stats.withSegment, color: '#fff' },
      { name: 'undefined', value: stats.withoutSegment, color: '#3700B3' },
    ];

    stats.isLoading = false;

    dispatch({
      type: GET_SEGMENTATION_STATS,
      payload: stats,
    });
  };
};

import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const CODES_BY_PAGE_GROUP = {
  '@id': 'CODES_BY_PAGE_GROUP',
  name: 'bundles.crawl.chart.codes_by_page_group.title',
  description: null,
  x: 0,
  y: 12,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'response_firstStatusCode'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = [];

      let labels = [];
      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });
      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      let codes = [];
      rows.map(arr => {
        codes.push(arr.response_firstStatusCode ? arr.response_firstStatusCode : 'Unknown');

        return true;
      });
      codes = codes.filter((v, i, a) => a.indexOf(v) === i).sort();

      codes.map(code => {
        let data = [];

        labels.map(label2 => {
          data.push(0);

          return true;
        });

        let backgroundColor = '';

        if ('Unknown' === code) {
          backgroundColor = '#323232';
        } else if (code < 300) {
          backgroundColor = '#4caf50';
        } else if (code < 400) {
          backgroundColor = '#ff9800';
        } else if (code < 500) {
          backgroundColor = '#f44336';
        } else {
          backgroundColor = '#dc004e';
        }

        datasets.push({
          label: code,
          data: data,
          backgroundColor: backgroundColor,
        });

        return true;
      });

      rows.map(arr => {
        const iLabel = labels.indexOf(arr.category);
        const iDataset = codes.indexOf(arr.response_firstStatusCode ? arr.response_firstStatusCode : 'Unknown');

        datasets[iDataset].data[iLabel] = arr.count;

        return true;
      });

      labels.map((label, i) => {
        let sum = 0;
        datasets.map((dataset, j) => {
          sum += dataset.data[i];

          return true;
        });

        datasets.map((dataset, j) => {
          datasets[j].data[i] = (datasets[j].data[i] / sum) * 100;

          return true;
        });

        return true;
      });

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let response_firstStatusCode = chartData.datasets[element[0].datasetIndex].label; // 404

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });

      if (null === response_firstStatusCode || 'Unknown' === response_firstStatusCode) {
        filters.push({
          label: 'dimension.crawled_pages.response_firstStatusCode',
          dimension: 'response_firstStatusCode',
          operator: 'empty',
          values: [true],
        });
      } else {
        filters.push({
          label: 'dimension.crawled_pages.response_firstStatusCode',
          dimension: 'response_firstStatusCode',
          operator: 'equals',
          values: [response_firstStatusCode],
        });
      }

      return {
        schemaName: 'crawled_pages',
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'response_firstStatusCode',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Button, Chip, Typography } from '@material-ui/core';

const CreateConfirmationModal = props => {
  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h2">
          <IntlMessages id="bundles.crawlProfile.components.createConfirmationModal.title" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <React.Fragment>
            <Box mb={6}>
              <IntlMessages id="bundles.crawlProfile.components.createConfirmationModal.content.1" />
            </Box>

            <Box mb={6}>
              <Chip label={props.data.name} variant="outlined" />
            </Box>

            <Box>
              <IntlMessages id="bundles.crawlProfile.components.createConfirmationModal.content.2" />
            </Box>
          </React.Fragment>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.handleYes}>
          <IntlMessages id="button.yes" />
        </Button>
        <Button color="primary" onClick={props.handleNo}>
          <IntlMessages id="button.no" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateConfirmationModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  handleYes: PropTypes.func,
  handleNo: PropTypes.func,
  handleClose: PropTypes.func,
};

export default CreateConfirmationModal;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { AppSubHeaderHeight } from '../../../../constants/AppConstants';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 72,
    left: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    backgroundColor: theme.palette.subheader.bgColor,
    borderBottom: `1px solid  ${theme.palette.borderColor.main}`,
    boxShadow: theme.palette.subheader.boxShadow,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: AppSubHeaderHeight,
  },
}));

const BreadcrumbLink = props => {
  if (props.obj.hasOwnProperty('to') && props.obj.to) {
    return (
      <Link color="inherit" to={props.obj.to}>
        {props.obj.text}
      </Link>
    );
  } else {
    return <Typography color="textPrimary">{props.obj.text}</Typography>;
  }
};

const SubHeader = props => {
  const classes = useStyles();

  const { breadcrumb, pageActions } = useContext(AppContext);

  return (
    <AppBar position="static" className={clsx(classes.root, 'SubHeader')}>
      <Toolbar className={classes.toolbar}>
        {breadcrumb.length > 0 && (
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumb.map((obj, i) => (
              <BreadcrumbLink key={i} obj={obj} />
            ))}
          </Breadcrumbs>
        )}

        <Box flex={1} />

        {pageActions.length > 0 && pageActions}
      </Toolbar>
    </AppBar>
  );
};

export default SubHeader;

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  colorThemeHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '12px 24px',
    textTransform: 'uppercase',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  colorThemeTitle: {
    marginBottom: 0,
    color: theme.palette.common.white,
  },

  '.color-theme-body': {
    padding: '28px 25px',

    h3: {
      marginBottom: '20px',
      color: theme.palette.common.white,
    },
  },
});

class DrawerHeader extends React.Component {
  render() {
    return (
      <div className={this.props.classes.colorThemeHeader}>
        <h3 className={this.props.classes.colorThemeTitle}>{this.props.title}</h3>
        <IconButton
          className="icon-btn"
          onClick={() => {
            this.props.handleClose();
          }}>
          <CloseIcon style={{ color: this.props.theme.palette.common.white }} />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DrawerHeader);

export const TOTAL_INTERNAL_LINKS_BY_GROUP = {
  '@id': 'TOTAL_INTERNAL_LINKS_BY_GROUP',
  name: 'bundles.crawl.chart.total_internal_links_by_group.title',
  description: null,
  x: 0,
  y: 12,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['sum_internal_links'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        data.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        data[i] = arr.sum_internal_links;

        return true;
      });

      datasets = [
        {
          label: 'Total internal links',
          data: data,
          backgroundColor: '#4caf50',
          //backgroundColor: '#1976d2',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'

      // filters
      let filters = [
        {
          label: 'dimension.crawled_pages.category',
          dimension: 'category',
          operator: 'equals',
          values: [category],
        },
        {
          label: 'dimension.crawled_pages.checks_is_html',
          dimension: 'checks_is_html',
          operator: 'equals',
          values: [true],
        },
        {
          label: 'dimension.crawled_pages.checks_was_redirected',
          dimension: 'checks_was_redirected',
          operator: 'equals',
          values: [false],
        },
      ];

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'response_firstStatusCode', 'content_internal_links_count'],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          //suggestedMin: 0,
          //suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value;
              //return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'checks_has_title',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import qs from 'qs';
import { SERVER_URL } from 'bundles/logAnalyzer/constants/UploadApiConstants';
import { CRAWL_STATUS, CRAWL_ACTIVE_STATUS } from '../constants';

const REFRESH_INTERVAL = 700;

/**
 * This component displays the number of crawled pages for a specific crawl ID.
 */
const CrawlPagesCrawled = props => {
  const [counters, setCounters] = useState({});

  let interval = useRef(null);

  /**
   * Get crawl statistics
   */
  const refresh = () => {
    if (CRAWL_ACTIVE_STATUS.includes(props.status)) {
      const key = `crawler.crawl-${props.id}.execution`;
      const payload = {
        key: key,
      };

      axios
        .get(SERVER_URL + 'cache/get?' + qs.stringify(payload))
        .then(result => {
          setCounters(result.data);
        })
        .catch(function(error) {
          //
        });
    }
  };

  /**
   * Triggered on component mount.
   * Start the interval.
   */
  useEffect(() => {
    if (CRAWL_ACTIVE_STATUS.includes(props.status)) {
      refresh();

      interval.current = setInterval(refresh, REFRESH_INTERVAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Triggered on component unmount.
   * Stop the interval.
   */
  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  /**
   * Triggered when the crawl status changes.
   * Stop the interval if the crawl no longer has a "pending" or "running" status.
   */
  useEffect(() => {
    if (!CRAWL_ACTIVE_STATUS.includes(props.status)) {
      clearInterval(interval.current);
    }
  }, [props.status]);

  if (counters.hasOwnProperty('totalCrawled')) {
    return counters.totalCrawled;
  }

  if (null !== props.urlsCrawled) {
    return props.urlsCrawled;
  }

  return null;
};

CrawlPagesCrawled.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(CRAWL_STATUS).isRequired,
  urlsCrawled: PropTypes.number,
};

export default React.memo(CrawlPagesCrawled);

export const PAGES_WITH_MOST_INBOUND_LINKS = {
  '@id': 'PAGES_WITH_MOST_INBOUND_LINKS',
  name: 'bundles.crawl.chart.pages_with_most_inbound_links.title',
  description: null,
  x: 0,
  y: 32,
  w: 12,
  h: 10,
  query: {
    table: 'internal_links',
    dimensions: ['href'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['nbLinksTotal'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'desc',
    orderBy: 'nbLinksTotal',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'table',
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

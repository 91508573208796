import React, { useEffect, useState } from 'react';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';
import { useDispatch } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import { fetchError, showMessage } from '../../redux/actions';
import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';
import { Box } from '@material-ui/core';
import CmtImage from '../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { validateUser } from 'services/api/MyApiUtil';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
}));

const ValidateEmail = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'bgColor', match }) => {
  const classes = useStyles({ variant });
  const [status, setStatus] = useState('running');

  const dispatch = useDispatch();

  useEffect(() => {
    const token = match.params.token;

    validateUser(token)
      .then(result => {
        dispatch(showMessage('validate_account.notification.success', 'success'));
        setStatus('success');
      })
      .catch(function(error) {
        dispatch(fetchError(<IntlMessages id="validate_account.notification.error" />));
        setStatus('failed');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>

        <Box>
          {'running' === status && (
            <React.Fragment>
              <Typography component="div" variant="h1" className={classes.titleRoot}>
                <IntlMessages id="validate_account.text.title.running" />
              </Typography>

              <Box mb={6}>
                <Typography variant="body1" component="span">
                  <IntlMessages id="validate_account.text.body.running" />
                </Typography>
              </Box>

              <Box align={'center'}>
                <CircularProgress />
              </Box>
            </React.Fragment>
          )}

          {'failed' === status && (
            <React.Fragment>
              <Typography component="div" variant="h1" className={classes.titleRoot}>
                <IntlMessages id="validate_account.text.title.failed" />
              </Typography>

              <Box mb={6}>
                <Typography variant="body1" component="span">
                  <IntlMessages id="validate_account.text.body.failed" />
                </Typography>
              </Box>

              <Box>
                <Link to={'/signin'}>
                  <Button variant="contained" color="primary">
                    <IntlMessages id="validate_account.action.sigin" />
                  </Button>
                </Link>
              </Box>
            </React.Fragment>
          )}

          {'success' === status && (
            <React.Fragment>
              <Typography component="div" variant="h1" className={classes.titleRoot}>
                <IntlMessages id="validate_account.text.title.success" />
              </Typography>

              <Box mb={6}>
                <Typography variant="body1" component="span">
                  <IntlMessages id="validate_account.text.body.success" />
                </Typography>
              </Box>

              <Box>
                <Link to={'/signin'}>
                  <Button variant="contained" color="primary">
                    <IntlMessages id="validate_account.action.sigin" />
                  </Button>
                </Link>
              </Box>
            </React.Fragment>
          )}
        </Box>

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ValidateEmail;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { TableRow, TableCell } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import InvoiceStatusBadge from './InvoiceStatusBadge';

/**
 * This component displays a row of the invoice list table.
 */
const InvoiceListTableRow = props => {
  const { locale } = useContext(AppContext);

  moment.locale(locale.locale);

  return (
    <TableRow key={props.invoice.id}>
      <TableCell align={'left'}>{props.invoice.number}</TableCell>
      <TableCell>{moment.unix(props.invoice.created).format('DD/MM/YYYY')}</TableCell>
      <TableCell>{props.invoice.amount_due / 100} &euro;</TableCell>
      <TableCell>
        <InvoiceStatusBadge invoice={props.invoice} />
      </TableCell>
      <TableCell align={'right'}>
        <a rel="noopener noreferrer" href={props.invoice.invoice_pdf} target="_blank">
          <IntlMessages id="bundles.Invoice.action.see" />
        </a>
      </TableCell>
    </TableRow>
  );
};

InvoiceListTableRow.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default React.memo(InvoiceListTableRow);

/**
 * @param {Object} values
 */
export function validateRegisterForm(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  }

  if (values.email && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  if (!values.password) {
    errors.password = 'Required';
  }

  if (values.password) {
    if (values.password.length < 8) {
      errors.password = 'This field is too short (minimum is 8 characters)';
    } else if (!/(?=.*[A-Z])/.test(values.password)) {
      errors.password = 'must contain at least one capital letter';
    } else if (!/(?=.*[a-z])/.test(values.password)) {
      errors.password = 'must contain at least one lowercase letter';
    } else if (!/(?=.*[0-9])/.test(values.password)) {
      errors.password = 'must contain at least one number';
    } else if (!/(?=.*[*.!@#$%^&(){}[\]:";'<>,.?/~`_+\-=|\\])/.test(values.password)) {
      errors.password = 'must contain at least special character';
    }
  }

  if (!values.country) {
    errors.country = 'Required';
  }

  return errors;
}

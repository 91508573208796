import React, { useContext, useEffect } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import LogAnalyzerConfigure from '../../components/LogAnalyzerConfigure';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

const LogsSendIndex = props => {
  const { setBreadcrumb } = useContext(AppContext);
  const { project } = useSelector(({ projectSelector }) => projectSelector);

  useEffect(() => {
    setBreadcrumb([
      {
        text: <IntlMessages id="sidebar.logAnalyzer" />,
      },
      {
        text: <IntlMessages id="pages.logAnalyzer.sendLogs" />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Box mb={6}>
        <h1>
          <IntlMessages id="pages.logAnalyzer.sendLogs" />
        </h1>
      </Box>

      <Box mb={6}>
        <Alert severity="info" style={{ marginBottom: 0 }}>
          <IntlMessages id="help.logAnalyzer.yourDataAreSecure" />
        </Alert>
      </Box>

      <LogAnalyzerConfigure projectId={project.id} />
    </div>
  );
};

export default LogsSendIndex;

import React from 'react';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import { GRID_SPACING } from '../../../@jumbo/constants/ThemeOptions';

import EventList from '../components/EventList';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const NotificationSelectorForm = props => {
  return (
    <Formik enableReinitialize={true} initialValues={props.data} onSubmit={values => props.onSubmit(values)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div>
          <EventList values={values} handleChange={handleChange} setFieldValue={setFieldValue} />

          <Box pt={GRID_SPACING}>
            <Grid container spacing={GRID_SPACING}>
              <Grid item xs={12}>
                <BackButton variant="outlined" color="primary">
                  <IntlMessages id="bundles.notificationSelector.action.cancel" />
                </BackButton>

                <Box ml={6} clone>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      for (const [eventName, obj] of Object.entries(values.notificationsConfig.events)) {
                        if (!obj.hasOwnProperty('channels')) {
                          setFieldValue(`notificationsConfig[events][${eventName}][channels][0]`, 'email', false);
                        }
                      }

                      handleSubmit();
                    }}>
                    <IntlMessages id="bundles.notificationSelector.action.save" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </Formik>
  );
};

export default NotificationSelectorForm;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, fade, Divider } from '@material-ui/core';
import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import RegisterForm from 'bundles/auth/form/RegisterForm';
import GoogleRegisterForm from 'bundles/auth/form/GoogleRegisterForm';

import { GoogleLogin } from 'react-google-login';
import { fetchError, fetchSuccess } from '../../../../redux/actions';

import axios from '../../../../services/auth/jwt/config';
import { getGoogleAuth } from 'services/api/MyApiUtil';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [name, setName] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('demo@example.com');
  const [socialAuthSuccess, setSocialAuthSuccess] = useState(false);
  const [socialId, setSocialId] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const dispatch = useDispatch();

  const classes = useStyles({ variant });

  const onGoogleAuthSuccess = async res => {
    // try to authenticate the user
    getGoogleAuth(res.tokenId)
      .then(result => {
        localStorage.setItem('token', result.data.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;

        dispatch(fetchSuccess());
        dispatch(AuhMethods[method].getAuthUser(true, result.data.token));
      })
      .catch(function(error) {
        if (error.response && 403 === error.response.status) {
          setSocialAuthSuccess(false);
          dispatch(fetchError('signup.notification.create.error.account_not_enabled'));
        } else {
          setName(res.profileObj.name);
          setFirstname(res.profileObj.givenName);
          setLastname(res.profileObj.familyName);
          setEmail(res.profileObj.email);
          setAvatarUrl(res.profileObj.imageUrl);
          setSocialId(res.googleId);
          setSocialAuthSuccess(true);
        }
      });
  };

  const onGoogleAuthFailure = res => {
    setSocialAuthSuccess(false);

    const message = (
      <IntlMessages
        id="signin.error.social_auth_failed"
        values={{
          message: res.error,
        }}
      />
    );

    dispatch(fetchError(message));
  };

  const onSubmit = values => {
    let firstname = values.firstname;
    let lastname = values.lastname;
    let email = values.email;
    let password = values.password;
    let country = values.country ? values.country : null;

    dispatch(
      AuhMethods[method].onRegister({
        avatarUrl,
        firstname,
        lastname,
        email,
        password,
        socialId,
        country,
      }),
    );
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/sign-up-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>

        {true === socialAuthSuccess ? (
          <GoogleRegisterForm
            data={{
              avatarUrl: avatarUrl,
              name: name,
              firstname: firstname,
              lastname: lastname,
              email: email,
            }}
            onSubmit={onSubmit}
            onCancel={() => setSocialAuthSuccess(false)}
          />
        ) : (
          <RegisterForm
            data={{
              firstname: '',
              lastname: '',
              email: '',
              password: '',
              country: '',
            }}
            onSubmit={onSubmit}
          />
        )}

        {false === socialAuthSuccess && (
          <React.Fragment>
            <Box mt={6} mb={6}>
              <Divider />
            </Box>

            <Box>
              <GoogleLogin
                clientId="434393344530-i09gp9f9tc93pn75svmlcjv2v9n39s1h.apps.googleusercontent.com"
                buttonText={<IntlMessages id="appModule.regsiter" />}
                onSuccess={onGoogleAuthSuccess}
                onFailure={onGoogleAuthFailure}
                cookiePolicy={'single_host_origin'}
              />

              {dispatch(AuhMethods[method].getSocialMediaIcons())}
            </Box>
          </React.Fragment>
        )}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;

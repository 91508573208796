import { LIST_PROJECTS, GET_PROJECT } from '../constants';

const INIT_STATE = {
  projectsList: {
    'hydra:member': [],
  },
  project: {
    name: '',
    domain: '',
    region: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PROJECTS: {
      return {
        ...state,
        projectsList: action.payload,
      };
    }
    case GET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    default:
      return state;
  }
};

import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const H1_PRESENCE_BY_GROUP = {
  '@id': 'H1_PRESENCE_BY_GROUP',
  name: 'bundles.crawl.chart.h1_presence_by_group.title',
  description: null,
  x: 0,
  y: 16,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'checks_has_h1'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let dataHasTag = [];
      let dataNotHasTag = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        dataHasTag.push(0);
        dataNotHasTag.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        if (true === arr['checks_has_h1']) {
          dataHasTag[i] = arr.count;
        } else {
          dataNotHasTag[i] = arr.count;
        }

        return true;
      });

      labels.map((label, i) => {
        const total = dataHasTag[i] + dataNotHasTag[i];

        dataHasTag[i] = (dataHasTag[i] / total) * 100;
        dataNotHasTag[i] = (dataNotHasTag[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: 'Exactly 1',
          data: dataHasTag,
          backgroundColor: '#4caf50',
        },
        {
          label: 'No h1',
          data: dataNotHasTag,
          backgroundColor: '#dc004e',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let checks_has_h1 = chartData.datasets[element[0].datasetIndex].label; // 'Exactly 1'

      const labels = ['Exactly 1', 'No h1'];

      const codes = [true, false];

      const i = labels.indexOf(checks_has_h1);
      checks_has_h1 = codes[i];

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.crawled_pages.checks_has_h1',
        dimension: 'checks_has_h1',
        operator: 'equals',
        values: [checks_has_h1],
      });

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'content_h1', 'content_h1_chars_count', 'response_firstStatusCode'],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'checks_has_title',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

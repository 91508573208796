import { UPDATE_CUSTOMER } from '../constants';

const INIT_STATE = {
  customer: {
    name: null,
    phone: null,
    address: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER: {
      if (null === action.payload) {
        return INIT_STATE;
      }

      return {
        ...state,
        customer: action.payload,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IntlMessages from '@jumbo/utils/IntlMessages';

class BillingTabs extends React.Component {
  render() {
    return (
      <Tabs value={this.props.tabActive} indicatorColor="primary" textColor="primary" aria-label="billing tabs">
        <Tab
          component={Link}
          label={<IntlMessages id="sidebar.shipping" />}
          to={'/billing/shipping'}
          value={'/billing/shipping'}
        />
        <Tab
          component={Link}
          label={<IntlMessages id="sidebar.paymentMethods" />}
          to={'/billing/paymentMethods'}
          value={'/billing/paymentMethods'}
        />
        <Tab
          component={Link}
          label={<IntlMessages id="sidebar.invoices" />}
          to={'/billing/invoices'}
          value={'/billing/invoices'}
        />
        <Tab component={Link} label={<IntlMessages id="sidebar.usage" />} to={'/billing/usage'} value={'/billing/usage'} />
        <Tab
          component={Link}
          label={<IntlMessages id="sidebar.subscriptions" />}
          to={'/billing/subscriptions'}
          value={'/billing/subscriptions'}
        />
      </Tabs>
    );
  }
}

export default withRouter(BillingTabs);

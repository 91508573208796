import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Columns selector custom hook.
 *
 * @param {array} schema
 */
export function useColumnsSelector(schema) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  // controls if the drawer containing the form is displayed.
  const [open, setOpen] = useState(false);

  // iterates the schema and builds an array containing the columns to display by default (key `visible`).
  let defaultValues = [];

  for (let i = 0; i < schema.length; i++) {
    let obj = schema[i];

    if (obj.hasOwnProperty('array') && obj.hasOwnProperty('fields') && true === obj.array) {
      for (let j = 0; j < obj.fields.length; j++) {
        if (obj.fields[j].visible) {
          defaultValues.push(obj.fields[j].dimension);
        }
      }
    } else {
      if (obj.visible) {
        defaultValues.push(obj.dimension);
      }
    }
  }

  // controls which columns to display (eg. ['url', 'statusCode']).
  const [columnsSelected, setColumnsSelected] = useState(
    urlParams.has('columns') ? JSON.parse(urlParams.get('columns')) : defaultValues,
  );

  /**
   * Triggered when the opening button is clicked.
   */
  const handleOpen = useCallback(() => {
    // open the drawer
    setOpen(true);
  }, []);

  /**
   * Triggered when the cancel button is clicked.
   */
  const handleCancel = useCallback(() => {
    // close the drawer
    setOpen(false);
  }, []);

  /**
   * Triggered when form is submitted.
   */
  const handleSubmit = useCallback(values => {
    urlParams.set('columns', JSON.stringify(values));

    history.replace({
      pathname: location.pathname,
      search: '?' + urlParams.toString(),
    });

    // close the drawer
    setOpen(false);

    // set the new columns
    if (JSON.stringify(values) !== JSON.stringify(columnsSelected)) {
      setColumnsSelected(values);
    }
    // eslint-disable-next-line
  }, []);

  return [open, columnsSelected, handleOpen, handleCancel, handleSubmit];
}

import React from 'react';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { querySql } from 'services/api/MyApiUtil';

/**
 * props required:
 *
 * - title
 * - projectId
 * - dimension
 * - operator
 * - value
 * - editedFilter
 * - handleSelectItem
 */
class AutocompleteField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      operator: 'equals',
      value: null,
      data: [],
      totalRequests: null,
      loadData: true,
    };

    if (this.props.editedFilter !== null) {
      this.state = {
        operator: this.props.editedFilter.operator,
        value: this.props.editedFilter.values[0],
        data: [],
        loadData: true,
      };
    }
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    /**
         data:
         hydra:member: Array(4) [
         0: {code: "200", count: 199}
         1: {code: "301", count: 4}
         2: {code: "302", count: 18}
         3: {code: "400", count: 1}
         ]
         */
    let sort = {};
    sort[this.props.dimension] = 'asc';

    const timeDimensions = {
      dimension: 'datestr',
      granularity: null,
      dateRange: [this.props.startDate, this.props.endDate],
    };

    const data = await querySql(
      this.props.projectId,
      'logs',
      [this.props.dimension],
      [],
      [],
      timeDimensions,
      ['count'],
      [],
      [],
      sort,
      '',
      '',
      0,
    );

    let totalRequests = 0;
    data['hydra:member'].map(obj => {
      totalRequests += obj.count;

      return obj;
    });

    this.setState({
      data: data['hydra:member'],
      totalRequests: totalRequests,
      loadData: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}

        <h3 className={'mb-2'}>{this.props.title}</h3>

        {true === this.state.loadData && <CircularProgress />}

        <List component="nav" aria-label="main mailbox folders">
          {this.state.data.map(obj => (
            <ListItem
              button
              onClick={() => this.props.handleSelectItem('equals', obj.code)}
              selected={this.props.operator === 'equals' && this.props.value === obj.code}>
              <ListItemText primary={obj.code} />
              <LinearProgress variant="determinate" value={(obj.count * 100) / this.state.totalRequests} />
              <Chip
                color={`${this.props.operator === 'equals' && this.props.value === obj.code && 'secondary'}`}
                className={'mr-1'}
                label={obj.count + ' requests'}
                onClick={() => this.props.handleSelectItem('equals', obj.code)}
              />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }
}

export default AutocompleteField;

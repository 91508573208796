import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const PAGE_SIZE = {
  '@id': 'page_size',
  id: 'page_size',
  name: 'sidebar.crawl.dashboard.pagesWeight',
  description: null,
  project: '/api/projects/131',
  questions: [
    {
      '@id': 'decoded_weight_without_assets',
      name: 'bundles.crawl.chart.decoded_weight_without_assets.title',
      description: null,
      x: 0,
      y: 0,
      w: 6,
      h: 8,
      query: {
        table: 'crawled_pages',
        dimensions: ['documentDecodedSizeString'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [
          {
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
        ],
        hiddenFilters: [],
        aggregationFilters: [],
        order: null,
        orderBy: null,
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getThemeColor',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
        containerOptions: {
          style: {
            height: 280,
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'encoded_weight_without_assets',
      name: 'bundles.crawl.chart.encoded_weight_without_assets.title',
      description: null,
      x: 6,
      y: 0,
      w: 6,
      h: 8,
      query: {
        table: 'crawled_pages',
        dimensions: ['documentEncodedSizeString'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [
          {
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
        ],
        hiddenFilters: [],
        aggregationFilters: [],
        order: null,
        orderBy: null,
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getThemeColor',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
        containerOptions: {
          style: {
            height: 280,
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'decoded_weight_with_assets',
      name: 'bundles.crawl.chart.decoded_weight_with_assets.title',
      description: null,
      x: 0,
      y: 8,
      w: 6,
      h: 8,
      query: {
        table: 'crawled_pages',
        dimensions: ['totalDecodedSizeString'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [
          {
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
        ],
        hiddenFilters: [],
        aggregationFilters: [],
        order: null,
        orderBy: null,
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getThemeColor',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
        containerOptions: {
          style: {
            height: 280,
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'encoded_weight_with_assets',
      name: 'bundles.crawl.chart.encoded_weight_with_assets.title',
      description: null,
      x: 6,
      y: 8,
      w: 6,
      h: 8,
      query: {
        table: 'crawled_pages',
        dimensions: ['totalEncodedSizeString'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [
          {
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
        ],
        hiddenFilters: [],
        aggregationFilters: [],
        order: null,
        orderBy: null,
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getThemeColor',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
        containerOptions: {
          style: {
            height: 280,
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
  ],
};

export const LOGS_SCHEMA = {
  table: 'logs',
  fields: [
    {
      dimension: 'datetime',
      label: 'dimension.logs.datetime',
      type: 'datetime',
      visible: true,
      filterable: false,
    },
    {
      dimension: 'bot',
      label: 'dimension.logs.bot',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'url',
      label: 'dimension.logs.url',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
    {
      dimension: 'code',
      label: 'dimension.logs.code',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'CodeFilter',
    },
    {
      dimension: 'category',
      label: 'dimension.logs.category',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'domain',
      label: 'dimension.logs.domain',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'domainType',
      label: 'dimension.logs.domainType',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'ip',
      label: 'dimension.logs.ip',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
    {
      dimension: 'scheme',
      label: 'dimension.logs.scheme',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'userAgent',
      label: 'dimension.logs.userAgent',
      type: 'string',
      visible: false,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
  ],
  order: 'desc',
  orderBy: 'datetime',
  hasTimeDimension: true,
  timeDimensionField: 'datestr',
};

import React from 'react';
import { withRouter } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Box, Select, ListSubheader, MenuItem } from '@material-ui/core';

import { Formik } from 'formik';
import { REGION_LIST } from '../constants';
import { validateProject } from '../validation/Project';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

const ProjectForm = props => {
  const location = useLocation();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      validate={values => validateProject(values)}
      onSubmit={values => props.onSubmit(authUser, values, location, history)}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.project.entity.project.name.long" />
              </InputLabel>
              <TextField
                id="name"
                name="name"
                placeholder={'My project'}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <Typography className={'form-help'} variant="body2" color={'textSecondary'}>
                <IntlMessages id="bundles.project.entity.project.name.help" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.project.entity.project.domain.long" />
              </InputLabel>
              <TextField
                disabled={props.disableDomainField}
                id="domain"
                name="domain"
                placeholder={'example.com'}
                value={values.domain}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant="outlined"
                error={touched.domain && Boolean(errors.domain)}
                helperText={touched.domain && errors.domain}
              />
              <Typography className={'form-help'} variant="body2" color={'textSecondary'}>
                <IntlMessages id="bundles.project.entity.project.domain.help" />
                <br />
                <IntlMessages id="bundles.project.global.informationDefinitive" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.project.entity.project.region.long" />
              </InputLabel>
              <Select
                disabled={props.disableRegionField}
                id="region"
                name="region"
                label={<IntlMessages id="bundles.project.entity.project.region.long" />}
                value={values.region}
                onChange={handleChange}
                onBlur={handleBlur}
                displayEmpty
                fullWidth
                variant="outlined"
                error={touched.region && Boolean(errors.region)}>
                <MenuItem value="" disabled>
                  <IntlMessages id="bundles.project.entity.project.region.short" />
                </MenuItem>

                <ListSubheader>{REGION_LIST[0].header}</ListSubheader>
                {REGION_LIST[0].choices.map(obj => {
                  return (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  );
                })}

                <ListSubheader>{REGION_LIST[1].header}</ListSubheader>
                {REGION_LIST[1].choices.map(obj => {
                  return (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  );
                })}

                <ListSubheader>{REGION_LIST[2].header}</ListSubheader>
                {REGION_LIST[2].choices.map(obj => {
                  return (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={touched.region && Boolean(errors.region)}>
                {touched.region && errors.region}
              </FormHelperText>

              <Typography className={'form-help'} variant="body2" color={'textSecondary'}>
                <IntlMessages id="bundles.project.entity.project.region.help" />
                <br />
                <IntlMessages id="bundles.project.global.informationDefinitive" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BackButton variant="outlined" color="primary">
                <IntlMessages id="bundles.project.action.cancel" />
              </BackButton>

              <Box ml={6} clone>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  <IntlMessages id="bundles.project.action.save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ProjectForm;

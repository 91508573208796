import { PAGES_WITH_CODE_2XX } from './status_codes/PAGES_WITH_CODE_2XX';
import { PAGES_WITH_CODE_3XX } from './status_codes/PAGES_WITH_CODE_3XX';
import { PAGES_WITH_CODE_4XX } from './status_codes/PAGES_WITH_CODE_4XX';
import { PAGES_WITH_CODE_5XX } from './status_codes/PAGES_WITH_CODE_5XX';
import { CODES_BREAKDOWN } from './status_codes/CODES_BREAKDOWN';
import { CODES_INSIGHT } from './status_codes/CODES_INSIGHT';
import { CODES_BY_PAGE_GROUP } from './status_codes/CODES_BY_PAGE_GROUP';

export const STATUS_CODES = {
  '@id': 'status_codes',
  id: 'status_codes',
  name: 'sidebar.crawl.dashboard.statusCodes',
  description: null,
  project: '/api/projects/131',
  questions: [
    PAGES_WITH_CODE_2XX,
    PAGES_WITH_CODE_3XX,
    PAGES_WITH_CODE_4XX,
    PAGES_WITH_CODE_5XX,
    CODES_BREAKDOWN,
    CODES_INSIGHT,
    CODES_BY_PAGE_GROUP,
  ],
};

import React, { useEffect, useContext } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Grid, Paper, Typography } from '@material-ui/core';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import InvoiceList from '../components/InvoiceList';
import BillingTabs from '../../components/BillingTabs';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesList } from '../actions';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';

const InvoiceIndex = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { invoicesList } = useSelector(({ invoice }) => invoice);
  const { setBreadcrumb } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumb([
      { text: <IntlMessages id="sidebar.billing" /> },
      { text: <IntlMessages id="bundles.Invoice.routes.index.title" /> },
    ]);

    dispatch(getInvoicesList(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={3}>
          <Typography variant="h1" gutterBottom>
            <IntlMessages id="bundles.Invoice.routes.index.title" />
          </Typography>

          <p>
            <IntlMessages id="bundles.Invoice.routes.index.subtitle" />
          </p>
        </Grid>

        <Grid item md={9}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item md={12}>
              <BillingTabs tabActive={'/billing/invoices'} />
            </Grid>

            <Grid item md={12}>
              {invoicesList.length === 0 ? (
                <ListEmptyResult
                  title={<IntlMessages id="bundles.Invoice.emptyPrompt.title" />}
                  content={<IntlMessages id="bundles.Invoice.emptyPrompt.text" />}
                />
              ) : (
                <Paper>
                  <InvoiceList />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvoiceIndex;

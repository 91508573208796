export const TOTAL_EXTERNAL_HOSTS = {
  '@id': 'TOTAL_EXTERNAL_HOSTS',
  name: 'bundles.crawl.chart.total_external_hosts.title',
  description: null,
  x: 0,
  y: 0,
  w: 4,
  h: 4,
  query: {
    table: 'crawled_pages',
    dimensions: ['content.external_links.host'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count_over'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'crawled_pages',
        columns: ['content.external_links.host'],
        filters: [
          {
            label: 'dimension.crawled_pages.checks_is_html',
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.checks_was_redirected',
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
        ],
        groupBy: ['content.external_links.host'],
        measures: ['count'],
        orderBy: 'count',
        order: 'desc',
        ungrouped: 0,
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { querySql } from 'services/api/MyApiUtil';
import { SCHEMAS } from 'constants/dataExplorer/schema';

import AddButton from '../AddButton';
import BaseFilter from './BaseFilter';
import ValuePrettier from 'bundles/logAnalyzer/LogsExplorer/components/ValuePrettier';

const parseDimension = str => {
  return str.includes('.') ? str.split('.').pop() : str;
};

const isItemSelected = (oldOperator, oldValue, newValue) => {
  let operator = 'equals';

  if (null === newValue) {
    operator = 'empty';
  }

  if (oldOperator === operator && oldValue === newValue) {
    return true;
  } else {
    return false;
  }
};

class AutomaticChoicesFilter extends BaseFilter {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      totalRequests: null,
      loadData: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    console.log('toto', this.props);
    let sort = {};
    sort[this.props.dimension] = 'asc';

    let timeDimensions = {
      //dateRange: [null, null],
    };

    if (SCHEMAS[this.props.schemaName].hasTimeDimension) {
      timeDimensions = {
        dimension: SCHEMAS[this.props.schemaName].timeDimensionField,
        granularity: null,
        dateRange: [this.props.startDate, this.props.endDate],
      };
    }

    const data = await querySql(
      this.props.projectId,
      SCHEMAS[this.props.schemaName].table,
      [this.props.dimension],
      [],
      [],
      timeDimensions,
      ['count'],
      [...this.props.filters, ...this.props.hiddenFilters],
      [],
      sort,
      '',
      '',
      0,
    );

    let totalRequests = 0;
    data['hydra:member'].map(obj => {
      totalRequests += obj.count;
      return obj;
    });

    this.setState({
      data: data['hydra:member'],
      totalRequests: totalRequests,
      loadData: false,
    });
  }

  handleClickItem(value) {
    let operator = 'equals';

    if (null === value) {
      operator = 'empty';
    }

    this.handleSelectItem(operator, value);
  }

  render() {
    return (
      <React.Fragment>
        <Box p={4} pb={0}>
          <h3>{this.props.title}</h3>
        </Box>

        {true === this.state.loadData && <CircularProgress />}

        <List component="nav" aria-label="main mailbox folders">
          {this.state.data.map((obj, i) => (
            <ListItem
              key={i}
              button
              onClick={() =>
                this.handleClickItem(
                  obj.hasOwnProperty(this.props.dimension)
                    ? obj[this.props.dimension]
                    : obj[parseDimension(this.props.dimension)],
                )
              }
              selected={isItemSelected(
                this.state.operator,
                this.state.value,
                obj.hasOwnProperty(this.props.dimension)
                  ? obj[this.props.dimension]
                  : obj[parseDimension(this.props.dimension)],
              )}>
              <ListItemText disableTypography>
                <ValuePrettier
                  value={
                    obj.hasOwnProperty(this.props.dimension)
                      ? obj[this.props.dimension]
                      : obj[parseDimension(this.props.dimension)]
                  }
                />
              </ListItemText>
              <LinearProgress variant="determinate" value={(obj.count * 100) / this.state.totalRequests} />
              <Chip
                color={
                  isItemSelected(
                    this.state.operator,
                    this.state.value,
                    obj.hasOwnProperty(this.props.dimension)
                      ? obj[this.props.dimension]
                      : obj[parseDimension(this.props.dimension)],
                  )
                    ? 'secondary'
                    : 'default'
                }
                className={'mr-1'}
                label={obj.count + ' requests'}
                onClick={() =>
                  this.handleClickItem(
                    obj.hasOwnProperty(this.props.dimension)
                      ? obj[this.props.dimension]
                      : obj[parseDimension(this.props.dimension)],
                  )
                }
              />
            </ListItem>
          ))}
        </List>

        <Box p={4} pt={0}>
          <AddButton
            schema={this.props.schema}
            operator={this.state.operator}
            value={this.state.value}
            editedFilterIndex={this.props.editedFilter ? this.props.editedFilter.index : null}
            handleSaveFilter={this.props.handleSaveFilter}
          />
        </Box>
      </React.Fragment>
    );
  }
}

AutomaticChoicesFilter.propTypes = {
  projectId: PropTypes.number.isRequired,
  dimension: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  schemaName: PropTypes.string.isRequired,
  editedFilter: PropTypes.object,
  filters: PropTypes.array.isRequired,
  hiddenFilters: PropTypes.array.isRequired,
  handleSaveFilter: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default AutomaticChoicesFilter;

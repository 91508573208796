import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const LOAD_TIME_BY_PAGE_GROUP_BREAKDOWN = {
  '@id': 'LOAD_TIME_BY_PAGE_GROUP_BREAKDOWN',
  name: 'bundles.crawl.chart.load_time_by_page_group_breakdown.title',
  description: null,
  x: 0,
  y: 12,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'totalLoadTimeString'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let dataTooSlow = [];
      let dataSlow = [];
      let dataMedium = [];
      let dataPerfect = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        dataTooSlow.push(0);
        dataSlow.push(0);
        dataMedium.push(0);
        dataPerfect.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        if (arr['totalLoadTimeString'].includes('Too slow')) {
          dataTooSlow[i] = arr.count;
        } else if (arr['totalLoadTimeString'].includes('Slow')) {
          dataSlow[i] = arr.count;
        } else if (arr['totalLoadTimeString'].includes('Medium')) {
          dataMedium[i] = arr.count;
        } else if (arr['totalLoadTimeString'].includes('Perfect')) {
          dataPerfect[i] = arr.count;
        }

        return true;
      });

      labels.map((label, i) => {
        const total = dataTooSlow[i] + dataSlow[i] + dataMedium[i] + dataPerfect[i];

        dataTooSlow[i] = (dataTooSlow[i] / total) * 100;
        dataSlow[i] = (dataSlow[i] / total) * 100;
        dataMedium[i] = (dataMedium[i] / total) * 100;
        dataPerfect[i] = (dataPerfect[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: 'Too slow (> 2s)',
          data: dataTooSlow,
          backgroundColor: '#dc004e',
        },
        {
          label: 'Slow (1s - 2s)',
          data: dataSlow,
          backgroundColor: '#f44336',
        },
        {
          label: 'Medium (500ms - 1s)',
          data: dataMedium,
          backgroundColor: '#ff9800',
        },
        {
          label: 'Perfect (< 500ms)',
          data: dataPerfect,
          backgroundColor: '#4caf50',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let totalLoadTimeString = chartData.datasets[element[0].datasetIndex].label; // 'Slow (1s - 2s)'

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.crawled_pages.totalLoadTimeString',
        dimension: 'totalLoadTimeString',
        operator: 'equals',
        values: [totalLoadTimeString],
      });

      return {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'performance_navigation_totalLoadTime',
          'totalLoadTimeString',
          'response_firstStatusCode',
        ],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'totalLoadTimeString',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

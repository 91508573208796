import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { REDIRECT_URI_AFTER_LOGIN } from '@jumbo/constants/AppConstants';
import SamplePage from './Pages/SamplePage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';

import ProjectSelectorContent from 'bundles/projectSelector/components/ProjectSelectorContent';
import CreateProjectButton from '../bundles/project/components/CreateProjectButton';
import SubscriptionRequired from '../components/SubscriptionRequired';

// resetting
import ForgotPasswordPage from 'bundles/resetting/routes/ForgotPassword';
import ChangePasswordPage from 'bundles/resetting/routes/ChangePassword';

// profile
import ProfileViewPage from '../bundles/profile/routes/view';
import ProfileEditPage from '../bundles/profile/routes/edit';
import ProfileChangePasswordPage from '../bundles/profile/routes/changePassword';

// notificationSelector
import NotificationSelectorIndex from 'bundles/notificationSelector/routes';

// billing
// invoice
import InvoiceIndex from 'bundles/billing/invoice/routes/index';
// usage
import UsageIndex from 'bundles/billing/usage/routes/index';
// paymentMethods
import PaymentModeIndex from 'bundles/billing/paymentMethod/routes/index';
// shipping
import ShippingIndex from 'bundles/billing/shipping/routes/index';
// changePlan
import ProductsPage from 'bundles/billing/subscription/routes/products';
// project
import ProjectIndex from 'bundles/project/routes/index';
import ProjectCreatePage from 'bundles/project/routes/create';
import ProjectEditPage from 'bundles/project/routes/edit';
// checkout
import SubscriptionIndex from 'bundles/billing/checkout/routes/index';
import CheckoutPage from 'bundles/billing/checkout/routes/checkout';
import CheckoutSuccessPage from 'bundles/billing/checkout/routes/checkoutSuccess';
import CheckoutFailedPage from 'bundles/billing/checkout/routes/checkoutFailed';
// logAnalyzer
import LogsSendIndex from 'bundles/logAnalyzer/SendLogs/routes/index';
import LogsExplorerIndex from 'bundles/logAnalyzer/LogsExplorer/routes/index';
// crawl
import CrawlIndex from 'bundles/crawl/routes/index';
import CrawlProfileCreatePage from 'bundles/crawlProfile/routes/create';
// crawlSchedule
import CrawlScheduleListIndex from 'bundles/crawlSchedule/routes/index';
import CrawlScheduleCreatePage from 'bundles/crawlSchedule/routes/create';
import CrawlScheduleEditPage from 'bundles/crawlSchedule/routes/edit';
// segmentation
import SegmentationIndex from 'bundles/segmentation/routes/index';
// dashboard
import DashboardIndex from 'bundles/dashboard/routes/index';
import DashboardView from 'bundles/dashboard/routes/view';
// urlViewer
import UrlViewer from 'bundles/urlViewer/routes/view';

import CheckEmail from './Auth/CheckEmail';
import ValidateEmail from './Auth/ValidateEmail';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { GRID_SPACING } from '../@jumbo/constants/ThemeOptions';
import IntlMessages from '../@jumbo/utils/IntlMessages';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser, isActiveSubscription } = useSelector(({ auth }) => auth);
  const { open, project } = useSelector(({ projectSelector }) => projectSelector);
  const [projectRequired, setProjectRequired] = useState(false);
  const [subscriptionRequired, setSubscriptionRequired] = useState(false);

  /**
   * Triggered on component mount.
   */
  useEffect(() => {
    setProjectRequired(false);

    if (rest.hasOwnProperty('projectRequired')) {
      if (true === rest.projectRequired) {
        if (project && project.id) {
          //
        } else {
          setProjectRequired(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.location.pathname, project, open]);

  useEffect(() => {
    setSubscriptionRequired(false);

    if (rest.hasOwnProperty('subscriptionRequired')) {
      if (true === rest.subscriptionRequired) {
        if (!isActiveSubscription) {
          setSubscriptionRequired(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.location.pathname, project, open]);

  if (true === subscriptionRequired) {
    return (
      <div className="app-wrapper">
        <SubscriptionRequired />
      </div>
    );
  }

  if (true === projectRequired) {
    return (
      <div className="app-wrapper">
        <Grid container spacing={GRID_SPACING}>
          <Grid item md={6}>
            <Typography variant="h1">
              <IntlMessages id="layout.projectRequired.title" />
            </Typography>
          </Grid>
          <Grid item md={6} align={'right'}>
            <CreateProjectButton variant={'outlined'} />
          </Grid>
          <Grid item md={12}>
            <Box color="text.secondary" mb={6}>
              <IntlMessages id="layout.projectRequired.body" />
            </Box>

            <Paper>
              <Box p={6}>
                <ProjectSelectorContent />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        authUser && authUser.id ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = store => {
  const { authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    history.push(REDIRECT_URI_AFTER_LOGIN);
    return null;
  } else if (authUser && authUser.id && location.pathname === '/signin') {
    history.push(REDIRECT_URI_AFTER_LOGIN);
    return null;
  } else if (authUser && location.pathname === '/signup') {
    history.push(REDIRECT_URI_AFTER_LOGIN);
    return null;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/sample-page" component={SamplePage} />
        <RestrictedRoute path="/billing/invoices" component={InvoiceIndex} />
        <RestrictedRoute path="/billing/usage" component={UsageIndex} />
        <RestrictedRoute path="/billing/paymentMethods" component={PaymentModeIndex} />
        <RestrictedRoute path="/billing/shipping" component={ShippingIndex} />
        <RestrictedRoute path="/billing/subscriptions" component={ProductsPage} />
        <RestrictedRoute path="/projects/edit/:id" component={ProjectEditPage} />
        <RestrictedRoute path="/projects/create" component={ProjectCreatePage} />
        <RestrictedRoute path="/projects" component={ProjectIndex} />
        <RestrictedRoute path="/settings/notificationSelector" component={NotificationSelectorIndex} />
        <RestrictedRoute path={`/products`} component={SubscriptionIndex} />
        <RestrictedRoute path={`/checkout/success`} component={CheckoutSuccessPage} />
        <RestrictedRoute path={`/checkout/failed`} component={CheckoutFailedPage} />
        <RestrictedRoute path={`/checkout`} component={CheckoutPage} />
        <RestrictedRoute
          path={`/log_analyzer/configure`}
          component={LogsSendIndex}
          projectRequired={true}
          subscriptionRequired={true}
        />
        <RestrictedRoute
          path={`/log_analyzer/explorer`}
          component={LogsExplorerIndex}
          projectRequired={true}
          subscriptionRequired={true}
        />

        <RestrictedRoute
          path="/crawl_profiles/edit/:id"
          component={CrawlProfileCreatePage}
          projectRequired={true}
          subscriptionRequired={true}
        />
        <RestrictedRoute
          path="/crawl_profiles/create"
          component={CrawlProfileCreatePage}
          projectRequired={true}
          subscriptionRequired={true}
        />

        <RestrictedRoute
          path="/crawl_schedules/create"
          component={CrawlScheduleCreatePage}
          projectRequired={true}
          subscriptionRequired={true}
        />
        <RestrictedRoute
          path="/crawl_schedules/edit/:id"
          component={CrawlScheduleEditPage}
          projectRequired={true}
          subscriptionRequired={true}
        />
        <RestrictedRoute
          path="/crawl_schedules"
          component={CrawlScheduleListIndex}
          projectRequired={true}
          subscriptionRequired={true}
        />

        <RestrictedRoute path="/crawls" component={CrawlIndex} projectRequired={true} subscriptionRequired={true} />

        <RestrictedRoute path="/segmentation" component={SegmentationIndex} projectRequired={true} />

        <RestrictedRoute
          path="/dashboards/view/:id"
          component={DashboardView}
          projectRequired={true}
          subscriptionRequired={true}
        />
        <RestrictedRoute path="/dashboards" component={DashboardIndex} subscriptionRequired={true} />

        <RestrictedRoute path="/urlViewer/:url/view" component={UrlViewer} subscriptionRequired={true} />

        <RestrictedRoute path="/profile/change-password" component={ProfileChangePasswordPage} />
        <RestrictedRoute path="/profile/edit" component={ProfileEditPage} />
        <RestrictedRoute path="/profile" component={ProfileViewPage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/change-password/:token" component={ChangePasswordPage} />
        <Route path="/check-email" component={CheckEmail} />
        <Route path="/validate-email/:token" component={ValidateEmail} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;

export const LINKS_STATUS_INSIGHT = {
  '@id': 'LINKS_STATUS_INSIGHT',
  name: 'bundles.crawl.chart.links_status_insight.title',
  description: null,
  x: 6,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'internal_links',
    dimensions: ['statusCode'],
    dimensionsFunctions: [{ function: "CONCAT('Pages pointing to ', %s)" }],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['nbUniqueHref', 'nbLinksTotal'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'asc',
    orderBy: 'statusCode',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'table',
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

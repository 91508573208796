import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const SCHEME = {
  '@id': 'scheme',
  id: 'scheme',
  name: 'sidebar.logs.dashboard.scheme',
  description: null,
  project: '/api/projects/131',
  questions: [
    {
      '@id': 'pages_crawled_by_scheme',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_scheme.title',
      description: null,
      x: 0,
      y: 3,
      w: 4,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['scheme'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'scheme',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getThemeColor',
        options: {
          responsive: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_scheme_day',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_scheme_day.title',
      description: null,
      x: 4,
      y: 3,
      w: 8,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['datetime', 'scheme'],
        dimensionsFunctions: [{ function: 'FORMAT_DATETIME("%%d-%%m-%%Y", DATETIME_TRUNC(%s, DAY))' }],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'datetime',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getThemeColor',

        getDatasetsCallback: function(rows) {
          const backgroundColor = ['#1976d2', '#4caf50', '#dc004e'];

          // get labels.
          const labels = rows.map(obj => obj.datetime).filter((v, i, a) => a.indexOf(v) === i);

          // get schemes.
          const schemes = rows
            .map(obj => obj.scheme)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over status codes.
          // a dataset will be added by code.
          const datasetsSetup = schemes.map((scheme, i) => ({
            label: scheme,
            data: dataWithEmptyValues,
            backgroundColor: backgroundColor[i],
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.datetime);
            const iDataset = schemes.indexOf(arr.scheme);

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },
        getLabelsCallback: function(rows) {
          let labels = [];

          rows.map(arr => {
            labels.push(arr.datetime);

            return true;
          });

          labels = labels.filter((v, i, a) => a.indexOf(v) === i);

          return labels;
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const day = chartData.labels[element[0].index]; // '27-04-2022'
          const scheme = chartData.datasets[element[0].datasetIndex].label;

          // convert date from DD-MM-YYYY to YYYY-MM-DD
          const dayUsFormat = day
            .split('-')
            .reverse()
            .join('-');

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.scheme',
                dimension: 'scheme',
                operator: 'equals',
                values: [scheme],
              },
            ],
            startDate: dayUsFormat,
            endDate: dayUsFormat,
          };
        },

        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'datetime',
          datasetsDimension: 'scheme',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
  ],
};

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { rootReducer, initialState } from '../reducers';

/**
 * @see https://github.com/supasate/connected-react-router#step-2
 */
const history = createBrowserHistory();

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return initialState;
    }

    let state = JSON.parse(serializedState);

    state.common.redirectTo = null;
    if (state.hasOwnProperty('location')) {
      state.location = null;
    }

    return state;
  } catch (err) {
    return initialState;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore write errors
  }
};

function configureStore(initialState = {}) {
  const persistedState = loadState();

  const store = createStore(
    rootReducer(history), // root reducer with router state
    persistedState,
    bindMiddleware([
      routerMiddleware(history), // for dispatching history actions
      thunk,
    ]),
  );

  /*
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000),
  );
   */

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
}

export default configureStore;
export { history };

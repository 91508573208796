import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Typography } from '@material-ui/core';

import { closeModal, selectProject, handleGetProjects } from '../actions';
import CreateProjectButton from '../../project/components/CreateProjectButton';
import ProjectSelectorContent from './ProjectSelectorContent';

const ProjectSelectorModal = props => {
  const location = useLocation();
  const history = useHistory();

  const { project } = useSelector(({ projectSelector }) => projectSelector);

  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    console.debug('[ProjectSelectorModal] component mounted');
    const token = localStorage.getItem('token');

    if (token) {
      if (urlParams.has('projectId') && urlParams.get('projectId')) {
        // if there is a project defined in querystring,
        // then use this project
        console.debug('[ProjectSelectorModal] project retrieved from query string', urlParams.get('projectId'));

        if (project && project.id && project.id === parseInt(urlParams.get('projectId'))) {
          console.debug('[ProjectSelectorModal] project in query string and store are same');
          //
        } else {
          dispatch(selectProject(urlParams.get('projectId'), location, history));
        }
      } else if (project && project.id) {
        // else if there is a project in storage, but not defined in querystring,
        // then redirect
        // important:
        // USEFULL ONLY IF THE STORE IS PERSISTENT WITH LOCAL STORAGE
        console.debug('[ProjectSelectorModal] project retrieved from store', project.id);
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('projectId', project.id);

        console.debug('[ProjectSelectorModal] add project in URL (wihtout redirect)', project.id);
        console.debug('history.replace()');
        history.replace({
          pathname: location.pathname,
          search: '?' + urlParams.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, urlParams.get('projectId')]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const orderObj = {};
      orderObj['name'] = 'asc';

      const payload = {
        order: orderObj,
      };

      dispatch(handleGetProjects(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={props.open} onClose={() => dispatch(closeModal())} fullWidth={true} maxWidth={'md'}>
      <DialogTitle disableTypography={true}>
        <div onClick={() => dispatch(closeModal())} style={{ float: 'right' }}>
          <CreateProjectButton variant={'outlined'} />
        </div>

        <Typography variant="h2">
          <IntlMessages id="bundles.projectSelector.components.ProjectSelectorModal.title" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ProjectSelectorContent />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())} color="primary">
          <IntlMessages id="generic.text.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProjectSelectorModal.propTypes = {
  open: PropTypes.bool,
};

export default ProjectSelectorModal;

import {
  DATE_PICKER_OPEN,
  DATE_PICKER_CLOSE,
  DATE_PICKER_SELECT,
  GET_LOGS_STARTED,
  GET_LOGS,
  CLEAR_LOGS,
  GET_METRIC,
  CLEAR_METRIC,
  START_EXPORT,
  SHOW_EXPORT_RESULT,
  CLOSE_EXPORT_RESULT,
} from '../constants';

const INIT_STATE = {
  // date picker
  openDateRangePicker: false,
  // logs explorer
  count: {},
  csvData: {},
  rows: {},
  isLoading: {},
  metric: {},
  // export modal
  showExportResult: false,
  exportResult: {},
  exportStatus: 'void',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DATE_PICKER_OPEN: {
      return {
        ...state,
        openDateRangePicker: true,
      };
    }
    case DATE_PICKER_CLOSE: {
      return {
        ...state,
        openDateRangePicker: false,
      };
    }
    case DATE_PICKER_SELECT: {
      return {
        ...state,
        openDateRangePicker: false,
      };
    }
    case GET_LOGS_STARTED: {
      let isLoading = state.isLoading;
      isLoading[action.payload.id] = true;

      return {
        ...state,
        isLoading: isLoading,
      };
    }
    case GET_LOGS: {
      let count = state.count;
      count[action.payload.id] = action.payload.count;

      let csvData = state.csvData;
      csvData[action.payload.id] = action.payload.csvData;

      let rows = state.rows;
      rows[action.payload.id] = action.payload.rows;

      let isLoading = state.isLoading;
      isLoading[action.payload.id] = false;

      return {
        ...state,
        count: count,
        csvData: csvData,
        rows: rows,
        isLoading: isLoading,
      };
    }
    case CLEAR_LOGS: {
      let count = state.count;
      let csvData = state.csvData;
      let rows = state.rows;
      let isLoading = state.isLoading;

      if (count.hasOwnProperty(action.payload.id)) {
        delete count[action.payload.id];
      }

      if (csvData.hasOwnProperty(action.payload.id)) {
        delete csvData[action.payload.id];
      }

      if (rows.hasOwnProperty(action.payload.id)) {
        delete rows[action.payload.id];
      }

      if (isLoading.hasOwnProperty(action.payload.id)) {
        delete isLoading[action.payload.id];
      }

      return {
        ...state,
        count: count,
        csvData: csvData,
        rows: rows,
        isLoading: isLoading,
      };
    }
    case GET_METRIC: {
      let metric = state.metric;
      metric[action.payload.id] = action.payload.data;

      let isLoading = state.isLoading;
      isLoading[action.payload.id] = false;

      return {
        ...state,
        metric: metric,
        isLoading: isLoading,
      };
    }
    case CLEAR_METRIC: {
      let metric = state.metric;
      let isLoading = state.isLoading;

      if (metric.hasOwnProperty(action.payload.id)) {
        delete metric[action.payload.id];
      }

      if (isLoading.hasOwnProperty(action.payload.id)) {
        delete isLoading[action.payload.id];
      }

      return {
        ...state,
        metric: metric,
        isLoading: isLoading,
      };
    }
    case START_EXPORT: {
      return {
        ...state,
        showExportResult: true,
        exportResult: {},
        exportStatus: 'running',
      };
    }
    case SHOW_EXPORT_RESULT: {
      return {
        ...state,
        showExportResult: true,
        exportResult: action.payload,
        exportStatus: 'finished',
      };
    }
    case CLOSE_EXPORT_RESULT: {
      return {
        ...state,
        showExportResult: false,
        exportResult: {},
        exportStatus: 'void',
      };
    }
    default:
      return state;
  }
};

import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

function getColorByStatusCode2(code) {
  let color = '';

  code = parseInt(code);

  if (code >= 200 && code <= 299) {
    color = green['500'];
  } else if (code >= 300 && code <= 399) {
    color = yellow['500'];
  } else if (code >= 400 && code <= 499) {
    color = orange['500'];
  } else if (code >= 500) {
    color = red['500'];
  }

  return color;
}

function getRandomColorByName(name) {
  let output = '';
  let randomColor = '';

  name = name.toString();

  for (let i = 0; i < name.length; i++) {
    output += name[i].charCodeAt(0);
  }

  randomColor = '#' + Math.floor(output * 16777215).toString(16);
  randomColor = randomColor.substr(0, 7);

  return randomColor;
}

export function getColorByStatusCode(chartData) {
  if (chartData.datasets.length === 0) {
    return chartData;
  }

  let chartType = chartData.datasets[0].hasOwnProperty('label') ? 'bar' : 'pie';

  if ('bar' === chartType) {
    chartData.datasets.map((dataset, datasetIndex) => {
      const color = getColorByStatusCode2(dataset.label);

      chartData.datasets[datasetIndex]['backgroundColor'] = color;

      return dataset;
    });
  } else if ('pie' === chartType) {
    chartData.labels.map((label, labelIndex) => {
      // on a pie chart: 'Red', 'Yellow', 'Blue'
      if (1 === chartData.datasets.length) {
        if (!chartData.datasets[0].hasOwnProperty('backgroundColor')) {
          chartData.datasets[0]['backgroundColor'] = [];
        }

        let color = getColorByStatusCode2(label);

        chartData.datasets[0].backgroundColor.push(color);
      }

      return label;
    });
  }

  return chartData;
}

export function getThemeColor(chartData) {
  if (chartData.datasets.length === 0) {
    return chartData;
  }

  const colorList = ['#1976d2', '#dc004e', '#f44336', '#ff9800', '#2196f3', '#4caf50'];

  let temp = [];

  let chartType = chartData.datasets[0].hasOwnProperty('label') ? 'bar' : 'pie';

  if ('bar' === chartType) {
    chartData.datasets.map((dataset, datasetIndex) => {
      let colorIndex = temp.indexOf(dataset.label);

      if (colorIndex === -1) {
        colorIndex = temp.push(dataset.label);
        colorIndex = colorIndex - 1;
      }

      let color = colorList[colorIndex];

      chartData.datasets[datasetIndex]['backgroundColor'] = color;

      return dataset;
    });
  } else if ('pie' === chartType) {
    chartData.labels.map((label, labelIndex) => {
      // on a pie chart: 'Red', 'Yellow', 'Blue'
      if (1 === chartData.datasets.length) {
        if (!chartData.datasets[0].hasOwnProperty('backgroundColor')) {
          chartData.datasets[0]['backgroundColor'] = [];
        }

        let colorIndex = temp.indexOf(label);

        if (colorIndex === -1) {
          colorIndex = temp.push(label);
          colorIndex = colorIndex - 1;
        }

        let color = colorList[colorIndex];

        chartData.datasets[0].backgroundColor.push(color);
      }

      return label;
    });
  }

  return chartData;
}

export function getRandomColor(chartData) {
  if (chartData.datasets.length === 0) {
    return chartData;
  }

  let chartType = chartData.datasets[0].hasOwnProperty('label') ? 'bar' : 'pie';

  if ('bar' === chartType) {
    chartData.datasets.map((dataset, datasetIndex) => {
      const color = getRandomColorByName(dataset.label);

      chartData.datasets[datasetIndex]['backgroundColor'] = color;

      return dataset;
    });
  } else if ('pie' === chartType) {
    chartData.labels.map((label, labelIndex) => {
      // on a pie chart: 'Red', 'Yellow', 'Blue'
      if (1 === chartData.datasets.length) {
        if (!chartData.datasets[0].hasOwnProperty('backgroundColor')) {
          chartData.datasets[0]['backgroundColor'] = [];
        }

        let color = getRandomColorByName(label);

        chartData.datasets[0].backgroundColor.push(color);
      }

      return label;
    });
  }

  return chartData;
}

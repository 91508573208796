import saMessages from '../locales/fr_FR.json';
import profileMessages from '../../bundles/profile/translations/fr_FR.json';
import invoiceMessages from '../../bundles/billing/invoice/translations/fr_FR.json';
import usageMessages from '../../bundles/billing/usage/translations/fr_FR.json';
import paymentMethodMessages from '../../bundles/billing/paymentMethod/translations/fr_FR.json';
import shippingMessages from '../../bundles/billing/shipping/translations/fr_FR.json';
import changePlanMessages from '../../bundles/billing/subscription/translations/fr_FR.json';
import projectMessages from '../../bundles/project/translations/fr_FR.json';
import projectSelectorMessages from '../../bundles/projectSelector/translations/fr_FR.json';
import paymentMessages from '../../bundles/billing/checkout/translations/fr_FR.json';
import notificationMessages from '../../bundles/notification/translations/fr_FR.json';
import notificationSelectorMessages from '../../bundles/notificationSelector/translations/fr_FR.json';

import installScriptMessages from '../../bundles/logAnalyzer/InstallScript/translations/fr_FR.json';
import logsExplorerMessages from '../../bundles/logAnalyzer/LogsExplorer/translations/fr_FR.json';
import logsHistoryMessages from '../../bundles/logAnalyzer/logsHistory/translations/fr_FR.json';
import logsFiltersMessages from '../../bundles/logAnalyzer/LogsFilters/translations/fr_FR.json';

import crawlMessages from '../../bundles/crawl/translations/fr_FR.json';
import crawlProfileMessages from '../../bundles/crawlProfile/translations/fr_FR.json';
import crawlScheduleMessages from '../../bundles/crawlSchedule/translations/fr_FR.json';

import dashboardMessages from '../../bundles/dashboard/translations/fr_FR.json';

import segmentationMessages from '../../bundles/segmentation/translations/fr_FR.json';

import resettingMessages from '../../bundles/resetting/translations/fr_FR.json';
import urlViewerMessages from '../../bundles/urlViewer/translations/fr_FR.json';

const saLang = {
  messages: {
    ...saMessages,
    ...profileMessages,
    ...invoiceMessages,
    ...usageMessages,
    ...paymentMethodMessages,
    ...shippingMessages,
    ...changePlanMessages,
    ...projectMessages,
    ...projectSelectorMessages,
    ...paymentMessages,
    ...notificationMessages,
    ...notificationSelectorMessages,
    ...installScriptMessages,
    ...logsExplorerMessages,
    ...logsFiltersMessages,
    ...logsHistoryMessages,
    ...crawlMessages,
    ...crawlProfileMessages,
    ...crawlScheduleMessages,
    ...dashboardMessages,
    ...segmentationMessages,
    ...resettingMessages,
    ...urlViewerMessages,
  },
  locale: 'fr-FR',
};
export default saLang;

import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import IntlMessages from '@jumbo/utils/IntlMessages';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import { GRID_SPACING } from '../../../../@jumbo/constants/ThemeOptions';

const useStyles = makeStyles(theme => ({
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    height: '60px',
    width: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  verticalDivider: {
    borderLeft: '1px solid grey',
    width: '10px',
  },
  horizontalSpacer: {
    width: '20px',
  },
  verticalSpacer: {
    height: '20px',
  },
}));

const LoadTime = props => {
  const classes = useStyles();

  return (
    <CmtCard>
      <CmtCardHeader title={<IntlMessages id="bundles.urlViewer.subtitle.loadTime" />} />
      <CmtCardContent>
        <Grid container spacing={GRID_SPACING}>
          <Grid md={12} item>
            <table>
              <thead>
                <tr>
                  <td>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.step" />
                  </td>
                </tr>
              </thead>
              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.redirectTime" />
                  </Box>
                </td>

                <td className={classes.horizontalSpacer} />
                <td className={classes.verticalDivider} />

                <td>{props.data.performance.navigation.redirectTime} ms</td>

                <td className={classes.horizontalSpacer} rowSpan={11} />
                <td className={classes.verticalDivider} rowSpan={11} />

                <td rowSpan={11}>{props.data.performance.navigation.totalNetworkTime} ms</td>

                <td className={classes.horizontalSpacer} rowSpan={40} />
                <td className={classes.verticalDivider} rowSpan={40} />

                <td rowSpan={40}>{props.data.performance.navigation.totalLoadTime} ms</td>
              </tr>

              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.appCache" />
                  </Box>
                </td>
                <td className={classes.horizontalSpacer} />
                <td className={classes.verticalDivider} />
                <td>{props.data.performance.navigation.cacheTime} ms</td>
              </tr>

              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.dnsTime" />
                  </Box>
                </td>

                <td className={classes.horizontalSpacer} />
                <td className={classes.verticalDivider} />

                <td>{props.data.performance.navigation.dnsTime} ms</td>
              </tr>

              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.connectTime" />
                  </Box>
                </td>

                <td className={classes.horizontalSpacer} />
                <td className={classes.verticalDivider} />

                <td>{props.data.performance.navigation.tcpTime} ms</td>
              </tr>

              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.requestTime" />
                  </Box>
                </td>

                <td className={classes.horizontalSpacer} />
                <td className={classes.verticalDivider} />

                <td>{props.data.performance.navigation.requestTime} ms</td>
              </tr>
              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.responseTime" />
                  </Box>
                </td>

                <td className={classes.horizontalSpacer} />
                <td className={classes.verticalDivider} />

                <td>{props.data.performance.navigation.responseTime} ms</td>
              </tr>

              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.processingTime" />
                  </Box>
                </td>

                <td className={classes.horizontalSpacer} rowSpan={4} />
                <td className={classes.verticalDivider} rowSpan={4} />

                <td rowSpan={4}>{props.data.performance.navigation.contentTime} ms</td>
              </tr>

              <tr>
                <td className={classes.verticalSpacer} />
              </tr>

              <tr>
                <td>
                  <Box className={classes.step}>
                    <IntlMessages id="bundles.urlViewer.text.loadTime.onLoadTime" />
                  </Box>
                </td>
                <td />
              </tr>
            </table>
          </Grid>
        </Grid>
      </CmtCardContent>
    </CmtCard>
  );
};

LoadTime.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LoadTime;

import React, { useEffect } from 'react';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';
import { useDispatch } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import { fetchError, fetchStart, fetchSuccess, showMessage, redirectReset } from '../../redux/actions';
import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';
import { Box } from '@material-ui/core';
import CmtImage from '../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { sendUserConfirmationEmail } from 'services/api/MyApiUtil';

const useStyles = makeStyles(theme => ({
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
}));

const CheckEmail = ({ method = CurrentAuthMethod, variant = 'standard', wrapperVariant = 'bgColor' }) => {
  const classes = useStyles({ variant });

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('signup'));

  useEffect(() => {
    dispatch(redirectReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    dispatch(fetchStart());

    sendUserConfirmationEmail(user.id)
      .then(result => {
        dispatch(showMessage('check_email.notification.success', 'success'));
        dispatch(fetchSuccess());
      })
      .catch(function(error) {
        dispatch(fetchError(<IntlMessages id="check_email.notification.error" />));
      });
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>

        <Box>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            <IntlMessages id="check_email.text.title" />
          </Typography>

          <Box mb={6}>
            <Typography variant="body1" component="span">
              <IntlMessages id="check_email.text.body" values={{ br: '<br>', email: user.email }} />
            </Typography>
          </Box>

          <Box mb={6}>
            <Button onClick={onSubmit} variant="outlined" color="primary">
              <IntlMessages id="check_email.action.resend_email" />
            </Button>
          </Box>

          <IntlMessages id="check_email.text.help" />
        </Box>

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default CheckEmail;

import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const Redirects = props => {
  return (
    <CmtCard>
      <CmtCardHeader
        title={
          props.data.request.redirectChain.length > 0
            ? `Redirects (${props.data.request.redirectChain.length})`
            : 'Redirects'
        }
      />
      <CmtCardContent>
        {props.data.request.redirectChain.length > 0 && (
          <Table>
            <TableBody>
              {props.data.request.redirectChain.map((row, i) => (
                <React.Fragment key={i}>
                  <TableRow>
                    <TableCell>statusCode</TableCell>
                    <TableCell>{row.statusCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>toUrl</TableCell>
                    <TableCell>{row.toUrl}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

Redirects.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Redirects;

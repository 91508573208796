import { ROOT_FIELDS } from './root';
import { SEO_TAGS_FIELDS } from './seo_tags';
import { INTERNAL_LINKS_FIELDS } from './internal_links';
import { EXTERNAL_LINKS_FIELDS } from './external_links';
import { PAGE_WEIGHT_FIELDS } from './page_weight';
import { CONSOLE_LOGS_FIELDS } from './console_logs';
import { REQUEST_HEADERS_FIELDS } from './request_headers';
import { RESPONSE_HEADERS_FIELDS } from './response_headers';

export const CRAWLED_PAGES_SCHEMA = {
  table: 'crawled_pages',
  fields: [
    ...ROOT_FIELDS,
    ...SEO_TAGS_FIELDS,
    ...INTERNAL_LINKS_FIELDS,
    ...EXTERNAL_LINKS_FIELDS,
    ...PAGE_WEIGHT_FIELDS,
    ...CONSOLE_LOGS_FIELDS,
    ...REQUEST_HEADERS_FIELDS,
    ...RESPONSE_HEADERS_FIELDS,
  ],
  order: 'asc',
  orderBy: 'url',
  hasTimeDimension: false,
};

export const NON_INDEXABILITY_REASONS = [
  'robots.txt',
  'meta-robots',
  'x-robots-tag',
  'canonical',
  'http-code-3xx',
  'http-code-4xx',
  'http-code-5xx',
  'non_indexable_page_type',
];

export const NON_INDEXABILITY_TRANSLATED_REASONS = [
  'bundles.crawl.chart.indexability.label.robots.txt.short',
  'bundles.crawl.chart.indexability.label.meta-robots.short',
  'bundles.crawl.chart.indexability.label.x-robots-tag.short',
  'bundles.crawl.chart.indexability.label.canonical.short',
  'bundles.crawl.chart.indexability.label.http-code-3xx.short',
  'bundles.crawl.chart.indexability.label.http-code-4xx.short',
  'bundles.crawl.chart.indexability.label.http-code-5xx.short',
  'bundles.crawl.chart.indexability.label.non_indexable_page_type.short',
];

import React, { useContext, useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { Link } from 'react-router-dom';
import { Chip, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { FREQUENCY_CUSTOM, FREQUENCY_DAY, FREQUENCY_HOUR, FREQUENCY_WEEK } from '../constants';

const useStyles = makeStyles(theme => ({
  lightText: {
    color: theme.palette.lightBtn.textColor,
  },
}));

const CrawlScheduleList = props => {
  const classes = useStyles();
  const { locale } = useContext(AppContext);

  const [menuState, setMenuState] = useState({});

  const columns = [
    { field: 'profile', hasHelp: false },
    { field: 'frequency', hasHelp: false },
    { field: 'cronExpression', hasHelp: false },
    { field: 'createdAt', hasHelp: false },
  ];

  moment.locale(locale.locale);

  const handleOpenMenu = e => {
    const id = e.currentTarget.attributes.getNamedItem('data-id').value;

    const newMenuState = {};
    newMenuState[id] = e.currentTarget;

    setMenuState(newMenuState);
  };

  const handleDelete = id => {
    setMenuState({});

    props.handleDelete(id);
  };

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.field} sortDirection={props.orderBy === column.field ? props.order : false}>
                <TableSortLabel
                  active={props.orderBy === column.field}
                  direction={props.orderBy === column.field ? props.order : 'asc'}
                  onClick={() => {
                    props.handleChangeSort(column.field);
                  }}>
                  <IntlMessages id={'bundles.crawlSchedule.field.' + column.field + '.short'} />
                  {column.hasHelp && (
                    <Box ml={1} clone>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <IntlMessages id={'bundles.crawlSchedule.field.' + column.field + '.help'} />
                          </React.Fragment>
                        }>
                        <HelpIcon color={'action'} fontSize={'small'} className={'ml-1'} />
                      </Tooltip>
                    </Box>
                  )}
                </TableSortLabel>
              </TableCell>
            ))}

            <TableCell colSpan={2} align={'right'} style={{ width: '15%' }}>
              <IntlMessages id="bundles.crawlSchedule.global.actions" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.crawls['hydra:member'].map(entity => (
            <TableRow key={entity.id} hover={true}>
              <TableCell>{entity.profile.name}</TableCell>

              <TableCell>
                {entity.frequency === FREQUENCY_HOUR && (
                  <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyHour.choice" />
                )}

                {entity.frequency === FREQUENCY_DAY && (
                  <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyDay.choice" />
                )}

                {entity.frequency === FREQUENCY_WEEK && (
                  <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyWeek.choice" />
                )}

                {entity.frequency === FREQUENCY_CUSTOM && (
                  <IntlMessages id="bundles.crawlSchedule.form.text.frequency.custom.choice" />
                )}
              </TableCell>

              <TableCell>
                <Chip label={entity.cronExpression} />
              </TableCell>

              <TableCell className={classes.lightText}>
                <Typography variant={'body2'}>{moment(entity.createdAt).fromNow()}</Typography>
              </TableCell>

              <TableCell>
                <Link to={`/crawl_schedules/edit/${entity.id}?projectId=${entity.project.split('/').pop()}`}>
                  <Button key={'detach-' + entity.id} size="small" color="primary">
                    <IntlMessages id="bundles.crawlSchedule.action.edit" />
                  </Button>
                </Link>
              </TableCell>

              <TableCell align={'right'}>
                <IconButton
                  data-id={entity.id}
                  aria-label="more"
                  aria-controls={'menu-' + entity.id}
                  aria-haspopup="true"
                  onClick={e => handleOpenMenu(e)}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  data-id={entity.id}
                  id={'menu-' + entity.id}
                  anchorEl={menuState[entity.id]}
                  open={menuState.hasOwnProperty(entity.id)}
                  onClose={() => setMenuState({})}
                  keepMounted>
                  <MenuItem onClick={() => handleDelete(entity.id)}>
                    <IntlMessages id="bundles.crawl.action.delete" />
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default CrawlScheduleList;

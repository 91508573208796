import {
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  UPDATE_SUBSCRIPTION_USER,
  UPDATE_PLAN_USER,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  authUser: null,
  planUser: null,
  subscriptionUser: null,
  loadUser: false,
  isActiveSubscription: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      let isActiveSubscription = false;
      if (action.payload.subscription) {
        if (action.payload.subscription.status === 'active' || action.payload.subscription.status === 'trialing') {
          isActiveSubscription = true;
        }
      }

      return {
        ...state,
        authUser: action.payload.user,
        planUser: action.payload.plan,
        subscriptionUser: action.payload.subscription,
        isActiveSubscription: isActiveSubscription,
        loadUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION_USER: {
      let isActiveSubscription = false;
      if (action.payload) {
        if (action.payload.status === 'active' || action.payload.status === 'trialing') {
          isActiveSubscription = true;
        }
      }

      return {
        ...state,
        subscriptionUser: action.payload,
        isActiveSubscription: isActiveSubscription,
      };
    }
    case UPDATE_PLAN_USER: {
      return {
        ...state,
        planUser: action.payload,
      };
    }
    default:
      return state;
  }
};

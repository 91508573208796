import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Box from '@material-ui/core/Box';
import MyDrawer from 'components/Drawer';

import CodeFilter from 'bundles/logAnalyzer/LogsFilters/components/Filter/CodeFilter';
import BasicFilter from 'bundles/logAnalyzer/LogsFilters/components/Filter/BasicFilter';
import AutomaticChoicesFilter from 'bundles/logAnalyzer/LogsFilters/components/Filter/AutomaticChoicesFilter';
import FiltersList from '../FiltersList';

/**
 * props required:
 *
 * - projectId
 * - name
 * - title
 * - open
 * - schema
 * - addFilter
 * - addFilterSchema
 * - hiddenFilters
 * - handleCancel
 * - handleAddFilter
 * - handleCancelAddFilter
 * - handleSaveFilter
 */
class ChooseFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.title.filterBy'} />,
    };

    this.handleAddFilter = this.handleAddFilter.bind(this);
  }

  handleClose() {
    if (!this.props.addFilter) {
      this.props.handleCancel(this.props.name);
    } else {
      this.props.handleCancelAddFilter();
    }

    this.setState({
      title: <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.title.filterBy'} />,
    });
  }

  handleAddFilter(obj) {
    this.props.handleAddFilter(obj.dimension);

    this.setState({
      title: <IntlMessages id={obj.label} />,
    });
  }

  render() {
    // filterComponent
    return (
      <MyDrawer
        open={this.props.open}
        withHeader={true}
        headerTitle={this.state.title}
        handleClose={() => {
          this.handleClose();
        }}>
        {this.props.addFilter ? (
          <div>
            {'AutomaticChoicesFilter' === this.props.addFilterSchema.filterComponent && (
              <AutomaticChoicesFilter
                projectId={this.props.projectId}
                title={<IntlMessages id={this.props.addFilterSchema.label} />}
                schemaName={this.props.schemaName}
                dimension={this.props.addFilterSchema.dimension}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                filters={this.props.filters}
                hiddenFilters={this.props.hiddenFilters}
                schema={this.props.addFilterSchema}
                editedFilter={this.props.editedFilter}
                handleSaveFilter={this.props.handleSaveFilter}
              />
            )}

            {'CodeFilter' === this.props.addFilterSchema.filterComponent && (
              <CodeFilter
                projectId={this.props.projectId}
                schema={this.props.addFilterSchema}
                editedFilter={this.props.editedFilter}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                hiddenFilters={this.props.hiddenFilters}
                handleSaveFilter={this.props.handleSaveFilter}
              />
            )}

            {'BasicFilter' === this.props.addFilterSchema.filterComponent && (
              <BasicFilter
                projectId={this.props.projectId}
                schema={this.props.addFilterSchema}
                editedFilter={this.props.editedFilter}
                handleSaveFilter={this.props.handleSaveFilter}
              />
            )}
          </div>
        ) : (
          <div>
            <Box p={6} pb={0}>
              <h3>
                <IntlMessages id={'bundles.LogsFilters.components.CodeFilter.title.chooseDimension'} />
              </h3>
            </Box>

            <FiltersList schema={this.props.schema} handleAddFilter={this.handleAddFilter} />
          </div>
        )}
      </MyDrawer>
    );
  }
}

export default ChooseFilters;

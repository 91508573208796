import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@material-ui/core';

const ChartPie = props => {
  const history = useHistory();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  useEffect(() => {
    prepareChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows[props.id]]);

  const prepareChartData = () => {
    // init chart data
    let chartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    // get metric data (from prometheus or bigquery)
    let data = null;

    if ('metric' === props.type && props.metric.hasOwnProperty(props.metricId)) {
      data = props.metric[props.metricId];
    } else if (props.rows.hasOwnProperty(props.id)) {
      data = props.rows[props.id];
    }

    if ('metric' === props.type && data) {
      data.data.result.map(arr => {
        // add dimensions
        chartData.labels.push(arr.metric[props.metricLabelName]);

        // add measures
        chartData.datasets[0].data.push(arr.value[1]);

        return 0;
      });
    } else if (data) {
      data.map(arr => {
        // add dimensions
        props.dimensions.map((dimension, i) => {
          chartData.labels.push(arr[dimension]);

          return dimension;
        });

        // add measures
        props.measures.map(measure => {
          chartData.datasets[0].data.push(arr[measure]);

          return measure;
        });

        return arr;
      });
    }

    chartData = props.colorChoice(chartData);

    setChartData(chartData);

    let copiedChartData = Object.assign({}, chartData);
    let changed = false;

    if (data && props.visualization.hasOwnProperty('getLabelsCallback') && props.visualization.getLabelsCallback) {
      changed = true;
      copiedChartData.labels = props.visualization.getLabelsCallback(data);
    }

    if (data && props.visualization.hasOwnProperty('getDatasetsCallback') && props.visualization.getDatasetsCallback) {
      changed = true;
      copiedChartData.datasets = props.visualization.getDatasetsCallback(data);
    }

    if (changed) {
      setChartData(copiedChartData);
    }
  };

  /**
   * Triggered when the chart is clicked.
   */
  const handleChartClick = element => {
    if (element.length === 0) {
      return;
    }

    const xValue = chartData.labels[element[0].index];

    let columns = [];
    let filters = [];
    let schemaName = props.schemaName;

    if (xValue) {
      filters.push({
        label: 'dimension.' + schemaName + '.' + props.dimensions[0],
        dimension: props.dimensions[0],
        operator: 'equals',
        values: [xValue],
      });
    }

    if (
      props.visualization.hasOwnProperty('getDataExplorerTableAndFilters') &&
      props.visualization.getDataExplorerTableAndFilters
    ) {
      const config = props.visualization.getDataExplorerTableAndFilters(element, chartData);

      if (config.hasOwnProperty('columns') && config.columns.length > 0) {
        columns = config.columns;
      }

      filters = config.filters;
      schemaName = config.schemaName;
    }

    let hiddenFilters = [];

    if (props.hiddenFilters.length > 0) {
      // add hidden filters
      props.hiddenFilters.map(obj => {
        obj.label = 'dimension.' + schemaName + '.' + obj.dimension;

        hiddenFilters.push(obj);

        return obj;
      });
    }

    let to = '/log_analyzer/explorer';
    to += '?projectId=' + props.projectId;
    to += '&schema=' + schemaName;
    to += '&filters=' + JSON.stringify(filters);

    if (hiddenFilters.length > 0) {
      to += '&hiddenFilters=' + JSON.stringify(hiddenFilters);
    }

    if (columns.length > 0) {
      to += '&columns=' + JSON.stringify(columns);
    }

    history.push(to);
  };

  const id = Math.floor(Math.random() * 100).toString();
  let visualizationOptions = props.visualization.options;

  visualizationOptions['onHover'] = function(element, elements) {
    var el = document.getElementById(id);

    if (!el || !el.style) {
      return;
    }

    if (element.length === 0 || elements.length === 0) {
      el.style.cursor = 'default';

      return;
    }

    el.style.cursor = 'pointer';

    return;
  };

  return (
    <Card className="shadow border-0 mb-0">
      <CardBody>
        <CardTitle>{props.title}</CardTitle>
        <CardText>
          {chartData && (
            <Box mb={6} style={props.visualization.containerOptions && props.visualization.containerOptions.style}>
              <Doughnut id={id} data={chartData} options={visualizationOptions} getElementAtEvent={handleChartClick} />
            </Box>
          )}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default ChartPie;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class CreateProjectDisabled extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DialogTitle id="alert-dialog-title">
          <IntlMessages id="bundles.project.tooltip.cannotCreate.quotaReached.title" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              <IntlMessages id="bundles.project.tooltip.cannotCreate.quotaReached.1" />
            </p>

            <p>
              <IntlMessages id="bundles.project.tooltip.cannotCreate.quotaReached.2" />
            </p>

            <p className={'mb-0'}>
              <Link onClick={this.handleClose} to={'/app/plans'}>
                <Button color="primary">
                  <IntlMessages id="bundles.project.tooltip.cannotCreate.quotaReached.upgrade" />
                </Button>
              </Link>
            </p>
          </DialogContentText>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default CreateProjectDisabled;

import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import HeaderPageSupplements from 'components/HeaderPageSupplements';

import CrawlScheduleList from '../components/CrawlScheduleList';
import { handleGetCrawlSchedules, handleDeleteCrawlSchedule } from '../actions';

const CrawlScheduleListIndex = props => {
  const history = useHistory();
  const { setBreadcrumb } = useContext(AppContext);
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const { crawlSchedulesList } = useSelector(({ crawlSchedule }) => crawlSchedule);
  // sorting
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');

  const dispatch = useDispatch();

  /**
   * Called after component is mounted
   */
  useEffect(() => {
    setBreadcrumb([{ text: <IntlMessages id="bundles.crawlSchedule.routes.index.title" /> }]);

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  /**
   * Refresh list
   */
  const refresh = () => {
    const orderObj = {};
    orderObj[orderBy] = order;

    const payload = {
      project: project.id,
      order: orderObj,
    };

    dispatch(handleGetCrawlSchedules(payload));
  };

  /**
   * sorting
   *
   * @param {string} dimension
   */
  const handleChangeSort = dimension => {
    const isAsc = orderBy === dimension && order === 'asc';

    const newOrderBy = dimension;
    const newOrder = isAsc ? 'desc' : 'asc';

    const orderObj = {};
    orderObj[newOrderBy] = newOrder;

    const payload = {
      project: project.id,
      order: orderObj,
    };

    dispatch(handleGetCrawlSchedules(payload));

    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  /**
   * Delete a Crawl
   *
   * @param {number} id
   */
  const handleDelete = id => {
    const orderObj = {};
    orderObj[orderBy] = order;

    const listPayload = {
      order: orderObj,
    };

    dispatch(handleDeleteCrawlSchedule(id, listPayload));
  };

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item lg={3}>
          <Typography variant="h1">
            <IntlMessages id="bundles.crawlSchedule.routes.index.title" />
          </Typography>
        </Grid>

        <Grid item lg={9}>
          <HeaderPageSupplements
            numbers={[]}
            actions={[
              <Link to={'/crawl_schedules/create?projectId=' + project.id}>
                <Button color="primary" variant={'contained'}>
                  <IntlMessages id="bundles.crawlSchedule.action.create" />
                </Button>
              </Link>,
            ]}
          />
        </Grid>

        <Grid md={12} item>
          {crawlSchedulesList['hydra:member'].length === 0 ? (
            <ListEmptyResult
              title={<IntlMessages id="bundles.crawlSchedule.emptyPrompt.title" />}
              content={<IntlMessages id="bundles.crawlSchedule.emptyPrompt.text" />}
              actionTitle={<IntlMessages id="bundles.crawlSchedule.action.create" />}
              onClick={() => {
                history.push('/crawl_schedules/create?projectId=' + project.id);
              }}
            />
          ) : (
            <Paper>
              <CrawlScheduleList
                crawls={crawlSchedulesList}
                order={order}
                orderBy={orderBy}
                handleChangeSort={handleChangeSort}
                handleDelete={handleDelete}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CrawlScheduleListIndex;

import { TOTAL_INBOUND_LINK } from './inbound_links/TOTAL_INBOUND_LINK';
import { AVERAGE_INBOUND_LINK } from './inbound_links/AVERAGE_INBOUND_LINK';
import { PAGES_WITH_MOST_INBOUND_LINKS } from './inbound_links/PAGES_WITH_MOST_INBOUND_LINKS';
import { PAGES_WITH_1_INLINK } from './inbound_links/PAGES_WITH_1_INLINK';
import { PAGES_WITH_LESS_10_INLINKS } from './inbound_links/PAGES_WITH_LESS_10_INLINKS';
import { PAGES_WITH_MORE_10_INLINKS } from './inbound_links/PAGES_WITH_MORE_10_INLINKS';
import { LINKS_STATUS_BREAKDOWN } from './inbound_links/LINKS_STATUS_BREAKDOWN';
import { LINKS_STATUS_INSIGHT } from './inbound_links/LINKS_STATUS_INSIGHT';
import { TOTAL_INLINKS_BY_GROUP } from './inbound_links/TOTAL_INLINKS_BY_GROUP';
import { AVERAGE_INLINKS_BY_GROUP } from './inbound_links/AVERAGE_INLINKS_BY_GROUP';
import { INLINKS_MAP } from './inbound_links/INLINKS_MAP';

export const INBOUND_LINKS = {
  '@id': 'inbound_links',
  id: 'inbound_links',
  name: 'sidebar.crawl.dashboard.inboundLinks',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_INBOUND_LINK,
    AVERAGE_INBOUND_LINK,
    LINKS_STATUS_BREAKDOWN,
    LINKS_STATUS_INSIGHT,
    PAGES_WITH_1_INLINK,
    PAGES_WITH_LESS_10_INLINKS,
    PAGES_WITH_MORE_10_INLINKS,
    TOTAL_INLINKS_BY_GROUP,
    AVERAGE_INLINKS_BY_GROUP,
    PAGES_WITH_MOST_INBOUND_LINKS,
    INLINKS_MAP,
  ],
};

import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { handleSelectPrice } from '../../actions';

const useStyles = makeStyles(theme => ({
  plan: {
    background: 'white',
    textAlign: 'center',
    marginBottom: '24px',

    '&.selected': {
      border: '1px solid ' + theme.palette.secondary.main,

      '& .titleContainer': {
        backgroundColor: theme.palette.secondary.main,
      },
    },

    '& .titleContainer': {
      backgroundColor: theme.palette.primary.main,
      padding: '24px',

      '& .title': {
        fontSize: '20px',
        textTransform: 'uppercase',
        color: 'white',
        fontWeight: 700,
      },

      '& .price': {
        fontSize: '20px',
        paddingTop: '12px',
        paddingBottom: '0px',
        color: 'white',
        fontWeight: 500,
        marginTop: 0,
        display: 'inline-block',
        width: '80%',

        '& p': {
          fontSize: '20px',
          display: 'inline-block',
          margin: 0,
        },

        '& span': {
          fontSize: '20px',
          display: 'inline-block',
        },
      },
    },

    '& .infoContainer': {
      padding: '24px',
      color: '#000000',
      boxSizing: 'border-box',

      '& .desc': {
        paddingBottom: '24px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        marginBottom: '24px',
        width: '90%',
        '& em': {
          fontSize: '18px',
          fontWeight: 500,
        },
      },

      '& .features': {
        fontSize: '20px',
        listStyle: 'none',
        paddingLeft: 0,
        li: {
          padding: '24px',
        },
      },
    },
  },
}));

const PriceItem = props => {
  const classes = useStyles();
  const { currentPrices, selectedPrices, subscriptionItems } = useSelector(({ payment }) => payment);

  const dispatch = useDispatch();

  const selectedPriceId =
    selectedPrices.hasOwnProperty(props.productCode) && selectedPrices[props.productCode]
      ? selectedPrices[props.productCode].id
      : null;

  const currentPriceId =
    currentPrices.hasOwnProperty(props.productCode) && currentPrices[props.productCode]
      ? currentPrices[props.productCode].id
      : null;

  const actionText = 'bundles.checkout.action.select';

  return (
    <div className={selectedPriceId && selectedPriceId === props.plan.id ? clsx(classes.plan, 'selected') : classes.plan}>
      <div className={'titleContainer'}>
        {props.plan.nickname && <div className={'title'}>{props.plan.nickname}</div>}

        <div className={'price'}>
          <p>
            <span>{Intl.NumberFormat().format(props.plan.unit_amount / 100)}</span>
            &nbsp;{props.plan.currency}
          </p>
          <span>
            &nbsp;/&nbsp;
            {'month' === props.frequency ? (
              <IntlMessages id="bundles.checkout.text.month" />
            ) : (
              <IntlMessages id="bundles.checkout.text.year" />
            )}
          </span>
        </div>
      </div>

      <div className={'infoContainer'}>
        <div className={'desc'}>{props.description(props.plan)}</div>

        {currentPriceId && currentPriceId === props.plan.id ? (
          <Button variant="outlined" disabled>
            <IntlMessages id="bundles.checkout.text.currentPlan" />
          </Button>
        ) : (
          <Button
            variant="outlined"
            color={selectedPriceId && selectedPriceId === props.plan.id ? 'secondary' : 'primary'}
            value={props.plan.id}
            onClick={() => dispatch(handleSelectPrice(props.plan.id, props.productCode, selectedPrices, subscriptionItems))}>
            <IntlMessages id={actionText} />
          </Button>
        )}
      </div>
    </div>
  );
};

class CardTheme extends React.Component {
  render() {
    return (
      <Box mb={6}>
        <div className={'planContainer'}>
          <PerfectScrollbar options={{ suppressScrollY: true }} style={{ height: '330px' }}>
            <Grid container spacing={3} style={{ width: '3000px' }}>
              {this.props.plans.map((arr, i) => (
                <Grid item key={i}>
                  <PriceItem
                    productCode={this.props.productCode}
                    description={this.props.description}
                    currentPlan={this.props.currentPlan}
                    selectedPlan={this.props.selectedPlan}
                    plan={arr}
                    price={arr.price}
                    frequency={this.props.frequency}
                    handleSelectPlan={this.props.handleSelectPlan}
                  />
                </Grid>
              ))}
            </Grid>
          </PerfectScrollbar>
        </div>
      </Box>
    );
  }
}

export default CardTheme;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultPaymentMethod2, getPaymentMethodsList } from '../../../paymentMethod/actions';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../../constants';

const useStyles = makeStyles({
  root: {},
  appBar: {
    position: 'relative',
  },
  item: {
    padding: '24px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    lineHeight: '24px',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  image: {},
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentMethodModal = props => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { defaultPaymentMethod, paymentMethodsList } = useSelector(({ paymentMethod }) => paymentMethod);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodsList(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    dispatch(getDefaultPaymentMethod2(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListItemClick = obj => {
    props.onSelectItem(obj);
    props.handleClose();
  };

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      fullScreen
      open={props.open}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h3">
            <IntlMessages id="bundles.PaymentMode.routes.index.title" />
          </Typography>
          <div>
            <IconButton color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <List>
        {paymentMethodsList.map(obj => (
          <ListItem key={obj.id} className={classes.item} button onClick={() => handleListItemClick(obj)}>
            <Grid container alignItems="center" spacing={6} className={classes.root}>
              <Grid item>
                <img
                  className={classes.image}
                  alt={obj.card.brand}
                  src={'/images/cards/' + obj.card.brand + '.png'}
                  width={'64px'}
                />
              </Grid>
              <Grid item>
                <p className={classes.title}>{obj.card.brand.charAt(0).toUpperCase() + obj.card.brand.slice(1)}</p>

                <div className={classes.description}>
                  <IntlMessages id="bundles.PaymentMode.global.endingWith" /> {obj.card.last4}.&nbsp;
                  <IntlMessages id="bundles.PaymentMode.global.validUntil" />{' '}
                  {obj.card.exp_month < 10 ? '0' + obj.card.exp_month : obj.card.exp_month}/{obj.card.exp_year}
                </div>
              </Grid>
              <Grid item>
                {defaultPaymentMethod && defaultPaymentMethod.id === obj.id && (
                  <Chip color={'secondary'} label={<IntlMessages id="bundles.PaymentMode.global.defaultMethod" />} />
                )}
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                <ChevronRightIcon />
              </Grid>
            </Grid>
            <Divider absolute={true} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default PaymentMethodModal;

import React, { useEffect, useContext } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import CustomerForm from '../form/CustomerForm';
import BillingTabs from '../../components/BillingTabs';

const ShippingIndex = () => {
  const { setBreadcrumb } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumb([
      { text: <IntlMessages id="sidebar.billing" /> },
      { text: <IntlMessages id="bundles.shipping.routes.index.title" /> },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={3}>
          <Typography variant="h1" gutterBottom>
            <IntlMessages id="bundles.shipping.routes.index.title" />
          </Typography>

          <p>
            <IntlMessages id="bundles.shipping.routes.index.subtitle" />
          </p>
        </Grid>

        <Grid item md={9}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item md={12}>
              <BillingTabs tabActive={'/billing/shipping'} />
            </Grid>

            <Grid item md={12}>
              <Paper>
                <Box p={6}>
                  <CustomerForm />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShippingIndex;

import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  dragButton: {
    minWidth: 'auto',
    '&:hover': {
      cursor: 'grab',
    },
    '&:focus, &:active, &:focus-within': {
      cursor: 'grabbing',
    },
  },
});

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const ExcludedUrl = props => {
  const classes = useStyles();

  return (
    <Grid container spacing={6}>
      <Grid item xs>
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item>
            <Tooltip title={<IntlMessages id="bundles.crawlProfile.action.dragAndDrop.help" />}>
              <Button size="small" className={classes.dragButton}>
                <DragHandle />
              </Button>
            </Tooltip>
          </Grid>

          <Grid item>
            {props.totalItems !== 1 && props.totalItems - 1 !== props.i && (
              <Tooltip title={<IntlMessages id="bundles.crawlProfile.action.remove.help" />}>
                <IconButton size={'small'} color="inherit" component="span" onClick={() => props.handleRemoveRule(props.i)}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}

            {props.totalItems - 1 === props.i && (
              <Tooltip title={<IntlMessages id="bundles.crawlProfile.action.add.help" />}>
                <IconButton size={'small'} color="inherit" component="span" onClick={props.handleAddRule}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>

          <Grid item xs>
            <TextField
              id={`rules-pattern-${props.i}`}
              name={`pattern`}
              placeholder={'Insert your regular expression'}
              value={props.value}
              onChange={e => props.handleRuleInputChange(e, props.i)}
              fullWidth
              variant={'standard'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SortableElement(ExcludedUrl);

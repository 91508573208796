import {
  NON_INDEXABILITY_REASONS,
  NON_INDEXABILITY_TRANSLATED_REASONS,
} from 'constants/dataExplorer/schema/crawled_pages/crawled_pages_schema';
import { tooltipLabelCallback } from '../../../../dashboard/services/ChartJsCallbacks';

export const NON_INDEXABLE_PAGES_BY_GROUP = {
  '@id': 'NON_INDEXABLE_PAGES_BY_GROUP',
  name: 'bundles.crawl.chart.non_indexable_pages_by_group.title',
  description: null,
  x: 0,
  y: 20,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['category', 'indexable_reason'],
    dimensionsFunctions: [],
    groupBy: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'indexable',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows, intl) {
      // colors.
      const backgroundColor = ['#ff9800', '#fcbf49', '#ca6702', '#dc004e', '#ef233c', '#ef233c', '#ef233c', '#b400dc'];
      // get labels.
      const labels = rows.map(obj => obj.category).filter((v, i, a) => a.indexOf(v) === i);

      const dataWithEmptyValues = Array(labels.length).fill(0);

      // iterate over non-indexability reasons.
      // a dataset will be added by non-indexability reason.
      const datasetsSetup = NON_INDEXABILITY_REASONS.map((reason, i) => ({
        label: intl.formatMessage({
          id: NON_INDEXABILITY_TRANSLATED_REASONS[i],
        }),
        data: dataWithEmptyValues,
        backgroundColor: backgroundColor[i],
      }));

      // clone it.
      let datasets = JSON.parse(JSON.stringify(datasetsSetup));

      // set count into it.
      rows.map(arr => {
        const iLabel = labels.indexOf(arr.category);
        const iDataset = NON_INDEXABILITY_REASONS.indexOf(arr.indexable_reason);

        if (iDataset > -1) {
          datasets[iDataset].data[iLabel] = parseInt(arr.count);
        }

        return true;
      });

      return datasets;
    },
    getLabelsCallback: function(rows) {
      const labels = rows.map(obj => obj.category).filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData, intl) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let indexable_reason = chartData.datasets[element[0].datasetIndex].label; // 'Non-indexable by robots.txt'

      const nonIndexabilityTranslatedReasons = NON_INDEXABILITY_TRANSLATED_REASONS.map(reason =>
        intl.formatMessage({
          id: reason,
        }),
      );

      const i = nonIndexabilityTranslatedReasons.indexOf(indexable_reason);
      indexable_reason = NON_INDEXABILITY_REASONS[i];

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.crawled_pages.indexable_reason',
        dimension: 'indexable_reason',
        operator: 'equals',
        values: [indexable_reason],
      });

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'indexable', 'indexable_reason', 'response_firstStatusCode'],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'nonIndexableReason',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

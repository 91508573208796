import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ActiveFilters from './ActiveFilters';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

/**
 * This component displays a row of active filters and add filter button.
 */
const ActiveFiltersAndAddFilterButton = props => {
  return (
    <React.Fragment>
      <ActiveFilters
        filters={props.filters}
        handleDeleteFilter={props.handleDeleteFilter}
        handleEditFilter={props.handleEditFilter}
      />

      {false === props.hideAddButton && (
        <Box mr={1}>
          <Tooltip title={<IntlMessages id={'bundles.LogsFilters.components.CodeFilter.action.filter.open.tooltip'} />}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                props.handleOpenFilteringDialog();
              }}>
              <IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.filter.btn" />
            </Button>
          </Tooltip>
        </Box>
      )}
    </React.Fragment>
  );
};

ActiveFiltersAndAddFilterButton.propTypes = {
  filters: PropTypes.array.isRequired,
  hideAddButton: PropTypes.bool,
  handleEditFilter: PropTypes.func.isRequired,
  handleDeleteFilter: PropTypes.func.isRequired,
  handleOpenFilteringDialog: PropTypes.func.isRequired,
};

ActiveFiltersAndAddFilterButton.defaultProps = {
  hideAddButton: false,
};

export default React.memo(ActiveFiltersAndAddFilterButton);

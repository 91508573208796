import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { Box, Button, Paper } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import { handleSelectPrice, handleRemovePrice } from '../../actions';

const RadioTheme = props => {
  const { currentPrices, selectedPrices, subscriptionItems } = useSelector(({ payment }) => payment);

  const dispatch = useDispatch();

  const selectedPriceId =
    selectedPrices.hasOwnProperty(props.productCode) && selectedPrices[props.productCode]
      ? selectedPrices[props.productCode].id
      : null;

  const currentPriceId =
    currentPrices.hasOwnProperty(props.productCode) && currentPrices[props.productCode]
      ? currentPrices[props.productCode].id
      : null;

  return (
    <React.Fragment>
      <Box mb={6}>
        <Paper>
          <RadioGroup
            aria-label="log_analyzer_plan"
            name="log_analyzer_plan"
            defaultValue={null}
            //value={props.frequency}
            onChange={props.handleSelectFrequency}>
            <List component="nav">
              {props.plans.map((arr, i) => (
                <div key={i}>
                  <Divider />
                  <ListItem button disabled={currentPriceId && currentPriceId === arr.id}>
                    <Grid container spacing={3} alignContent={'center'} alignItems={'center'} justify={'center'}>
                      <Grid item lg={3}>
                        <FormControlLabel
                          checked={selectedPriceId && selectedPriceId === arr.id}
                          value={arr.id}
                          control={<Radio />}
                          label={arr.nickname}
                          onClick={() =>
                            dispatch(handleSelectPrice(arr.id, props.productCode, selectedPrices, subscriptionItems))
                          }
                        />
                      </Grid>
                      <Grid item lg={6}>
                        {props.description(arr)}
                      </Grid>
                      <Grid item lg={3} align={'right'}>
                        <span>{Intl.NumberFormat().format(arr.unit_amount / 100)}</span>
                        &nbsp;{arr.currency} /&nbsp;
                        {'month' === props.frequency ? (
                          <IntlMessages id="bundles.checkout.text.month" />
                        ) : (
                          <IntlMessages id="bundles.checkout.text.year" />
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                </div>
              ))}
            </List>
          </RadioGroup>
        </Paper>
      </Box>

      <Box align={'right'}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => dispatch(handleRemovePrice(props.productCode, selectedPrices, subscriptionItems))}>
          <IntlMessages id="bundles.checkout.action.cancelOption" />
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default RadioTheme;

/**
 * @param {Object} values
 */
export function validateProfileForm(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  }

  if (values.email && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  if (!values.country) {
    errors.country = 'Required';
  }

  return errors;
}

import { TOTAL_CRAWLED_PAGES } from './summary/TOTAL_CRAWLED_PAGES';
import { TOTAL_INDEXABLE_PAGES } from './summary/TOTAL_INDEXABLE_PAGES';
import { TOTAL_NON_INDEXABLE_PAGES } from './summary/TOTAL_NON_INDEXABLE_PAGES';
import { CODES_BREAKDOWN_SUMMARY } from './summary/CODES_BREAKDOWN';
import { LOAD_TIME_BREAKDOWN_SUMMARY } from './summary/LOAD_TIME_BREAKDOWN';
import { CODES_BY_PAGE_GROUP_SUMMARY } from './summary/CODES_BY_PAGE_GROUP';
import { LOAD_TIME_BY_PAGE_GROUP_SUMMARY } from './summary/LOAD_TIME_BY_PAGE_GROUP';

export const SUMMARY = {
  '@id': 'summary',
  id: 'summary',
  name: 'sidebar.crawl.dashboard.summary',
  description: null,
  project: '/api/projects/131',
  questions: [
    TOTAL_CRAWLED_PAGES,
    TOTAL_INDEXABLE_PAGES,
    TOTAL_NON_INDEXABLE_PAGES,
    CODES_BREAKDOWN_SUMMARY,
    LOAD_TIME_BREAKDOWN_SUMMARY,
    CODES_BY_PAGE_GROUP_SUMMARY,
    LOAD_TIME_BY_PAGE_GROUP_SUMMARY,
  ],
};

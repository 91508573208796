import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const DOMAIN = {
  '@id': 'domain',
  id: 'domain',
  name: 'sidebar.logs.dashboard.host',
  description: null,
  project: '/api/projects/131',
  questions: [
    {
      '@id': 'pages_crawled_by_domainType',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_domainType.title',
      description: null,
      x: 0,
      y: 0,
      w: 4,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['domainType'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'domainType',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'pie',
        colorFunction: 'getThemeColor',
        options: {
          responsive: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              align: 'center',
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelCalcPercentCallback,
              },
            },
          },
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_domainType_day',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_domainType_day.title',
      description: null,
      x: 4,
      y: 0,
      w: 8,
      h: 10,
      query: {
        table: 'logs',
        dimensions: ['datetime', 'domainType'],
        dimensionsFunctions: [{ function: 'FORMAT_DATETIME("%%d-%%m-%%Y", DATETIME_TRUNC(%s, DAY))' }],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'datetime',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getThemeColor',

        getDatasetsCallback: function(rows) {
          const backgroundColor = ['#1976d2', '#4caf50', '#dc004e'];

          // get labels.
          const labels = rows.map(obj => obj.datetime).filter((v, i, a) => a.indexOf(v) === i);

          // get domainTypes.
          const domainTypes = rows
            .map(obj => obj.domainType)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over domain types.
          // a dataset will be added by domain type.
          const datasetsSetup = domainTypes.map((domainType, i) => ({
            label: domainType,
            data: dataWithEmptyValues,
            backgroundColor: backgroundColor[i],
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.datetime);
            const iDataset = domainTypes.indexOf(arr.domainType);

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },

        getLabelsCallback: function(rows) {
          return rows.map(obj => obj.datetime).filter((v, i, a) => a.indexOf(v) === i);
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const day = chartData.labels[element[0].index]; // '27-04-2022'
          const domainType = chartData.datasets[element[0].datasetIndex].label;

          // convert date from DD-MM-YYYY to YYYY-MM-DD
          const dayUsFormat = day
            .split('-')
            .reverse()
            .join('-');

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.domainType',
                dimension: 'domainType',
                operator: 'equals',
                values: [domainType],
              },
            ],
            startDate: dayUsFormat,
            endDate: dayUsFormat,
          };
        },

        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'datetime',
          datasetsDimension: 'domainType',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
    {
      '@id': 'pages_crawled_by_domain',
      name: 'bundles.logAnalyzer.chart.pages_crawled_by_domain.title',
      description: null,
      x: 0,
      y: 10,
      w: 12,
      h: 8,
      query: {
        table: 'logs',
        dimensions: ['datetime', 'domain'],
        dimensionsFunctions: [{ function: 'FORMAT_DATETIME("%%d-%%m-%%Y", DATETIME_TRUNC(%s, DAY))' }],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count'],
        filters: [],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'asc',
        orderBy: 'datetime',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'bar',
        colorFunction: 'getThemeColor',

        getDatasetsCallback: function(rows) {
          const backgroundColor = ['#1976d2', '#4caf50', '#dc004e'];

          // get labels.
          const labels = rows.map(obj => obj.datetime).filter((v, i, a) => a.indexOf(v) === i);

          // get domains.
          const domains = rows
            .map(obj => obj.domain)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();

          const dataWithEmptyValues = Array(labels.length).fill(0);

          // iterate over domains.
          // a dataset will be added by domain.
          const datasetsSetup = domains.map((domain, i) => ({
            label: domain,
            data: dataWithEmptyValues,
            backgroundColor: backgroundColor[i],
          }));

          // clone it.
          let datasets = JSON.parse(JSON.stringify(datasetsSetup));

          // set count into it.
          rows.map(arr => {
            const iLabel = labels.indexOf(arr.datetime);
            const iDataset = domains.indexOf(arr.domain);

            datasets[iDataset].data[iLabel] = parseInt(arr.count);

            return true;
          });

          return datasets;
        },

        getLabelsCallback: function(rows) {
          return rows.map(obj => obj.datetime).filter((v, i, a) => a.indexOf(v) === i);
        },

        getDataExplorerTableAndFilters: function(element, chartData) {
          const day = chartData.labels[element[0].index]; // '27-04-2022'
          const domain = chartData.datasets[element[0].datasetIndex].label;

          // convert date from DD-MM-YYYY to YYYY-MM-DD
          const dayUsFormat = day
            .split('-')
            .reverse()
            .join('-');

          return {
            schemaName: 'logs',
            columns: ['datetime', 'bot', 'url', 'code'],
            filters: [
              {
                label: 'dimension.logs.domain',
                dimension: 'domain',
                operator: 'equals',
                values: [domain],
              },
            ],
            startDate: dayUsFormat,
            endDate: dayUsFormat,
          };
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              align: 'center',
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        containerOptions: {
          style: {
            height: 280,
          },
        },
        extra: {
          stacked: true,
          labelDimension: 'datetime',
          datasetsDimension: 'domain',
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
  ],
};

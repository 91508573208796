import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { openDatePicker, closeDatePicker, selectDateRange } from 'bundles/logAnalyzer/LogsExplorer/actions';
import { DateRangePicker } from '@matharumanpreet00/react-daterange-picker';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { getProjectModuleByCode } from 'services/api/MyApiUtil';
import { useDispatch, useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDateRangePicker = props => {
  const { openDateRangePicker } = useSelector(({ logsExplorer }) => logsExplorer);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let minDate = null;
  let maxDate = null;

  const startDateParameterName = 'startDate';
  const endDateParameterName = 'endDate';
  const projectId = props.projectId;

  /**
   * Triggered on location.pathname changed.
   */
  useEffect(() => {
    console.debug('[AppDateRangePicker] location.pathname changed.');

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const refresh = () => {
    getProjectModuleByCode(projectId, 'log_analyzer')
      .then(projectModule => {
        /**
         * Block dates in the date range picker that do not contain any data
         */
        minDate = new Date(projectModule.periodStart);
        maxDate = new Date(projectModule.periodStop);

        const urlParams = new URLSearchParams(location.search);

        if (!urlParams.has(startDateParameterName) || !urlParams.has(endDateParameterName)) {
          /**
           * if startDate and endDate are not filled then calculate their default values
           */
          let newStartDate = moment(maxDate).subtract(30, 'days');

          if (minDate.getTime() > newStartDate.toDate().getTime()) {
            // the first data is less than 30 days old,
            // set the start date with the date of the first data.
            newStartDate = minDate;
          } else {
            newStartDate = newStartDate.toDate();
          }

          handleDateSelect({
            startDate: newStartDate,
            endDate: maxDate,
          });
        }
      })
      .catch(function(error) {});
  };

  const formatDate = d => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const handleClose = () => {
    dispatch(closeDatePicker());
  };

  const handleDateChange = () => {
    dispatch(openDatePicker());
  };

  const handleDateSelect = range => {
    const urlParams = new URLSearchParams(location.search);

    urlParams.set('startDate', formatDate(range.startDate));
    urlParams.set('endDate', formatDate(range.endDate));

    history.push({
      pathname: location.pathname,
      search: '?' + urlParams.toString(),
    });

    dispatch(selectDateRange(formatDate(range.startDate), formatDate(range.endDate)));

    if (props.onDateChange !== null) {
      props.onDateChange();
    }
  };

  const urlParams = new URLSearchParams(location.search);
  let label = '';

  if (urlParams.has(startDateParameterName) && urlParams.has(endDateParameterName)) {
    label = urlParams.get(startDateParameterName) + ' - ' + urlParams.get(endDateParameterName);
  }

  return (
    <div className={'mb-4'}>
      <Dialog
        open={openDateRangePicker}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DateRangePicker open={openDateRangePicker} onChange={handleDateSelect} minDate={minDate} maxDate={maxDate} />
      </Dialog>

      <div align={'right'}>
        <TextField
          id="startDate"
          name="startDate"
          value={label}
          onClick={handleDateChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateRangeIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AppDateRangePicker;

import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const SeoTags = props => {
  return (
    <React.Fragment>
      <Box mb={GRID_SPACING}>
        <CmtCard>
          <CmtCardHeader title={`Title`} />
          <CmtCardContent>{props.data.content.title}</CmtCardContent>
        </CmtCard>
      </Box>

      <Box mb={GRID_SPACING}>
        <CmtCard>
          <CmtCardHeader title={`Meta description`} />
          <CmtCardContent>{props.data.content.description}</CmtCardContent>
        </CmtCard>
      </Box>

      <CmtCard>
        <CmtCardHeader title={`Heading tags (${props.data.content.heading_titles.length})`} />
        <CmtCardContent>
          <Table>
            <TableBody>
              {props.data.content.heading_titles.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{row.tagName}</TableCell>
                  <TableCell>{row.text}</TableCell>
                  <TableCell>{row.html}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CmtCardContent>
      </CmtCard>
    </React.Fragment>
  );
};

SeoTags.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SeoTags;

import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const H1_PRESENCE = {
  '@id': 'H1_PRESENCE',
  name: 'bundles.crawl.chart.h1_presence.title',
  description: null,
  x: 0,
  y: 8,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['checks_has_h1'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [0, 0];
      let backgroundColor = ['#4caf50', '#dc004e'];

      rows.map(arr => {
        if (true === arr['checks_has_h1']) {
          data[0] = arr.count;
        } else {
          data[1] = arr.count;
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },

    getLabelsCallback: function(rows) {
      return ['Exactly 1', 'No h1'];
    },

    getDataExplorerTableAndFilters: function(element, chartData) {
      const value = chartData.labels[element[0].index];
      console.debug('value:');
      console.debug(value);

      const labels = ['Exactly 1', 'No h1'];
      const codes = [true, false];

      const i = labels.indexOf(value);
      const checks_has_h1 = codes[i];

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'content_h1', 'content_h1_chars_count', 'response_firstStatusCode'],
        filters: [
          {
            label: 'dimension.crawled_pages.checks_is_html',
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.checks_was_redirected',
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
          {
            label: 'dimension.crawled_pages.checks_has_h1',
            dimension: 'checks_has_h1',
            operator: 'equals',
            values: [checks_has_h1],
          },
        ],
      };
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Grid } from '@material-ui/core';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import BillingTabs from '../../components/BillingTabs';
import { handleGetSubscriptions } from '../actions';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';
import SubscriptionCard from '../components/SubscriptionCard';

const ProductsPage = () => {
  const history = useHistory();
  const { setBreadcrumb } = useContext(AppContext);
  const { authUser } = useSelector(({ auth }) => auth);
  const { subscriptions } = useSelector(({ changePlan }) => changePlan);

  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumb([{ text: <IntlMessages id="sidebar.billing" /> }, { text: <IntlMessages id="sidebar.subscriptions" /> }]);

    dispatch(handleGetSubscriptions(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let hasNotCanceledSubscription = false;

  subscriptions.data.map(subscription => {
    if (
      'canceled' !== subscription.status &&
      'incomplete_expired ' !== subscription.status &&
      'unpaid' !== subscription.status
    ) {
      hasNotCanceledSubscription = true;
    }

    return true;
  });

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={12}>
          <BillingTabs tabActive={'/billing/subscriptions'} />
        </Grid>

        <Grid item md={12}>
          {!hasNotCanceledSubscription && subscriptions.data.length > 0 && (
            <ListEmptyResult
              title={<IntlMessages id="bundles.subscription.noActiveSubscriptionPrompt.title" />}
              content={<IntlMessages id="bundles.subscription.noActiveSubscriptionPrompt.text" />}
              actionTitle={<IntlMessages id="bundles.Subscription.action.subscribePlan" />}
              onClick={() => {
                history.push('/products');
              }}
            />
          )}

          {subscriptions.data.length < 1 && (
            <ListEmptyResult
              title={<IntlMessages id="bundles.subscription.emptyPrompt.title" />}
              content={<IntlMessages id="bundles.subscription.emptyPrompt.text" />}
              actionTitle={<IntlMessages id="bundles.Subscription.action.subscribePlan" />}
              onClick={() => {
                history.push('/products');
              }}
            />
          )}
        </Grid>

        {subscriptions.data.map(subscription => (
          <Grid key={subscription.id} item md={12}>
            <SubscriptionCard subscription={subscription} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductsPage;

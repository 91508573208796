import { querySql } from 'services/api/MyApiUtil';

export const getUrlData = async (projectId, hiddenFilters = [], url) => {
  return querySql(
    projectId,
    'crawled_pages',
    [],
    [],
    [],
    null,
    [],
    [
      {
        dimension: 'url',
        operator: 'equals',
        values: [url],
      },
      ...hiddenFilters,
    ],
    [],
    {},
    1,
    0,
    1,
  );
};

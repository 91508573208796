import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box, Grid, Paper, Typography, FormControl } from '@material-ui/core';

import {
  handleGetSegmentationProfiles,
  handleGetSegmentationProfile,
  handleClearSegmentationProfile,
  handleCreateSegmentationProfile,
  handleUpdateSegmentationProfile,
} from '../actions';

import SegmentProfileForm from '../form/SegmentProfileForm';

const SegmentationIndex = props => {
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const { segmentationProfileList, segmentationProfile } = useSelector(({ segmentation }) => segmentation);
  const { setBreadcrumb } = useContext(AppContext);

  // profile selector
  const [profilesValue, setProfilesValue] = useState(null);
  const [profilesOptions, setProfilesOptions] = useState([]);

  const dispatch = useDispatch();

  /**
   * Refresh values in the Profile Selector when:
   *
   * - a Profile is selected
   *
   * @param newValue
   * @param actionMeta
   */
  const handleChange = (newValue, actionMeta) => {
    if (null === newValue) {
      setProfilesValue(null);
      // clear profile (will create a new profile after save)
      dispatch(handleClearSegmentationProfile());
    } else {
      setProfilesValue(newValue);
      // change profile
      dispatch(handleGetSegmentationProfile(newValue.value));
    }
  };

  /**
   * Create, update Profile
   *
   * @param authUser
   * @param values
   */
  const handleSubmit = (authUser, values) => {
    if (segmentationProfile && segmentationProfile.id) {
      // update profile
      dispatch(handleUpdateSegmentationProfile(segmentationProfile.id, project.id, values));
    } else {
      // create profile
      dispatch(handleCreateSegmentationProfile(authUser, project.id, values));
    }
  };

  useEffect(() => {
    dispatch(
      handleGetSegmentationProfiles(project.id, {
        order: {
          name: 'asc',
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  /**
   * Refresh Profile list and
   * Refresh value in the Profile Selector when:
   *
   * - a Profile is created
   * - a Profile is updated
   * - a Profile is deleted
   * - a Profile is selected
   */
  useEffect(() => {
    dispatch(
      handleGetSegmentationProfiles(project.id, {
        order: {
          name: 'asc',
        },
      }),
    );

    if (segmentationProfile && segmentationProfile.id) {
      setProfilesValue({
        value: segmentationProfile.id,
        label: segmentationProfile.name,
      });

      //dispatch(handleCountUrlBySegement(project.id, 1, segmentationProfile));

      setBreadcrumb([
        {
          to: '/segmentation',
          text: <IntlMessages id="bundles.segmentation.routes.create.title" />,
        },
        { text: segmentationProfile.name },
      ]);
    } else {
      setProfilesValue(null);

      setBreadcrumb([{ text: <IntlMessages id="bundles.segmentation.routes.create.title" /> }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentationProfile]);

  useEffect(() => {
    let options = [];
    for (const obj of segmentationProfileList['hydra:member']) {
      options.push({
        value: obj.id,
        label: obj.name,
      });
    }

    setProfilesOptions(options);
  }, [segmentationProfileList]);

  return (
    <div className="app-wrapper">
      <Box mb={GRID_SPACING}>
        <Grid container spacing={GRID_SPACING} alignContent={'center'} alignItems={'center'} justify={'flex-start'}>
          <Grid item>
            <Typography variant="h1">
              <IntlMessages id="bundles.segmentation.routes.create.title" />
            </Typography>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: 220 }}>
              <Select
                placeholder={'Default profile'}
                defaultValue={segmentationProfile.id || null}
                value={profilesValue}
                isClearable={true}
                //isLoading={crawlProfilesListLoading}
                options={profilesOptions}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Paper>
        <Box p={6}>
          <SegmentProfileForm
            id={segmentationProfile.id || null}
            projectId={project.id}
            data={{
              name: segmentationProfile.name || 'Default profile',
              rules: segmentationProfile.rules || [{ name: '', pattern: '' }],
            }}
            onSubmit={handleSubmit}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default SegmentationIndex;

import { tooltipLabelCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const NON_INDEXABLE_CANONICAL_BY_GROUP = {
  '@id': 'NON_INDEXABLE_CANONICAL_BY_GROUP',
  name: 'bundles.crawl.chart.non_indexable_canonical_by_group.title',
  description: null,
  x: 0,
  y: 12,
  w: 12,
  h: 8,
  query: {
    table: 'canonicals',
    dimensions: ['category', 'indexable'],
    dimensionsFunctions: [],
    groupBy: ['category'],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['countUniqueCanonicalUrl'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let indexableData = [];
      let nonIndexableData = [];
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        indexableData.push(0);
        nonIndexableData.push(0);

        return true;
      });

      rows.map(arr => {
        const i = labels.indexOf(arr.category);

        if (arr.indexable) {
          indexableData[i] = arr.countUniqueCanonicalUrl;
        } else {
          nonIndexableData[i] = arr.countUniqueCanonicalUrl;
        }

        return true;
      });

      labels.map((label, i) => {
        const total = indexableData[i] + nonIndexableData[i];

        indexableData[i] = (indexableData[i] / total) * 100;
        nonIndexableData[i] = (nonIndexableData[i] / total) * 100;

        return true;
      });

      datasets = [
        {
          label: 'Indexable canonical pages',
          data: indexableData,
          backgroundColor: '#4caf50',
        },
        {
          label: 'Non-indexable canonical pages',
          data: nonIndexableData,
          backgroundColor: '#dc004e',
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.category);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const category = chartData.labels[element[0].index]; // 'Homepage'
      let indexable = chartData.datasets[element[0].datasetIndex].label; // 'Indexable canonical pages'

      const labels = ['Indexable canonical pages', 'Non-indexable canonical pages'];

      const codes = [true, false];

      const i = labels.indexOf(indexable);
      indexable = codes[i];

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.canonicals.category',
        dimension: 'category',
        operator: 'equals',
        values: [category],
      });
      filters.push({
        label: 'dimension.canonicals.indexable',
        dimension: 'indexable',
        operator: 'equals',
        values: [indexable],
      });

      return {
        schemaName: 'canonicals',
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            callback: function(value, index, values) {
              return value + '%';
            },
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'indexable',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiRocketLaunch } from '@mdi/js';

import HeaderPageSupplements from 'components/HeaderPageSupplements';

import CrawlList from '../components/CrawlList';
import FilteringContainer from '../../logAnalyzer/LogsFilters/components/FilteringContainer';
import ChooseFilters from 'bundles/logAnalyzer/LogsFilters/components/Drawer/ChooseFilters';
import { deleteCrawl, getCrawlList, stopCrawl } from 'services/api/MyApiUtil';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import ActiveFiltersAndAddFilterButton from '../../logAnalyzer/LogsFilters/components/ActiveFiltersAndAddFilterButton';

const REFRESH_INTERVAL = 3000;

const schema = [
  {
    dimension: 'id',
    label: 'id',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'BasicFilter',
  },
];

const CrawlIndex = props => {
  return (
    <FilteringContainer schema={schema}>
      <CrawlIndexChild {...props} />
    </FilteringContainer>
  );
};

const CrawlIndexChild = props => {
  const history = useHistory();
  const { setBreadcrumb } = useContext(AppContext);
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  // crawls list
  const [loading, setLoading] = useState(false);
  const [crawlsList, setCrawlsList] = useState({
    'hydra:member': [],
  });
  // sorting
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');
  // auto refresh
  let autoRefreshInterval = useRef(null);
  // duration counters
  const [durationValues, setDurationValues] = useState({});
  const [durationIntervalActive, setDurationIntervalActive] = useState(false);
  let durationInterval = useRef(null);

  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(props.location.search);

  //  useEffect(() => {}, [durationValues]);

  /**
   * Start interval and get crawls list
   */
  useEffect(() => {
    console.debug('useEffect() deps[project, order, orderBy, props.filters]');
    setBreadcrumb([{ text: <IntlMessages id="bundles.crawl.routes.index.title" /> }]);

    // refresh list if the project is set
    if (project && project.id) {
      dispatch(fetchStart());
      clearIntervals();
      refresh();
      autoRefreshInterval.current = setInterval(refresh, REFRESH_INTERVAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, order, orderBy, props.filters]);

  /**
   * Called when the Crawls list is updated
   */
  useEffect(() => {
    let newDurationValues = durationValues;

    for (let i = 0; i < crawlsList['hydra:member'].length; i++) {
      const obj = crawlsList['hydra:member'][i];

      if ('running' === obj.status) {
        if (!durationValues.hasOwnProperty(obj.id.toString())) {
          let value = (moment().format('x') - moment(obj.crawlStartedAt).format('x')) / 1000;

          newDurationValues[obj.id.toString()] = parseInt(value);

          setDurationValues(newDurationValues);
        }
      }
    }

    if (!durationIntervalActive && crawlsList['hydra:member'].length > 0) {
      setDurationIntervalActive(true);

      durationInterval.current = setInterval(async () => {
        let newDurationValues = durationValues;

        for (const [key, value] of Object.entries(durationValues)) {
          newDurationValues[key] = value + 1;
        }

        if (Object.keys(newDurationValues.length > 0)) {
          setDurationValues(newDurationValues);
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlsList]);

  /**
   * Refresh Crawls list
   */
  const refresh = () => {
    console.debug('refresh()');
    const orderObj = {};
    orderObj[orderBy] = order;

    let payload = {
      website: project.id,
      order: orderObj,
    };

    for (let i = 0; i < props.filters.length; i++) {
      payload[props.filters[i]['dimension']] = props.filters[i].values.join(', ');
    }

    if (urlParams.has('profile')) {
      payload['profile'] = urlParams.get('profile');
    }

    if (urlParams.has('status')) {
      payload['status'] = urlParams.get('status');
    }

    getCrawlList(payload)
      .then(result => {
        setCrawlsList(result);
        setLoading(true);
        dispatch(fetchSuccess());
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };

  /**
   * sorting
   *
   * @param {string} dimension
   */
  const handleChangeSort = dimension => {
    const isAsc = orderBy === dimension && order === 'asc';

    const newOrderBy = dimension;
    const newOrder = isAsc ? 'desc' : 'asc';

    const orderObj = {};
    orderObj[newOrderBy] = newOrder;

    setOrderBy(newOrderBy);
    setOrder(newOrder);
  };

  /**
   * Delete a Crawl
   *
   * @param {number} id
   */
  const handleDelete = id => {
    console.debug('handleDelete()');
    dispatch(fetchStart());

    deleteCrawl(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawl.notification.deleted.success', 'success'));
        // refresh list
        refresh();
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };

  /**
   * Stop a Crawl
   *
   * @param {number} id
   */
  const handleStop = id => {
    console.debug('handleStop()');
    dispatch(fetchStart());

    stopCrawl(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawl.notification.stop.success', 'success'));
        // refresh list
        refresh();
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };

  /**
   * Clear intervals
   */
  const clearIntervals = () => {
    clearInterval(autoRefreshInterval.current);
    clearInterval(durationInterval.current);
  };

  /**
   * Anything in here is fired on component unmount
   */
  useEffect(() => {
    return () => {
      clearIntervals();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item lg={3}>
          <Typography variant="h1">
            <IntlMessages id="bundles.crawl.routes.index.title" />
          </Typography>
        </Grid>

        <Grid item lg={9}>
          <HeaderPageSupplements
            numbers={[
              {
                label: <IntlMessages id="bundles.crawl.global.crawlsCount" />,
                number: crawlsList['hydra:totalItems'] || 0,
              },
            ]}
            actions={[
              <Link to={'/crawl_schedules/create?projectId=' + project.id}>
                <Button color="primary" variant={'contained'}>
                  <IntlMessages id="bundles.crawlSchedule.action.create" />
                </Button>
              </Link>,
              <Link to={'/crawl_profiles/create?projectId=' + project.id}>
                <Button color="primary" variant={'contained'}>
                  <Box mr={2} clone>
                    <Icon path={mdiRocketLaunch} size={1} />
                  </Box>
                  <IntlMessages id="bundles.crawl.action.create" />
                </Button>
              </Link>,
            ]}
          />
        </Grid>

        <Grid md={12} item>
          <ActiveFiltersAndAddFilterButton
            filters={props.filters}
            handleDeleteFilter={props.handleDeleteFilter}
            handleEditFilter={props.handleEditFilter}
            handleOpenFilteringDialog={props.handleOpenFilteringDialog}
            hideAddButton={true}
          />
        </Grid>

        <Grid md={12} item>
          {loading && (
            <React.Fragment>
              {crawlsList['hydra:member'].length === 0 ? (
                <ListEmptyResult
                  title={<IntlMessages id="bundles.crawl.emptyPrompt.title" />}
                  content={<IntlMessages id="bundles.crawl.emptyPrompt.text" />}
                  actionTitle={<IntlMessages id="bundles.crawl.action.create" />}
                  onClick={() => {
                    history.push('/crawl_profiles/create?projectId=' + project.id);
                  }}
                />
              ) : (
                <Paper>
                  <CrawlList
                    crawls={crawlsList}
                    order={order}
                    orderBy={orderBy}
                    durationValues={durationValues}
                    handleChangeSort={handleChangeSort}
                    handleDelete={handleDelete}
                    handleStop={handleStop}
                  />
                </Paper>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Grid>

      <ChooseFilters
        projectId={project.id}
        name={'filter'}
        title={<IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.filter.title" />}
        open={props.filtersOpen}
        schema={schema}
        schemaName={null}
        addFilter={props.addFilter}
        addFilterSchema={props.addFilterSchema}
        editedFilter={props.editedFilter}
        startDate={''}
        endDate={''}
        filters={props.filters}
        handleCancel={props.handleCloseFilteringDialog}
        handleAddFilter={props.handleAddFilter}
        handleCancelAddFilter={props.handleCancelAddFilter}
        handleSaveFilter={props.handleSaveFilter}
      />
    </div>
  );
};

CrawlIndex.whyDidYouRender = {
  logOnDifferentValues: true,
};

export default CrawlIndex;

import React, { useEffect, useContext } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Grid, Paper, Typography } from '@material-ui/core';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import UsageList from '../components/UsageList';
import BillingTabs from '../../components/BillingTabs';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetUsage } from '../actions';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

const UsageIndex = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { usage } = useSelector(({ usage }) => usage);
  const { subscriptions } = useSelector(({ changePlan }) => changePlan);
  const { setBreadcrumb } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumb([
      { text: <IntlMessages id="sidebar.billing" /> },
      { text: <IntlMessages id="bundles.usage.routes.index.title" /> },
    ]);

    dispatch(handleGetUsage(authUser['@id']));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = () => {
    dispatch(handleGetUsage(authUser['@id']));
  };

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={3}>
          <Typography variant="h1" gutterBottom>
            <IntlMessages id="bundles.usage.routes.index.title" />
          </Typography>

          <p>
            <IntlMessages id="bundles.usage.routes.index.subtitle" />
          </p>
        </Grid>

        <Grid item md={9}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item md={12}>
              <BillingTabs tabActive={'/billing/usage'} />
            </Grid>

            <Grid item md={12}>
              <Box>
                <Tooltip title={<IntlMessages id={'generic.action.refresh.title'} />}>
                  <IconButton aria-label="refresh" onClick={handleRefresh}>
                    <RefreshIcon color={'primary'} fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>

              <Paper>
                <UsageList usage={usage} subscriptions={subscriptions} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsageIndex;

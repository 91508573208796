import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';

import moment from 'moment';

const ExportResultModal = props => {
  // avoid error after deployment
  if (props.data === undefined) {
    return null;
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h2">
          {'running' === props.status ? (
            <IntlMessages id="bundles.LogsExplorer.component.exportResultModal.title.running" />
          ) : (
            <IntlMessages id="bundles.LogsExplorer.component.exportResultModal.title.finished" />
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {'running' === props.status ? (
            <Box color="text.secondary" justifyContent="center" p={2}>
              <CircularProgress size={16} />
              <Box component="span" ml={2}>
                Loading...
              </Box>
            </Box>
          ) : (
            <React.Fragment>
              <Box mb={3}>
                <IntlMessages id="bundles.LogsExplorer.component.exportResultModal.content.1" />
              </Box>

              <Box mb={3}>
                <a href={props.data.signedUrl} target="_blank" rel="noopener noreferrer">
                  <IntlMessages id="bundles.LogsExplorer.component.exportResultModal.action.download" />
                </a>
              </Box>
              <Box>
                <IntlMessages
                  id="bundles.LogsExplorer.component.exportResultModal.content.2"
                  values={{
                    expiration: moment(props.data.expiration).format('LT'),
                  }}
                />
              </Box>
            </React.Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          <IntlMessages id="generic.text.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExportResultModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  status: PropTypes.string,
  handleClose: PropTypes.func,
};

export default ExportResultModal;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { getProjectModuleByCode, detectLogs } from 'services/api/MyApiUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { Box } from '@material-ui/core';

class SendLogFtp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectModule: {
        options: [],
      },
      logsCount: 0,
      interval: null,
    };

    this.checkLogs = this.checkLogs.bind(this);
  }

  async componentDidMount() {
    //this.props.handleDisableNextStep();

    this.refresh();

    this.interval = setInterval(this.checkLogs, 3000);
  }

  async refresh() {
    //this.props.store.dispatch(fetchStart());

    const projectId = this.props.projectId;

    try {
      if (projectId) {
        const projectModule = await getProjectModuleByCode(projectId, 'log_analyzer');

        this.setState({
          projectModule: projectModule,
        });
      }
    } catch (e) {
      this.setState(() => {
        throw e;
      });
    }

    //this.props.store.dispatch(fetchSuccess());
  }

  async checkLogs() {
    const projectId = this.props.projectId;

    const result = await detectLogs(projectId);
    const logsCount = result['hydra:totalItems'];

    this.setState({
      logsCount: logsCount,
    });

    if (logsCount > 0) {
      //this.props.handleEnableNextStep();

      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box mb={6}>
            <h3>Steps</h3>
          </Box>

          <Box pl={4}>
            <ol>
              <li>Connect to FTP with your credentials.</li>
              <li>Upload one or more files into that directory.</li>
            </ol>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box mb={6}>
            <h3>FTP credentials</h3>
          </Box>

          <Box pl={4}>
            <ul>
              <li>
                Server: <strong>import.seo-logs.com</strong>
              </li>
              <li>
                Port: <strong>21</strong>
              </li>
              <li>
                Username: <strong>yfrelicot</strong>
              </li>
              <li>
                Password: <strong>your FTP password sent by email</strong>
              </li>
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} align={'center'}>
          <Box mb={6}>
            <strong>Please upload compressed and filtered files</strong>
          </Box>

          {this.state.logsCount > 0 ? (
            <CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
          ) : (
            <CircularProgress />
          )}

          <Box mb={6}>
            <strong>{this.state.logsCount}</strong> files received
          </Box>

          <p className="mb-0">Supported compressed formats: text, zip, gz, 7z</p>
        </Grid>
      </Grid>
    );
  }
}

export default SendLogFtp;

export const ROOT_FIELDS = [
  {
    dimension: 'url',
    label: 'dimension.crawled_pages.url',
    type: 'string',
    visible: true,
    filterable: true,
    filterComponent: 'BasicFilter',
  },
  {
    dimension: 'path',
    label: 'dimension.crawled_pages.path',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'BasicFilter',
  },
  {
    dimension: 'category',
    label: 'dimension.crawled_pages.category',
    type: 'string',
    visible: true,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'depth',
    label: 'dimension.crawled_pages.depth',
    type: 'number',
    visible: true,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'indexable',
    label: 'dimension.crawled_pages.indexable',
    type: 'boolean',
    visible: true,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'indexable_reason',
    label: 'dimension.crawled_pages.indexable_reason',
    type: 'string',
    visible: true,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'checks_has_canonical',
    label: 'dimension.crawled_pages.checks_has_canonical',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'checks_has_ssl',
    label: 'dimension.crawled_pages.checks_has_ssl',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'checks_was_redirected',
    label: 'dimension.crawled_pages.checks_was_redirected',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'checks_is_html',
    label: 'dimension.crawled_pages.checks_is_html',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'request_scheme',
    label: 'dimension.crawled_pages.request_scheme',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'request_host',
    label: 'dimension.crawled_pages.request_host',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'request_subdomain',
    label: 'dimension.crawled_pages.request_subdomain',
    type: 'string',
    visible: false,
    filterComponent: 'AutomaticChoicesFilter',
    filterable: true,
  },
  {
    dimension: 'response_contentType',
    label: 'dimension.crawled_pages.response_contentType',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'response_firstStatusCode',
    label: 'dimension.crawled_pages.response_firstStatusCode',
    type: 'number',
    visible: true,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'response_statusCode',
    label: 'dimension.crawled_pages.response_statusCode',
    type: 'number',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'content_meta_robots',
    label: 'dimension.crawled_pages.content_meta_robots',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
  {
    dimension: 'content_internal_links_count',
    label: 'dimension.crawled_pages.content_internal_links_count',
    type: 'number',
    visible: false,
    filterable: true,
    filterComponent: 'BasicFilter',
  },
  {
    dimension: 'content_external_links_count',
    label: 'dimension.crawled_pages.content_external_links_count',
    type: 'number',
    visible: false,
    filterable: true,
    filterComponent: 'BasicFilter',
  },
  {
    dimension: 'totalLoadTimeString',
    label: 'dimension.crawled_pages.totalLoadTimeString',
    type: 'string',
    visible: false,
    filterable: true,
    filterComponent: 'AutomaticChoicesFilter',
  },
];

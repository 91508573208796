export const LINKS_FLOW_SCHEMA = {
  table: 'links_flow',
  fields: [
    {
      dimension: 'categorySource',
      label: 'dimension.links_flow.categorySource',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'categoryDestination',
      label: 'dimension.links_flow.categoryDestination',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'rel',
      label: 'dimension.links_flow.rel',
      type: 'string',
      visible: true,
      filterable: true,
      filterComponent: 'AutomaticChoicesFilter',
    },
    {
      dimension: 'nbLinks',
      label: 'dimension.links_flow.nbLinks',
      type: 'number',
      visible: true,
      filterable: true,
      filterComponent: 'BasicFilter',
    },
  ],
  order: null,
  orderBy: null,
  hasTimeDimension: false,
};

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Tooltip from '@material-ui/core/Tooltip';

import AddButton from '../AddButton';
import BaseFilter from './BaseFilter';
import AutocompleteField from '../AutocompleteField';

const categories = [
  {
    code: '2',
    colorClass: 'bg-green',
  },
  {
    code: '3',
    colorClass: 'bg-amber',
  },
  {
    code: '4',
    colorClass: 'bg-deep-orange',
  },
  {
    code: '5',
    colorClass: 'bg-pink',
  },
];

class CodeFilter extends BaseFilter {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
      totalRequests: null,
      loadData: true,
    };
  }

  render() {
    return (
      <React.Fragment>
        <h3>
          <IntlMessages id="bundles.LogsFilters.components.CodeFilter.title.categories" />
        </h3>

        <ul className="color-option">
          {categories.map(obj => (
            <li>
              <h4 align={'center'}>{obj.code}xx</h4>
              <Tooltip title={<IntlMessages id={'bundles.LogsFilters.components.CodeFilter.tooltip.' + obj.code + 'xx'} />}>
                <span
                  onClick={() => this.handleSelectItem('startsWith', obj.code)}
                  className={`jr-link ${obj.colorClass} ${this.state.operator === 'startsWith' &&
                    this.state.value === obj.code &&
                    'active'}`}
                />
              </Tooltip>
            </li>
          ))}
        </ul>

        <AutocompleteField
          title={<IntlMessages id="bundles.LogsFilters.components.CodeFilter.title.subcategories" />}
          projectId={this.props.projectId}
          dimension={'code'}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          value={this.state.value}
          operator={this.state.operator}
          editedFilter={this.props.editedFilter}
          handleSelectItem={this.handleSelectItem}
        />

        <AddButton
          schema={this.props.schema}
          operator={this.state.operator}
          value={this.state.value}
          editedFilterIndex={this.props.editedFilter ? this.props.editedFilter.index : null}
          handleSaveFilter={this.props.handleSaveFilter}
        />
      </React.Fragment>
    );
  }
}

export default CodeFilter;

import React from 'react';
import PieChartIcon from '@material-ui/icons/PieChart';
import TableChartIcon from '@material-ui/icons/TableChart';
import { mdiChartBar, mdiNumeric } from '@mdi/js';
import Icon from '@mdi/react';

export const CHART_TYPES = [
  {
    type: 'bar',
    icon: <Icon path={mdiChartBar} size={1} />,
    defaultOptions: {
      grouped: false,
      legend: {
        display: true,
        position: 'top',
      },
    },
    disabled: function(props) {
      if (1 === props.measures.length) {
        if (0 === props.dimensions.length || 1 === props.dimensions.length) {
          // not disabled
          return false;
        }
      }
      // disabled
      return true;
    },
  },
  {
    type: 'number',
    icon: <Icon path={mdiNumeric} size={1} />,
    defaultOptions: {},
    disabled: function(props) {
      if (1 === props.measures.length) {
        if (0 === props.dimensions.length) {
          // not disabled
          return false;
        }
      }
      // disabled
      return true;
    },
  },
  {
    type: 'pie',
    icon: <PieChartIcon />,
    defaultOptions: {
      legend: {
        display: true,
        position: 'top',
      },
    },
    disabled: function(props) {
      if (1 === props.measures.length) {
        if (0 === props.dimensions.length || 1 === props.dimensions.length) {
          // not disabled
          return false;
        }
      }
      // disabled
      return true;
    },
  },
  {
    type: 'table',
    icon: <TableChartIcon />,
    defaultOptions: {},
    disabled: function(props) {
      // not disabled
      return false;
    },
  },
];

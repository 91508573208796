import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { NotificationManager } from 'react-notifications';
import React from 'react';

export const fetchSuccess = () => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    if (error) {
      if ('object' === typeof error) {
        NotificationManager.error(error);
      } else {
        NotificationManager.error(<IntlMessages id={error} />);
      }
    }

    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const showMessage = (message, level) => {
  return dispatch => {
    switch (level) {
      case 'success':
        NotificationManager.success(<IntlMessages id={message} />);
        break;
      default:
      //
    }
  };
};

export const hideMessage = () => {
  return dispatch => {
    dispatch({
      type: 'HIDE_MESSAGE',
    });
  };
};

export const redirect = link => {
  return { type: 'REDIRECT', payload: link };
};

export const redirectReset = () => {
  return { type: 'REDIRECT_RESET' };
};

export const updateThemeType2 = type => {
  return { type: 'UPDATE_THEME_TYPE', payload: type };
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { Box } from '@material-ui/core';

const ChartBar = props => {
  const history = useHistory();
  const intl = useIntl();
  let chartData = {
    labels: [],
    datasets: [],
  };

  // get metric data (from prometheus or bigquery)
  let data = null;

  if ('metric' === props.type && props.metric.hasOwnProperty(props.metricId)) {
    data = props.metric[props.metricId];
  } else if (props.rows.hasOwnProperty(props.id)) {
    data = props.rows[props.id];
  }

  let copiedChartData = Object.assign({}, chartData);
  let changed = false;

  if (data && props.visualization.hasOwnProperty('getLabelsCallback') && props.visualization.getLabelsCallback) {
    changed = true;
    copiedChartData.labels = props.visualization.getLabelsCallback(data, intl);
  }

  if (data && props.visualization.hasOwnProperty('getDatasetsCallback') && props.visualization.getDatasetsCallback) {
    changed = true;
    copiedChartData.datasets = props.visualization.getDatasetsCallback(data, intl);
  }

  if (changed) {
    chartData = copiedChartData;
  }

  /**
   * Triggered when the chart is clicked.
   */
  const handleChartClick = element => {
    if (element.length === 0) {
      return;
    }

    let periodQueryString = '';
    let columns = [];
    let filters = [];
    let schemaName = props.schemaName;

    if (
      props.visualization.hasOwnProperty('getDataExplorerTableAndFilters') &&
      props.visualization.getDataExplorerTableAndFilters
    ) {
      const config = props.visualization.getDataExplorerTableAndFilters(element, chartData, intl);

      if (config.hasOwnProperty('columns') && config.columns.length > 0) {
        columns = config.columns;
      }

      if (config.hasOwnProperty('startDate') && config.hasOwnProperty('endDate')) {
        periodQueryString = `&startDate=${config.startDate}&endDate=${config.endDate}`;
      }

      filters = [];
      filters = config.filters;
      schemaName = config.schemaName;
      // schemaName
    } else if (
      props.visualization.hasOwnProperty('extra') &&
      props.visualization.extra.datasetsDimension &&
      props.visualization.extra.labelDimension
    ) {
      const xValue = chartData.labels[element[0].index];
      const yValue = chartData.datasets[element[0].datasetIndex].label;

      if ('datetime' === props.visualization.extra.labelDimension) {
        const date = xValue
          .split('-')
          .reverse()
          .join('-');

        periodQueryString = `&startDate=${date}&endDate=${date}`;
      } else {
        /**
         * no longer used
         */
        filters.push({
          label: `dimension.${schemaName}.${props.visualization.extra.labelDimension}`,
          dimension: props.visualization.extra.labelDimension,
          operator: 'equals',
          values: [xValue],
        });
      }

      /**
       * no longer used
       */
      filters.push({
        label: `dimension.${schemaName}.${props.visualization.extra.datasetsDimension}`,
        dimension: props.visualization.extra.datasetsDimension,
        operator: 'equals',
        values: [yValue],
      });
    }

    let hiddenFilters = [];

    if (props.hiddenFilters.length > 0) {
      // add hidden filters
      props.hiddenFilters.map(obj => {
        obj.label = 'dimension.' + schemaName + '.' + obj.dimension;

        hiddenFilters.push(obj);

        return obj;
      });
    }

    let to =
      '/log_analyzer/explorer?projectId=' +
      props.projectId +
      '&schema=' +
      schemaName +
      '&filters=' +
      JSON.stringify(filters) +
      periodQueryString;

    if (hiddenFilters.length > 0) {
      to += '&hiddenFilters=' + JSON.stringify(hiddenFilters);
    }

    if (columns.length > 0) {
      to += '&columns=' + JSON.stringify(columns);
    }

    history.push(to);
  };

  const id = Math.floor(Math.random() * 100).toString();
  let visualizationOptions = props.visualization.options;

  visualizationOptions['onHover'] = function(element, elements) {
    var el = document.getElementById(id);

    if (!el || !el.style) {
      return;
    }

    if (element.length === 0 || elements.length === 0) {
      el.style.cursor = 'default';

      return;
    }

    el.style.cursor = 'pointer';

    return;
  };

  return (
    <Card className="shadow border-0 mb-0">
      <CardBody>
        <CardTitle>{props.title}</CardTitle>
        <CardText>
          {chartData && (
            <Box mb={0} style={props.visualization.containerOptions && props.visualization.containerOptions.style}>
              <Bar id={id} data={chartData} options={visualizationOptions} getElementAtEvent={handleChartClick} />
            </Box>
          )}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default ChartBar;

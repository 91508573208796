import { LIST_CRAWL_PROFILES, GET_CRAWL_PROFILE, CLEAR_CRAWL_PROFILE, RESET_CRAWL_PROFILES } from '../constants';

const INIT_STATE = {
  crawlProfilesList: {
    'hydra:member': [],
  },
  crawlProfile: {
    name: '',
    settings: {},
  },
  crawlProfilesListLoading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CRAWL_PROFILES: {
      return {
        ...state,
        crawlProfilesList: action.payload,
        crawlProfilesListLoading: false,
      };
    }
    case GET_CRAWL_PROFILE: {
      return {
        ...state,
        crawlProfile: action.payload,
      };
    }
    case CLEAR_CRAWL_PROFILE: {
      return {
        ...state,
        crawlProfile: INIT_STATE.crawlProfile,
      };
    }
    case RESET_CRAWL_PROFILES: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};

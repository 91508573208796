import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

/**
 * This component displays the header row at the top of the crawl list table.
 */
const CrawlListTableHead = props => {
  const columns = [
    { field: 'name', hasHelp: false },
    { field: 'status', hasHelp: false },
    { field: 'crawlEndedAt', hasHelp: false },
    { field: 'duration', hasHelp: false },
    { field: 'pagesCount', hasHelp: true },
  ];

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell key={column.field} sortDirection={props.orderBy === column.field ? props.order : false}>
            <TableSortLabel
              active={props.orderBy === column.field}
              direction={props.orderBy === column.field ? props.order : 'asc'}
              onClick={() => {
                props.handleChangeSort(column.field);
              }}>
              <IntlMessages id={'bundles.crawl.entity.crawl.' + column.field + '.short'} />
              {column.hasHelp && (
                <Box ml={1} clone>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <IntlMessages id={'bundles.crawl.entity.crawl.' + column.field + '.help'} />
                      </React.Fragment>
                    }>
                    <HelpIcon color={'action'} fontSize={'small'} className={'ml-1'} />
                  </Tooltip>
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell colSpan={2} align={'right'} style={{ width: '15%' }}>
          <IntlMessages id="bundles.crawl.global.actions" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

CrawlListTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
};

export default CrawlListTableHead;

import { LIST_DASHBOARDS, GET_DASHBOARD, CLEAR_DASHBOARD } from '../constants';

const INIT_STATE = {
  dashboardsList: {
    'hydra:member': [],
  },
  dashboard: {
    questions: [],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_DASHBOARDS: {
      return {
        ...state,
        dashboardsList: action.payload,
      };
    }
    case GET_DASHBOARD: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }
    case CLEAR_DASHBOARD: {
      return {
        ...state,
        dashboard: INIT_STATE.dashboard,
      };
    }
    default:
      return state;
  }
};

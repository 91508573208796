import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { UPDATE_CUSTOMER } from '../constants';
import { getCustomer, updateCustomer, getTaxIdList, createTaxId, deleteTaxId } from 'services/stripe/StripeUtil';
import { getCountryList } from 'services/api/MyApiUtil';

/**
 * @param id customer ID in Stripe
 * @returns {function(*): void}
 */
export const handleGetCustomer = id => {
  return dispatch => {
    dispatch(fetchStart());

    getCustomer(id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_CUSTOMER,
          payload: data.shipping,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

/**
 * @param id customer ID in Stripe
 * @param values
 * @param onSuccess
 * @returns {function(*): void}
 */
export const handleUpdateCustomer = (id, values, onSuccess) => {
  return async dispatch => {
    dispatch(fetchStart());

    let userCountry = null;

    const countries = await getCountryList({
      code: values.address.country,
    });

    if (countries['hydra:member'].length > 0) {
      userCountry = countries['hydra:member'][0];
    }

    // get vatNumber
    let vatNumber = null;
    if (values.hasOwnProperty('vatNumber')) {
      vatNumber = values.vatNumber;
      delete values.vatNumber;
    }

    const HOME_COUNTRY_CODE = 'FR';

    /**
     * get the customer tax IDs in stripe,
     * it will be useful to know if the VAT number has changed.
     */
    const oldTaxIds = await getTaxIdList(id);

    if (!vatNumber) {
      console.log(`No VAT number entered`);
      if (oldTaxIds.data.length > 0) {
        console.log(`a tax ID exists in stripe, delete it (${oldTaxIds.data[0].id})`);
        await deleteTaxId(id, oldTaxIds.data[0].id);
      } else {
        console.log('no tax ID exists in stripe, nothing to do');
      }
    } else {
      console.log(`VAT number entered (${vatNumber})`);
      if (oldTaxIds.data.length > 0) {
        console.log(`a tax ID exists in stripe (${oldTaxIds.data[0].id})`);
        if (vatNumber !== oldTaxIds.data[0].value) {
          console.log('the entered VAT number has changed');
          console.log(`delete the old tax ID (${oldTaxIds.data[0].id})`);
          await deleteTaxId(id, oldTaxIds.data[0].id);

          console.log('create the new tax ID');
          await createTaxId(id, {
            type: 'eu_vat',
            value: vatNumber,
          });
        }
      } else {
        console.log('no tax ID exists in stripe, nothing to do');
        console.log('create the new tax ID');
        // et on cree le nouveau tax id
        await createTaxId(id, {
          type: 'eu_vat',
          value: vatNumber,
        });
      }
    }

    /**
     * Calculate `tax_exempt`
     */
    let taxExempt = null;

    if (true === userCountry.isEU) {
      if (vatNumber && values.address.country !== HOME_COUNTRY_CODE) {
        // if it is a country in the EU and not from austria, reverse charge applies
        taxExempt = 'reverse';
      } else {
        // b2c in EU or business from our home country, VAT applies normally
        taxExempt = 'none';
      }
    } else {
      // customer from outside the EU, no VAT applies
      taxExempt = 'exempt';
    }

    const payload = {
      shipping: values,
      tax_exempt: taxExempt,
    };

    updateCustomer(id, payload)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.shipping.notification.update.success', 'success'));
        dispatch({
          type: UPDATE_CUSTOMER,
          payload: data.shipping,
        });

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

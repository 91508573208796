import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { GET_USAGE } from '../constants';
import { getUsageList } from 'services/api/MyApiUtil';

export const handleGetUsage = userId => {
  return dispatch => {
    dispatch(fetchStart());

    const payload = {
      user: userId,
    };

    getUsageList(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_USAGE,
          payload: result['hydra:member'][0],
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import MyDrawer from 'components/Drawer';
import AddIcon from '@material-ui/icons/Add';
import { GRANULARITY_CHOICES, GRANULARITY_DEFAULT } from '../../bundles/logAnalyzer/LogsExplorer/constants';
import MeasuresListGrouped from './MeasuresList';

const getDefaultDimensionFunction = dimensionObj => {
  if ('datetime' === dimensionObj.type) {
    return GRANULARITY_CHOICES[GRANULARITY_DEFAULT];
  }

  return null;
};

/**
 * props required:
 *
 * - name
 * - title
 * - open
 * - schema
 * - values (selected groupBy [val1, val2, ...])
 * - handleAddMeasure
 * - handleAddGroupBy
 * - handleDeleteGroupBy
 * - handleCancel
 */
class ChooseMeasures extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openMeasuresChoices: null,
      openGranularityChoices: null,
    };

    this.handleOpenMeasuresChoices = this.handleOpenMeasuresChoices.bind(this);
    this.handleCloseMeasuresChoices = this.handleCloseMeasuresChoices.bind(this);
    this.handleOpenGranularityChoices = this.handleOpenGranularityChoices.bind(this);
    this.handleCloseGranularityChoices = this.handleCloseGranularityChoices.bind(this);
    this.handleAddMeasure = this.handleAddMeasure.bind(this);
    this.handleAddGroupBy = this.handleAddGroupBy.bind(this);
  }

  handleOpenMeasuresChoices(e) {
    this.setState({
      openMeasuresChoices: e.currentTarget,
    });
  }

  handleCloseMeasuresChoices() {
    this.setState({
      openMeasuresChoices: null,
    });
  }

  handleOpenGranularityChoices(e) {
    e.stopPropagation();

    this.setState({
      openGranularityChoices: e.currentTarget,
    });
  }

  handleCloseGranularityChoices() {
    this.setState({
      openGranularityChoices: null,
    });
  }

  handleAddGroupBy(dimension, extraObj = null) {
    this.setState({
      openGranularityChoices: null,
    });

    this.props.handleAddGroupBy(dimension, extraObj);
  }

  handleAddMeasure(measure) {
    this.setState({
      openMeasuresChoices: null,
    });

    this.props.handleAddMeasure(measure);
  }

  render() {
    const measuresChoices = [
      {
        label: 'Count',
        value: 'count',
      },
    ];

    // show/hide `groupBy` block
    let canGroupBy = false;

    if (this.props.measuresActives.indexOf('count') !== -1) {
      canGroupBy = true;
    }

    return (
      <MyDrawer
        open={this.props.open}
        withHeader={true}
        headerTitle={this.props.title}
        handleClose={() => {
          this.props.handleCancel(this.props.name);
        }}>
        <Box p={0}>
          {this.props.measuresActives.length > 0 && (
            <React.Fragment>
              <Box p={6} pb={0}>
                <h3>{this.props.title}</h3>
              </Box>

              <List component="nav" aria-labelledby="nested-list-subheader" className={'styled-list'}>
                {this.props.measuresActives.map((measure, i) => (
                  <ListItem
                    key={i}
                    button
                    className={'active'}
                    onClick={() => {
                      this.props.handleDeleteMeasure(measure);
                    }}>
                    <ListItemText primary={measure} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          this.props.handleDeleteMeasure(measure);
                        }}>
                        <CancelIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          )}
        </Box>

        <Box p={6}>
          <Button
            aria-controls="measures-choices"
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={this.handleOpenMeasuresChoices}>
            Add a measure
          </Button>

          <Popover
            id={'measures-choices'}
            open={Boolean(this.state.openMeasuresChoices)}
            anchorEl={this.state.openMeasuresChoices}
            onClose={this.handleCloseMeasuresChoices}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <List component="nav" aria-labelledby="nested-list-subheader">
              {measuresChoices.map((obj, i) => (
                <ListItem
                  key={i}
                  button
                  onClick={() => {
                    this.handleAddMeasure(obj.value);
                  }}>
                  <ListItemText primary={obj.label} />
                </ListItem>
              ))}
            </List>
          </Popover>
        </Box>

        <Divider />

        {true === canGroupBy ? (
          <div>
            <Box p={6} pb={0}>
              <Typography variant="h6" gutterBottom={false}>
                <IntlMessages id={'generic.action.group.title'} />
              </Typography>
            </Box>

            <MeasuresListGrouped
              schema={this.props.schema}
              values={this.props.values}
              handleClickMeasure={obj => {
                if (this.props.values.indexOf(obj.dimension) < 0) {
                  const extraObj = getDefaultDimensionFunction(obj);
                  this.handleAddGroupBy(obj.dimension, extraObj);
                }
              }}
              handleClickAddIcon={obj => {
                const dimensionFunction = getDefaultDimensionFunction(obj);
                this.props.handleAddGroupBy(obj.dimension, dimensionFunction);
              }}
              handleClickDeleteIcon={obj => {
                this.props.handleDeleteGroupBy(obj.dimension);
              }}
            />
          </div>
        ) : null}
      </MyDrawer>
    );
  }
}

export default ChooseMeasures;

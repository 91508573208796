import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultPaymentMethod2, getPaymentMethodsList } from '../../../paymentMethod/actions';
import { Box } from '@material-ui/core';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CustomerForm from '../../../shipping/form/CustomerForm';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../../constants';

const useStyles = makeStyles({
  root: {},
  appBar: {
    position: 'relative',
  },
  item: {
    padding: '24px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    lineHeight: '24px',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  image: {},
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BillingFormModal = props => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodsList(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    dispatch(getDefaultPaymentMethod2(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      fullScreen
      open={props.open}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h3">
            <IntlMessages id="bundles.shipping.routes.index.title" />
          </Typography>
          <div>
            <IconButton color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Box p={6}>
        <CustomerForm handleCancel={props.handleClose} onSuccess={props.handleClose} />
      </Box>
    </Dialog>
  );
};

export default BillingFormModal;

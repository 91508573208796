import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, fade, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  emptyListContainer: {
    flexDirection: 'column',
    minHeight: 250,
    height: '100%',
    display: 'flex',
    padding: 20,
    paddingBottom: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${fade(theme.palette.common.black, 0.12)}`,
    borderRadius: 4,
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: 'row',
  },
}));

const SubscriptionRequired = props => {
  const classes = useStyles();
  const { subscriptionUser } = useSelector(({ auth }) => auth);

  /*
  switch (subscriptionUser.status) {

    case 'canceled':
      return <AlertCanceled />;
  }

   */

  return (
    <Box className={clsx(classes.emptyListContainer, 'CmtList-EmptyResult')}>
      <Box align={'center'}>
        <ReportProblemOutlinedIcon color="primary" style={{ fontSize: 40 }} />
      </Box>
      <Box component="h4" fontSize={28} color="text.primary" mb={3}>
        <IntlMessages id={'subscription_required.title'} />
      </Box>

      <Box fontSize={18} component="p" color="text.secondary" mb={3}>
        {!subscriptionUser ? (
          <IntlMessages id={'subscription.status.none'} />
        ) : (
          <IntlMessages id={'subscription.status.' + subscriptionUser.status} />
        )}
      </Box>
      <Grid container justify="center" spacing={GRID_SPACING}>
        <Grid item>
          <Link to={'/products'}>
            <Button color="primary" variant="contained" style={{ marginTop: 30, height: 45, minWidth: 150 }}>
              <IntlMessages id={'subscription_required.action.select_plan'} />
            </Button>
          </Link>
        </Grid>

        <Grid item>
          <Link to={'/billing/paymentMethods'}>
            <Button color="primary" variant="contained" style={{ marginTop: 30, height: 45, minWidth: 150 }}>
              <IntlMessages id={'subscription_required.action.select_payment_method'} />
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubscriptionRequired;

import React from 'react';
import Grid from '@material-ui/core/Grid';

import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import { SERVER_URL, SERVER_METHOD, FILES_KEY } from '../constants/UploadApiConstants';

class UploadLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logCollection: '',
    };

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  async componentDidMount() {
    //
  }

  handleFileChange(files) {
    let items = files.map(fileItem => fileItem.file);

    this.setState({
      logCollection: [...this.state.logCollection, items],
    });
  }

  render() {
    const projectId = this.props.projectId;

    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <form>
            <div className="filepond-wrapper">
              <FilePond
                files={this.state.logCollection}
                allowMultiple={true}
                server={{
                  process: {
                    url: SERVER_URL,
                    method: SERVER_METHOD,
                    headers: {
                      Authorization: `Bearer eyJH18ui0...`,
                    },
                    ondata: formData => {
                      formData.append('projectId', projectId);
                      return formData;
                    },
                    onload: () => {
                      //
                    },
                  },
                }}
                name={FILES_KEY}
                instantUpload={false}
                onupdatefiles={fileItems => this.handleFileChange(fileItems)}>
                >
              </FilePond>
            </div>
          </form>
        </Grid>
      </Grid>
    );
  }
}

export default UploadLog;

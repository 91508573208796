import { LIST_NOTIFICATIONS, READ_ALL_NOTIFICATIONS, READ_NOTIFICATION, START_NOTIFICATION_INTERVAL } from '../constants';

const INIT_STATE = {
  notificationInterval: null,
  notificationsList: {
    'hydra:member': [],
    'hydra:totalItems': 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_NOTIFICATION_INTERVAL: {
      return {
        ...state,
        notificationInterval: action.payload,
      };
    }
    case LIST_NOTIFICATIONS: {
      return {
        ...state,
        notificationsList: action.payload,
      };
    }
    case READ_ALL_NOTIFICATIONS: {
      return {
        ...state,
      };
    }
    case READ_NOTIFICATION: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { UPDATE_DEFAULT_PAYMENT_METHOD, UPDATE_PAYMENT_METHODS_LIST, SHOW_ADD_CARD_FORM } from '../constants';
import {
  attachPaymentMethod,
  detachPaymentMethod,
  getDefaultPaymentMethod,
  getPaymentMethodList,
  setDefaultPaymentMethod,
} from 'services/stripe/StripeUtil';
import { showMessage } from 'redux/actions';

export const handleShowAddCardForm = value => {
  return dispatch => {
    dispatch({
      type: SHOW_ADD_CARD_FORM,
      payload: value,
    });
  };
};

/**
 * @param customerId customer ID in Stripe
 * @returns {function(*): void}
 */
export const getDefaultPaymentMethod2 = customerId => {
  return dispatch => {
    dispatch(fetchStart());

    getDefaultPaymentMethod(customerId)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_DEFAULT_PAYMENT_METHOD,
          payload: data,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

/**
 * @param customerId customer ID in Stripe
 * @returns {function(*): void}
 */
export const getPaymentMethodsList = customerId => {
  return dispatch => {
    dispatch(fetchStart());

    getPaymentMethodList(customerId)
      .then(({ data }) => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_PAYMENT_METHODS_LIST,
          payload: data,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleAttach = (id, customerId, defaultMode = false) => {
  return dispatch => {
    dispatch(fetchStart());

    attachPaymentMethod(customerId, id)
      .then(({ data }) => {
        if (true === defaultMode) {
          dispatch(handleSetDefaultMode(id, customerId));
        }
        dispatch(getPaymentMethodsList(customerId));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleDetach = (id, customerId) => {
  return dispatch => {
    dispatch(fetchStart());

    detachPaymentMethod(id)
      .then(async ({ data }) => {
        await dispatch(getDefaultPaymentMethod2(customerId));
        dispatch(showMessage('bundles.PaymentMode.notification.detach.success', 'success'));
        dispatch(getPaymentMethodsList(customerId));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleSetDefaultMode = (id, customerId) => {
  return dispatch => {
    dispatch(fetchStart());

    setDefaultPaymentMethod(customerId, id)
      .then(async ({ data }) => {
        await dispatch(getDefaultPaymentMethod2(customerId));
        dispatch(showMessage('bundles.PaymentMode.notification.setDefaultMethod.success', 'success'));
        dispatch(getPaymentMethodsList(customerId));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

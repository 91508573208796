import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const ValuePrettier = ({ value }) => {
  let jsx = null;

  if (typeof value === 'boolean') {
    jsx =
      true === value ? (
        <Chip label={'true'} color={'primary'} variant="outlined" size="small" />
      ) : (
        <Chip label={'false'} variant="outlined" size="small" />
      );
  } else if (null === value) {
    jsx = <Chip label={'null'} variant="outlined" size="small" />;
  } else {
    jsx = value;
  }

  return <div>{jsx}</div>;
};

ValuePrettier.propTypes = {
  value: PropTypes.any,
};

export default ValuePrettier;

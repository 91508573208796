import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { Link } from 'react-router-dom';
import { Typography, TableRow, TableCell, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IntlMessages from '@jumbo/utils/IntlMessages';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import CrawlStatusBadge from './CrawlStatusBadge';
import CrawlPagesCrawled from './CrawlPagesCrawled';
import { CRAWL_STATUS } from '../constants';

const useStyles = makeStyles(theme => ({
  lightText: {
    color: theme.palette.lightBtn.textColor,
  },
}));

/**
 * This component displays a row of the crawl list table.
 */
const CrawlListTableRow = props => {
  const classes = useStyles();
  const { locale } = useContext(AppContext);

  const [menuState, setMenuState] = useState({});

  moment.locale(locale.locale);

  const handleOpenMenu = e => {
    const id = e.currentTarget.attributes.getNamedItem('data-id').value;

    const newMenuState = {};
    newMenuState[id] = e.currentTarget;

    setMenuState(newMenuState);
  };

  /**
   * Triggered when the "Delete crawl" button is clicked.
   *
   * @param {number} id
   */
  const handleDelete = id => {
    setMenuState({});

    props.handleDelete(id);
  };

  /**
   * Triggered when the "Stop crawl" button is clicked.
   *
   * @param {number} id
   */
  const handleStop = id => {
    setMenuState({});

    props.handleStop(id);
  };

  return (
    <TableRow key={props.id} hover={true}>
      <TableCell>{props.name}</TableCell>
      <TableCell>
        <CrawlStatusBadge
          clickable={false}
          id={props.id}
          status={props.status}
          projectId={parseInt(props.website.split('/').pop())}
        />
      </TableCell>
      <TableCell className={classes.lightText}>
        <Typography variant={'body2'}>{props.crawlEndedAt && moment(props.crawlEndedAt).fromNow()}</Typography>
      </TableCell>
      <TableCell className={classes.lightText}>
        <Typography variant={'body2'}>
          {'running' === props.status && moment.duration(props.durationValues[props.id], 'seconds').humanize()}
          {'running' === props.status && <LinearProgress color="primary" />}

          {'finished' === props.status &&
            moment
              .duration(
                (moment(props.crawlEndedAt).format('x') - moment(props.crawlStartedAt).format('x')) / 1000,
                'seconds',
              )
              .humanize()}
        </Typography>
      </TableCell>
      <TableCell className={classes.lightText}>
        <Typography variant={'body2'}>
          <CrawlPagesCrawled id={props.id} status={props.status} urlsCrawled={props.urlsCrawled} />
        </Typography>
      </TableCell>

      <TableCell>
        <Link
          to={`/dashboards/view/summary?predefined=1&module=crawled_pages&projectId=${props.website
            .split('/')
            .pop()}&crawlId=${props.id}`}>
          <Button key={'detach-' + props.id} size="small" color="primary">
            <IntlMessages id="bundles.crawl.action.view" />
          </Button>
        </Link>
      </TableCell>

      <TableCell align={'right'}>
        <IconButton
          data-id={props.id}
          aria-label="more"
          aria-controls={'menu-' + props.id}
          aria-haspopup="true"
          onClick={e => handleOpenMenu(e)}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          data-id={props.id}
          id={'menu-' + props.id}
          anchorEl={menuState[props.id]}
          open={menuState.hasOwnProperty(props.id)}
          onClose={() => setMenuState({})}
          keepMounted>
          {'running' === props.status && (
            <MenuItem onClick={() => handleStop(props.id)}>
              <IntlMessages id="bundles.crawl.action.stop" />
            </MenuItem>
          )}

          {'running' !== props.status && (
            <MenuItem onClick={() => handleDelete(props.id)}>
              <IntlMessages id="bundles.crawl.action.delete" />
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};

CrawlListTableRow.propTypes = {
  durationValues: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  crawlStartedAt: PropTypes.string,
  crawlEndedAt: PropTypes.string,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(CRAWL_STATUS).isRequired,
  website: PropTypes.string.isRequired,
  urlsCrawled: PropTypes.number,
  handleDelete: PropTypes.func.isRequired,
  handleStop: PropTypes.func.isRequired,
};

export default React.memo(CrawlListTableRow);

import { CANONICALS_SCHEMA } from './canonicals/canonical_schema';
import { CRAWLED_PAGES_SCHEMA } from './crawled_pages/crawled_pages_schema';
import { INTERNAL_LINKS_SCHEMA } from './internal_links/internal_links_schema';
import { LINKS_FLOW_SCHEMA } from './links_flow/links_flow_schema';
import { LOGS_SCHEMA } from './logs/logs_schema';

export const SCHEMAS = {
  canonicals: CANONICALS_SCHEMA,
  crawled_pages: CRAWLED_PAGES_SCHEMA,
  internal_links: INTERNAL_LINKS_SCHEMA,
  links_flow: LINKS_FLOW_SCHEMA,
  logs: LOGS_SCHEMA,
};

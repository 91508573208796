import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { LIST_CRAWL_SCHEDULES, GET_CRAWL_SCHEDULE } from '../constants';
import {
  getCrawlSchedule,
  getCrawlScheduleList,
  deleteCrawlSchedule,
  createCrawlSchedule,
  updateCrawlSchedule,
} from 'services/api/MyApiUtil';
import { redirect, showMessage } from 'redux/actions';

export const handleGetCrawlSchedules = payload => {
  return dispatch => {
    getCrawlScheduleList(payload)
      .then(result => {
        dispatch({
          type: LIST_CRAWL_SCHEDULES,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleGetCrawlSchedule = id => {
  return dispatch => {
    getCrawlSchedule(id)
      .then(result => {
        dispatch({
          type: GET_CRAWL_SCHEDULE,
          payload: result,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleDeleteCrawlSchedule = (id, listPayload) => {
  return dispatch => {
    dispatch(fetchStart());

    deleteCrawlSchedule(id)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawlSchedule.notification.deleted.success', 'success'));
        dispatch(handleGetCrawlSchedules(listPayload));
        dispatch(redirect('/crawl_schedules'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleCreateCrawlSchedule = (projectId, payload, history) => {
  return dispatch => {
    dispatch(fetchStart());

    payload['project'] = '/api/projects/' + projectId;

    createCrawlSchedule(payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawlSchedule.notification.create.success', 'success'));
        dispatch({
          type: GET_CRAWL_SCHEDULE,
          payload: result,
        });
        dispatch(redirect('/crawl_schedules'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleUpdateCrawlSchedule = (id, projectId, payload, history) => {
  return dispatch => {
    dispatch(fetchStart());

    updateCrawlSchedule(id, payload)
      .then(result => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.crawlSchedule.notification.update.success', 'success'));
        dispatch({
          type: GET_CRAWL_SCHEDULE,
          payload: result,
        });
        dispatch(redirect('/crawl_schedules'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

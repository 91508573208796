import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const AlertTrialing = props => {
  const startDate = moment();
  const endDate = moment.unix(props.subscriptionUser.trial_end);
  const remainingDays = endDate.diff(startDate, 'days');
  const { defaultPaymentMethod } = useSelector(({ paymentMethod }) => paymentMethod);

  return (
    <Alert severity="warning">
      <IntlMessages id="layout.currentPlan.text.willExpire" values={{ days: remainingDays }} />
      {defaultPaymentMethod === null || !defaultPaymentMethod.id ? (
        <Box ml={3} clone>
          <Link to={'/billing/paymentMethods'}>
            <Button variant="contained" color="primary" size="small">
              Update your payment method
            </Button>
          </Link>
        </Box>
      ) : null}
    </Alert>
  );
};

export default AlertTrialing;

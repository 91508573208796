import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../@jumbo/constants/ActionTypes';
import { THEME_TYPES } from '../../@jumbo/constants/ThemeOptions';

const INIT_STATE = {
  initialURL: '/',
  error: '',
  loading: false,
  message: '',
  level: '',
  redirectTo: null,
  themeType: THEME_TYPES.LIGHT,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    case 'SHOW_MESSAGE': {
      return {
        ...state,
        error: '',
        message: action.payload.message,
        level: action.payload.level,
        loading: false,
      };
    }
    case 'HIDE_MESSAGE': {
      return { ...state, error: '', level: '', message: '', loading: false };
    }
    case 'REDIRECT':
      return { redirectTo: action.payload };
    case 'REDIRECT_RESET':
      return { redirectTo: null };
    case 'UPDATE_THEME_TYPE':
      return { themeType: action.payload };
    default:
      return state;
  }
};

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import HelpIcon from '@material-ui/icons/Help';
import MyTooltip from 'components/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import CreateProjectDisabled from './TooltipContent/CreateProjectDisabled';
import GenericActionDisabledTrialPeriodExpired from './TooltipContent/GenericActionDisabledTrialPeriodExpired';
import { useSelector } from 'react-redux';

const CreateProjectButton = props => {
  const { planUser, isActiveSubscription } = useSelector(({ auth }) => auth);
  const { projectsList } = useSelector(({ project }) => project);

  if (!isActiveSubscription) {
    return (
      <MyTooltip title={<GenericActionDisabledTrialPeriodExpired />}>
        <span>
          <Button disabled={true} variant={props.variant || 'text'}>
            <AddCircleOutlineIcon className={'mr-2'} />
            <IntlMessages id="bundles.project.action.create" />
            <HelpIcon fontSize={'small'} className={'ml-1'} />
          </Button>
        </span>
      </MyTooltip>
    );
  }

  const projectsCount = projectsList['hydra:totalItems'] || 0;

  if (
    !planUser ||
    (projectsCount >= parseInt(planUser.metadata.quota_projects) && 0 !== parseInt(planUser.metadata.quota_projects))
  ) {
    return (
      <MyTooltip title={<CreateProjectDisabled />}>
        <span>
          <Button disabled={true} variant={props.variant || 'text'}>
            <AddCircleOutlineIcon className={'mr-2'} />
            <IntlMessages id="bundles.project.action.create" />
            <HelpIcon fontSize={'small'} className={'ml-1'} />
          </Button>
        </span>
      </MyTooltip>
    );
  }

  return (
    <React.Fragment>
      <Link onClick={props.handleClose} to={'/projects/create'}>
        <Button color="primary" variant={props.variant || 'text'}>
          <AddCircleOutlineIcon className={'mr-2'} />
          <IntlMessages id="bundles.project.action.create" />
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default CreateProjectButton;

import React, { useContext } from 'react';
import { Box, fade, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import AppContext from '../../../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../../../constants/ThemeOptions';
import { updateThemeType2 } from '../../../../../../redux/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  iconRoot: {
    position: 'relative',
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
}));

const DarkMode = () => {
  const classes = useStyles();
  const { themeType, updateThemeType } = useContext(AppContext);

  const dispatch = useDispatch();

  const onClick = event => {
    let newThemeType = '';

    if (themeType === THEME_TYPES.LIGHT) {
      newThemeType = THEME_TYPES.DARK;
    } else {
      newThemeType = THEME_TYPES.LIGHT;
    }

    // change theme style
    updateThemeType(newThemeType);
    // save the new theme style in storage
    dispatch(updateThemeType2(newThemeType));
  };

  return (
    <Box pr={2}>
      <Tooltip title="Dark mode">
        <IconButton
          onClick={onClick}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: themeType === THEME_TYPES.DARK,
          })}>
          <Brightness2Icon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DarkMode;

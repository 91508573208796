import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
//import Select from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select/Select';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import { Box, FormHelperText } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { Alert } from '@material-ui/lab';

import { TimePicker } from '@material-ui/pickers';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Formik } from 'formik';
import { validateCrawlSchedule } from '../validation/CrawlSchedule';
import { handleDeleteCrawlSchedule } from '../actions';

import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { handleGetCrawlProfiles } from '../../crawlProfile/actions';
import { DAYS_WEEK, FREQUENCY_HOUR, FREQUENCY_DAY, FREQUENCY_WEEK, FREQUENCY_CUSTOM } from '../constants';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

/**
 * @param {string} frequency
 * @param {string} cronExpression
 */
function getDateByFrequencuAndCronExpression(frequency, cronExpression) {
  if (!frequency || !cronExpression) {
    return null;
  }

  const cronArr = cronExpression.split(' ');
  const minute = cronArr[0];
  const hour = cronArr[1];

  const d = new Date();

  switch (frequency) {
    case FREQUENCY_HOUR:
      d.setMinutes(minute);
      return d;

    case FREQUENCY_DAY:
      d.setHours(hour, minute);
      return d;

    case FREQUENCY_WEEK:
      d.setHours(hour, minute);
      return d;

    case FREQUENCY_CUSTOM:
      return null;

    default:
      return null;
  }
}

/**
 * @param {string} frequency
 * @param {string} cronExpression
 */
function getDayWeekByFrequencuAndCronExpression(frequency, cronExpression) {
  if (!frequency || !cronExpression) {
    return '';
  }

  const cronArr = cronExpression.split(' ');
  const day = cronArr[4];

  switch (frequency) {
    case FREQUENCY_WEEK:
      return day;

    default:
      return '';
  }
}

const CrawlScheduleForm = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { crawlProfilesList } = useSelector(({ crawlProfile }) => crawlProfile);
  const { project } = useSelector(({ projectSelector }) => projectSelector);

  const [profileOptions, setProfileOptions] = useState([]);
  const [profileDefaultValue, setProfileDefaultValue] = useState('');

  const [activeStep, setActiveStep] = useState(0);

  let defaultCompletedSteps = [false, false, false];
  if (props.id) {
    defaultCompletedSteps = [true, true, true];
  }

  let defaultSelectDate = null;
  defaultSelectDate = getDateByFrequencuAndCronExpression(props.data.frequency, props.data.cronExpression);

  let defaultDayWeek = '';
  defaultDayWeek = getDayWeekByFrequencuAndCronExpression(props.data.frequency, props.data.cronExpression);

  const [completedSteps, setCompletedSteps] = useState(defaultCompletedSteps);
  const [expandedSteps, setExpandedSteps] = useState([true, false, false]);

  const [frequency, setFrequency] = useState(props.data.frequency || null);
  const [cronExpression, setCronExpression] = useState(props.data.cronExpression || null);
  const [selectedDate, handleDateChange] = useState(defaultSelectDate);
  const [dayWeek, setDayWeek] = useState(defaultDayWeek);

  const dispatch = useDispatch();

  const completeSteps = () => {
    let cloneCompletedSteps = [...completedSteps];
    cloneCompletedSteps[2] = false;

    switch (frequency) {
      case FREQUENCY_HOUR:
        if (selectedDate) {
          cloneCompletedSteps[2] = true;
        }

        break;
      case FREQUENCY_DAY:
        if (selectedDate) {
          cloneCompletedSteps[2] = true;
        }

        break;
      case FREQUENCY_WEEK:
        if (dayWeek && selectedDate) {
          cloneCompletedSteps[2] = true;
        }

        break;
      case FREQUENCY_CUSTOM:
        if (cronExpression) {
          cloneCompletedSteps[2] = true;
        }

        break;
      default:
      // nothing to do
    }

    setCompletedSteps(cloneCompletedSteps);
  };

  /**
   * Refresh Profile list on startup
   */
  useEffect(() => {
    if (project && project.id) {
      const payload = {
        project: project.id,
        order: {
          name: 'asc',
        },
      };

      dispatch(handleGetCrawlProfiles(payload));

      if (props.id) {
        // expand all steps
        setExpandedSteps([true, true, true]);
      }

      completeSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    let options = [];

    for (const obj of crawlProfilesList['hydra:member']) {
      options.push({
        value: obj['@id'],
        label: obj.name,
      });
    }

    setProfileOptions(options);

    for (const obj of crawlProfilesList['hydra:member']) {
      if (props.data.profile === obj['@id']) {
        setProfileDefaultValue(obj['@id']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlProfilesList]);

  useEffect(() => {
    completeSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency, dayWeek, selectedDate, cronExpression]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      validate={values => validateCrawlSchedule(values, dayWeek, selectedDate)}
      onSubmit={values => props.onSubmit(authUser, values, dayWeek, selectedDate)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step key={0} expanded={expandedSteps[0]} completed={completedSteps[0]}>
                  <StepLabel>
                    <strong>
                      <IntlMessages id="bundles.crawlSchedule.form.text.selectProfile.title" />
                    </strong>
                  </StepLabel>
                  <StepContent>
                    <Box mb={3}>
                      <InputLabel>
                        <IntlMessages id="bundles.crawlSchedule.form.text.selectProfile.description" />
                      </InputLabel>
                    </Box>

                    <Grid container spacing={GRID_SPACING}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Select
                          value={profileDefaultValue}
                          onChange={event => {
                            setProfileDefaultValue(event.target.value);
                            setFieldValue('profile', event.target.value, false);
                            setActiveStep(1);

                            let cloneCompletedSteps = [...completedSteps];
                            cloneCompletedSteps[0] = true;
                            console.log('profile onChange');
                            setCompletedSteps(cloneCompletedSteps);
                          }}
                          fullWidth={true}
                          required>
                          {profileOptions.map(obj => (
                            <MenuItem key={obj.value} value={obj.value}>
                              {obj.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {touched.profile && Boolean(errors.profile) && (
                          <FormHelperText error={true}>{touched.profile && errors.profile}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </StepContent>
                </Step>

                <Step key={1} expanded={expandedSteps[1]} completed={completedSteps[1]}>
                  <StepLabel>
                    <strong>
                      <IntlMessages id="bundles.crawlSchedule.form.text.selectFrequency.title" />
                    </strong>
                  </StepLabel>
                  <StepContent>
                    <RadioGroup
                      aria-label="frequency"
                      name="frequency"
                      value={values.frequency}
                      onChange={event => {
                        setFieldValue('frequency', event.target.value, false);
                        setActiveStep(2);
                        setFrequency(event.target.value);

                        let cloneCompletedSteps = [...completedSteps];
                        cloneCompletedSteps[1] = true;
                        setCompletedSteps(cloneCompletedSteps);
                        console.log('frequency onChange');

                        let cloneExpandedSteps = [...expandedSteps];
                        cloneExpandedSteps[1] = true;
                        cloneExpandedSteps[2] = true;
                        setExpandedSteps(cloneExpandedSteps);
                      }}>
                      <FormControlLabel
                        value={FREQUENCY_HOUR}
                        control={<Radio />}
                        label={<IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyHour.choice" />}
                      />
                      <FormControlLabel
                        value={FREQUENCY_DAY}
                        control={<Radio />}
                        label={<IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyDay.choice" />}
                      />
                      <FormControlLabel
                        value={FREQUENCY_WEEK}
                        control={<Radio />}
                        label={<IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyWeek.choice" />}
                      />
                      <FormControlLabel
                        value={FREQUENCY_CUSTOM}
                        control={<Radio />}
                        label={<IntlMessages id="bundles.crawlSchedule.form.text.frequency.custom.choice" />}
                      />
                    </RadioGroup>

                    {touched.frequency && Boolean(errors.frequency) && (
                      <FormHelperText error={true}>{touched.frequency && errors.frequency}</FormHelperText>
                    )}
                  </StepContent>
                </Step>

                <Step key={2} expanded={expandedSteps[2]} completed={completedSteps[2]}>
                  <StepLabel>
                    <IntlMessages id="bundles.crawlSchedule.form.text.selectTime.title" />
                  </StepLabel>
                  <StepContent>
                    {FREQUENCY_HOUR === values.frequency && (
                      <React.Fragment>
                        <Box mb={3}>
                          <InputLabel>
                            <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyHour.description" />
                          </InputLabel>
                        </Box>

                        <Box mb={GRID_SPACING}>
                          <TimePicker
                            ampm={false}
                            openTo="minutes"
                            views={['minutes']}
                            format="mm"
                            value={selectedDate}
                            onChange={handleDateChange}
                          />

                          {Boolean(errors.selectedDate) && (
                            <FormHelperText error={true}>{errors.selectedDate}</FormHelperText>
                          )}
                        </Box>

                        {selectedDate && (
                          <Alert severity="info" style={{ marginBottom: 0 }}>
                            <IntlMessages
                              id="bundles.crawlSchedule.form.text.frequency.everyHour.info"
                              values={{
                                minute: moment(selectedDate).format('mm'),
                              }}
                            />
                          </Alert>
                        )}
                      </React.Fragment>
                    )}
                  </StepContent>
                  <StepContent>
                    {FREQUENCY_DAY === values.frequency && (
                      <React.Fragment>
                        <Box mb={3}>
                          <InputLabel>
                            <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyDay.description" />
                          </InputLabel>
                        </Box>

                        <Box mb={GRID_SPACING}>
                          <TimePicker autoOk ampm={false} value={selectedDate} onChange={handleDateChange} />

                          {Boolean(errors.selectedDate) && (
                            <FormHelperText error={true}>{errors.selectedDate}</FormHelperText>
                          )}
                        </Box>

                        {selectedDate && (
                          <Alert severity="info" style={{ marginBottom: 0 }}>
                            <IntlMessages
                              id="bundles.crawlSchedule.form.text.frequency.everyDay.info"
                              values={{
                                hour: moment(selectedDate).format('HH'),
                                minute: moment(selectedDate).format('mm'),
                              }}
                            />
                          </Alert>
                        )}
                      </React.Fragment>
                    )}
                  </StepContent>
                  <StepContent>
                    {FREQUENCY_WEEK === values.frequency && (
                      <React.Fragment>
                        <Grid container spacing={GRID_SPACING}>
                          <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box mb={3}>
                              <InputLabel>
                                <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyWeek.description1" />
                              </InputLabel>
                            </Box>

                            <Box>
                              <Select
                                value={dayWeek}
                                onChange={event => {
                                  setDayWeek(event.target.value);
                                }}
                                fullWidth={true}>
                                {DAYS_WEEK.map(obj => (
                                  <MenuItem key={obj.value} value={obj.value}>
                                    {obj.label}
                                  </MenuItem>
                                ))}
                              </Select>

                              {Boolean(errors.dayWeek) && <FormHelperText error={true}>{errors.dayWeek}</FormHelperText>}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box mb={3}>
                              <InputLabel>
                                <IntlMessages id="bundles.crawlSchedule.form.text.frequency.everyWeek.description2" />
                              </InputLabel>
                            </Box>

                            <Box mb={GRID_SPACING}>
                              <TimePicker autoOk ampm={false} value={selectedDate} onChange={handleDateChange} />

                              {Boolean(errors.selectedDate) && (
                                <FormHelperText error={true}>{errors.selectedDate}</FormHelperText>
                              )}
                            </Box>
                          </Grid>
                        </Grid>

                        {dayWeek && selectedDate && (
                          <Alert severity="info" style={{ marginBottom: 0 }}>
                            <IntlMessages
                              id="bundles.crawlSchedule.form.text.frequency.everyWeek.info"
                              values={{
                                day: DAYS_WEEK[dayWeek].label,
                                hour: moment(selectedDate).format('HH'),
                                minute: moment(selectedDate).format('mm'),
                              }}
                            />
                          </Alert>
                        )}
                      </React.Fragment>
                    )}
                  </StepContent>
                  <StepContent>
                    {FREQUENCY_CUSTOM === values.frequency && (
                      <React.Fragment>
                        <Box mb={3}>
                          <InputLabel>
                            <IntlMessages id="bundles.crawlSchedule.form.text.frequency.custom.description" />
                          </InputLabel>
                        </Box>

                        <TextField
                          id="cronExpression"
                          name="cronExpression"
                          placeholder={'5 12 * * *'}
                          value={values.cronExpression}
                          onChange={e => {
                            setFieldValue('cronExpression', e.target.value, false);
                            setCronExpression(e.target.value);

                            let cloneCompletedSteps = [...completedSteps];

                            if (e.target.value) {
                              cloneCompletedSteps[2] = true;
                            } else {
                              cloneCompletedSteps[2] = false;
                            }

                            console.log('cronExpression onChange');
                            setCompletedSteps(cloneCompletedSteps);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          variant={'outlined'}
                          error={touched.cronExpression && Boolean(errors.cronExpression)}
                          helperText={touched.cronExpression && errors.cronExpression}
                        />
                      </React.Fragment>
                    )}
                  </StepContent>
                </Step>
              </Stepper>
            </Grid>

            <Grid item xs={12}>
              <BackButton variant="outlined" color="primary">
                <IntlMessages id="bundles.crawlSchedule.action.cancel" />
              </BackButton>

              <Box ml={6} clone>
                <Button
                  disabled={!completedSteps[0] || !completedSteps[1] || !completedSteps[2] ? true : false}
                  type="submit"
                  variant="contained"
                  color="primary">
                  <IntlMessages id="bundles.crawlSchedule.action.save" />
                </Button>
              </Box>

              {props.id && (
                <Box ml={6} clone>
                  <Button
                    type="button"
                    variant="contained"
                    color="danger"
                    onClick={() => dispatch(handleDeleteCrawlSchedule(props.id))}>
                    <IntlMessages id="bundles.crawlSchedule.action.delete" />
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CrawlScheduleForm;

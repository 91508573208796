export const INLINKS_MAP = {
  '@id': 'INLINKS_MAP',
  name: 'bundles.crawl.chart.inlinks_map.title',
  description: null,
  x: 0,
  y: 26,
  w: 12,
  h: 12,
  query: {
    table: 'links_flow',
    dimensions: ['categorySource', 'categoryDestination'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['nbLinksTotal'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'chordDiagram',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let labels = [];
      let data = [];

      rows.map(arr => {
        labels.push(arr.categorySource);

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      rows.map(arr => {
        if (arr.categorySource === arr.categoryDestination) {
          //
        } else {
          data.push({
            from: arr.categorySource,
            to: arr.categoryDestination,
            value: arr.nbLinksTotal,
          });
        }

        return true;
      });

      return data;
    },
    containerOptions: {
      style: {
        width: '100%',
        height: 480,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

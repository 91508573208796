import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const DESCRIPTION_LENGTH = {
  '@id': 'DESCRIPTION_LENGTH',
  name: 'bundles.crawl.chart.description_length.title',
  description: null,
  x: 6,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['contentDescriptionLengthString'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
      {
        dimension: 'checks_has_description',
        operator: 'equals',
        values: [true],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [0, 0, 0, 0];
      let backgroundColor = ['#4caf50', '#ff9800', '#f44336', '#dc004e'];

      rows.map(arr => {
        if (arr['contentDescriptionLengthString'].includes('Perfect')) {
          data[0] = arr.count;
        } else if (arr['contentDescriptionLengthString'].includes('Empty')) {
          data[1] = arr.count;
        } else if (arr['contentDescriptionLengthString'].includes('short')) {
          data[2] = arr.count;
        } else if (arr['contentDescriptionLengthString'].includes('long')) {
          data[3] = arr.count;
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      return ['Perfect', 'Empty', 'Too short', 'Too long'];
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const value = chartData.labels[element[0].index];

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'content_description', 'content_description_chars_count', 'response_firstStatusCode'],
        filters: [
          {
            label: 'dimension.crawled_pages.checks_is_html',
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.checks_was_redirected',
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
          {
            label: 'dimension.crawled_pages.checks_has_description',
            dimension: 'checks_has_description',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.contentDescriptionLengthString',
            dimension: 'contentTitleLengthString',
            operator: 'equals',
            values: [value],
          },
        ],
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import { fetchError, fetchStart, fetchSuccess, redirect, showMessage } from '../../../redux/actions';
import { setAuthUser, updateLoadUser, destroySession } from '../../../redux/actions/Auth';
import React from 'react';
import jwt_decode from 'jwt-decode';
import axios from './config';
import { getSubscriptionList } from '../../stripe/StripeUtil';
import { createUser, sendResetEmail, changePassword } from '../../api/MyApiUtil';

const JWTAuth = {
  onRegister: ({ avatarUrl, firstname, lastname, email, password, socialId, country }) => {
    return async dispatch => {
      try {
        dispatch(fetchStart());

        let payload = {
          avatarUrl: avatarUrl,
          firstname: firstname,
          lastname: lastname,
          username: email,
          email: email,
          plainPassword: password,
          socialId: socialId,
          country: country,
        };

        // create user
        // -----------
        const result = await createUser(payload);

        localStorage.setItem(
          'signup',
          JSON.stringify({
            id: result.id,
            email: result.email,
            stripeCustomerId: result.stripeCustomerId,
          }),
        );

        dispatch(fetchSuccess());
        dispatch(redirect('/check-email'));
      } catch (error) {
        dispatch(fetchError('signup.notification.create.error'));
      }
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        const form_data = new FormData();
        form_data.append('_username', email);
        form_data.append('_password', password);

        axios
          .post('/login_check', form_data)
          .then(({ data }) => {
            if (data.token) {
              localStorage.setItem('token', data.token);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.token));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: notificationInterval => {
    return dispatch => {
      dispatch(fetchStart());

      if (null !== notificationInterval && undefined !== notificationInterval) {
        clearInterval(notificationInterval.current);
      }

      dispatch(fetchSuccess());
      localStorage.removeItem('token');
      dispatch(setAuthUser(null));
      dispatch(destroySession());
      dispatch(updateLoadUser(true));
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }

      if (!token) {
        token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }

      if (!token) {
        dispatch(updateLoadUser(true));
        return;
      }

      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      const decoded = jwt_decode(token);

      axios
        .get('/users?username=' + decoded.username)
        .then(async ({ data }) => {
          if (data['hydra:member'][0]) {
            const user = data['hydra:member'][0];

            let price = null;
            let subscription = null;

            const subscriptions = await getSubscriptionList(user.stripeCustomerId);

            if (subscriptions && subscriptions.data.length > 0) {
              subscription = subscriptions.data[0];

              for (let i = 0; i < subscription.items.data.length; i++) {
                if ('crawler' === subscription.items.data[i].price.metadata.module) {
                  price = subscription.items.data[i].price;
                }
              }
            }

            dispatch(fetchSuccess());
            dispatch(setAuthUser(user, price, subscription));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: ({ email }) => {
    return dispatch => {
      sendResetEmail(email)
        .then(result => {
          dispatch(fetchSuccess());
          dispatch(showMessage('bundles.resetting.notification.forgotPassword.success', 'success'));
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  onChangePassword: ({ token, newPassword, newPasswordConfirm }) => {
    return dispatch => {
      const payload = {
        plainPassword: newPassword,
      };

      changePassword(token, payload)
        .then(result => {
          dispatch(fetchSuccess());
          dispatch(showMessage('bundles.resetting.notification.changePassword.success', 'success'));
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;

import {
  LIST_SEGMENTATION_PROFILES,
  GET_SEGMENTATION_PROFILE,
  CLEAR_SEGMENTATION_PROFILE,
  GET_SEGMENTATION_STATS,
} from '../constants';

const INIT_STATE = {
  segmentationProfileList: {
    'hydra:member': [],
  },
  segmentationProfile: {},
  segmentationStats: {
    isLoading: false,
    labels: [],
    data: [],
    crawled: 0,
    withSegment: 0,
    withoutSegment: 0,
    pieData: [
      { name: 'segmented', value: 0, color: '#fff' },
      { name: 'undefined', value: 0, color: '#3700B3' },
    ],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SEGMENTATION_PROFILES: {
      return {
        ...state,
        segmentationProfileList: action.payload,
      };
    }
    case GET_SEGMENTATION_PROFILE: {
      return {
        ...state,
        segmentationProfile: action.payload,
      };
    }
    case CLEAR_SEGMENTATION_PROFILE: {
      return {
        ...state,
        segmentationProfile: INIT_STATE.segmentationProfile,
        segmentationStats: INIT_STATE.segmentationStats,
      };
    }

    case GET_SEGMENTATION_STATS: {
      return {
        ...state,
        segmentationStats: action.payload,
      };
    }
    default:
      return state;
  }
};

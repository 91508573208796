import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import WarningIcon from '@material-ui/icons/Warning';
import { Box, Grid } from '@material-ui/core';

import { selectProject, handleGetProjects } from '../actions';

const ProjectSelectorContent = props => {
  const history = useHistory();
  const location = useLocation();

  const { projectsList } = useSelector(({ project }) => project);
  const { project } = useSelector(({ projectSelector }) => projectSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const orderObj = {};
      orderObj['name'] = 'asc';

      const payload = {
        order: orderObj,
      };

      dispatch(handleGetProjects(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {projectsList['hydra:member'].length > 0 ? (
        <RadioGroup
          key={project.id}
          aria-label="gender"
          name="project"
          value={project.id || null}
          onChange={event => dispatch(selectProject(event.target.value, location, history))}>
          {projectsList['hydra:member'].map(entity => (
            <FormControlLabel key={entity.id} value={entity.id} control={<Radio />} label={entity.name} />
          ))}
        </RadioGroup>
      ) : (
        <Alert color="primary" className="mb-0">
          <Grid container direction="row" alignItems="center">
            <Box mr={2} clone>
              <WarningIcon color={'error'} />
            </Box>
            <IntlMessages id="bundles.projectSelector.components.ProjectSelectorModal.noProject" />
          </Grid>
        </Alert>
      )}
    </React.Fragment>
  );
};

export default ProjectSelectorContent;

import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { withStyles } from '@material-ui/core/styles';
import LogHasDataBadge from './LogHasDataBadge';
import CrawlHasDataBadge from './CrawlHasDataBadge';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { useDispatch, useSelector } from 'react-redux';
import { handleDeleteProject } from '../actions';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))(TableRow);

const ProjectList = props => {
  const location = useLocation();
  const history = useHistory();
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const [menuState, setMenuState] = useState({});

  const dispatch = useDispatch();

  const columns = ['name', 'domain'];

  const handleOpenMenu = e => {
    const id = e.currentTarget.attributes.getNamedItem('data-id').value;

    const newMenuState = {};
    newMenuState[id] = e.currentTarget;

    setMenuState(newMenuState);
  };

  const handleDelete = id => {
    /**
     * This is used to clear the selected project in the appbar.
     */
    const clearProject = project && project.id && project.id === id ? true : false;

    setMenuState({});

    dispatch(handleDeleteProject(id, clearProject, location, history));
  };

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column} sortDirection={props.orderBy === column ? props.order : false}>
                <TableSortLabel
                  active={props.orderBy === column}
                  direction={props.orderBy === column ? props.order : 'asc'}
                  onClick={() => {
                    props.handleChangeSort(column);
                  }}>
                  <IntlMessages id={'bundles.project.entity.project.' + column + '.short'} />
                </TableSortLabel>
              </TableCell>
            ))}

            <TableCell align={'center'} style={{ width: '25%' }}>
              <IntlMessages id="bundles.project.entity.project.lastCrawl.short" />
            </TableCell>

            <TableCell align={'center'} style={{ width: '25%' }}>
              <IntlMessages id="bundles.project.entity.project.logAnalysis.short" />
            </TableCell>

            <TableCell align={'right'} style={{ width: '15%' }}>
              <IntlMessages id="bundles.project.global.actions" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects['hydra:member'].map(entity => (
            <StyledTableRow key={entity.id} hover={true}>
              <TableCell>{entity.name}</TableCell>
              <TableCell>{entity.domain}</TableCell>
              <TableCell align={'center'}>
                <CrawlHasDataBadge id={entity.id} />
              </TableCell>
              <TableCell align={'center'}>
                <LogHasDataBadge id={entity.id} />
              </TableCell>
              <TableCell align={'right'}>
                <IconButton
                  data-id={entity.id}
                  aria-label="more"
                  aria-controls={'menu-' + entity.id}
                  aria-haspopup="true"
                  onClick={e => handleOpenMenu(e)}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  data-id={entity.id}
                  id={'menu-' + entity.id}
                  anchorEl={menuState[entity.id]}
                  open={menuState.hasOwnProperty(entity.id)}
                  onClose={() => setMenuState({})}
                  keepMounted>
                  <MenuItem component={Link} to={'/projects/edit/' + entity.id}>
                    <IntlMessages id="bundles.project.action.edit" />
                  </MenuItem>
                  <MenuItem data-id={entity.id} onClick={() => handleDelete(entity.id)}>
                    <IntlMessages id="bundles.project.action.delete" />
                  </MenuItem>
                </Menu>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default ProjectList;

import React from 'react';
import { Box, Grid } from '@material-ui/core';

class HeaderPageSupplements extends React.Component {
  render() {
    return (
      <Grid container justify={'flex-end'} alignItems="center">
        {this.props.numbers.map((obj, i) => (
          <Grid key={i} item>
            <Box mr={6}>
              <dl className={'dl-numbers-heading'}>
                <dt>{obj.label}</dt>
                <dd>
                  <strong>{obj.number}</strong>
                </dd>
              </dl>
            </Box>
          </Grid>
        ))}

        {this.props.actions.map((action, i) => (
          <Grid key={i} item>
            {i < this.props.actions.length - 1 ? <Box mr={6}>{action}</Box> : <Box>{action}</Box>}
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default HeaderPageSupplements;

import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Number = props => {
  let counter = 0;

  if ('metric' === props.type) {
    if (props.metric.hasOwnProperty(props.metricId)) {
      counter = props.metric[props.metricId].data.result[0].value[1];
    }
  } else if (props.rows.hasOwnProperty(props.id)) {
    props.rows[props.id].map((entity, i) => {
      if (i === 0) {
        props.measures.map(measure => {
          counter = entity[measure];

          return true;
        });
      }

      return true;
    });
  }

  if (
    props.rows.hasOwnProperty(props.id) &&
    props.visualization.hasOwnProperty('transformValueCallback') &&
    props.visualization.transformValueCallback
  ) {
    counter = props.visualization.transformValueCallback(counter);
  }

  let showLink = false;
  let to = '';
  if (
    props.visualization.hasOwnProperty('getDataExplorerTableAndFilters') &&
    props.visualization.getDataExplorerTableAndFilters
  ) {
    showLink = true;

    const config = props.visualization.getDataExplorerTableAndFilters(null, null);

    let periodQueryString = '';
    let filters = config.filters;
    let schemaName = config.schemaName;

    let hiddenFilters = [];

    if (props.hiddenFilters.length > 0) {
      // add hidden filters
      props.hiddenFilters.map(obj => {
        obj.label = 'dimension.' + schemaName + '.' + obj.dimension;

        hiddenFilters.push(obj);

        return obj;
      });
    }

    to =
      '/log_analyzer/explorer?projectId=' +
      props.projectId +
      '&schema=' +
      schemaName +
      '&filters=' +
      JSON.stringify(filters) +
      periodQueryString;

    if (hiddenFilters.length > 0) {
      to += '&hiddenFilters=' + JSON.stringify(hiddenFilters);
    }

    if (config.hasOwnProperty('columns') && config.columns.length > 0) {
      to += '&columns=' + JSON.stringify(config.columns);
    }

    if (config.hasOwnProperty('groupBy') && config.groupBy.length > 0) {
      to += '&groupBy=' + JSON.stringify(config.groupBy);
    }

    if (config.hasOwnProperty('measures') && config.measures.length > 0) {
      to += '&measures=' + JSON.stringify(config.measures);
    }

    if (config.hasOwnProperty('aggregationFilters') && config.aggregationFilters.length > 0) {
      to += '&aggregationFilters=' + JSON.stringify(config.aggregationFilters);
    }

    if (config.hasOwnProperty('orderBy')) {
      to += '&orderBy=' + config.orderBy;
    }

    if (config.hasOwnProperty('order')) {
      to += '&order=' + config.order;
    }

    if (config.hasOwnProperty('ungrouped')) {
      to += '&ungrouped=' + config.ungrouped;
    }
  }

  return (
    <React.Fragment>
      {!showLink ? (
        <Typography variant={'h1'} component={'h1'} style={{ fontSize: '32px' }} align={'center'}>
          {counter}
        </Typography>
      ) : (
        <Link to={to}>
          <Typography color="textPrimary" variant={'h1'} component={'h1'} style={{ fontSize: '32px' }} align={'center'}>
            {counter}
          </Typography>
        </Link>
      )}

      <Typography
        align={
          (props.visualization.options && props.visualization.options.legend && props.visualization.options.legend.align) ||
          'center'
        }
        variant={'subtitle1'}
        color={'textSecondary'}>
        {props.name}
      </Typography>
    </React.Fragment>
  );
};

export default Number;

export const NON_INDEXABLE_BREAKDOWN = {
  '@id': 'NON_INDEXABLE_BREAKDOWN',
  name: 'bundles.crawl.chart.non_indexable_breakdown.title',
  description: null,
  x: 0,
  y: 4,
  w: 12,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['indexable_reason'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'indexable',
        operator: 'equals',
        values: [false],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'desc',
    orderBy: 'count',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'bar',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      /**
       * data indexes:
       *
       * 0 : 'Non-indexable by robots.txt',
       * 1 : 'Non-indexable by meta robots',
       * 2 : 'Non-indexable by header X-Robots-Tag',
       * 3 : 'Non-canonical pages',
       * 4 : '3xx, 4xx and 5xx',
       */
      let data = [0, 0, 0, 0, 0, 0];
      let datasets = {};
      let backgroundColor = ['#ff9800', '#fcbf49', '#ca6702', '#dc004e', '#ef233c', '#ef233c', '#ef233c', '#b400dc'];

      rows.map(arr => {
        switch (arr.indexable_reason) {
          case 'robots.txt':
            data[0] = arr.count;
            break;
          case 'meta-robots':
            data[1] = arr.count;
            break;
          case 'x-robots-tag':
            data[2] = arr.count;
            break;
          case 'canonical':
            data[3] = arr.count;
            break;
          case 'http-code-3xx':
            data[4] = arr.count;
            break;
          case 'http-code-4xx':
            data[5] = arr.count;
            break;
          case 'http-code-5xx':
            data[6] = arr.count;
            break;
          case 'non_indexable_page_type':
            data[7] = arr.count;
            break;
          default:
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows, intl) {
      return [
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.robots.txt.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.meta-robots.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.x-robots-tag.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.canonical.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.http-code-3xx.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.http-code-4xx.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.http-code-5xx.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.non_indexable_page_type.short',
        }),
      ];
    },
    getDataExplorerTableAndFilters: function(element, chartData, intl) {
      let indexable_reason = chartData.labels[element[0].index]; // 'Non-indexable by robots.txt'

      const labels = [
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.robots.txt.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.meta-robots.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.x-robots-tag.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.canonical.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.http-code-3xx.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.http-code-4xx.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.http-code-5xx.short',
        }),
        intl.formatMessage({
          id: 'bundles.crawl.chart.indexability.label.non_indexable_page_type.short',
        }),
      ];

      const codes = [
        'robots.txt',
        'meta-robots',
        'x-robots-tag',
        'canonical',
        'http-code-3xx',
        'http-code-4xx',
        'http-code-5xx',
        'non_indexable_page_type',
      ];

      const i = labels.indexOf(indexable_reason);
      indexable_reason = codes[i];

      // filters
      let filters = [];
      filters.push({
        label: 'dimension.crawled_pages.indexable_reason',
        dimension: 'indexable_reason',
        operator: 'equals',
        values: [indexable_reason],
      });

      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'indexable', 'indexable_reason', 'response_firstStatusCode'],
        filters: filters,
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          align: 'center',
        },
      },
      scales: {
        x: {
          stacked: false,
        },
        y: {
          beginAtZero: true,
          stacked: false,
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
    extra: {
      labelDimension: 'category',
      datasetsDimension: 'indexable_reason',
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

const CrawlerHelpers = {
  getDefaultHiddenFilters: ({ partitionId }) => {
    return [
      {
        dimension: 'crawlId',
        operator: 'equals',
        values: [partitionId],
      },
    ];
  },
};

export default CrawlerHelpers;

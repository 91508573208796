import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class GenericActionDisabledTrialPeriodExpired extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DialogTitle id="alert-dialog-title">
          <IntlMessages id="bundles.project.tooltip.genericActionDisabled.quotaReached.title" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              <IntlMessages id="bundles.project.tooltip.genericActionDisabled.quotaReached.1" />
            </p>

            <p>
              <IntlMessages id="bundles.project.tooltip.genericActionDisabled.quotaReached.2" />
            </p>

            <ul className={'mb-0'}>
              <li>
                <Link onClick={this.handleClose} to={'/app/plans'}>
                  <Button color="primary">
                    <IntlMessages id="bundles.project.tooltip.genericActionDisabled.quotaReached.upgrade" />
                  </Button>
                </Link>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default GenericActionDisabledTrialPeriodExpired;

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

class HistoryFileStatus extends React.Component {
  render() {
    let label = 'info';
    let color = '';
    let bg = '';

    if ('waiting' === this.props.entity.status) {
      label = 'bundles.LogsHistory.status.waiting';
    } else if ('canceled' === this.props.entity.status) {
      label = 'bundles.LogsHistory.status.canceled';
    } else if ('running' === this.props.entity.status) {
      label = 'bundles.LogsHistory.status.running';
      color = '#fff';
      bg = '#3f51b5';
    } else if ('success' === this.props.entity.status) {
      label = 'bundles.LogsHistory.status.success';
      color = '#fff';
      bg = '#4caf50';
    } else if ('configuration_required' === this.props.entity.status) {
      label = 'bundles.LogsHistory.status.configuration_required';
      color = '#fff';
      bg = '#f44336';
    } else if ('failed' === this.props.entity.status || 'danger' === this.props.entity.status) {
      label = 'bundles.LogsHistory.status.danger';
      color = '#fff';
      bg = '#f44336';
    }
    return <Chip label={<IntlMessages id={label} />} style={{ backgroundColor: bg, color: color }} variant="outlined" />;
  }
}

export default HistoryFileStatus;

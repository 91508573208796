import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';

const Canonical = props => {
  return (
    <CmtCard>
      <CmtCardHeader title={<IntlMessages id="bundles.urlViewer.subtitle.canonical" />} />
      <CmtCardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.hasCanonical" />
              </TableCell>
              <TableCell>
                {props.data.checks.has_canonical ? (
                  <Chip label={'Yes'} style={{ color: '#ffffff', backgroundColor: '#28a745' }} />
                ) : (
                  <Chip label={'No'} style={{ color: '#ffffff', backgroundColor: '#dc3545' }} />
                )}
              </TableCell>
            </TableRow>

            {props.data.checks.has_canonical && (
              <React.Fragment>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="bundles.urlViewer.text.canonicalStatus" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id={'bundles.urlViewer.text.canonicalStatus.' + props.data.content.canonical_status} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <IntlMessages id="bundles.urlViewer.text.canonicalUrl" />
                  </TableCell>
                  <TableCell>{props.data.content.canonical}</TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </CmtCardContent>
    </CmtCard>
  );
};

Canonical.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Canonical;

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 500,
  },
});

class MyTooltip extends React.Component {
  render() {
    return <Tooltip interactive {...this.props} />;
  }
}

export default withStyles(styles, { withTheme: true })(MyTooltip);

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import CmtCardFooter from '../../../../@coremat/CmtCard/CmtCardFooter';
import { handleCancelSubscription } from '../actions';
import { Link } from 'react-router-dom';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';
import { STRIPE_PRODUCTS } from '../../constants';

/**
 * This component properly displays a subscription card.
 */
const SubscriptionCard = props => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { authUser } = useSelector(({ auth }) => auth);

  const title =
    'canceled' !== props.subscription.status &&
    'incomplete_expired ' !== props.subscription.status &&
    'unpaid' !== props.subscription.status
      ? intl.formatMessage({ id: 'bundles.Subscription.text.currentPlan' })
      : intl.formatMessage({ id: 'bundles.Subscription.text.oldPlan' });

  let subtitle = null;
  switch (props.subscription.status) {
    case 'active':
    case 'incomplete':
    case 'past_due':
      subtitle =
        intl.formatMessage({ id: 'bundles.Subscription.text.startedOn' }) +
        ' ' +
        moment.unix(props.subscription.start_date).format('dddd, MMMM Do YYYY, h:mm:ss a');
      break;
    case 'canceled':
    case 'incomplete_expired':
    case 'unpaid':
      subtitle =
        intl.formatMessage({ id: 'bundles.Subscription.text.startedOn' }) +
        ' ' +
        moment.unix(props.subscription.start_date).format('dddd, MMMM Do YYYY, h:mm:ss a');
      subtitle +=
        ' / ' +
        intl.formatMessage({ id: 'bundles.Subscription.text.canceledOn' }) +
        ' ' +
        moment.unix(props.subscription.cancel_at).format('dddd, MMMM Do YYYY, h:mm:ss a');
      break;
    case 'trialing':
      subtitle =
        intl.formatMessage({ id: 'bundles.Subscription.text.trialEndOn' }) +
        ' ' +
        moment.unix(props.subscription.trial_end).format('dddd, MMMM Do YYYY, h:mm:ss a');
      break;
    default:
    //
  }

  // subscription price
  let subscriptionPrice = 0;
  props.subscription.items.data.map(item => {
    subscriptionPrice += item.price.unit_amount;

    return true;
  });

  // subscription price interval (monthly / yearly)
  let priceInterval = null;
  props.subscription.items.data.map(item => {
    'month' === item.price.recurring.interval
      ? (priceInterval = <IntlMessages id="bundles.Subscription.text.month" />)
      : (priceInterval = <IntlMessages id="bundles.Subscription.text.year" />);

    return true;
  });

  let priceCurrency = null;
  props.subscription.items.data.map(item => {
    priceCurrency = item.price.currency;

    return true;
  });

  const hasActions =
    'canceled' !== props.subscription.status &&
    'incomplete_expired ' !== props.subscription.status &&
    'unpaid' !== props.subscription.status
      ? true
      : false;

  return (
    <CmtCard>
      <CmtCardHeader title={title} subTitle={subtitle}>
        <SubscriptionStatusBadge subscription={props.subscription} />
      </CmtCardHeader>

      <CmtCardContent>
        <Box display="flex" alignItems="flex-end" mb={5}>
          <Box
            component="span"
            fontSize={{ xs: 36, md: 40, xl: 48 }}
            fontWeight="fontWeightBold"
            color="common.dark"
            lineHeight="1">
            {Intl.NumberFormat().format(subscriptionPrice / 100)} {priceCurrency}
          </Box>
          <Typography component="span" variant="subtitle1">
            /{priceInterval}
          </Typography>
        </Box>

        {props.subscription.items.data.map((item, i) => (
          <React.Fragment key={i}>
            <Typography variant="subtitle2" color="primary" component="p" gutterBottom={true}>
              <IntlMessages id={'bundles.Subscription.text.' + item.price.metadata.module} />
            </Typography>

            {STRIPE_PRODUCTS.map((product, j) => (
              <React.Fragment key={j}>
                {product.code === item.price.metadata.module && (
                  <Typography variant="body2" color="textSecondary" component="p">
                    {product.description(item.price)}
                  </Typography>
                )}
              </React.Fragment>
            ))}

            {i + 1 !== props.subscription.items.data.length && (
              <Box mt={3} mb={3}>
                <Divider light={true} />
              </Box>
            )}
          </React.Fragment>
        ))}
      </CmtCardContent>

      {hasActions && (
        <CmtCardFooter separator={{ color: '#bdbdbd', borderWidth: 1, borderStyle: 'solid' }}>
          <Box display="flex" alignItems="center" width={1}>
            <Box mr={4}>
              {'canceled' !== props.subscription.status &&
                'incomplete_expired ' !== props.subscription.status &&
                'unpaid' !== props.subscription.status && (
                  <Link to={'/products'}>
                    <Button variant="outlined" color="primary">
                      <IntlMessages id="bundles.Subscription.action.changePlan" />
                    </Button>
                  </Link>
                )}

              {'canceled' !== props.subscription.status &&
                'incomplete_expired ' !== props.subscription.status &&
                'unpaid' !== props.subscription.status && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: 14 }}
                    onClick={() => dispatch(handleCancelSubscription(authUser, props.subscription.id))}>
                    <IntlMessages id="bundles.subscription.action.cancel" />
                  </Button>
                )}
            </Box>
          </Box>
        </CmtCardFooter>
      )}
    </CmtCard>
  );
};

SubscriptionCard.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default React.memo(SubscriptionCard);

import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { UPDATE_SUBSCRIPTIONS_LIST } from '../constants';
import { getSubscriptionList, cancelSubscription } from 'services/stripe/StripeUtil';
import { UPDATE_PLAN_USER, UPDATE_SUBSCRIPTION_USER } from '@jumbo/constants/ActionTypes';
import { RESET_CHECKOUT } from '../../checkout/constants';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';

export const handleGetSubscriptions = customerId => {
  return dispatch => {
    dispatch(fetchStart());

    const payload = {
      limit: 100,
      /**
       * @see https://stripe.com/docs/api/subscriptions/list#list_subscriptions-status
       */
      status: 'all',
    };

    getSubscriptionList(customerId, payload)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_SUBSCRIPTIONS_LIST,
          payload: data,
        });
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const handleCancelSubscription = (authUser, subscriptionId) => {
  return dispatch => {
    dispatch(fetchStart());

    cancelSubscription(subscriptionId)
      .then(result => {
        dispatch({
          type: UPDATE_SUBSCRIPTION_USER,
          payload: null,
        });
        dispatch({
          type: UPDATE_PLAN_USER,
          payload: null,
        });
        dispatch({
          type: RESET_CHECKOUT,
        });

        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.subscription.notification.cancel.success', 'success'));
        dispatch(handleGetSubscriptions(authUser[STRIPE_CUSTOMER_ID_FIELD]));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };
};

export const SEO_TAGS_FIELDS = [
  {
    array: true,
    label: 'dimension.content.seo_tags.title',
    fields: [
      {
        dimension: 'checks_has_title',
        label: 'dimension.crawled_pages.checks_has_title',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content_title',
        label: 'dimension.crawled_pages.content_title',
        type: 'string',
        visible: true,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content_title_chars_count',
        label: 'dimension.crawled_pages.content_title_chars_count',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'contentTitleLengthString',
        label: 'dimension.crawled_pages.contentTitleLengthString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
    ],
  },
  {
    array: true,
    label: 'dimension.content.seo_tags.description',
    fields: [
      {
        dimension: 'checks_has_description',
        label: 'dimension.crawled_pages.checks_has_description',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content_description',
        label: 'dimension.crawled_pages.content_description',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content_description_chars_count',
        label: 'dimension.crawled_pages.content_description_chars_count',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'contentDescriptionLengthString',
        label: 'dimension.crawled_pages.contentDescriptionLengthString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
    ],
  },
  {
    array: true,
    label: 'dimension.content.seo_tags.h1',
    fields: [
      {
        dimension: 'checks_has_h1',
        label: 'dimension.crawled_pages.checks_has_h1',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content_h1',
        label: 'dimension.crawled_pages.content_h1',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content_h1_chars_count',
        label: 'dimension.crawled_pages.content_h1_chars_count',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'contentH1LengthString',
        label: 'dimension.crawled_pages.contentH1LengthString',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
    ],
  },
  {
    array: true,
    label: 'dimension.content.seo_tags.canonical',
    fields: [
      {
        dimension: 'checks_has_canonical',
        label: 'dimension.crawled_pages.checks_has_canonical',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content_canonical',
        label: 'dimension.crawled_pages.content_canonical',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
      {
        dimension: 'content_canonical_status',
        label: 'dimension.crawled_pages.content_canonical_status',
        type: 'string',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'checks_is_canonical_link_self',
        label: 'dimension.crawled_pages.checks_is_canonical_link_self',
        type: 'boolean',
        visible: false,
        filterable: true,
        filterComponent: 'AutomaticChoicesFilter',
      },
      {
        dimension: 'content_canonical_tags_count',
        label: 'dimension.crawled_pages.content_canonical_tags_count',
        type: 'number',
        visible: false,
        filterable: true,
        filterComponent: 'BasicFilter',
      },
    ],
  },
];

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError } from '../../../redux/actions';
import PageLoader from '../PageComponents/PageLoader';
import { Redirect } from 'react-router';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const ContentLoader = () => {
  const { error, loading, message, redirectTo } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchError(''));
    }, 3000);
  }, [dispatch, error, message]);

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      <NotificationContainer />
      {redirectTo && <Redirect to={redirectTo} />}
    </React.Fragment>
  );
};

export default ContentLoader;

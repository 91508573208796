import { tooltipLabelCalcPercentCallback } from 'bundles/dashboard/services/ChartJsCallbacks';

export const LOAD_TIME_BREAKDOWN = {
  '@id': 'LOAD_TIME_BREAKDOWN',
  name: 'bundles.crawl.chart.load_time_breakdown.title',
  description: null,
  x: 0,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['totalLoadTimeString'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [0, 0, 0, 0];
      let backgroundColor = ['#4caf50', '#ff9800', '#f44336', '#dc004e'];

      rows.map(arr => {
        if (arr['totalLoadTimeString'].includes('Too slow')) {
          data[3] = arr.count;
        } else if (arr['totalLoadTimeString'].includes('Slow')) {
          data[2] = arr.count;
        } else if (arr['totalLoadTimeString'].includes('Medium')) {
          data[1] = arr.count;
        } else if (arr['totalLoadTimeString'].includes('Perfect')) {
          data[0] = arr.count;
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      return ['Perfect (< 500ms)', 'Medium (500ms - 1s)', 'Slow (1s - 2s)', 'Too slow (> 2s)'];
    },
    getDataExplorerTableAndFilters: function(element, chartData) {
      const value = chartData.labels[element[0].index];

      return {
        schemaName: 'crawled_pages',
        columns: [
          'url',
          'category',
          'performance_navigation_totalLoadTime',
          'totalLoadTimeString',
          'response_firstStatusCode',
        ],
        filters: [
          {
            label: 'dimension.crawled_pages.totalLoadTimeString',
            dimension: 'totalLoadTimeString',
            operator: 'equals',
            values: [value],
          },
        ],
      };
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCalcPercentCallback,
          },
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

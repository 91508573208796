import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChangePasswordForm from '../form/ChangePasswordForm';
import { handleChangePassword } from '../actions';

const ChangePassword = props => {
  const { authUser } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(handleChangePassword(authUser.id, values.newPassword));
  };

  return (
    <ChangePasswordForm
      data={{
        newPassword: '',
        newPasswordConfirm: '',
      }}
      onSubmit={values => dispatch(onSubmit(values))}
    />
  );
};

export default ChangePassword;

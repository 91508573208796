import React, { useEffect, useRef, useContext } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Box, Grid, Button, Paper, Typography } from '@material-ui/core';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { useDispatch, useSelector } from 'react-redux';
import ListEmptyResult from '@coremat/CmtList/ListEmptyResult';
import { getPaymentMethodsList, getDefaultPaymentMethod2, handleShowAddCardForm } from '../actions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import DefaultPaymentMethodCard from '../components/DefaultPaymentMethodCard';
import PaymentModeList from '../components/PaymentModeList';
import CardForm from '../components/CardForm';
import BillingTabs from '../../components/BillingTabs';
import { STRIPE_CUSTOMER_ID_FIELD, STRIPE_PUBLIC_KEY } from '../../constants';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CardFormContainer = props => {
  const ref = React.createRef();
  const textInput = useRef(null);

  return (
    <Elements stripe={stripePromise}>
      <CardForm refa={ref} textInput={textInput} />
    </Elements>
  );
};

const PaymentModeIndex = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { defaultPaymentMethod, paymentMethodsList, showAddCardForm } = useSelector(({ paymentMethod }) => paymentMethod);
  const { setBreadcrumb } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumb([
      { text: <IntlMessages id="sidebar.billing" /> },
      { text: <IntlMessages id="bundles.PaymentMode.routes.index.title" /> },
    ]);

    dispatch(getPaymentMethodsList(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    dispatch(getDefaultPaymentMethod2(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={3}>
          <Typography variant="h1" gutterBottom>
            <IntlMessages id="bundles.PaymentMode.routes.index.title" />
          </Typography>

          <p>
            <IntlMessages id="bundles.PaymentMode.routes.index.subtitle" />
          </p>
        </Grid>

        <Grid item md={9}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item md={12}>
              <BillingTabs tabActive={'/billing/paymentMethods'} />
            </Grid>

            {defaultPaymentMethod !== null && defaultPaymentMethod.id && (
              <Grid item md={12}>
                <Box mb={6}>
                  <h3>
                    <IntlMessages id="bundles.PaymentMode.global.defaultMethod" />
                  </h3>
                </Box>

                <DefaultPaymentMethodCard paymentMethod={defaultPaymentMethod} />
              </Grid>
            )}

            <Grid item md={12}>
              {paymentMethodsList.length === 0 ? (
                <ListEmptyResult
                  title={<IntlMessages id="bundles.PaymentMode.emptyPrompt.title" />}
                  content={<IntlMessages id="bundles.PaymentMode.emptyPrompt.text" />}
                  actionTitle={<IntlMessages id="bundles.PaymentMode.action.addCard" />}
                  onClick={() => dispatch(handleShowAddCardForm(true))}
                />
              ) : (
                <Paper>
                  <PaymentModeList data={paymentMethodsList} defaultPaymentMethod={defaultPaymentMethod} />
                </Paper>
              )}
            </Grid>

            {paymentMethodsList.length > 0 && (
              <Grid item md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(handleShowAddCardForm(!showAddCardForm))}>
                  <IntlMessages id="bundles.PaymentMode.action.addCard" />
                </Button>
              </Grid>
            )}

            {true === showAddCardForm && (
              <React.Fragment>
                <Grid item md={12}>
                  <Paper>
                    <Box p={6}>
                      <CardFormContainer />
                    </Box>
                  </Paper>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentModeIndex;

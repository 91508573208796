export const EMPTY_DESCRIPTION = {
  '@id': 'EMPTY_DESCRIPTION',
  name: 'bundles.crawl.chart.empty_description.title',
  description: null,
  x: 6,
  y: 0,
  w: 6,
  h: 4,
  query: {
    table: 'crawled_pages',
    dimensions: [],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [
      {
        dimension: 'checks_is_html',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'checks_was_redirected',
        operator: 'equals',
        values: [false],
      },
      {
        dimension: 'checks_has_description',
        operator: 'equals',
        values: [true],
      },
      {
        dimension: 'content_description',
        operator: 'equals',
        values: [''],
      },
    ],
    hiddenFilters: [],
    aggregationFilters: [],
    order: null,
    orderBy: null,
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'number',
    getDataExplorerTableAndFilters: function(element, chartData) {
      return {
        schemaName: 'crawled_pages',
        columns: ['url', 'category', 'content_description', 'content_description_chars_count', 'response_firstStatusCode'],
        filters: [
          {
            label: 'dimension.crawled_pages.checks_is_html',
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.checks_was_redirected',
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
          {
            label: 'dimension.crawled_pages.checks_has_description',
            dimension: 'checks_has_description',
            operator: 'equals',
            values: [true],
          },
          {
            label: 'dimension.crawled_pages.content_description',
            dimension: 'content_description',
            operator: 'equals',
            values: [''],
          },
        ],
      };
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

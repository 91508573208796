import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { GRID_SPACING } from '@jumbo/constants/ThemeOptions';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { getUser } from 'services/api/MyApiUtil';
import NotificationSelectorForm from '../form/NotificationSelectorForm';
import { fetchError, fetchStart, fetchSuccess, showMessage } from '../../../redux/actions';
import { updateUser } from 'services/api/MyApiUtil';
import ProfileTabs from 'bundles/profile/components/ProfileTabs';

const NOTIFICATIONS_CONFIG_DEFAULT = {};

const NotificationSelectorIndex = props => {
  const { setBreadcrumb } = useContext(AppContext);

  const { authUser } = useSelector(({ auth }) => auth);
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStart());

    getUser(authUser.id)
      .then(data => {
        if (Array.isArray(data.notificationsConfig) && !data.notificationsConfig.length) {
          data.notificationsConfig = NOTIFICATIONS_CONFIG_DEFAULT;
        }

        setUser(data);
        dispatch(fetchSuccess());
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        text: <IntlMessages id="bundles.notificationSelector.routes.index.title" />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = values => {
    dispatch(fetchStart());

    let payload = {
      notificationsConfig: values.notificationsConfig ? values.notificationsConfig : null,
    };

    updateUser(authUser.id, payload)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(showMessage('bundles.notificationSelector.notification.update.success', 'success'));
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
  };

  return (
    <div className="app-wrapper">
      <Grid container spacing={GRID_SPACING}>
        <Grid item md={12}>
          <ProfileTabs tabActive={'/settings/notificationSelector'} />
        </Grid>

        <Grid item md={12}>
          <Typography variant="h1">
            <IntlMessages id="bundles.notificationSelector.routes.index.title" />
          </Typography>
        </Grid>

        <Grid item lg={12}>
          <Paper>
            <Box p={GRID_SPACING}>
              <Box mb={GRID_SPACING}>
                <IntlMessages id="bundles.notificationSelector.routes.index.description" />
              </Box>

              <NotificationSelectorForm
                data={{
                  notificationsConfig: user.notificationsConfig,
                }}
                onSubmit={values => dispatch(onSubmit(values))}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationSelectorIndex;

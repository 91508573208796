import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MyDrawer from 'components/Drawer';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import clsx from 'clsx';

import { CHART_TYPES } from '../../bundles/logAnalyzer/Visualization/constants';
import VisualizationConfigurator from '../../bundles/logAnalyzer/Visualization/components/VisualizationConfigurator';

const useStyles = makeStyles(theme => ({
  iconButton: {
    backgroundColor: fade(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.main,
    '&:hover,&.active': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}));

const ChooseVisualization = props => {
  const classes = useStyles();
  const intl = useIntl();
  const [openForm, setOpenForm] = useState(false);

  const handleCancel = () => {
    props.handleCancel(props.name);

    setOpenForm(false);
  };

  const handleOpenForm = (type, defaultOptions) => {
    props.handleChange(type, defaultOptions);

    //setOpenForm(true);
  };

  const handleCloseForm = type => {
    setOpenForm(false);
  };

  return (
    <MyDrawer
      anchor={'left'}
      open={props.open}
      withHeader={true}
      headerTitle={intl.formatMessage({ id: props.title })}
      handleClose={() => {
        handleCancel();
      }}>
      <div>
        {true === openForm ? (
          <VisualizationConfigurator
            visualization={props.visualization}
            handleCancel={handleCloseForm}
            handleChange={props.handleChange}
          />
        ) : (
          <Box mb={6}>
            <Box p={6}>
              <h3>
                <IntlMessages id={'bundles.dataExplorer.visualization.text.chooseVisualization'} />
              </h3>
            </Box>

            <Box p={6} pt={0}>
              <Grid container alignItems="flex-start" item spacing={6}>
                {CHART_TYPES.map((obj, i) => (
                  <Grid key={i} item md={3}>
                    <p>
                      <IconButton
                        className={
                          obj.type === props.visualization.type ? clsx(classes.iconButton, 'active') : classes.iconButton
                        }
                        disabled={obj.disabled(props)}
                        aria-label={obj.type}
                        onClick={() => {
                          handleOpenForm(obj.type, obj.defaultOptions);
                        }}>
                        {obj.icon}
                      </IconButton>
                    </p>

                    <p align={'center'} className={'mb-0'}>
                      {obj.type}
                    </p>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box align={'center'} className={'mb-0'}>
              <Box mr={3} clone>
                <Button
                  autoFocus
                  onClick={() => {
                    handleCancel();
                  }}
                  color="primary">
                  <IntlMessages id="generic.text.cancel" />
                </Button>
              </Box>

              <Button
                //onClick={() => { this.props.handleOk(this.props.name, this.state.values); }}
                color="primary"
                variant="contained">
                <IntlMessages id="generic.text.save" />
              </Button>
            </Box>
          </Box>
        )}
      </div>
    </MyDrawer>
  );
};

ChooseVisualization.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  visualization: PropTypes.object,
  handleCancel: PropTypes.func,
  handleChange: PropTypes.func,
};

export default ChooseVisualization;

import React, { useContext, useEffect } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import ProfileEdit from '../components/ProfileEdit';
import { Box, Paper, Typography } from '@material-ui/core';

const ProfileEditPage = () => {
  const { setBreadcrumb } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumb([
      {
        to: '/profile',
        text: <IntlMessages id="sidebar.profile" />,
      },
      { text: <IntlMessages id="bundles.profile.routes.edit.title" /> },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-wrapper">
      <Box mb={6}>
        <Typography variant="h1">
          <IntlMessages id="bundles.profile.routes.edit.title" />
        </Typography>
      </Box>

      <Paper>
        <Box p={6}>
          <ProfileEdit />
        </Box>
      </Paper>
    </div>
  );
};

export default ProfileEditPage;

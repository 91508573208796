import { GET_CRAWL, RESET_CRAWLS } from '../constants';

const INIT_STATE = {
  crawl: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CRAWL: {
      return {
        ...state,
        crawl: action.payload,
      };
    }
    case RESET_CRAWLS: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Formik } from 'formik';
import { handleGetCustomer, handleUpdateCustomer } from '../actions';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';
import { getCountryList } from 'services/api/MyApiUtil';
import { getTaxIdList } from 'services/stripe/StripeUtil';

const MyBackButton = ({ history: { goBack }, children, ...props }) => (
  <Button variant={props.variant} color={props.color} onClick={goBack}>
    {children}
  </Button>
);

const BackButton = withRouter(MyBackButton);

/**
 * address.state
 *
 * @see https://stripe.com/docs/api/customers/update
 */
const CustomerForm = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { customer } = useSelector(({ shipping }) => shipping);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);

  const [vatNumber, setVatNumber] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getCountries();
    getVatNumber();

    dispatch(handleGetCustomer(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customer.address.country) {
      const result = countries.find(obj => obj.value === customer.address.country);

      if (result) {
        setCountry({
          value: result.value,
          label: result.label,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handleSubmit = values => {
    dispatch(handleUpdateCustomer(authUser[STRIPE_CUSTOMER_ID_FIELD], values, props.onSuccess || null));
  };

  const getVatNumber = async () => {
    const taxIds = await getTaxIdList(authUser[STRIPE_CUSTOMER_ID_FIELD]);

    if (taxIds.data.length > 0) {
      setVatNumber(taxIds.data[0].value);
    }
  };

  const getCountries = async inputValue => {
    let options = [];

    const data = await getCountryList();

    for (let i = 0; i < data['hydra:member'].length; i++) {
      options.push({
        label: data['hydra:member'][i]['name'],
        value: data['hydra:member'][i]['code'],
      });
    }

    setCountries(options);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={customer}
      //validate={values => myFunction(values)}
      onSubmit={values => handleSubmit(values)}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.name" />
              </InputLabel>
              <TextField
                id="name"
                name="name"
                placeholder={'John'}
                value={values.name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.address.line1" />
              </InputLabel>
              <TextField
                id="address"
                name="address[line1]"
                placeholder={''}
                value={values.address.line1 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.address.line2" />
              </InputLabel>
              <TextField
                id="address2"
                name="address[line2]"
                placeholder={''}
                value={values.address.line2 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.city" />
              </InputLabel>
              <TextField
                id="city"
                name="address[city]"
                placeholder={''}
                value={values.address.city || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.postal_code" />
              </InputLabel>
              <TextField
                id="postal_code"
                name="address[postal_code]"
                placeholder={''}
                value={values.address.postal_code || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.country" />
              </InputLabel>
              <Select
                id="country"
                name="address[country]"
                value={country}
                options={countries}
                onChange={(newValue, actionMeta) => {
                  setCountry(newValue);
                  setFieldValue('address[country]', newValue ? newValue.value : null, false);
                }}
                pageSize={500}
                isClearable={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.vatNumber" />
              </InputLabel>
              <TextField
                id="vatNumber"
                name="vatNumber"
                placeholder={''}
                value={vatNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <IntlMessages id="bundles.shipping.form.field.label.phone" />
              </InputLabel>
              <TextField
                id="phone"
                name="phone"
                placeholder={''}
                value={values.phone || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              {props.handleCancel ? (
                <Button variant="outlined" color="primary" onClick={props.handleCancel}>
                  <IntlMessages id="bundles.shipping.action.cancel" />
                </Button>
              ) : (
                <BackButton variant="outlined" color="primary">
                  <IntlMessages id="bundles.shipping.action.cancel" />
                </BackButton>
              )}

              <Box ml={6} clone>
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id={props.btnSubmitText || 'bundles.shipping.action.save'} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CustomerForm;

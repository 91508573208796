import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import MyTooltip from 'components/Tooltip';
import CrawlStatusBadge from 'bundles/crawl/components/CrawlStatusBadge';

import { getLatestCrawl } from 'services/api/MyApiUtil';
import GenericActionDisabledTrialPeriodExpired from './TooltipContent/GenericActionDisabledTrialPeriodExpired';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

/**
 * Returns the data freshness status with a colored circle
 *
 * @param {string} color grey|success|warning
 * @returns {*}
 */
const Badge = ({ color }) => {
  return <i className={`zmdi zmdi-circle jr-fs-sm text-${color} mr-2`} />;
};

/**
 *
 * @param {string} title
 * @returns {*}
 */
const DisabledAction = ({ title }) => {
  return (
    <MyTooltip title={<GenericActionDisabledTrialPeriodExpired />}>
      <span>
        <Button disabled={true}>
          {title}
          <HelpIcon fontSize={'small'} className={'ml-1'} />
        </Button>
      </span>
    </MyTooltip>
  );
};

const useStyles = makeStyles(theme => ({
  lightText: {
    color: theme.palette.lightBtn.textColor,
  },
}));

const CrawlHasDataBadge = props => {
  const classes = useStyles();
  const { isActiveSubscription } = useSelector(({ auth }) => auth);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const refresh = async () => {
    getLatestCrawl(props.id).then(result => {
      setData(result);
      setLoading(true);
    });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return (
      <div>
        <Badge color={'grey'} />
        <IntlMessages id="bundles.project.global.noData" />

        <p className={'mb-0'}>
          {!isActiveSubscription ? (
            <DisabledAction title={<IntlMessages id="bundles.crawl.action.create" />} />
          ) : (
            <Link to={`/crawl_profiles/create?projectId=${props.id}`}>
              <IntlMessages id="bundles.crawl.action.create" />
            </Link>
          )}
        </p>
      </div>
    );
  }

  let badge = null;

  switch (data.status) {
    case 'pending':
    case 'running':
    case 'finished':
    case 'stopped':
    case 'failed':
      badge = (
        <CrawlStatusBadge
          clickable={true}
          id={data.id}
          status={data.status}
          projectId={parseInt(data.website.split('/').pop())}
        />
      );
      break;
    default:
      throw new Error('unknown `data.status` value');
  }

  let date = null;

  switch (data.status) {
    case 'pending':
      date = (
        <Typography className={classes.lightText} variant={'body2'}>
          {data.createdAt && moment(data.createdAt).fromNow()}
        </Typography>
      );
      break;

    case 'running':
      date = (
        <Typography className={classes.lightText} variant={'body2'}>
          <IntlMessages id="bundles.project.global.lastDataReceived" /> {data.updatedAt && moment(data.updatedAt).fromNow()}
        </Typography>
      );
      break;

    case 'finished':
      date = (
        <Typography className={classes.lightText} variant={'body2'}>
          {data.crawlEndedAt && moment(data.crawlEndedAt).fromNow()}
        </Typography>
      );
      break;

    case 'stopped':
      date = (
        <Typography className={classes.lightText} variant={'body2'}>
          {data.crawlEndedAt && moment(data.crawlEndedAt).fromNow()}
        </Typography>
      );
      break;

    case 'failed':
      date = (
        <Typography className={classes.lightText} variant={'body2'}>
          {data.updatedAt && moment(data.updatedAt).fromNow()}
        </Typography>
      );
      break;

    default:
      throw new Error('unknown `data.status` value');
  }

  return (
    <div>
      {badge}
      {date}{' '}
      <Link
        to={`/dashboards/view/summary?predefined=1&module=crawled_pages&projectId=${parseInt(
          data.website.split('/').pop(),
        )}&crawlId=${data.id}`}>
        <IntlMessages id="bundles.project.global.dashboard" />
      </Link>
    </div>
  );
};

CrawlHasDataBadge.propTypes = {
  id: PropTypes.number,
};

export default CrawlHasDataBadge;

import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chip: {
    //
  },
});

/**
 * This component properly displays the status of a subscription.
 */
const SubscriptionStatusBadge = props => {
  const classes = useStyles();

  let style = null;
  let label = null;

  /**
   * incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
   */
  switch (props.subscription.status) {
    case 'incomplete':
      style = { color: '#721c24', backgroundColor: '#f8d7da' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.incomplete" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.incomplete.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'incomplete_expired':
      style = { color: '#721c24', backgroundColor: '#f8d7da' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.incomplete_expired" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.incomplete_expired.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'trialing':
      style = { color: '#856404', backgroundColor: '#fff3cd' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.trialing" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.trialing.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'active':
      style = { color: '#155724', backgroundColor: '#d4edda' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.active" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.active.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'past_due':
      style = { color: '#721c24', backgroundColor: '#f8d7da' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.past_due" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.past_due.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'canceled':
      style = { color: '#721c24', backgroundColor: '#f8d7da' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.canceled" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.canceled.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    case 'unpaid':
      style = { color: '#721c24', backgroundColor: '#f8d7da' };
      label = <IntlMessages id="bundles.Subscription.entity.subscription.status.unpaid" />;

      return (
        <Tooltip title={<IntlMessages id="bundles.Subscription.entity.subscription.status.unpaid.help" />}>
          <div>
            <Chip className={classes.chip} component={'span'} clickable={false} label={label} style={style} />
          </div>
        </Tooltip>
      );

    default:
      throw new Error('unknown `props.subscription.status` value');
  }
};

SubscriptionStatusBadge.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default React.memo(SubscriptionStatusBadge);

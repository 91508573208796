import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import IntlMessages from '@jumbo/utils/IntlMessages';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const Ssl = props => {
  return (
    <CmtCard>
      <CmtCardHeader title={`SSL`} />
      <CmtCardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.ssl.subjectName" />
              </TableCell>
              <TableCell>{props.data.response.ssl.subjectName}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.ssl.issuer" />
              </TableCell>
              <TableCell>{props.data.response.ssl.issuer}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.ssl.validFrom" />
              </TableCell>
              <TableCell>{moment(props.data.response.ssl.validFrom * 1000).format('L')}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.ssl.validTo" />
              </TableCell>
              <TableCell>{moment(props.data.response.ssl.validTo * 1000).format('L')}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <IntlMessages id="bundles.urlViewer.text.ssl.protocol" />
              </TableCell>
              <TableCell>{props.data.response.ssl.protocol}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CmtCardContent>
    </CmtCard>
  );
};

Ssl.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Ssl;

import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Grid, Paper } from '@material-ui/core';

class DefaultPaymentMethodCard extends React.Component {
  render() {
    if (null === this.props.paymentMethod || !this.props.paymentMethod.id) {
      return null;
    }

    return (
      <Paper>
        <Box p={6}>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={6}>
            <Grid item>
              <img
                alt={this.props.paymentMethod.card.brand}
                src={'/images/cards/' + this.props.paymentMethod.card.brand + '.png'}
                width={'64px'}
              />
            </Grid>
            <Grid item>
              {this.props.paymentMethod.card.brand.charAt(0).toUpperCase()}
              {this.props.paymentMethod.card.brand.slice(1)}&nbsp;
              <IntlMessages id="bundles.PaymentMode.global.endingWith" />
              &nbsp;
              {this.props.paymentMethod.card.last4}
              <br />
              expires on{' '}
              {this.props.paymentMethod.card.exp_month < 10
                ? '0' + this.props.paymentMethod.card.exp_month
                : this.props.paymentMethod.card.exp_month}
              /{this.props.paymentMethod.card.exp_year}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}

export default DefaultPaymentMethodCard;

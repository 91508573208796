import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import CmtImage from '@coremat/CmtImage';

import IntlMessages from '@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';

import { AuhMethods } from 'services/auth';
import { NavLink } from 'react-router-dom';

import ChangePasswordForm from '../form/ChangePasswordForm';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
}));
//variant = 'default', 'standard', 'bgColor'
const ChangePassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default', match }) => {
  const dispatch = useDispatch();

  const classes = useStyles({ variant });

  const onSubmit = values => {
    const token = match.params.token;
    const newPassword = values.newPassword;
    const newPasswordConfirm = values.newPasswordConfirm;

    dispatch(
      AuhMethods[method].onChangePassword({
        token,
        newPassword,
        newPasswordConfirm,
      }),
    );
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <NavLink to="/">
            <CmtImage src={'/images/auth/forgot-img.png'} />
          </NavLink>
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <NavLink to="/">
            <CmtImage src={'/images/logo.png'} />
          </NavLink>
        </Box>

        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="bundles.resetting.changePassword.title" />
        </Typography>

        <ChangePasswordForm
          data={{ passwordNew: '', passwordNewConfirm: '' }}
          onSubmit={values => dispatch(onSubmit(values))}
        />

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ChangePassword;

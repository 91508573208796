import React, { useEffect } from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { getInvoicesList } from '../actions';
import { STRIPE_CUSTOMER_ID_FIELD } from '../../constants';
import InvoiceListTableRow from './InvoiceListTableRow';

const InvoiceList = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { invoicesList } = useSelector(({ invoice }) => invoice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoicesList(authUser[STRIPE_CUSTOMER_ID_FIELD]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '15%' }}>
            <IntlMessages id="bundles.Invoice.entity.invoice.number.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.Invoice.entity.invoice.date.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.Invoice.entity.invoice.amount_paid.short" />
          </TableCell>
          <TableCell>
            <IntlMessages id="bundles.Invoice.entity.invoice.status.short" />
          </TableCell>
          <TableCell align={'right'}>
            <IntlMessages id="bundles.Invoice.global.actions" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoicesList.map(entity => (
          <InvoiceListTableRow key={entity.id} invoice={entity} />
        ))}
      </TableBody>
    </Table>
  );
};

export default InvoiceList;

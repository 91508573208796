export const CODES_BREAKDOWN = {
  '@id': 'CODES_BREAKDOWN',
  name: 'bundles.crawl.chart.codes_breakdown.title',
  description: null,
  x: 0,
  y: 4,
  w: 6,
  h: 8,
  query: {
    table: 'crawled_pages',
    dimensions: ['response_firstStatusCode'],
    dimensionsFunctions: [],
    timeDimensions: {
      dateRange: [null, null],
    },
    measures: ['count'],
    filters: [],
    hiddenFilters: [],
    aggregationFilters: [],
    order: 'asc',
    orderBy: 'response_firstStatusCode',
    limit: null,
    offset: null,
    ungrouped: 0,
  },
  visualization: {
    type: 'pie',
    colorFunction: 'getThemeColor',
    getDatasetsCallback: function(rows) {
      let datasets = {};
      let data = [];
      let backgroundColor = [];

      let labels = [];

      rows.map(arr => {
        labels.push(arr.response_firstStatusCode ? arr.response_firstStatusCode : 'Unknown');

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      labels.map(label => {
        data.push(0);
        backgroundColor.push('');

        return true;
      });

      rows.map(arr => {
        const iLabel = labels.indexOf(arr.response_firstStatusCode ? arr.response_firstStatusCode : 'Unknown');

        data[iLabel] = arr.count;

        if (null === arr.response_firstStatusCode) {
          backgroundColor[iLabel] = '#323232';
        } else if (arr.response_firstStatusCode < 300) {
          backgroundColor[iLabel] = '#4caf50';
        } else if (arr.response_firstStatusCode < 400) {
          backgroundColor[iLabel] = '#ff9800';
        } else if (arr.response_firstStatusCode < 500) {
          backgroundColor[iLabel] = '#f44336';
        } else {
          backgroundColor[iLabel] = '#dc004e';
        }

        return true;
      });

      datasets = [
        {
          data: data,
          backgroundColor: backgroundColor,
        },
      ];

      return datasets;
    },
    getLabelsCallback: function(rows) {
      let labels = [];

      rows.map(arr => {
        labels.push(arr.response_firstStatusCode ? arr.response_firstStatusCode : 'Unknown');

        return true;
      });

      labels = labels.filter((v, i, a) => a.indexOf(v) === i);

      return labels;
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          align: 'center',
        },
      },
    },
    containerOptions: {
      style: {
        height: 280,
      },
    },
  },
  project: '/api/projects/131',
  groupedQuestions: null,
};

import React, { useEffect } from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { Box } from '@material-ui/core';

const ChordDiagram = props => {
  useEffect(() => {
    Promise.all([
      import('@amcharts/amcharts4/core'),
      import('@amcharts/amcharts4/charts'),
      import('@amcharts/amcharts4/themes/animated'),
    ])
      .then(modules => {
        const am4core = modules[0];
        const am4charts = modules[1];
        const am4themes_animated = modules[2].default;

        am4core.useTheme(am4themes_animated);

        // Chart code goes here
        let chart = am4core.create('chartdiv', am4charts.ChordDiagram);

        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = [];

        if (
          props.rows.hasOwnProperty(props.id) &&
          props.visualization.hasOwnProperty('getDatasetsCallback') &&
          props.visualization.getDatasetsCallback
        ) {
          chart.data = props.visualization.getDatasetsCallback(props.rows[props.id]);
        }

        let hoverState = chart.links.template.states.create('hover');
        hoverState.properties.fillOpacity = 0.6;

        chart.dataFields.fromName = 'from';
        chart.dataFields.toName = 'to';
        chart.dataFields.value = 'value';

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'left';
      })
      .catch(e => {
        console.error('Error when creating chart', e);
      });
    //setChart(chart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows[props.id]]);

  return (
    <Card className="shadow border-0 mb-0">
      <CardBody>
        <CardTitle>{props.title}</CardTitle>
        <CardText>
          <Box mb={6}>
            <div id="chartdiv" style={props.visualization.containerOptions && props.visualization.containerOptions.style} />
          </Box>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default ChordDiagram;

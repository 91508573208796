export const CATEGORY = {
  '@id': 'category',
  id: 'category',
  name: 'Pages by category',
  description: null,
  project: '/api/projects/131',
  questions: [
    {
      '@id': 'crawl_page_category',
      name: 'Category',
      description: null,
      x: 0,
      y: 0,
      w: 12,
      h: 20,
      query: {
        table: 'crawled_pages',
        dimensions: ['category'],
        dimensionsFunctions: [],
        timeDimensions: {
          dateRange: [null, null],
        },
        measures: ['count', 'percent_over_total'],
        filters: [
          /*
          {
            dimension: 'checks_is_html',
            operator: 'equals',
            values: [true],
          },
          {
            dimension: 'checks_was_redirected',
            operator: 'equals',
            values: [false],
          },
          {
            dimension: 'checks_has_title',
            operator: 'equals',
            values: [false],
          },
             */
        ],
        hiddenFilters: [],
        aggregationFilters: [],
        order: 'desc',
        orderBy: 'count',
        limit: null,
        offset: null,
        ungrouped: 0,
      },
      visualization: {
        type: 'table',
        colorFunction: 'getThemeColor',
        options: {
          maintainAspectRatio: false,
          indexAxis: 'y',
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: true,
            },
          },
        },
        extra: {
          stacked: false,
          labelDimension: 'category',
          datasetsDimension: 'category',
          multipleDatasets: true,
        },
      },
      project: '/api/projects/131',
      groupedQuestions: null,
    },
  ],
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStart, fetchSuccess, fetchError } from 'redux/actions';
import { getUser } from 'services/api/MyApiUtil';
import ProfileForm from '../form/ProfileForm';
import { handleUpdateProfile } from '../actions';

const ProfileEdit = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStart());

    getUser(authUser.id)
      .then(data => {
        setUser(data);
        dispatch(fetchSuccess());
      })
      .catch(function(error) {
        dispatch(fetchError(error.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = values => {
    dispatch(handleUpdateProfile(authUser.id, values));
  };

  return (
    <ProfileForm
      data={{
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        emailBilling: user.emailBilling || '',
        country: user.country || '',
      }}
      onSubmit={values => dispatch(onSubmit(values))}
    />
  );
};

export default ProfileEdit;
